import {PageTemplate} from "../bananaframework/src/controls/PageTemplate.js";
import {Panel} from "../bananaframework/src/controls/Panel.js";
import {Label} from "../bananaframework/src/controls/Label.js";
import {Image} from "../bananaframework/src/controls/Image.js";
import {InputControl} from "../bananaframework/src/controls/InputControl.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";
import {Iframe} from "../components/various.js";

import {ManiaPreferences} from "../util/preferences.js";
import {ThemeManager} from "../util/theme.js";
import {Router} from "../util/router.js";
import {Subscriber} from "../util/subscriber.js";
import {DataProvider} from "../data/dataprovider.js";
import {Favorites} from "../util/favorites.js";
import {ManiaCache} from "../util/cache.js";
import {Socializer} from "../util/socializer.js";
import {Localizer} from "../util/localizer.js";

import {LanguageSelectModal} from "../components/news/languageselectmodal.js";
import {ManiaAds} from "../util/ads.js";
import {MatchItemRender} from "../components/home/listrendershome.js";
import {MatchUtil} from "../util/matchutil.js";

export class ManiaTemplate extends PageTemplate {

    init() {
        console.log("STARTING APPLICATION, Developed by Gillis Haasnoot (gillis.haasnoot@gmail.com)")
        super.init();
        var currentPageName;

        var topBar = new Panel();
        topBar.tagName = "header";
        topBar.addCssClass("topBar");
        this.addControl(topBar);

        var topBarTopContainer = new Panel();
        topBarTopContainer.addCssClass("topBarTopContainer");
        topBar.addControl(topBarTopContainer);

        var topTitleContainer = new Panel();
        topTitleContainer.addCssClass("flexHorizontalAlign topBarTopTitleContainer");
        topBar.addControl(topTitleContainer);
        this.topTitleContainer = topTitleContainer;

        // var offlineIndicator = new Panel();
        // offlineIndicator.setStyle("height:20px; width:100%; background-color:red;")
        // topBar.addControl(offlineIndicator);

        var topLogoContainer = new Panel();
        topLogoContainer.addCssClass("topLogoContainer");
        topBarTopContainer.addControl(topLogoContainer);
        topLogoContainer.bind("click", () => {
            this.getPage().ignoreResizeEvents = true; //ignore resize events to prevent flickering;
            let isFromHome = currentPageName == "Home";
            console.log("click home ",currentPageName);
            if (FootballManiaConfig.isIphone) {
                console.log("back", currentPageName)
                if (currentPageName != "Home"
                    && (!Router.isNavigatedFromPush() && history.length > 1)
                ) {
                    history.back();
                } else {
                    Router.showHome(this,isFromHome);
                }
            } else {
                Router.showHome(this,isFromHome);
            }
        });

        var topLogo = new Panel();
        this.topLogo = topLogo;

        if (FootballManiaConfig.isIphone) {
            topLogo.addCssClass("topLogoIOS");
        } else {
            topLogo.addCssClass("topLogo");
        }
        topLogo.setAttribute("alt", "App logo");
        topLogoContainer.addControl(topLogo);

        var menuItems = new Panel();
        menuItems.addCssClass("menuitemContainer")
        this.menuItems = menuItems;

        var search = new Panel();
        search.addCssClass("topBarSearchContainer")
        search.setVisible(false);

        var searchBackArrow = new Panel();
        searchBackArrow.addCssClass("menu-icon icon-back")
        searchBackArrow.setStyle("margin-inline-start:10px;margin-inline-end:30px;")

        var searchResultsCon = new Panel();
        searchResultsCon.addCssClass("topBarSearchResultsContainer")

        var fade = new Panel();
        fade.addCssClass("topBarSearchResultsFade")
        fade.setStyle("height:1000px;width:100%;background-color:#090c11db;position:fixed;left:0;");
        searchResultsCon.addControl(fade);
        fade.bind("click", (e) => {
            this.deActivateSearch();
        });
        fade.bind("touchmove", (e) => {
            e.preventDefault();
            e.stopPropagation();
        });

        var searchResult = new Panel();
        searchResult.addCssClass("topBarSearchResults")
        this.searchResults = searchResult;
        searchResultsCon.addControl(searchResult);

        var searchInput = new InputControl();
        search.addControl(searchBackArrow);
        search.addControl(searchInput);
        search.addControl(searchResultsCon);

        this.search = search;
        //menuItems.addControl("version "+FootballManiaConfig.clientVersion);

        var offlineIcon = new Panel();
        this.offlineIcon = offlineIcon;
        topBarTopContainer.addControl(offlineIcon);
        topBarTopContainer.addControl(menuItems);
        topBarTopContainer.addControl(search);

        if (!AppInstance.isOnline()) {
            this.onOffline();
        }

        this.topBar = topBar;
        this.topLogoContainer = topLogoContainer;
        if (FootballManiaConfig.isWebStandalone) {
            topBarTopContainer.addControl("<p style='position:absolute;top:0;right:0;font-size:7px;'>build " + FootballManiaConfig.clientVersion + "</p>", true);
        }

        if (localStorage.getItem("clientVersion") != FootballManiaConfig.clientVersion) {
            this.showFakeLoader(FootballManiaConfig.clientVersion);
            localStorage.setItem("clientVersion", FootballManiaConfig.clientVersion);
        }

        this.hideTitle();

        this.bind("preLoadPage", (e, page) => {
            currentPageName = page;
            if (FootballManiaConfig.isIphone) {
                if (page == "Home") {
                    topLogo.setVisible(false);
                } else {
                    topLogo.setVisible(true);
                }
            }
        })

        if (AppInstance.useWebAds()) {
            var bottom = new Iframe();
            bottom.addCssClass("stickybanner")
            bottom.setAttribute("frameborder", "0");
            this.addControl(bottom, true)
            this.stickyBannerTargetContainer = bottom;
            this.stickyBannerTargetContainer.setVisible(false);
        }
    }

    hideStickBannerAd() {
        if (this.stickyBannerTargetContainer) {
            this.stickyBannerTargetContainer.setVisible(false);
            this.pageControl.triggerEvent("stickyBannerHide");
        }
    }

    loadStickyBannerAd() {
        if (!this.stickyBannerTargetContainer) {
            console.error("cannot load sticky banner ad without container");
            return;
        }
        ManiaAds.loadStickyBanner(this.stickyBannerTargetContainer, (error) => {
            if (error) {
                //this.stickyBannerTargetContainer.setVisible(false);
                console.warn("error loading sticky banner", error);
                ManiaAds.loadHouseAd(this.stickyBannerTargetContainer, (error) => {
                    if (error) {
                        this.stickyBannerTargetContainer.setVisible(false);
                        console.log("house ad failed");
                        this.pageControl.triggerEvent("stickyBannerLoadFail");
                    } else {
                        this.stickyBannerTargetContainer.setVisible(true);
                        console.log("house ad loaded");
                        this.pageControl.triggerEvent("stickyBannerLoadSuccess");
                    }
                });
            } else {
                this.stickyBannerTargetContainer.setVisible(true);
                console.log("custom ad loaded", this.pageControl);
                this.pageControl.triggerEvent("stickyBannerLoadSuccess");
            }
        })
    }

    showTitle() {
        this.topTitleContainer.clear();
        this.topTitleContainer.setVisible(true);
    }

    hideTitle() {
        this.topTitleContainer.clear();
        this.topTitleContainer.setVisible(false);
    }

    onOffline() {
        this.offlineIcon.addCssClass("icon icon-offline");
    }

    onOnline() {
        this.offlineIcon.removeCssClass("icon icon-offline");
    }

    // recreateLastMenu() {
    //     if (lastCreatedMenu == "Home") {
    //         this.createMenuHome(true);
    //     } else if (lastCreatedMenu == "Team") {
    //         this.createMenuTeam();
    //     } else if (lastCreatedMenu == "League") {
    //         this.createMenuLeague();
    //     } else if (lastCreatedMenu == "Match") {
    //         this.createMenuMatch();
    //     } else if (lastCreatedMenu == "Player") {
    //         this.createMenuPlayer();
    //     } else if (lastCreatedMenu == "News") {
    //         this.createMenuNews();
    //     } else if (lastCreatedMenu == "League") {
    //         this.createMenuLeague();
    //     } else if (lastCreatedMenu == "CountryLeagues") {
    //         this.createMenuCountryLeagues();
    //     } else if (lastCreatedMenu == "ForumChat") {
    //         this.createMenuForumChat();
    //     } else if (lastCreatedMenu == "TopVoters") {
    //         this.createMenuTopVoters();
    //     } else if (lastCreatedMenu == "ForumIndex") {
    //         this.createMenuForumIndex();
    //     } else if (lastCreatedMenu == "FifaRanking") {
    //         this.createMenuFifaRanking();
    //     }
    // }

    createMenuHome(showFilters, showIosBackButton) {
        this.lastCreatedMenu = "Home";
        this.deActivateSearch();
        this.menuItems.clear();

        var search = new Panel();
        search.addCssClass("menu-icon icon-search")

        if (AppInstance.isOnline()) {
            this.menuItems.addControl(search, true);
        }

        search.bind("click", () => {
            this.activateSearch();
        });

        if (showFilters) {

            if (AppInstance.getConfig().enabledFeatures.teamImages) {
                var listType = new Panel();
                //filterFavorites.setStyle("width:32px;height:32px;")
                listType.addCssClass("menu-icon icon-listtype")
                this.menuItems.addControl(listType, true);

                listType.bind("click", () => {
                    listType.addCssClass("toggleRotate");
                    setTimeout(()=>{
                        listType.removeCssClass("toggleRotate");
                    },400);
                    var currentType = ManiaPreferences.getMatchListType();
                    if (currentType == "1") {
                        ManiaPreferences.setMatchListType("2");
                    } else if (currentType == "2") {
                        ManiaPreferences.setMatchListType("3");
                    } else {
                        ManiaPreferences.setMatchListType("1");
                    }
                });
            }

            var filterFavorites = new Panel();
            //filterFavorites.setStyle("width:32px;height:32px;")
            filterFavorites.addCssClass("menu-icon icon-filter-favorites")
            this.menuItems.addControl(filterFavorites, true);

            filterFavorites.bind("click", () => {
                let set = ManiaPreferences.getShowOnlyFavoritesAgenda();
                if (set) {
                    ManiaPreferences.setShowOnlyFavoritesAgenda(false);
                    filterFavorites.removeCssClass("icon-filter-favorites-active")
                } else {
                    ManiaPreferences.setShowOnlyFavoritesAgenda(true);
                    filterFavorites.addCssClass("icon-filter-favorites-active")
                }
            });

            let set = ManiaPreferences.getShowOnlyFavoritesAgenda();
            if (set) {
                filterFavorites.addCssClass("icon-filter-favorites-active")
            } else {
                filterFavorites.removeCssClass("icon-filter-favorites-active")
            }
        }

        if (FootballManiaConfig.isAndroid) {
            var settings = new Panel();
            settings.addCssClass("menu-icon icon-settings")
            settings.bind("click", function () {
                Router.showSettings();
            })
            this.menuItems.addControl(settings, true);
        } else if (FootballManiaConfig.isIphone || FootballManiaConfig.isWebStandalone) {
            var darkmode = new Panel();
            darkmode.addCssClass("menu-icon icon-darkmode")
            this.menuItems.addControl(darkmode, true);
            darkmode.bind("click", function () {
                if (ThemeManager.getCurrentTheme() == "dark") {
                    ThemeManager.applyTheme("light");
                } else if (ThemeManager.getCurrentTheme() == "light") {
                    ThemeManager.applyTheme("blue");
                } else {
                    ThemeManager.applyTheme("dark");
                }
            })

            if (FootballManiaConfig.isIphone) {
                if (showIosBackButton) {
                    this.topLogo.setVisible(true);
                } else {
                    this.topLogo.setVisible(false);
                }
            }
        }
        // else if (FootballManiaConfig.isIphone){
        //     if (showIosBackButton){
        //         this.topLogo.setVisible(true);
        //     }
        //     else{
        //         this.topLogo.setVisible(false);
        //     }
        // }
    }

    createMenuAgenda() {
        this.lastCreatedMenu = "Agenda";
        this.menuItems.clear();

        var filterFavorites = new Panel();
        filterFavorites.addCssClass("menu-icon icon-filter-favorites")
        this.menuItems.addControl(filterFavorites, true);

        filterFavorites.bind("click", () => {
            let set = ManiaPreferences.getShowOnlyFavoritesAgenda();
            if (set) {
                ManiaPreferences.setShowOnlyFavoritesAgenda(false);
                filterFavorites.removeCssClass("icon-filter-favorites-active")
            } else {
                ManiaPreferences.setShowOnlyFavoritesAgenda(true);
                filterFavorites.addCssClass("icon-filter-favorites-active")
            }
        });

        let set = ManiaPreferences.getShowOnlyFavoritesAgenda();
        if (set) {
            filterFavorites.addCssClass("icon-filter-favorites-active")
        } else {
            filterFavorites.removeCssClass("icon-filter-favorites-active")
        }
    }

    createMenuLeague(leagueData) {
        this.lastCreatedMenu = "League";
        this.deActivateSearch();
        this.menuItems.clear();

        if (!leagueData || !leagueData.info) {
            console.error("no league info");
            return;
        }

        var favorite = new Panel();
        favorite.setStyle("margin-right:0;")
        if (Favorites.getFavoriteLeagues()[leagueData.info.keygs]) {
            favorite.addCssClass("menu-icon")
            favorite.addCssClass("icon-favorite-on")
        } else {
            favorite.addCssClass("menu-icon")
            favorite.addCssClass("icon-favorite-off icon-favorite-off-topbar")
        }
        this.menuItems.addControl(favorite, true);

        favorite.bind("click", () => {
            if (Favorites.getFavoriteLeagues()[leagueData.info.keygs]) {
                favorite.removeCssClass("icon-favorite-on")
                favorite.addCssClass("icon-favorite-off icon-favorite-off-topbar")
                Favorites.removeFavoriteLeague(leagueData.info.keygs);
            } else {
                if (!Favorites.addFavoriteLeague(leagueData.info.keygs, leagueData.info.countrygs, leagueData.info.title)) {
                    console.log("show toast")
                    return this.showToast(Localizer.getString("max_favorite_leagues_reached"));
                } else {
                    favorite.removeCssClass("icon-favorite-off icon-favorite-off-topbar")
                    favorite.addCssClass("icon-favorite-on");
                }
            }
            ManiaCache.deleteMemoryCache("homeData");
        });

        var subscribe = new Panel();
        subscribe.setStyle("width:32px;height:32px;")
        subscribe.addCssClass("menu-icon icon-bell")

        if (Subscriber.hasLeagueSubscription(leagueData.info.keygs)) {
            subscribe.addCssClass("icon-bell-active");
        } else {
            subscribe.addCssClass("icon-bell-inactive");
        }

        if (AppInstance.isOnline()) {
            subscribe.addCssClass("menu-icon icon-bell")
            subscribe.bind("click", (e) => {

                if (Subscriber.hasLeagueSubscription(leagueData.info.keygs)) {
                    subscribe.removeCssClass("icon-bell-active")
                    subscribe.addCssClass("icon-bell-inactive");
                    Subscriber.unsubscribeLeague(leagueData.info.keygs, (result) => {
                        if (!result) {
                            subscribe.removeCssClass("icon-bell-inactive");
                            subscribe.addCssClass("icon-bell-error");
                            setTimeout(() => {
                                subscribe.removeCssClass("icon-bell-error");
                                subscribe.addCssClass("icon-bell-active");
                            }, 800)
                        }
                    });
                } else {
                    subscribe.removeCssClass("icon-bell-inactive")
                    subscribe.addCssClass("icon-bell-active");

                    Subscriber.subscribeLeague(leagueData.info.keygs, (result) => {
                        if (!result) {
                            subscribe.removeCssClass("icon-bell-active");
                            subscribe.addCssClass("icon-bell-error");
                            setTimeout(() => {
                                subscribe.removeCssClass("icon-bell-error");
                                subscribe.addCssClass("icon-bell-inactive");
                            }, 800)
                        }
                    });
                }

            })
        }
        else{
            subscribe.addCssClass("icon-bell-disabled");
        }
        this.menuItems.addControl(subscribe, true);
    }

    createMenuPlayer(teamData) {
        this.lastCreatedMenu = "Player";
        this.deActivateSearch();
        this.menuItems.clear();
    }

    createMenuPlayerMarketValues(teamData) {
        this.lastCreatedMenu = "PlayerMarketValues";
        this.deActivateSearch();
        this.menuItems.clear();

        var filterFavorites = new Panel();
        //filterFavorites.setStyle("width:32px;height:32px;")
        filterFavorites.addCssClass("menu-icon icon-filter-favorites")
        this.menuItems.addControl(filterFavorites, true);

        filterFavorites.bind("click", () => {
            this.pageControl.triggerEvent("filterClicked");
        });
    }

    createMenuFifaRanking() {
        this.lastCreatedMenu = "FifaRanking";
        this.deActivateSearch();
        this.menuItems.clear();
    }

    createMenuForumIndex(teamData) {
        this.lastCreatedMenu = "ForumIndex";
        this.deActivateSearch();
        this.menuItems.clear();

        if (Socializer.isLoggedIn()) {
            if (Socializer.getSocialUser() == null) { //weird error
                Socializer.requestLogout(() => {
                    console.log("logged out");
                    this.createMenuForumIndex();
                    this.showToast("logged_out");
                });
                return;
            }

            console.log("create menu forum chat -> login status true ");
            var logout = new Panel();
            logout.addCssClass("menu-icon icon-logout")
            logout.bind("click", () => {
                Socializer.requestLogout(() => {
                    console.log("logged out");
                    this.createMenuForumIndex();
                    this.showToast("logged_out");
                });
            })
            this.menuItems.addControl("<div style='font-size:16px;line-height:22px;font-variant: small-caps'>" + Socializer.getSocialUser().un + "</div>", true);
            this.menuItems.addControl(logout, true);
        } else {
            console.log("create menu forum chat -> login status false ");
            var login = new Panel();
            login.addCssClass("menu-icon icon-login")
            login.bind("click", () => {
                this.hideStickBannerAd();
                Socializer.requestLogin((result) => {
                    console.log("login result", result);
                    if (result) {
                        this.createMenuForumIndex();
                        this.showToast(Localizer.getString("logged_in_as").replace("%s", Socializer.getSocialUser().un));
                    }
                    this.loadStickyBannerAd();
                });
            });
            this.menuItems.addControl(login, true);
        }
    }

    createMenuTopVoters() {
        this.lastCreatedMenu = "TopVoters";
        this.createMenuForumChat();
    }

    createMenuForumChat() {
        this.lastCreatedMenu = "ForumChat";
        this.deActivateSearch();
        this.menuItems.clear();

        if (Socializer.isLoggedIn()) {
            if (Socializer.getSocialUser() == null) { //weird error
                Socializer.requestLogout(() => {
                    console.log("logged out");
                    this.createMenuForumChat();
                    this.showToast("logged_out");
                });
                return;
            }

            console.log("create menu forum chat -> login status true ");
            var logout = new Panel();
            logout.addCssClass("menu-icon icon-logout")
            logout.bind("click", () => {
                Socializer.requestLogout(() => {
                    console.log("logged out");
                    this.createMenuForumChat();
                    this.showToast("logged_out");
                });
            })
            this.menuItems.addControl("<div style='font-size:16px;line-height:22px;font-variant: small-caps'>" + Socializer.getSocialUser().un + "</div>", true);
            this.menuItems.addControl(logout, true);
        } else {
            console.log("create menu forum chat -> login status false ");
            var login = new Panel();
            login.addCssClass("menu-icon icon-login")
            login.bind("click", () => {
                Socializer.requestLogin((result) => {
                    console.log("login result", result);
                    if (result) {
                        this.createMenuForumChat();
                        this.showToast(Localizer.getString("logged_in_as").replace("%s", Socializer.getSocialUser().un));
                    }
                });
            });
            this.menuItems.addControl(login, true);
        }
    }

    createMenuTeam(teamData) {
        this.lastCreatedMenu = "Team";
        this.deActivateSearch();
        this.menuItems.clear();

        if (!teamData){
            return;
        }

        var favorite = new Panel();
        favorite.setStyle("margin-right:0;")
        if (Favorites.getFavoriteTeams()[teamData.id_gs]) {
            favorite.addCssClass("menu-icon")
            favorite.addCssClass("icon-favorite-on")
        } else {
            favorite.addCssClass("menu-icon")
            favorite.addCssClass("icon-favorite-off icon-favorite-off-topbar")
        }
        this.menuItems.addControl(favorite, true);

        favorite.bind("click", () => {
            if (Favorites.getFavoriteTeams()[teamData.id_gs]) {
                favorite.removeCssClass("icon-favorite-on")
                favorite.addCssClass("icon-favorite-off icon-favorite-off-topbar")
                Favorites.removeFavoriteTeam(teamData.id_gs);
            } else {
                if (!Favorites.addFavoriteTeam(teamData.id_gs, teamData.teamname)) {
                    return this.showToast(Localizer.getString("max_favorite_teams_reached"));
                } else {
                    favorite.removeCssClass("icon-favorite-off icon-favorite-off-topbar")
                    favorite.addCssClass("icon-favorite-on");
                }
            }
            ManiaCache.deleteMemoryCache("homeData");
        });

        var subscribe = new Panel();
        subscribe.setStyle("width:32px;height:32px;")

        if (Subscriber.hasTeamSubscription(teamData.id_gs)) {
            subscribe.addCssClass("icon-bell-active");
        } else {
            subscribe.addCssClass("icon-bell-inactive");
        }

        subscribe.addCssClass("menu-icon icon-bell")
        subscribe.bind("click", (e) => {

            if (Subscriber.hasTeamSubscription(teamData.id_gs)) {
                subscribe.removeCssClass("icon-bell-active")
                subscribe.addCssClass("icon-bell-inactive");
                Subscriber.unsubscribeTeam(teamData.id_gs, (result) => {
                    if (!result) {
                        subscribe.removeCssClass("icon-bell-inactive");
                        subscribe.addCssClass("icon-bell-error");
                        setTimeout(() => {
                            subscribe.removeCssClass("icon-bell-error");
                            subscribe.addCssClass("icon-bell-active");
                        }, 800)
                    }
                });
            } else {
                subscribe.removeCssClass("icon-bell-inactive")
                subscribe.addCssClass("icon-bell-active");

                Subscriber.subscribeTeam(teamData.id_gs, (result) => {
                    if (!result) {
                        subscribe.removeCssClass("icon-bell-active");
                        subscribe.addCssClass("icon-bell-error");
                        setTimeout(() => {
                            subscribe.removeCssClass("icon-bell-error");
                            subscribe.addCssClass("icon-bell-inactive");
                        }, 800)
                    }
                });
            }
        })
        this.menuItems.addControl(subscribe, true);
    }

    createMenuMatch(showBell, matchid) {
        this.lastCreatedMenu = "Match";
        this.deActivateSearch();
        this.menuItems.clear();

        if (!showBell || !matchid) return;

        var subscribe = new Panel();
        subscribe.setStyle("width:32px;height:32px;")

        if (Subscriber.hasMatchSubscription(matchid)) {
            subscribe.addCssClass("icon-bell-active");
        } else {
            subscribe.addCssClass("icon-bell-inactive");
        }

        subscribe.addCssClass("menu-icon icon-bell")
        subscribe.bind("click", (e) => {

            if (Subscriber.hasMatchSubscription(matchid)) {
                subscribe.removeCssClass("icon-bell-active")
                subscribe.addCssClass("icon-bell-inactive");
                Subscriber.unsubscribeMatch(matchid, (result) => {
                    if (!result) {
                        subscribe.removeCssClass("icon-bell-inactive");
                        subscribe.addCssClass("icon-bell-error");
                        setTimeout(() => {
                            subscribe.removeCssClass("icon-bell-error");
                            subscribe.addCssClass("icon-bell-active");
                        }, 800)
                    }
                });
            } else {
                subscribe.removeCssClass("icon-bell-inactive")
                subscribe.addCssClass("icon-bell-active");

                Subscriber.subscribeMatch(matchid, (result) => {
                    if (!result) {
                        subscribe.removeCssClass("icon-bell-active");
                        subscribe.addCssClass("icon-bell-error");
                        setTimeout(() => {
                            subscribe.removeCssClass("icon-bell-error");
                            subscribe.addCssClass("icon-bell-inactive");
                        }, 800)
                    }
                });
            }
        })
        this.menuItems.addControl(subscribe, true);
    }

    createMenuCountryLeagues() {
        this.lastCreatedMenu = "CountryLeagues";
        this.menuItems.clear();
    }

    createMenuNews() {
        this.lastCreatedMenu = "News";
        this.menuItems.clear();

        var languageSelect = new Panel();
        languageSelect.setStyle("width:32px;height:32px;")

        languageSelect.addCssClass("menu-icon icon-language")
        languageSelect.bind("click", (e) => {
            var urlIdentifier = "languageselectmodal";
            var modal = new LanguageSelectModal(urlIdentifier);
            this.getPage().addControl(modal, true);
        })
        this.menuItems.addControl(languageSelect, true);
    }

    activateSearch() {
        if (!AppInstance.isOnline()){
            console.warn("Search is not available in offline mode");
            return;
        }

        this.searchActivated = true;
        this.pageControl.triggerEvent("searchActivated");

        var urlIdentifier = "search";

        if (!UrlManager.getModule(urlIdentifier)) {
            UrlManager.registerModule(urlIdentifier);
            UrlManager.setModule(urlIdentifier, "open");
        }

        UrlManager.listenModule(urlIdentifier, () => {
            if (!UrlManager.getModule(urlIdentifier)) {
                UrlManager.unlistenModule(urlIdentifier);
                UrlManager.removeModule(urlIdentifier);
                this.deActivateSearch();
            }
        });

        this.menuItems.setVisible(false);
        this.topLogoContainer.setVisible(false);
        this.search.setVisible(true);
        jQuery("#" + this.search.controls[1].clientId).focus();

        var that = this;

        var renderLeagueSearchResult = (data) => {
            var resultP = new Panel();
            resultP.addCssClass("topBarSearchResultItem");
            resultP.setStyle("justify-content: space-between;");

            var image = new Image();
            image.setStyle("width:40px;height:40px;");

            image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.countrygs.replaceAll(" ", "-").toLowerCase() + ".png");
            resultP.addControl(image);

            var name = new Panel();
            if (FootballManiaConfig.isRtl) {
                name.setStyle("margin-left:auto;");
            } else {
                name.setStyle("margin-right:auto;");
            }

            if (data.subtype) {
                name.addControl(data.name + " - " + data.subtype || "");
            } else {
                name.addControl(data.name);
            }
            resultP.addControl(name);

            var favoriteToggle = new Panel();
            favoriteToggle.setStyle("width:35px;height:35px;margin-inline-end:20px;");
            if (Favorites.getFavoriteLeagues()[data.keygs]) {
                favoriteToggle.addCssClass("icon-favorite-on");
            } else {
                favoriteToggle.addCssClass("icon-favorite-off");
            }
            resultP.addControl(favoriteToggle);
            favoriteToggle.bind("click", (e) => {
                e.stopPropagation();
                e.stopImmediatePropagation();
                e.preventDefault();
                if (!Favorites.getFavoriteLeagues()[data.keygs]) {
                    console.log("add favorite league", data.name, data.keygs);
                    Favorites.addFavoriteLeague(data.keygs, data.countrygs, data.name);
                    favoriteToggle.removeCssClass("icon-favorite-off");
                    favoriteToggle.addCssClass("icon-favorite-on");
                } else {
                    Favorites.removeFavoriteLeague(data.keygs);
                    favoriteToggle.removeCssClass("icon-favorite-on");
                    favoriteToggle.addCssClass("icon-favorite-off");
                }
                console.log("onFavoriteChanged", this.getPage());
                this.getPage().pageControl.triggerEvent("onFavoriteChanged");
            });

            this.searchResults.addControl(resultP);
            resultP.bind("click", function () {
                var previousSearchClicks = localStorage.getItem("recentSearchClicks_v2");
                if (!previousSearchClicks) {
                    previousSearchClicks = [];
                } else {
                    previousSearchClicks = JSON.parse(previousSearchClicks);
                }

                var found = false;
                for (var i = 0; i < previousSearchClicks.length; i++) {
                    if (previousSearchClicks[i].type == "l" && previousSearchClicks[i].keygs == data.keygs) {
                        //remove
                        previousSearchClicks.splice(i, 1);
                        break;
                    }
                }
                previousSearchClicks.push(data);

                that.deActivateSearch();
                Router.showLeagueScreen(data);

                setTimeout(() => {
                    localStorage.setItem("recentSearchClicks_v2", JSON.stringify(previousSearchClicks));

                    DataProvider.searchtrack({
                        id: data.id_gs,
                        leaguekey: data.keygs,
                        track_name: "league",
                        track_action: "search_action",
                    });
                }, 1000)
            });
        }

        var renderTeamResult = (data) => {
            var resultP = new Panel();
            resultP.addCssClass("topBarSearchResultItem");
            resultP.setStyle("justify-content: space-between;");

            if (AppInstance.getConfig().enabledFeatures.teamImages) {
                var image = new Image();
                image.setStyle("width:40px;height:40px;")
                image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/teams_gs/" + data.id_gs + "_rnd.png");
                resultP.addControl(image);
            }

            var name = new Panel();
            if (FootballManiaConfig.isRtl) {
                name.setStyle("margin-left:auto;margin-inline-start:6px;");
            } else {
                name.setStyle("margin-right:auto;margin-inline-start:6px;");
            }

            if (data.countrygs) {
                name.addControl(data.name + " - " + data.countrygs);
            } else {
                name.addControl(data.name);
            }
            resultP.addControl(name);

            var favoriteToggle = new Panel();
            favoriteToggle.setStyle("width:35px;height:35px;margin-inline-end:20px;");

            if (Favorites.getFavoriteTeams()[data.id_gs]) {
                favoriteToggle.addCssClass("icon-favorite-on");
            } else {
                favoriteToggle.addCssClass("icon-favorite-off");
            }
            resultP.addControl(favoriteToggle);
            favoriteToggle.bind("click", (e) => {
                e.stopPropagation();
                e.stopImmediatePropagation();
                e.preventDefault();
                if (!Favorites.getFavoriteTeams()[data.id_gs]) {
                    console.log("add favorite team", data.id_gs, data.name);
                    Favorites.addFavoriteTeam(data.id_gs, data.name);
                    favoriteToggle.removeCssClass("icon-favorite-off");
                    favoriteToggle.addCssClass("icon-favorite-on");
                } else {
                    Favorites.removeFavoriteTeam(data.id_gs);
                    favoriteToggle.removeCssClass("icon-favorite-on");
                    favoriteToggle.addCssClass("icon-favorite-off");
                }
                console.log("onFavoriteChanged", this.getPage());
                this.getPage().pageControl.triggerEvent("onFavoriteChanged");
            });

            this.searchResults.addControl(resultP);

            resultP.bind("click", function () {
                var previousSearchClicks = localStorage.getItem("recentSearchClicks_v2");
                if (!previousSearchClicks) {
                    previousSearchClicks = [];
                } else {
                    previousSearchClicks = JSON.parse(previousSearchClicks);
                }

                var found = false;
                for (var i = 0; i < previousSearchClicks.length; i++) {
                    if (previousSearchClicks[i].type == "t" && previousSearchClicks[i].id_gs == data.id_gs) {
                        previousSearchClicks.splice(i, 1);
                        break;
                    }
                }

                previousSearchClicks.push(data);

                that.deActivateSearch();
                Router.showTeamScreen(data.id_gs);

                setTimeout(function () {

                    localStorage.setItem("recentSearchClicks_v2", JSON.stringify(previousSearchClicks));

                    DataProvider.searchtrack({
                        id: data.id_gs,
                        track_name: "team",
                        track_action: "search_action",
                    });
                }, 1000);
            });
        }

        var renderMatchResult = (data) => {
            var itemRender = new MatchItemRender(null, 2);
            data.style = {
                "height": "40px",
            }
            itemRender.showAlwaysTime = true;
            itemRender.setData(data);

            var flag = new Image();
            flag.setStyle("margin-inline-start: 9px;margin-top: 14px;width:20px;height:20px;");
            flag.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.filegroup.toLowerCase().replaceAll(" ", "-") + ".png");
            itemRender.panel.addControlAt(flag, 0);
            this.searchResults.addControl(itemRender);
            itemRender.contentPanel.setStyle("margin-right:16px;")

            itemRender.bind("click", function () {
                that.deActivateSearch();
                Router.showMatchScreen(null, data.data.id);
            });
        }

        var renderPlayerResult = (data) => {
            var resultP = new Panel();
            resultP.addCssClass("topBarSearchResultItem");

            var image = new Image();
            image.setStyle("width:40px;height:40px;")

            image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/playerimages/" + data.id_gs + "_small_rnd.png");

            resultP.addControl(image);

            if (data.team_name) {
                resultP.addControl(data.name + " - " + data.team_name);
            } else {
                resultP.addControl(data.name);
            }
            this.searchResults.addControl(resultP);

            resultP.bind("click", function () {

                that.deActivateSearch();
                Router.showPlayerScreen(data.id_gs);

                var previousSearchClicks = localStorage.getItem("recentSearchClicks_v2");
                if (!previousSearchClicks) {
                    previousSearchClicks = [];
                } else {
                    previousSearchClicks = JSON.parse(previousSearchClicks);
                }

                var found = false;
                for (var i = 0; i < previousSearchClicks.length; i++) {
                    if (previousSearchClicks[i].type == "p" && previousSearchClicks[i].id_gs == data.id_gs) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    previousSearchClicks.push(data);
                }

                setTimeout(function () {

                    localStorage.setItem("recentSearchClicks_v2", JSON.stringify(previousSearchClicks));

                    DataProvider.searchtrack({
                        id: data.id_gs,
                        track_name: "player",
                        track_action: "search_action",
                    });
                }, 1000)
            });
        };

        var renderCoachResult = (data) => {
            var resultP = new Panel();
            resultP.addCssClass("topBarSearchResultItem");

            var image = new Image();
            image.setStyle("width:40px;height:40px;border: 1px solid #ffd7008f;border-radius: 24px;")

            image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/coachimages/" + data.id_gs + "_small_rnd.png");

            resultP.addControl(image);

            if (data.team_name) {
                resultP.addControl(data.name + " - " + data.team_name);
            } else {
                resultP.addControl(data.name);
            }
            this.searchResults.addControl(resultP);

            resultP.bind("click", function () {

                that.deActivateSearch();
                Router.showCoachScreen(data.id_gs);

                var previousSearchClicks = localStorage.getItem("recentSearchClicks_v2");
                if (!previousSearchClicks) {
                    previousSearchClicks = [];
                } else {
                    previousSearchClicks = JSON.parse(previousSearchClicks);
                }

                var found = false;
                for (var i = 0; i < previousSearchClicks.length; i++) {
                    if (previousSearchClicks[i].type == "p" && previousSearchClicks[i].id_gs == data.id_gs) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    previousSearchClicks.push(data);
                }

                setTimeout(function () {

                    localStorage.setItem("recentSearchClicks_v2", JSON.stringify(previousSearchClicks));

                    DataProvider.searchtrack({
                        id: data.id_gs,
                        track_name: "coach",
                        track_action: "search_action",
                    });
                }, 1000)
            });
        };

        var handlePreviousResults = () => {
            //put previous search clicks in results when user clicks search
            localStorage.removeItem("recentSearchClicks");
            var previousSearchClicks = localStorage.getItem("recentSearchClicks_v2");
            if (previousSearchClicks) {
                previousSearchClicks = JSON.parse(previousSearchClicks);

                previousSearchClicks = previousSearchClicks.reverse();

                this.searchResults.clear();
                for (var i = 0; i < previousSearchClicks.length; i++) {
                    if (i > 15) break;
                    var result = previousSearchClicks[i];
                    if (result.type == "t") {
                        renderTeamResult(result);
                    } else if (result.type == "l") {
                        renderLeagueSearchResult(result);
                    } else if (result.type == "p") {
                        renderPlayerResult(result);
                    }else if (result.type == "c") {
                        renderCoachResult(result);
                    }

                }
                this.searchResults.invalidateDisplay();
            }
        }
        handlePreviousResults();

        this.search.controls[0].unbind("click");
        this.search.controls[0].bind("click", () => {
            history.back();
            //this.deActivateSearch();
        });

        this.search.controls[1].unbind("keydown");
        this.search.controls[1].bind("keydown", () => {
            clearTimeout(this.inputter);
            this.inputter = setTimeout(() => {

                if (!jQuery("#" + this.search.controls[1].clientId).val()) {
                    this.searchResults.clear();
                    handlePreviousResults();
                    return;
                }

                DataProvider.search(jQuery("#" + this.search.controls[1].clientId).val(), (response) => {
                    this.searchResults.clear();
                    if (!response.isOk()) {
                        return;
                    }
                    var d = response.getData();
                    var processedTeams = 0;
                    var processedLeagues = 0;
                    var processedPlayers = 0;
                    var processedCoaches = 0;
                    var max = 40;
                    for (var i = 0; i < d.result.length; i++) {

                        var result = d.result[i];

                        console.log("result", result);

                        if (result.type == "t") {
                            if (processedTeams >= max) continue;
                            processedTeams++;
                            renderTeamResult(result);

                            var past = [];
                            var future = [];

                            if (result.matches && result.matches.length) {
                                for (var j = result.matches.length - 1; j >= 0; j--) {
                                    if (MatchUtil.isFinished(result.matches[j])) {
                                        if (past.length >= 2) continue;
                                        past.push(result.matches[j]);
                                    }
                                }
                                for (var j = 0; j < result.matches.length; j++) {
                                    if (!MatchUtil.isFinished(result.matches[j])) {
                                        if (future.length >= 3) continue;
                                        future.push(result.matches[j]);
                                    }
                                }
                            }
                            if (future.length == 0) {
                                var matches = past;
                            } else {
                                var matches = past.concat(future);
                            }
                            for (var k = 0; k < matches.length; k++) {
                                renderMatchResult(matches[k]);
                            }
                        } else if (result.type == "l") {
                            if (processedLeagues >= max) continue;
                            processedLeagues++;
                            renderLeagueSearchResult(result);

                            var past = [];
                            var future = [];

                            if (result.matches && result.matches.length) {

                                //reverse array
                                for (var j = result.matches.length - 1; j >= 0; j--) {
                                    if (MatchUtil.isFinished(result.matches[j])) {
                                        if (past.length >= 0) continue;
                                        past.push(result.matches[j]);
                                    }
                                }
                                for (var j = 0; j < result.matches.length; j++) {
                                    if (!MatchUtil.isFinished(result.matches[j])) {
                                        if (future.length >= 8) continue;
                                        future.push(result.matches[j]);
                                    }
                                }
                            }
                            if (future.length == 0) {
                                var matches = past;
                            } else {
                                var matches = past.concat(future);
                            }
                            for (var k = 0; k < matches.length; k++) {
                                renderMatchResult(matches[k]);
                            }

                        } else if (result.type == "p") {
                            if (processedPlayers >= max) continue;
                            processedPlayers++;
                            console.log("render player");
                            renderPlayerResult(result);
                        } else if (result.type == "c") {
                            if (processedCoaches >= max) continue;
                            processedCoaches++;
                            console.log("render coach");

                            renderCoachResult(result);
                        }
                    }
                    this.searchResults.invalidateDisplay();
                });
            }, 200);
        });
    }

    deActivateSearch() {
        console.log("deactivate");

        if (this.searchActivated) {
            this.pageControl.triggerEvent("searchDeactivated");
        }

        var urlIdentifier = "search";
        UrlManager.unlistenModule(urlIdentifier);
        UrlManager.removeModule(urlIdentifier);
        UrlManager.updateUrl(true);

        this.menuItems.setVisible(true);
        this.topLogoContainer.setVisible(true);
        this.search.setVisible(false);
        jQuery("#" + this.search.controls[1].clientId).val("");
        this.search.controls[0].unbind("click");
        this.searchActivated = false;
    }

    showToast(message) {

        if (this.toast) this.toast.remove();
        clearTimeout(this.toasterTimeoutHandler);
        var toast = new Panel();
        toast.addCssClass("snackbar");
        toast.addCssClass("snackbarshow");
        toast.addControl(message);
        this.addControl(toast, true);
        this.toast = toast;

        this.toasterTimeoutHandler = setTimeout(() => {
            this.toast.remove();
        }, 4000);
    }

    showFakeLoader(version){
        var container = new Panel();
        container.addCssClass("loaderContainer");
        var loader = new Panel();
        loader.addCssClass("loader");
        var text = new Label();
        text.setData(Localizer.getString("update_version").replace("{}",version));
        //toast.addCssClass("snackbarshow");
        container.addControl(loader);
        container.addControl(text);
        this.addControl(container, true);
        clearTimeout(this.toasterTimeoutHandler);
        this.toasterTimeoutHandler = setTimeout(() => {
            if (this.unloaded)return;
            container.remove();
        }, 1500);
    }
};