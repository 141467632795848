export const Favorites = {
    storageLeagueKey : "leagueFavorites",
    storageTeamKey : "teamFavorites",
    cachedLeagueFavorites : null,
    cachedTeamFavorites : null,

    getFavoriteTeams : function(){
        // return {
        //     "6999" : "Club Universado",
        //     "6559" : "Club Energetik",
        //     "22853" : "Araz"
        // }

        if (this.cachedTeamFavorites) return this.cachedTeamFavorites;
        var data = {};
        try {
            var raw = localStorage.getItem(this.storageTeamKey);
            data = JSON.parse(raw);
            if (!data) data = {};
        }
        catch (e) {
            console.warn("error getting teams ",e);
        }
        for (var key in data){
            if (data[key].index == undefined){
                console.log("incorrect team favorite indexing, fixing");
                var index = 0;
                for (var key in data){
                    data[key].index = index;
                    index++;
                }
                localStorage.setItem(this.storageTeamKey,JSON.stringify(data));
                break;
            }
        }

        this.cachedTeamFavorites = data;
        return data;
    },

    hasFavoriteTeams : function(){
        var data = this.getFavoriteTeams();
        return Object.keys(data).length > 0;
    },

    hasFavoriteLeagues : function(){
        var data = this.getFavoriteLeagues();
        return Object.keys(data).length > 0;
    },

    addFavoriteTeam : function(key,name){
        this.cachedTeamFavorites = null;
        var data = this.getFavoriteTeams();
        var index = Object.keys(data).length;
        if (index > 10) return false;
        data[key] = {"name" : name,"index" : index};
        localStorage.setItem(this.storageTeamKey,JSON.stringify(data));
        return true;
    },

    removeFavoriteTeam : function(key){
        this.cachedTeamFavorites = null;
        var data = this.getFavoriteTeams();
        var oldIndex = data[key].index;
        delete data[key];

        if (oldIndex != undefined){
            for (var key in data){
                if (data[key].index > oldIndex){
                    data[key].index = data[key].index - 1;
                }
            }
        }
        localStorage.setItem(this.storageTeamKey,JSON.stringify(data));
    },

    swapFavoriteTeams : function(key1,key2){
        this.cachedTeamFavorites = null;
        var data = this.getFavoriteTeams();
        var index1 = data[key1].index;
        var index2 = data[key2].index;
        data[key1].index = index2;
        data[key2].index = index1;
        localStorage.setItem(this.storageTeamKey,JSON.stringify(data));
    },

    //[{key : "EnglandPremierLeague" , country: "england", name : "Premier League"}],
    getFavoriteLeagues : function(){
        if (this.cachedLeagueFavorites) return this.cachedLeagueFavorites;
        var data = {};
        try {
            var raw = localStorage.getItem(this.storageLeagueKey);
            data = JSON.parse(raw);
            if (!data) data = {};

            var keysToDelete = [];
            for (var key in data){
                if (!data[key].country || !data[key].name){
                    console.warn("broken favorite league data, removing");
                    keysToDelete.push(key);
                }
                if (data[key].index == undefined){
                    console.log("incorrect league favorite indexing, fixing");
                    var index = 0;
                    for (var key in data){
                        data[key].index = index;
                        index++;
                    }
                    localStorage.setItem(this.storageLeagueKey,JSON.stringify(data));
                    break;
                }
            }
            var deletedItems = false;
            for (var i = 0; i < keysToDelete.length; i++){
                delete data[keysToDelete[i]];
                deletedItems = true;
            }
            if (deletedItems){
                localStorage.setItem(this.storageLeagueKey,JSON.stringify(data));
            }
        }
        catch (e) {
            console.warn("error getting leagues ",e);
        }
        this.cachedLeagueFavorites = data;
        return data;
    },

    addFavoriteLeague : function(key,country,name){
        console.log("adding favorite league",key,country,name);
        this.cachedLeagueFavorites = null;
        var data = this.getFavoriteLeagues();
        var index = Object.keys(data).length;
        if (index > 15) return false;
        data[key] = {"country": country, "name" : name,"index" : index};
        localStorage.setItem(this.storageLeagueKey,JSON.stringify(data));
        this.cachedLeagueFavorites = null;
        return true;
    },

    removeFavoriteLeague : function(key){
        console.log("removing favorite league",key);
        this.cachedLeagueFavorites = null;
        var data = this.getFavoriteLeagues();
        var oldIndex = data[key].index;
        delete data[key];

        if (oldIndex != undefined){
            for (var key in data){
                if (data[key].index > oldIndex){
                    data[key].index = data[key].index - 1;
                }
            }
        }

        localStorage.setItem(this.storageLeagueKey,JSON.stringify(data));
        this.cachedLeagueFavorites = null;
    },

    swapFavoriteLeagues : function(key1,key2){
        this.cachedLeagueFavorites = null;
        var data = this.getFavoriteLeagues();
        var index1 = data[key1].index;
        var index2 = data[key2].index;
        data[key1].index = index2;
        data[key2].index = index1;
        localStorage.setItem(this.storageLeagueKey,JSON.stringify(data));
    },

    migrateAndroidFavoriteTeams : function(androidData){

        var dict_reverse = function(obj) {
            var new_obj= {}
            var rev_obj = Object.keys(obj).reverse();
            rev_obj.forEach(function(i) {
                new_obj[i] = obj[i];
            })
            return new_obj;
        }

        //{"9446|1":"Wolves|9446|1","9002|1":"Arsenal|9002|1"}
        androidData = JSON.parse(androidData);
        var newData = {};
        for (var key in androidData) {
            var split = androidData[key].split("|");
            newData[split[1]] = {name:split[0]};
        }
        newData = dict_reverse(newData);
        var index = 0;
        for (var key in newData){
            newData[key].index = index;
            index++;
        }
        localStorage.setItem(this.storageTeamKey,JSON.stringify(newData));
    },

    migrateAndroidFavoriteLeagues : function(androidData){

        var dict_reverse = function(obj) {
            var new_obj= {}
            var rev_obj = Object.keys(obj).reverse();
            rev_obj.forEach(function(i) {
                new_obj[i] = obj[i];
            })
            return new_obj;
        }

        ///"{"EurocupsUEFAChampionsLeague":"UEFA Champions League|Eurocups","EnglandPremierLeague":"Premier League|England","WorldCupBrazil2014":"World Cup|World Cup"}
        androidData = JSON.parse(androidData);
        var newData = {};
        for (var key in androidData) {
            var split = androidData[key].split("|");
            newData[key] = {"country": split[1], "name": split[0]};
        }
        newData = dict_reverse(newData);
        var index = 0;
        for (var key in newData){
            newData[key].index = index;
            index++;
        }

        localStorage.setItem(this.storageLeagueKey,JSON.stringify(newData));
    },

    saveLeagueFavorite : function(){

    }
};