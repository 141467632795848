import { BouncePanel} from "./bouncepanel.js";

import { Panel} from "../bananaframework/src/controls/Panel.js";
import { UiControl} from "../bananaframework/src/controls/UiControl.js";
import { Label } from "../bananaframework/src/controls/Label.js";
import { Image } from "../bananaframework/src/controls/Image.js";
import { UrlManager } from "../bananaframework/src/util/UrlManager.js";

class TabViewTitleHolder extends Panel {

    init(){
        this.tabTitles = [];
        this.currentActiveTab = -1;

        super.init();
        this.addCssClass("tabholder");
        this.tagName = "nav";

        var tabIndicator = new Panel();
        tabIndicator.addCssClass("tabviewindicator");
        this.addControl(tabIndicator);

        this.tabIndicator =tabIndicator;
    }

    recalculateSizes (tabw){
        for (var i=0; i < this.controls.length;i++){
            if (this.controls[i] instanceof UiControl){
                if (this.controls[i].hasCssClass("tabholder-title-container")) {
                    this.controls[i].setCss({'width':tabw+"px"});
                }
            }
        }
    }

    renderTabs (tabs){

        var w = this.getDimensions().width;
        var c = tabs.length;

        var tabw = Math.floor(w/c);

        if (c == 1){
            this.tabIndicator.setCss({'width':(tabw/2)-20+"px","margin-left":(tabw/4)+10+"px"});
        }
        else{
            this.tabIndicator.setCss({'width':tabw-20+"px","margin-left":"10px"});
        }


        for (var i =0; i < tabs.length;i++) {

            var tabTitleContainer = new Panel();
            tabTitleContainer.addCssClass("tabholder-title-container");
            tabTitleContainer.setStyle("width:" + tabw + "px");
            this.addControl(tabTitleContainer);

            var tabTitleContainerBg = new Panel();
            tabTitleContainerBg.addCssClass("tabholder-title-container-bg");
            tabTitleContainer.addControl(tabTitleContainerBg);

            var tabTitle = new Panel();
            //tabTitle.addControl(tabs[i].title);
            if (tabs[i].iconClass){
                var tabLogo = new Panel();
                tabLogo.setStyle("width: 32px;height:32px;");
                tabLogo.addCssClass(tabs[i].iconClass);
                tabTitle.addControl(tabLogo);
            }
            else {
                tabTitle.addControl(tabs[i].title);
            }

            tabTitle.addCssClass("tabholder-title");
            tabTitleContainer.addControl(tabTitle);

            this.tabTitles.push(tabTitle);

            var that = this;
            var startClickTime = 0;
            (function (index, tabTitleContainer, tabTitleContainerBg) {
                tabTitleContainer.bind("touchstart", function () {
                    tabTitleContainerBg.addCssClass("tabholder-title-container-bg-touch")
                    tabTitleContainerBg.addCssClass("tabholder-title-container-bg-touch2")
                    startClickTime = new Date().getTime();
                    //console.log("tap")
                })
                tabTitleContainer.bind("touchend", function (e) {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    e.preventDefault();
                    if (new Date().getTime() - startClickTime > 300) {
                        tabTitleContainerBg.removeCssClass("tabholder-title-container-bg-touch2")
                    } else {
                        setTimeout(function () {
                            tabTitleContainerBg.removeCssClass("tabholder-title-container-bg-touch2")
                        }, 300)
                    }

                    if (new Date().getTime() - startClickTime > 100) {
                        tabTitleContainerBg.removeCssClass("tabholder-title-container-bg-touch")
                    } else {
                        setTimeout(function () {
                            tabTitleContainerBg.removeCssClass("tabholder-title-container-bg-touch")
                        }, 100)
                    }
                    console.log("tap end");
                    that.tabClicked(index, 150);
                });
                tabTitleContainer.bind("click", function () {
                    console.log('click');
                    that.tabClicked(index, 150);
                });
            })(i, tabTitleContainer, tabTitleContainerBg);
        }
    }

    tabClicked (index,speed){
        this.triggerEvent("onTabClicked",{index:index,speed:speed});
    }
};

export class TabView extends Panel{

    init(){

        super.init();

        this.currentActiveTab = 0;
        this.enableScrolling = true;
        this.cachedElements = [];
        this.cachedParllaxBgXOffset = 0;
        this.windowResizeHandler = null;
        this.scrollIndexes = {};
        this.tabsRendered = false;

        this.cachedParllaxBgXOffset = null;
        this.addCssClass("tabview");

        var tabHolder = new TabViewTitleHolder();
        this.addControl(tabHolder);

        var tabHolderBg = new Panel();
        tabHolder.addControl(tabHolderBg);
        tabHolderBg.addCssClass("tabholder-bg");

        var tabContents = new Panel();
        tabContents.tagName = "main";
        tabContents.addCssClass("tabContents");
        this.addControl(tabContents);

        this.tabHolder = tabHolder;
        this.tabContents = tabContents;
    }

    unload(){
        this.cachedElements = null;
        this.tabs = null;
        this.cachedParllaxBgXOffset = null;
    }

    onWindowResize(){
        var delay = 20;
        if (!this.windowResizeHandler) delay = 0;

        //clearTimeout(this.windowResizeHandler);

        //this.windowResizeHandler = setTimeout(this.getProxy(function(){
        this.recalculateSizes();
        //}),delay)
    }

    createComponents(){
    }

    setTabs(tabs){
        this.tabs = tabs;
        for (var i=0;i<tabs.length;i++){
            tabs[i].tabIndex = i;
        }
    }

    addTab(index,tab){
        this.tabs.splice(index,0,tab);

        var w = this.getDimensions().width;
        var c = this.tabs.length;
        tab.tabIndex = index;

        var tabw = Math.floor(w/c);
        var i = index;
        var previousSelected = this.currentActiveTab;

        var tabTitleContainer = new Panel();
        tabTitleContainer.addCssClass("tabholder-title-container");
        tabTitleContainer.setStyle("width:"+tabw+"px");

        var tabTitleContainerBg = new Panel();
        tabTitleContainerBg.addCssClass("tabholder-title-container-bg");
        tabTitleContainer.addControl(tabTitleContainerBg);

        var tabTitle = new Panel();
        tabTitle.addControl(this.tabs[i].title);
        tabTitle.addCssClass("tabholder-title");
        tabTitleContainer.addControl(tabTitle);

        var that =this;
        var startClickTime = 0;

        tabTitleContainer.bind("touchstart",function(){
            tabTitleContainerBg.addCssClass("tabholder-title-container-bg-touch")
            tabTitleContainerBg.addCssClass("tabholder-title-container-bg-touch2")
            startClickTime = new Date().getTime();
            //console.log("tap")
        })
        tabTitleContainer.bind("touchend",function(){
            if (new Date().getTime() - startClickTime > 300) {
                tabTitleContainerBg.removeCssClass("tabholder-title-container-bg-touch2")
            }
            else{
                setTimeout(function(){
                    tabTitleContainerBg.removeCssClass("tabholder-title-container-bg-touch2")
                },300)
            }

            if (new Date().getTime() - startClickTime > 100) {
                tabTitleContainerBg.removeCssClass("tabholder-title-container-bg-touch")
            }
            else{
                setTimeout(function(){
                    tabTitleContainerBg.removeCssClass("tabholder-title-container-bg-touch")
                },100)
            }
            //console.log("tap end");
            that.moveToTab(index,150);
        });
        tabTitleContainer.bind("click",function(){
            that.moveToTab(index,150);
        });

        var tabw = Math.floor(w/this.tabs.length);

        //this.tabHolder.addControlAt(tabTitleContainer,index+1,true);//hack
        this.tabHolder.addControlAt(tabTitleContainer,index+2,true);//hack
        this.tabTitles.splice(index,0,tabTitle);

        for (var i=0; i < this.tabHolder.controls.length;i++){
            if (this.tabHolder.controls[i] instanceof UiControl){
                if (this.tabHolder.controls[i].hasCssClass("tabholder-title-container")) {
                    this.tabHolder.controls[i].setCss({'width':tabw+"px"});
                }
            }
        }

        var p = new BouncePanel();
        p.enableFastScroll = this.enableFastScroll;

        p.addCssClass("tab-content-item");
        p.setStyle("width:0px;height:100%;left:0px;");
        p.addControl(this.tabs[index]);
        this.tabContents.addControlAt(p,index,true);

        this.recalculateSizes();

        if (previousSelected >= index){
            this.moveToTab(index+1,0);
        }

        this.triggerEvent("tabAdded");
    }

    renderTabs(){
        if (this.parallaxBg) {
            if (this.tabs.length > 1) {
                this.parallaxBg.setCss({'width': '120%'});
            }
            else{
                this.parallaxBg.setCss({'width': '100%'});
            }
        }

        this.tabHolder.renderTabs(this.tabs);
        this.tabHolder.bind("onTabClicked",(e,data)=>{
            this.moveToTab(data.index,data.speed);
        });
        this.tabHolder.invalidateDisplay();

        //
        var windowHeight = jQuery(window).height();
        var top = $("#"+this.tabHolder.getClientId()).height();
        var height = windowHeight - top - $("#"+this.tabHolder.getClientId()).offset().top;

        this.tabContents.setCss({'height':height+"px"});

        //calculate width and create tabs
        var w = this.getDimensions().width;

        //add the conent items
        var left = 0;
        for (var i =0; i < this.tabs.length;i++){

            var p = new BouncePanel();
            p.enableFastScroll = this.enableFastScroll;

            p.addCssClass("tab-content-item");
            p.setStyle("width:"+w+"px;height:100%;left:"+left+"px;");
            this.tabContents.addControl(p);
            p.addControl(this.tabs[i]);
            left += w;
        }

        this.tabContents.invalidateDisplay();
        this.registerSwipe();

        this.scrollIndexes = {};

        this.triggerEvent("onReady");
        // console.log(Util.UrlManager.getModule("tabindex"));
        if (UrlManager.getModule("tabindex")){
            this.moveToTab(UrlManager.getModule("tabindex"), 0);
        }
        else {
            this.moveToTab(this.currentActiveTab, 0,true);
        }
    }

    recalculateSizes(){

        var left = 0;
        var w = this.getDimensions().width;
        var tabw = Math.floor(w/this.tabs.length);

        var windowHeight = jQuery(window).height();
        var top = $("#"+this.tabHolder.getClientId()).height();
        //we set the hight of the content here. we take the tabholder top and offsettop (when in collapsed mode)
        var height = windowHeight - top - $("#"+this.tabHolder.getClientId())[0].offsetTop;
        //var height = windowHeight - top;// - $("#"+this.tabHolder.getClientId()).offset().top;

        //console.log("recalulalte tab size height",height," window height",windowHeight," tab holder top",$("#"+this.tabHolder.getClientId()).offset(),$("#"+this.tabHolder.getClientId())[0].offsetTop);

        for (var i=0; i < this.tabContents.controls.length;i++){
            this.tabContents.controls[i].recalculate();
            this.tabContents.controls[i].setCss({"width":w+"px","left":left+"px"});
            //this.tabContents.controls[i].setCss({"width":w+"px","height":"100%","left":left+"px"});
            //this.tabContents.controls[i].setCss({"width":w+"px","left":left+"px","height":height+"px"});
            this.tabContents.controls[i].repositionScrollbar();
            left += w;
        }
        this.tabHolder.recalculateSizes(tabw);

        var speed = 0;
        var index = this.currentActiveTab;
        var w = this.getDimensions().width;
        var offset = index * w;
        var bg = (w*index)/(w*this.tabs.length)
        var indicatorOffset = (w/this.tabs.length) * index;

        $("#"+this.tabContents.getClientId()).css({"transform":"translate("+-offset+"px)","transition-duration": speed+"ms"});
        $("#"+this.tabHolder.tabIndicator.getClientId()).css({"transform":"translate("+indicatorOffset+"px)","transition-duration": speed+"ms"});

        if (this.parallaxBg){
            this.cachedParllaxBgXOffset = ((-bg)*10);
            if (this.tabs.length > 1) {
                if (FootballManiaConfig.isRtl) {
                    this.parallaxBg.setCss({
                        "margin-right": "-20%",
                        "transform": "translateY(-50px) translateX(" + this.cachedParllaxBgXOffset + "%)",
                        "transition-duration": speed + "ms"
                    })
                } else {
                    this.parallaxBg.setCss({
                        "transform": "translateY(-50px) translateX(" + this.cachedParllaxBgXOffset + "%)",
                        "transition-duration": speed + "ms"
                    })
                }
            }
        }

        if (this.tabs.length == 1){
            this.tabHolder.tabIndicator.setCss({'width':(tabw/2)-20+"px","margin-left":(tabw/4)+10+"px"});

           // this.tabHolder.tabIndicator.setCss({'width':tabw-20+"px","margin-left":"4px"});
        }
        else{
            this.tabHolder.tabIndicator.setCss({'width':tabw-20+"px","margin-left":"10px"});
            //this.tabHolder.tabIndicator.setCss({'width':tabw-20+"px","margin-left":"4px"});
        }

        //this.tabHolder.tabIndicator.setCss({'width':tabw-20+"px","margin-left":"4px"});

        //calculate tab contents height
        this.tabContents.setCss({'height':height+"px"});
         console.log("set height tab content ",height);
    }

    updateDisplay(){
        if (!this.tabsRendered) {
            this.renderTabs();
            this.tabsRendered = true;
            this.triggerEvent("tabsReady");
        }
    }

    enableSwipe(){
        this.swipeDisabled =false;
    }

    disableSwipe(){
        this.swipeDisabled = true;
    }

    moveToTab(index,speed,force){
        if (speed == null) speed = 240;

        console.log("move to tab ",index,speed,this.currentActiveTab);
        if (!force && this.currentActiveTab == index){
            console.warn("cannot move to tab ",index,"already selected")
            return;
        }

        var trigger = false;
        if (this.currentActiveTab != index){
            trigger =true;
        }
        if (!this.tabs[this.currentActiveTab]){
            console.error("cannot move to tab ",this.currentActiveTab,"tab does not exist");
            return;
        }

        //console.log("move to tab ",index,speed,this.currentActiveTab);
        //if (this.currentActiveTab == index) return;


        this.tabs[this.currentActiveTab].removeCssClass("active");

        this.tabHolder.tabTitles[this.currentActiveTab].removeCssClass("active");
        this.currentActiveTab = index;
        this.tabs[this.currentActiveTab].addCssClass("active");
        this.tabHolder.tabTitles[this.currentActiveTab].addCssClass("active");

        var w = this.getDimensions().width;
        var offset = index * w;
        var bg = (w*index)/(w*this.tabs.length)

        var indicatorOffset = (w/this.tabs.length) * index;

        $("#"+this.tabContents.getClientId()).css({"transform":"translate("+-offset+"px)","transition-duration": speed+"ms"});
        $("#"+this.tabHolder.tabIndicator.getClientId()).css({"transform":"translate("+indicatorOffset+"px)","transition-duration": speed+"ms"});

        if (this.parallaxBg){
            this.cachedParllaxBgXOffset = ((-bg)*10);

            if (this.tabs.length > 1) {
                if (FootballManiaConfig.isRtl) {
                    this.parallaxBg.setCss({
                        "margin-right": "-20%",
                        "transform": "translateY(-50px) translateX(" + this.cachedParllaxBgXOffset + "%)",
                        "transition-duration": speed + "ms"
                    })
                } else {
                    this.parallaxBg.setCss({
                        "transform": "translateY(-50px)  translateX(" + this.cachedParllaxBgXOffset + "%)",
                        "transition-duration": speed + "ms"
                    })
                }
            }
        }

        if (trigger){
            this.triggerEvent("tabChanged");
        }
    }

    storeScrollPositions(){

    }

    restoreScrollPositions(){

    }

    registerSwipe() {

        var that = this;
        var w = this.getDimensions().width;
        var cancelSpeed = 350;
        var stopSwiping = false;
        var stopScrolling = false;
        var finchingY = false;
        var finchingX = false;
        var lastScrollPosition = 0;
        var disableLeftRightAction = false;

        var tabContentsElem = jQuery("#"+that.tabContents.getClientId());
        var tabIndicatorElem = jQuery("#"+that.tabHolder.tabIndicator.getClientId())
        this.cachedElements = [];

        $("#"+this.tabContents.getClientId()).swipe( {

            allowPageScroll:"vertical",
            //Generic swipe handler for all directions
            swipe:function(event, direction, distance, duration, fingerCount, fingerData,currentDirection) {

                console.log("ending swipte");
                //console
                if (fingerData[0].start.x < 35){
                    that.isSwiping = false;
                    event.stopPropagation();
                    return;
                }

                if (that.isSwiping){
                    that.isSwiping = false;
                    setTimeout(()=> {
                        that.triggerEvent("swipeEnd");
                    },200);
                }
                if (that.swipeDisabled) return;

                that.tabs[that.currentActiveTab].parent.enableBounce();

                disableLeftRightAction = false;
                //console.log("swiped ","dir",direction,"dis",distance,"dur",duration,"w",w,disableLeftRightAction,currentDirection);

                if (finchingY){
                   // that.tabs[that.currentActiveTab].setCss({"transform":"scaleY(1)"});
                    $("#"+that.tabs[that.currentActiveTab].getClientId()).addClass( "tabContentsScaleBack" ).one("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function(){
                        $(this).removeClass( "tabContentsScaleBack" );
                        $(this).css("transform","none");
                    });

                    setTimeout(function(){
                        that.tabs[that.currentActiveTab].removeCssClass("tabContentsScaleBack")
                    },200)
                    finchingY = false;
                }
                else if (finchingX){
                    $("#"+that.tabs[that.currentActiveTab].getClientId()).addClass( "tabContentsScaleBackX" ).one("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function(){
                        $(this).removeClass( "tabContentsScaleBackX" );
                        $(this).css("transform","none");
                    });

                    setTimeout(function(){
                        that.tabs[that.currentActiveTab].removeCssClass("tabContentsScaleBackX")
                    },200)
                    finchingX = false;
                }

                stopSwiping=  false;
                stopScrolling = false;
                if (direction == "left") {

                    if (disableLeftRightAction) return;

                    var targetIndex = that.currentActiveTab+1;
                    var speed = 200;

                    if (duration > 200) {
                        if (distance / w <= 0.50){
                            console.log("cancel",(distance / w));
                        //if (distance <= w - (w*0.2)){
                            targetIndex = that.currentActiveTab;
                            speed = cancelSpeed;
                        }
                    }

                    if (targetIndex > that.tabs.length-1){
                        targetIndex = that.tabs.length-1;
                        speed = cancelSpeed;
                    }

                    that.moveToTab(targetIndex,speed,true);
                }

                else if (direction == "right") {

                    if (disableLeftRightAction) return;

                    var targetIndex = that.currentActiveTab-1;
                    var speed = 200;

                    if (duration > 200) {
                        if (distance / w <= 0.50){
                            console.log("cancel");
                        //if (distance <= w - (w*0.2)){
                            targetIndex = that.currentActiveTab;
                            speed = cancelSpeed;
                        }
                    }

                    if (targetIndex < 0){
                        targetIndex = 0;
                        speed = cancelSpeed;
                    }
                    that.moveToTab(targetIndex,speed,true);
                }
                else{
                    speed = cancelSpeed;
                    that.moveToTab(that.currentActiveTab,speed,true);
                }
            },
            swipeStatus:function(event, phase, direction, distance, duration, fingers, fingerData, currentDirection)
            {
                if (Math.abs(fingerData[0].start.x < 35)){
                    return;
                }

                //that.tabs[that.currentActiveTab].parent.disableBounce();
                if (!that.tabs || !that.tabs[that.currentActiveTab]){
                    console.error("swipe status failed. tabs not available");
                    return;
                }

                if (that.swipeDisabled) return;

                if (!that.cachedElements[that.tabs[that.currentActiveTab].getClientId()]){
                    that.cachedElements[that.tabs[that.currentActiveTab].getClientId()] = document.querySelector("#"+that.tabs[that.currentActiveTab].getClientId());
                }
                if (that.tabs[that.currentActiveTab].scrollerContainerReference){
                    if (!that.cachedElements[that.tabs[that.currentActiveTab].scrollerContainerReference.getClientId()]){
                        that.cachedElements[that.tabs[that.currentActiveTab].scrollerContainerReference.getClientId()] = document.querySelector("#"+that.tabs[that.currentActiveTab].scrollerContainerReference.getClientId());
                    }
                    var elem = that.cachedElements[that.tabs[that.currentActiveTab].scrollerContainerReference.getClientId()];
                }
                else{
                    elem = that.cachedElements[that.tabs[that.currentActiveTab].getClientId()];
                }

                var translation = 0;
                //console.log(elem,elem.scrollHeight - elem.scrollTop, elem.offsetHeight)
               // console.log("set translation",duration,elem.scrollTop())
                if (!that.isSwiping && elem.scrollTop == 0 && direction == "down"){
                    var t = Math.min(1,distance/100)
                    translation = 1+ (0.02 * t);
                    that.tabs[that.currentActiveTab].setCss({
                        "transform":"scaleY("+translation+")",
                        "transform-origin": "top right"
                    });
                    finchingY = true;
                    return;
                }
                else if (!that.isSwiping && direction == "up" && elem.scrollHeight - elem.scrollTop == elem.offsetHeight) {

                    var t = Math.min(1,distance/100)
                    translation = 1+ (0.02 * t);
                    that.tabs[that.currentActiveTab].setCss({
                        "transform":"scaleY("+translation+")",
                        "transform-origin": "bottom right"
                    });
                    finchingY = true;
                }

                lastScrollPosition = elem.scrollTop;


                if (phase=="cancel"){
                    var speed = cancelSpeed;
                    that.moveToTab(that.currentActiveTab,speed,true);
                    //stopSwiping=  false;
                    stopScrolling = false;
                    //str +="<br/>Handler not triggered. <br/> One or both of the thresholds was not met "
                    return;
                }
                else if (phase=="end"){
                    //console.log("end");
                    speed = cancelSpeed;
                    //that.moveToTab(that.currentActiveTab,speed,true);
                    //stopSwiping=  false;
                    stopScrolling = false;
                    //str +="<br/>Handler was triggered."
                    return;
                }

                //var xDistance = Math.abs(fingerData[0].start.x - fingerData[0].last.x);
                var xDistance = Math.abs(distance);
                var startOffset = w * that.currentActiveTab;
                var indicatorOffset = (w/that.tabs.length) * that.currentActiveTab;

                //disable scrolling
                if (direction == "up" || direction == "down") {
                    disableLeftRightAction = true;
                    if (!stopScrolling) {
                        stopSwiping = true;
                    }
                }

                if (!disableLeftRightAction && !stopSwiping && direction == "right") {
                    stopScrolling = true;


                    var t = -startOffset + xDistance;
                    var tNonNormalised = t;
                    var t2 = indicatorOffset - ((xDistance/w) * (w/that.tabs.length));

                    if (t > 0){
                        t = 0;
                        t2 = 0;
                    }
                    var progression = t/(w*that.tabs.length);

                    if (!that.isSwiping){
                        that.triggerEvent("swipeStart");
                        that.isSwiping = true;
                    }

                    tabContentsElem.css({"transition-duration":"20ms","transform": "translate("+t+"px)"})
                    tabIndicatorElem.css({"transition-duration":"20ms","transform": "translate("+t2+"px)"})

                    if (progression == 0 && fingerData[0].start.x > 30){

                        var translation = 1+ (0.0005 * Math.min(40,tNonNormalised));
                        that.tabs[that.currentActiveTab].setCss({
                            "transform":"scaleX("+translation+")",
                            "transform-origin": "bottom left"
                        });
                        finchingX = true;
                    }
                    if (that.parallaxBg){
                        that.cachedParllaxBgXOffset = ((progression)*10);

                        if (FootballManiaConfig.isRtl){
                            that.parallaxBg.setCss({"margin-right":"-20%","transform": "translateY(-50px) translateX("+this.cachedParllaxBgXOffset+"%)","transition-duration": "0ms"})
                        }
                        else{
                            that.parallaxBg.setCss({"transform": "translateY(-50px)  translateX("+that.cachedParllaxBgXOffset+"%)","transition-duration": "0ms"})
                        }
                    }
                    //that.triggerEvent("onProgress",progression);
                }
                else if (!disableLeftRightAction && !stopSwiping && direction == "left"){
                    stopScrolling = true;

                    // if (finchingY){
                    //     return;
                    // }
                    //event.preventDefault();
                    //console.log("left",fingerData[0],xDistance)

                    var t = (-startOffset - xDistance);
                    var t2 = indicatorOffset + ((xDistance/w) * (w/that.tabs.length));

                    if (t < -((that.tabs.length-1) * w)){
                        t = -((that.tabs.length-1) * w);
                        t2 = w - (w/that.tabs.length);
                    }
                    var progression = t/(w*that.tabs.length);

                    if(-t  ==((that.tabs.length-1) * w)){
                        var translation = 1+ (0.0005 * Math.min(20,xDistance));
                        that.tabs[that.currentActiveTab].setCss({
                            "transform":"scaleX("+translation+")",
                            "transform-origin": "bottom right"
                        });
                        finchingX = true;
                    }

                    if (!that.isSwiping){
                        console.log("trigger swipe start");
                        that.triggerEvent("swipeStart");
                        that.isSwiping = true;
                    }

                    //console.log("progression","transition-duration","20ms","transform","translate("+t+"px)");
                    tabContentsElem.css({"transition-duration":"20ms","transform": "translate("+t+"px)"})
                    tabIndicatorElem.css({"transition-duration":"20ms","transform": "translate("+t2+"px)"})

                    if (that.parallaxBg){
                        that.cachedParllaxBgXOffset = ((progression)*10);

                        if (FootballManiaConfig.isRtl){
                            that.parallaxBg.setCss({"margin-right":"-20%","transform": "translateY(-50px) translateX("+this.cachedParllaxBgXOffset+"%)","transition-duration": "0ms"})
                        }
                        else{
                            that.parallaxBg.setCss({"transform": "translateY(-50px)  translateX("+that.cachedParllaxBgXOffset+"%)","transition-duration": "0ms"})
                        }
                    }
                    //jQuery("#"+that.parallaxBg).css({"transition-duration":"0ms","transform": "translate3d("+((progression)*10)+"%, 0px, 0px)"})
                    //that.triggerEvent("onProgress",progression);
                }
                else{

                }
            },
            //Default is 75px, set to 0 for demo so any distance triggers swipe
            threshold:30,
        });
    }
};

export class TabViewTab extends Panel{

    hideLoader(text,tab){
        if (!this.wrapper){
            //return console.error("cannot hide loader. never shown");
            return;
        }
        this.wrapper.setVisible(false);
    }

    showLoader(text,tab){

        if (this.wrapper){
            if (text) this.loaderText.setData(text);
            this.wrapper.setVisible(true);
            return;
        }

        this.loaderContainer = new Panel();
        this.loaderContainer.addCssClass("loader_container");

        this.wrapper = new Panel();
        this.wrapper.setStyle("margin-top:-50px;")
        this.wrapper.addCssClass("loader_wrapper")
        this.wrapper.addControl(this.loaderContainer);
        this.loaderSpinner = new Image();
        this.loaderSpinner.addCssClass("loader_spinner");
        this.loaderSpinner.setImage("images/loader1235.svg");
        this.loaderContainer.addControl(`
        <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
   <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
</svg>  
        `);

        this.loaderText = new Label();
        this.loaderText.setData();
        if (text) this.loaderText.setData(text);
        this.loaderText.addCssClass("loader_text");
        this.loaderContainer.addControl(this.loaderText);

        var fade = new Panel();
        fade.addCssClass("loader_fade");
        this.wrapper.addControl(fade);

        var background = new Panel();
        //background.addCssClass("loader_background");
        //this.loaderContainer.addControl(background);

        this.loaderfade = fade;
        this.addControl(this.wrapper,true);
    }
};