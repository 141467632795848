
//this control runs in combination with collapser (topbar) and tabiew
// when target scroller scrolls down it collapses the topbar and tabview tabs.
export const AnimatorHome = function(){

    var version = "1.0";

    var tabs = [];
    var scrollerMethods = [];
    var touchStartMethod = null;
    var touchEndMethod = null;
    var collapser = null;
    var tabview = null;
    var ignoreEvents = false;
    var ignoreEventForIndex = [];
    var lastListIndexScrolling = -1
    var unloaded = false;

    this.isTouching = false;
    this.reducerRatio = 0.3;
    this.transitionDuration = 100;
    this.alwaysCollapseOnBackScrolling = true;
    this.isCollapsed = false;
    this.lastRecalculateWasCollapsed = null;
    this.isVirtuallyCollapsed = false;
    this.lastScrollPositionAfterTouchStart = -1;
    this.lastYOffsetAfterTouchStart = 0;
    this.currentTranslateY = 0;
    this.currentActiveTab = -1;
    this.onCollapseChanged = null;

    this.addScrollerContainer = function(tab){
        tabs.push(tab);
    }

    this.addCollapser = function(c){
        collapser = c;
    }

    this.addTabView = function(tab){
        tabview = tab;
    }

    this.unload = function(){
        unloaded = true;
        if (collapser) {
            collapser.setCss({"transform": "translate(0px,0px)", "transition-duration": "0ms"}) //reset
        }
        if (tabs) {
            for (var i = 0; i < tabs.length; i++) {
                var scrollEl = jQuery("#" + tabs[i].getClientId());

                if (scrollerMethods[i]) {
                    scrollEl.off("scroll", scrollerMethods[i]);
                }
            }
        }

        $('body').off({
            'touchstart': touchStartMethod,
            'touchend' : touchEndMethod});

        collapser = null;
        tabs = null;
    }

    this.onWindowResize = function(){
        this.recalculate(true);
    }

    this.tabChange = function(){

        if (!FootballManiaConfig.graphics.animations) return;

        if (this.currentActiveTab == -1){
            this.currentActiveTab = tabview.currentActiveTab;
            //return;
        }

        var delay =300;
        var that =this;
        $("#"+collapser.getClientId()).css({"transform":"translate(0px,0px)","transition-duration": delay+"ms"});
        $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,0px)","transition-duration": delay+"ms"});

        this.currentTranslateY = 0;
        this.isVirtuallyCollapsed = false; //set this to false, because we set all scroll positions to 0 so everything becomes decollapsed
        this.isCollapsed = false;
        setTimeout(function(){
            //that.currentTranslateY = 0;
            that.recalculate();
        },delay)

        for (var i=0; i < tabs.length;i++) {
            if (i == tabview.currentActiveTab) continue;
            ignoreEvents = true;
            if (ignoreEventForIndex.indexOf(i) == -1) {
                ignoreEventForIndex.push(i);
            }
            (function (i) {
                setTimeout(function() {
                    var scrollElement = tabs[i];
                    var lazygrid = scrollElement.controls[0].controls[0];
                    if (lazygrid && lazygrid.scrollToLastSelectedOrTop) {
                        lazygrid.scrollToLastSelectedOrTop();
                    }
                    else{
                        document.querySelector("#" + scrollElement.getClientId()).scrollTo({
                            top: 0,
                            left: 0,
                        });
                    }

                },0);
            })(i)
            ignoreEvents = false;
        }

        that.currentActiveTab = tabview.currentActiveTab;
    }

    this.recalculateTab = function(i){


        if (!FootballManiaConfig.graphics.animations) return;

        if (!tabs || !tabs[i]){
            console.warn("cannot recalcuate without tabs");
            return;
        }
        if (!tabview || !tabview.tabContents || !tabview.tabContents.controls[i] || !tabview.tabContents.controls[i].controls[1] || !tabview.tabContents.controls[i].controls[1].controls[0]){
            console.warn("cannot recalcuate without tabview elements");
            return;
        }

        var contentWindowHeight = tabview.tabContents.getDimensions().height;

        if (collapser) {
            var collapserHeight = collapser.getDimensions().height;
        }

        var scrollEl = jQuery("#"+tabs[i].getClientId());
        var top  = collapserHeight;

        if (!scrollEl || !scrollEl[0]){
            console.warn("no scroller")
            return;
        }

        //console.log("RECALCULATE TAB ",i,"scrollheight",scrollEl[0].scrollHeight,"contentheight",contentWindowHeight)

        //when scrolllist is longer than the window (we can acualy scroll)
        if (scrollEl[0].scrollHeight > contentWindowHeight) {

            if (!tabview.tabContents.controls[i]){
                console.error("no tab content found for tab ",i);
                return;
            }

            //console.log("put it there");
            $("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "-" +top+"px"});
            $("#" + tabview.tabContents.controls[i].getClientId()).css({"height": contentWindowHeight + collapserHeight + "px"});

            $("#" + tabview.tabContents.controls[i].controls[1].controls[0].getClientId()).css({
                "position": "relative",
                "top": top+"px",
                "height" : contentWindowHeight+collapserHeight+"px"
            });
            //}
            $("#" + tabview.tabContents.controls[i].controls[0].getClientId()).css({"height": contentWindowHeight + collapserHeight + "px"});
            //enlarge the scrollbar
        }
        else{
            //console.log("no nonon ono no");
           //console.log("tab ",i,' content is not needed for scroll',contentWindowHeight)
            if (this.isCollapsed){
                //$("#" + tabview.tabContents.controls[i].getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "100ms"});
                //$("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "-" +top+"px"});
                //$("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "-" +top+"px","height": contentWindowHeight + "px"});
                $("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "-" +top+"px","height": "100%"});
            }
            else{
                //$("#" + tabview.tabContents.controls[i].getClientId()).css({"transform":"translate(0px,0px)","transition-duration": "100ms"})
                //$("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "0px"});
                //$("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "0px","height": contentWindowHeight + "px"});
                $("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "0px","height": "100%"});

                if (scrollEl.scrollTop() == 0){
                    $("#" + tabview.tabContents.controls[i].controls[1].controls[0].getClientId()).css({
                        "position": "absolute",
                        "top": "0px",
                    });
                }
                else{

                }
            }
        }
    }

    this.recalculate = function(force,onlyTabIndex){

        if (!FootballManiaConfig.graphics.animations) return;

        if (!tabview){
            //console.log("cannot recalculate without tabview");
            return;
        }

        ///console.log("RECALCULATE POSITIONS",tabview.currentActiveTab,"isCollapsed",this.isCollapsed);

        if (!force && this.lastRecalculateWasCollapsed!= null && this.isCollapsed == this.lastRecalculateWasCollapsed){
            //console.log("dont repeat calculation");
            return;
        }
        this.lastRecalculateWasCollapsed = this.isCollapsed;
        var collapserHeight = collapser.getDimensions().height;

        if (this.isCollapsed){
            $("#"+collapser.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
        }
        for (var i =0; i < tabs.length;i++) {
            this.recalculateTab(i);
        }
    }

    this.autoCollapseOrOpen = function(scrollPosition){

        if (!collapser) return;

        if (this.currentTranslateY == 0){
            this.isCollapsed = false;
            this.isVirtuallyCollapsed = false;
        }
        else if (this.currentTranslateY == -collapser.getDimensions().height){
            this.isCollapsed = true;
            this.isVirtuallyCollapsed = true;
        }

        console.log("AUTO collapse",this.isCollapsed,this.isVirtuallyCollapsed);

        if (this.currentTranslateY == 0 || this.currentTranslateY == -collapser.getDimensions().height){
            //console.log("no auto collapse when already open or closed",this.currentTranslateY);
            this.recalculate();
            return;
        }
        console.log("AUTO COLLAPSE",this.currentTranslateY)

        var collapserHeight = collapser.getDimensions().height;
        var that = this;
        var delay = 300;

        if ((scrollPosition) > (collapserHeight * (1/this.reducerRatio))/2){
            $("#"+collapser.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": delay+"ms"});
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": delay+"ms"});
            console.log(this.isTouching, "auto collapser scroll position = ", scrollPosition,this.currentTranslateY);

            that.currentTranslateY = -collapserHeight;
            this.isCollapsed = true;
            this.isVirtuallyCollapsed = true;
            this.onCollapseChanged();
            clearTimeout(this.tempTimeout);
            this.tempTimeout = setTimeout(function(){
                that.currentTranslateY = -collapserHeight;
                that.recalculate();
            },delay)

        }
        else {
            $("#"+collapser.getClientId()).css({"transform":"translate(0px,0px)","transition-duration": delay+"ms"});
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,0px)","transition-duration": delay+"ms"});
            console.log(this.isTouching, "auto collapser scroll position = ", scrollPosition);

            that.currentTranslateY = 0;
            this.isCollapsed = false;
            this.isVirtuallyCollapsed = false;
            this.onCollapseChanged();
            clearTimeout(this.tempTimeout);
            this.tempTimeout = setTimeout(function(){
                //that.currentTranslateY = 0;
                that.recalculate();
            },delay)
        }
    }

    this.prepare = function(){

        if (!FootballManiaConfig.graphics.animations) return;

        var collapserHeight = collapser.getDimensions().height;

        console.log("PREPARE ",this.isCollapsed);
        //this.isVirtuallyCollapsed = false;
        //make sure we start with a collapsed one before everything gets rendered. causes flickering
        if (this.isCollapsed){
            this.isVirtuallyCollapsed = true;
            $("#"+collapser.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
        }
    }

    this.run = function(){

        if (!FootballManiaConfig.graphics.animations) return;

        console.log("RUN ANIMATOR FOR HOMESCREEN "+version);
        lastListIndexScrolling = tabview.currentActiveTab;

        this.recalculate();

        var that = this;
        var timeoutHandler = null;
        var timeoutHandler2 = null;

        touchStartMethod = function (e) {
            clearTimeout(timeoutHandler2);
            clearTimeout(timeoutHandler);
            clearTimeout(that.tempTimeout);
            that.isTouching = true;
            var scrollElement = tabs[tabview.currentActiveTab];
            var topOffset = document.querySelector("#" + scrollElement.getClientId()).scrollTop
            that.lastScrollPositionAfterTouchStart = topOffset;
            that.lastYOffsetAfterTouchStart = that.currentTranslateY;
        };

        touchEndMethod = function(){
            that.isTouching = false;
            ///that.lastScrollPositionAfterTouchStart =-1;
        }

        $('body').on({
            'touchstart': touchStartMethod,
            'touchend' : touchEndMethod});

        function runTabSCrollLogic(index){

            var scrollEl = jQuery("#"+tabs[index].getClientId());

            var prevScroll = null;
            var ticking = false;

            var scrollDiff

            scrollerMethods[index] = function(e){

                clearTimeout(timeoutHandler2);
                clearTimeout(timeoutHandler);

                if (ticking) return;

                window.requestAnimationFrame(function() {

                    //console.log('scrolling tab '+index);
                    ticking = true;
                    if (ignoreEvents || index != tabview.currentActiveTab) {
                        console.log("ignore scroll event if not this active tab ", index)
                        ticking = false;
                        return;
                    }
                    if (ignoreEventForIndex.indexOf(index) != -1) {
                        console.log("ignore scroll event this time", index, ignoreEventForIndex);
                        //ignoreEventForIndex = -1;
                        ignoreEventForIndex.splice(ignoreEventForIndex.indexOf(index), 1);
                        ticking = false;
                        return;
                    }

                    var topOffset = e.originalEvent.target.scrollTop;
                    if (prevScroll == topOffset) {
                        ticking = false;
                        // console.log("do nothing no scroll change");
                        return;
                    }
                    lastListIndexScrolling = index;
                    clearTimeout(timeoutHandler2);

                    timeoutHandler2 = setTimeout(function () {
                        clearTimeout(timeoutHandler);
                        var topOffset = e.originalEvent.target.scrollTop;
                        that.lastScrollPositionAfterTouchStart =-1;
                        if (!that.isTouching) {
                            that.autoCollapseOrOpen(topOffset);
                        }
                    }, 250);

                    var topOffset = e.originalEvent.target.scrollTop;

                    var collapserHeight = collapser.getDimensions().height;

                    if (that.lastScrollPositionAfterTouchStart == -1) {
                        ///HACK investigate. when user clicks outside there is no touch start event
                        //that.lastScrollPositionAfterTouchStart = collapserHeight;
                    }

                    if (!that.alwaysCollapseOnBackScrolling || that.lastScrollPositionAfterTouchStart == -1) {
                        scrollDiff = Math.max(-topOffset, -collapserHeight);
                    } else {

                        if (that.isCollapsed && that.isVirtuallyCollapsed) {

                            var t = ((that.lastScrollPositionAfterTouchStart - topOffset) * that.reducerRatio) - collapserHeight;
                            scrollDiff = Math.max(-collapserHeight, Math.min(0, t));
                        } else {
                            if (that.lastScrollPositionAfterTouchStart !=-1) {
                                var t = ((that.lastScrollPositionAfterTouchStart - topOffset) * that.reducerRatio) + that.lastYOffsetAfterTouchStart;
                                scrollDiff = Math.min(0, Math.max(-collapserHeight, t));
                            }
                            else{
                                //first time otherwise we always have collapsed after startng
                                scrollDiff = 0;
                            }
                        }
                    }
                    that.currentTranslateY = scrollDiff;

                    ///console.log("scroll event ",index,scrollDiff,that.isCollapsed)

                    //this virtualcollapsed value is a little different than iscollapsed
                    //when user is collapsing and no touchend event is fired then the state of
                    //iscollapsed remains the same. the virtualcollapsed one changes
                    //this is needed in the recalculation of positions
                    if (scrollDiff == -collapserHeight) {
                        that.isVirtuallyCollapsed = true;
                    } else {
                        that.isVirtuallyCollapsed = false;
                    }

                    //console.log('scrolling tab ----> scrolldif: '+scrollDiff,that.lastScrollPositionAfterTouchStart);

                    //collapser.setCss({"transform":"translate(0px,"+scrollDiff+"px)","transition-duration": this.transitionDuration+"ms"});
                    collapser.setCss({
                        "transform": "translate(0px," + scrollDiff + "px)",
                        "transition-duration": that.transitionDuration + "ms"
                    });
                    //tabview.tabHolder.setCss({"transform":"translate(0px,"+scrollDiff+"px)","transition-duration": this.transitionDuration+"100ms"});
                    tabview.tabHolder.setCss({
                        "transform": "translate(0px," + scrollDiff + "px)",
                        "transition-duration": that.transitionDuration + "ms"
                    });

                    prevScroll = e.originalEvent.target.scrollTop;
                    ticking = false;
                    // });
                    //
                });
            }

            if (scrollerMethods[index]){
                scrollEl.off("scroll",scrollerMethods[index]);
            }

            scrollEl.scroll(scrollerMethods[index]);
        }

        for (var i =0; i < tabs.length;i++){
            runTabSCrollLogic(i);
        }
    }
}