import {CountryUtil} from "../util/country.js";
import {Localizer} from "../util/localizer.js";
import {MatchUtil} from "../util/matchutil.js";

export const Processors = {

    prepareTodayFeedData: function (options) {

        //console.log("prepare live feed",options);

        Localizer.LocalizedTranslations = options.translations;
        Localizer.DefaultTranslations = options.defaultTranslations;

        var countries = options.responseData;
        var fixedRegions = options.fixedRegions;
        var fixedLeagueOrder = options.fixedLeagueOrder;
        var favoriteTeams = options.favoriteTeams;
        var favoriteLeagues = options.favoriteLeagues;
        var hiddenTitles = options.hiddenTitles;
        var locale = options.locale;
        var userRegionLocale = options.userRegionLocale;
        var showOnlyFavorites = options.showOnlyFavorites;
        var subscribedTeams = options.subscribedTeams;
        var subscribedLeagues = options.subscribedLeagues;
        var subscribedMatches = options.subscribedMatches;
        var fontSize = options.fontSize;
        var userCountry = options.userCountry;

        //console.log("==========>",userCountry,locale,userRegionLocale);

        if (fontSize == "xlarge" || fontSize == "large") {
            var itemHeight = 60;
        }
        if (options.listType == "3"){
            itemHeight = 70;
        }
        else {
            itemHeight = 55;
        }

        var listData = [];

        var header;
        var country;
        var league;
        var match;
        var foundFixedIndex = 1;
        var isFixedToTop;
        var headerId = 0;
        var tempid = -1;
        var tempid2 = -1;
        var foundUnfiltered = false;
        var leagueIsSubscribed = false;
        var isFixedRegion = false;

        var foundFixedRegions = fixedLeagueOrder[userRegionLocale];

        if (foundFixedRegions && foundFixedRegions.leagues){
            var foundFixedLeagues = foundFixedRegions.leagues || [];
        }
        else{
            foundFixedLeagues = [];
        }
        if (foundFixedRegions && foundFixedRegions.countries){
            var foundFixedCountries = foundFixedRegions.countries || [];
        }
        else{
            foundFixedCountries = [];
        }

        for (var i = 0; i < countries.length; i++) {
            country = countries[i];

            var fixedCountryIndex = foundFixedCountries.indexOf(country.country);

            country.localizedCountry = CountryUtil.getLocalizedCountryByName(country.country, locale);

            for (var j = 0; j < country.leagues.length; j++) {
                league = country.leagues[j];
                isFixedToTop = false;
                headerId++;

                leagueIsSubscribed = false;
                if (subscribedLeagues.indexOf(league.key) != -1){
                    leagueIsSubscribed = true;
                }

                foundFixedIndex = foundFixedLeagues.indexOf(league.key);
                //foundFixedIndex = fixedLeagueOrder.indexOf(league.key);
                if (foundFixedIndex != -1) {
                    league.fixedIndex = foundFixedIndex;
                    foundFixedIndex++;
                    //console.log("is fixed",league.key);
                    isFixedToTop = true;
                }
                else if (fixedCountryIndex != -1){
                    league.fixedIndex = foundFixedIndex + 200;
                    foundFixedIndex++;
                    //console.log("is fixed",league.key);
                    isFixedToTop = true;
                }

                for (var k = 0; k < league.matches.length; k++) {
                    foundUnfiltered = true;
                    match = league.matches[k];
                    match.title = match.localteam + " - " + match.visitorteam;
                    match.type = 1;
                    match.country = country.country;
                    match.localizedCountry = country.localizedCountry;
                    match.league = league.league;
                    match.headerId = headerId;
                    match.style = {"height": itemHeight};
                    match.leagueKey = league.key;

                    if (isFixedToTop) {
                        match.foundFixedIndex = league.fixedIndex;
                        match.fixedIndex = true;
                    }

                    var defaultAdd = true;
                    if (showOnlyFavorites) {
                        defaultAdd = false;
                    }

                    // if (isFixedRegion){
                    //     match.isFixedRegion = true;
                    // }

                    tempid = favoriteTeams[match.gs_localteamid];
                    tempid2 = favoriteTeams[match.gs_visitorteamid];
                    if (tempid) {
                        match.isFavouritedByTeam = true;
                        match.favoriteTeamIndex = tempid.index;
                        defaultAdd = true;
                    } else if (tempid2) {
                        match.isFavouritedByTeam = true;
                        match.favoriteTeamIndex = tempid2.index;
                        defaultAdd = true;
                    } else {
                        match.isFavouritedByTeam = false;
                        match.favoriteTeamIndex = null;
                    }
                    tempid = favoriteLeagues[match.leagueKey]
                    if (tempid) {
                        match.isFavouritedByLeague = true;
                        match.favoriteLeagueIndex = tempid.index;
                        defaultAdd = true;
                    } else {
                        match.isFavouritedByLeague = false;
                        match.favoriteLeagueIndex = null;
                    }

                    if (subscribedMatches.indexOf(match.id+"") != -1){
                        match.isMatchSubscribed = true;
                        defaultAdd =true;
                    }
                    if (subscribedTeams.indexOf(match.gs_localteamid+"") != -1){
                        match.isLocalTeamSubscribed = true;
                        defaultAdd =true;
                    }
                    if (subscribedTeams.indexOf(match.gs_visitorteamid+"") != -1){
                        match.isVisitorTeamSubscribed = true;
                        defaultAdd =true;
                    }
                    if (leagueIsSubscribed){
                        match.isLeagueSubscribed = true;
                        defaultAdd =true;
                    }

                    if (!defaultAdd) continue;
                    listData.push(match);
                }
            }
        }

        listData.sort(function (a, b) {


            if (a.isFavouritedByTeam && b.isFavouritedByTeam) {
                return a.favoriteTeamIndex - b.favoriteTeamIndex;
            }
            else if (a.isFavouritedByTeam && !b.isFavouritedByTeam) return -1;
            else if (b.isFavouritedByTeam && !a.isFavouritedByTeam) return 1;

            else if (a.isFavouritedByLeague && b.isFavouritedByLeague) {
                return a.favoriteLeagueIndex - b.favoriteLeagueIndex;
            }
            else if (a.isFavouritedByLeague && !b.isFavouritedByLeague) return -1;
            else if (b.isFavouritedByLeague && !a.isFavouritedByLeague) return 1;

            if (userCountry){
                if (a.country == userCountry && b.country != userCountry) return -1;
                else if (b.country == userCountry && a.country != userCountry) return 1;
            }

            if (a.fixedIndex && !b.fixedIndex) return -1;
            if (b.fixedIndex && !a.fixedIndex) return 1;
            if (b.fixedIndex && a.fixedIndex){
                return a.foundFixedIndex - b.foundFixedIndex;
            }


            // if (a.isFixedRegion && b.isFixedRegion) {
            //     return 1;
            // }
            // else if (a.isFixedRegion && !b.isFixedRegion) return -1;
            // else if (b.isFixedRegion && !a.isFixedRegion) return 1;

            // if (a.isFavouritedByTeam && !b.isFavouritedByTeam) return -1;
            // else if (b.isFavouritedByTeam && !a.isFavouritedByTeam) return 1;
            // if (a.isFavouritedByLeague && !b.isFavouritedByLeague) return -1;
            // else if (b.isFavouritedByLeague && !a.isFavouritedByLeague) return 1;
            // if (a.fixedIndex && !b.fixedIndex) return -1;
            // else if (b.fixedIndex && !a.fixedIndex) return 1;

            //if (a.time == b.time){
            return a.localizedCountry.localeCompare(b.localizedCountry);
            //}
            //else{
            //  return a.time.localeCompare(b.time);
            //}

        });

        var usedHeader = -1;

        for (var i = 0; i < listData.length; i++) {
            if (listData[i].headerId != usedHeader) {
                listData[i]
                header = {
                    "country": listData[i].country,
                    "localizedCountry": listData[i].localizedCountry,
                    "league": listData[i].league,
                    "title": listData[i].localizedCountry + " - " + listData[i].league,
                    "type": 2,
                    "style": {"height": 45},
                    "isFavorite": listData[i].isFavouritedByTeam || listData[i].isFavouritedByLeague,
                    "isFavoriteTeam": listData[i].isFavouritedByTeam,
                    "isFavoriteLeague": listData[i].isFavouritedByLeague,
                    "key": listData[i].leagueKey
                };

                if (hiddenTitles.indexOf(listData[i].country) != -1) {
                    header.title = listData[i].league;
                }

                usedHeader = listData[i].headerId;
                listData.splice(i, 0, header);
                ++i;
            }
        }

        return {
            listData : listData,
            foundUnfiltered : foundUnfiltered
        };
    },

    prepareLiveFeedData : function(options){

        console.log("prepare live feed",options);

        Localizer.LocalizedTranslations = options.translations;
        Localizer.DefaultTranslations = options.defaultTranslations;

        var countries = options.responseData;
        var fixedRegions = options.fixedRegions;
        var fixedLeagueOrder = options.fixedLeagueOrder;
        var favoriteTeams = options.favoriteTeams;
        var favoriteLeagues = options.favoriteLeagues;
        var hiddenTitles = options.hiddenTitles;
        var locale = options.locale;
        var userRegionLocale = options.userRegionLocale;
        var showOnlyFavorites = options.showOnlyFavorites;
        var subscribedTeams = options.subscribedTeams;
        var subscribedLeagues = options.subscribedLeagues;
        var subscribedMatches = options.subscribedMatches;
        var fontSize = options.fontSize;
        var userCountry = options.userCountry;


        //TODO when coming from worker. theme size is not available. need to fix
        if (fontSize == "xlarge" || fontSize == "large") {
            var itemHeight = 60;
        }
        if (options.listType == "3"){
            itemHeight = 70;
        }
        else {
            itemHeight = 55;
        }

        var i =0;
        for (var key in favoriteLeagues){
            favoriteLeagues[key].index = ++i;
        }
        i=0;
        for (var key in favoriteTeams){
            //favoriteTeams[key].index = ++i;
        }

        var listData = [];

        var header;
        var country;
        var league;
        var match;
        var foundFixedIndex = 1;
        var isFixedToTop;
        var headerId = 0;
        var tempid = -1;
        var tempid2 = -1;
        var foundUnfiltered = false;
        var leagueIsSubscribed = false;

        var foundFixedRegions = fixedLeagueOrder[userRegionLocale];
        if (foundFixedRegions && foundFixedRegions.leagues){
            var foundFixedLeagues = foundFixedRegions.leagues || [];
        }
        else{
            foundFixedLeagues = [];
        }
        if (foundFixedRegions && foundFixedRegions.countries){
            var foundFixedCountries = foundFixedRegions.countries || [];
        }
        else{
            foundFixedCountries = [];
        }

        for (var i=0; i < countries.length;i++) {
            country = countries[i];
            var fixedCountryIndex = foundFixedCountries.indexOf(country.country);
            country.localizedCountry = CountryUtil.getLocalizedCountryByName(country.country,locale);

            for (var j = 0; j < country.leagues.length; j++) {
                league = country.leagues[j];

                leagueIsSubscribed = false;
                if (subscribedLeagues.indexOf(league.key) != -1){
                    leagueIsSubscribed = true;
                }

                isFixedToTop = false;
                headerId++;

                foundFixedIndex = foundFixedLeagues.indexOf(league.key);
                //foundFixedIndex = fixedLeagueOrder.indexOf(league.key);
                if (foundFixedIndex != -1) {
                    league.fixedIndex = foundFixedIndex;
                    foundFixedIndex++;
                    //console.log("is fixed",league.key);
                    isFixedToTop = true;
                }
                else if (fixedCountryIndex != -1){
                    league.fixedIndex = foundFixedIndex + 200;
                    foundFixedIndex++;
                    //console.log("is fixed",league.key);
                    isFixedToTop = true;
                }

                for (var k = 0; k < league.matches.length; k++) {
                    foundUnfiltered = true;
                    match = league.matches[k];
                    match.title = match.localteam + " - " + match.visitorteam;
                    match.type = 1;
                    match.country = country.country;
                    match.localizedCountry = country.localizedCountry;
                    match.league = league.league;
                    match.headerId = headerId;
                    match.style = {"height": itemHeight};

                    if (isFixedToTop){
                        match.foundFixedIndex = league.fixedIndex;
                        match.fixedIndex = true;
                    }

                    var defaultAdd = true;
                    if (showOnlyFavorites){
                        defaultAdd = false;
                    }

                    tempid = favoriteTeams[match.gs_localteamid];
                    tempid2 = favoriteTeams[match.gs_visitorteamid];
                    if (tempid){
                        match.isFavouritedByTeam = true;
                        match.favoriteTeamIndex = tempid.index;
                        defaultAdd = true;
                    }
                    else if (tempid2){
                        match.isFavouritedByTeam = true;
                        match.favoriteTeamIndex = tempid2.index;
                        defaultAdd = true;
                    }
                    else{
                        match.isFavouritedByTeam = false;
                        match.favoriteTeamIndex = null;
                    }
                    tempid = favoriteLeagues[match.leagueKey]
                    if (tempid){
                        match.isFavouritedByLeague = true;
                        match.favoriteLeagueIndex = tempid.index;
                        defaultAdd = true;
                    }
                    else{
                        match.isFavouritedByLeague = false;
                        match.favoriteLeagueIndex = null;
                    }

                    if (subscribedMatches.indexOf(match.id+"") != -1){
                        match.isMatchSubscribed = true;
                        defaultAdd =true;
                    }
                    if (subscribedTeams.indexOf(match.gs_localteamid+"") != -1){
                        match.isLocalTeamSubscribed = true;
                        defaultAdd =true;
                    }
                    if (subscribedTeams.indexOf(match.gs_visitorteamid+"") != -1){
                        match.isVisitorTeamSubscribed = true;
                        defaultAdd =true;
                    }
                    if (leagueIsSubscribed){
                        match.isLeagueSubscribed = true;
                        defaultAdd =true;
                    }


                    if (!defaultAdd) continue;
                    listData.push(match);
                }
            }
        }

        listData.sort(function(a, b) {
            if (a.isFavouritedByTeam && b.isFavouritedByTeam) {
                return a.favoriteTeamIndex - b.favoriteTeamIndex;
            }
            else if (a.isFavouritedByTeam && !b.isFavouritedByTeam) return -1;
            else if (b.isFavouritedByTeam && !a.isFavouritedByTeam) return 1;

            else if (a.isFavouritedByLeague && b.isFavouritedByLeague) {
                return a.favoriteLeagueIndex - b.favoriteLeagueIndex;
            }
            else if (a.isFavouritedByLeague && !b.isFavouritedByLeague) return -1;
            else if (b.isFavouritedByLeague && !a.isFavouritedByLeague) return 1;

            if (userCountry){
                if (a.country == userCountry && b.country != userCountry) return -1;
                else if (b.country == userCountry && a.country != userCountry) return 1;
            }

            if (a.fixedIndex && !b.fixedIndex) return -1;
            if (b.fixedIndex && !a.fixedIndex) return 1;
            if (b.fixedIndex && a.fixedIndex){
                return a.foundFixedIndex - b.foundFixedIndex;
            }


            // if (a.isFavouritedByTeam && b.isFavouritedByTeam) {
            //     return a.favoriteTeamIndex - b.favoriteTeamIndex;
            // }
            // else if (a.isFavouritedByTeam && !b.isFavouritedByTeam) return -1;
            // else if (b.isFavouritedByTeam && !a.isFavouritedByTeam) return 1;
            //
            // else if (a.isFavouritedByLeague && b.isFavouritedByLeague) {
            //     return a.favoriteLeagueIndex - b.favoriteLeagueIndex;
            // }
            // else if (a.isFavouritedByLeague && !b.isFavouritedByLeague) return -1;
            // else if (b.isFavouritedByLeague && !a.isFavouritedByLeague) return 1;
            // else if (a.fixedIndex && b.fixedIndex) {
            //     return a.foundFixedIndex - b.foundFixedIndex;
            // }
            // else if (a.fixedIndex && !b.fixedIndex) return -1;
            // else if (b.fixedIndex && !a.fixedIndex) return 1;
            // if (a.isFavouritedByTeam && b.isFavouritedByTeam){
            //     return a.favoriteTeamIndex - b.favoriteTeamIndex;
            // }
            // if (a.isFavouritedByLeague && b.isFavouritedByLeague){
            //     return a.favoriteLeagueIndex - b.favoriteLeagueIndex;
            // }
            // if (a.fixedIndex && b.fixedIndex){
            //     return a.foundFixedIndex - b.foundFixedIndex;
            // }
            //
            // if (a.isFavouritedByTeam && !b.isFavouritedByTeam) return -1;
            // else if (b.isFavouritedByTeam && !a.isFavouritedByTeam) return 1;
            // if (a.isFavouritedByLeague && !b.isFavouritedByLeague) return -1;
            // else if (b.isFavouritedByLeague && !a.isFavouritedByLeague) return 1;
            // if (a.fixedIndex && !b.fixedIndex) return -1;
            // else if (b.fixedIndex && !a.fixedIndex) return 1;
            return a.localizedCountry.localeCompare(b.localizedCountry);
        });

        var usedHeader = -1;

        for (var i=0;i < listData.length;i++){
            if (listData[i].headerId != usedHeader){
                listData[i]
                header = {
                    "country":listData[i].country,
                    "localizedCountry": listData[i].localizedCountry,
                    "league":listData[i].league,
                    "title": listData[i].localizedCountry +" - "+listData[i].league,
                    "type": 2,
                    "style": {"height": 45},
                    "isFavorite" : listData[i].isFavouritedByTeam || listData[i].isFavouritedByLeague,
                    "isFavoriteTeam": listData[i].isFavouritedByTeam,
                    "isFavoriteLeague": listData[i].isFavouritedByLeague,
                    "key" : listData[i].leagueKey
                };

                if (hiddenTitles.indexOf(listData[i].country) != -1){
                    header.title = listData[i].league;
                }

                usedHeader = listData[i].headerId;
                listData.splice(i,0,header);
                ++i;
            }
        }
        return {
            listData : listData,
            foundUnfiltered : foundUnfiltered
        };
    },

    prepareHomeFeedData : function(options) {
        Localizer.LocalizedTranslations = options.translations;
        Localizer.DefaultTranslations = options.defaultTranslations;

        var enabledFeatures = options.enabledFeatures;
        var responseData = options.responseData;
        var fixedRegions = options.fixedRegions;
        var translations = options.translations;
        var favoriteTeams = options.favoriteTeams;
        var favoriteLeagues = options.favoriteLeagues;
        var locale = options.locale;
        var userRegionLocale = options.userRegionLocale;
        var defaultTranslations = options.defaultTranslations;
        var availableNewsLocales = options.availableNewsLocales;
        var newsLocaleRemap = options.newsLocaleRemap;

        var listData = [];

        var item = {
            "title": Localizer.getString("agenda"),
            "image": "agendaitem.png",
            "type": 6,
            "style": {"height": 50}
        };
        listData.push(item);

        if (enabledFeatures.news) {
            var item = {
                "title": Localizer.getString("news"),
                "image": "newsitem.png",
                "type": 3,
                "style": {"height": 50},
                "item": "news"
            };

            var templocale = locale;
            if (newsLocaleRemap[locale]) {
                templocale = newsLocaleRemap[locale];
            }

            if (availableNewsLocales.indexOf(templocale) != -1) {
                listData.push(item);
            }
        }

        if (responseData && responseData.showRemoveAds) {
            var item = {
                "title": Localizer.getString("remove_ads"),
                "image": "noadsitem.png",
                "type": 3,
                "style": {"height": 50},
                "item": "news"
            };
            listData.push(item);
        }

        //console.log(Object.keys(favoriteTeams))
        var header = {"title": Localizer.getString("favoriteteams"), "type": 8, "style": {"height": 40}};
        listData.push(header);

        if (Object.keys(favoriteTeams).length) {
            var tempTeams = [];
            for (var key in favoriteTeams) {
                favoriteTeams[key].key = key;
                tempTeams.push(favoriteTeams[key]);
                //console.log(favoriteTeams[key]);
            }
            tempTeams.sort(function (a, b) {
                if (a.index != undefined && b.index != undefined) return a.index - b.index;
            });
            // console.log(JSON.stringify(tempTeams));
            // console.log(tempTeams);
            for (var i=0; i <tempTeams.length;i++){
                listData.push(
                    {
                        name: tempTeams[i].name,
                        id: tempTeams[i].key,
                        is_live: responseData.livestats ? responseData.livestats.teams.indexOf(tempTeams[i].key) != -1 ? true : false : false,
                        type: 4, style: {"height": 50}
                    });
            }
        }
        else{
            var header = {"type": 7, "style": {"height": 50}};
            listData.push(header);
        }

        var header = {"title":Localizer.getString("favoriteleagues"),"type":9,"style":{"height":40}};
        listData.push(header);

        if (Object.keys(favoriteLeagues).length != 0){

            var tempLeagues = [];
            for (var key in favoriteLeagues) {
                favoriteLeagues[key].key = key;
                tempLeagues.push(favoriteLeagues[key]);
                //console.log(favoriteTeams[key]);
            }

            tempLeagues.sort(function (a, b) {
                if (a.index != undefined && b.index != undefined) return a.index - b.index;
            });

            for (var i=0; i <tempLeagues.length;i++){
                listData.push(
                    {
                        name: tempLeagues[i].name,
                        country:tempLeagues[i].country,
                        key:tempLeagues[i].key,
                        is_live: responseData.livestats ? responseData.livestats.leagues[tempLeagues[i].key] != null ? true : false : false,
                        type: 5, style: {"height": 50}
                    });
            }

            // for (var key in favoriteLeagues){
            //     listData.push(
            //         {name:favoriteLeagues[key].name,
            //             key:key,
            //             country:favoriteLeagues[key].country,
            //             is_live : responseData.livestats ? responseData.livestats.leagues[key] != null ? true : false : false,
            //             type:5,style:{"height":50}});
            // }
        }
        else{
            var header = {"type": 10, "style": {"height": 50}};
            listData.push(header);
        }

        var header = {"title":Localizer.getString("entertainment"),"type":2,"style":{"height":40}};
        listData.push(header);

        var item = {"title":Localizer.getString("top_voters"),"image":"topvoters.png","type":3,"style":{"height":50}};
        //listData.push(item);
        var item = {"title":Localizer.getString("forum"),"image":"specialitem8.png","type":3,"style":{"height":50}};
        //listData.push(item);
        var item = {"item":"fifaranking","title":Localizer.getString("world_ranking_list"),"image":"ranking.png","type":3,"style":{"height":50}};
        listData.push(item);
        var item = {"item":"playervalues","title":Localizer.getString("player_market_values"),"image":"playermarketvalue.png","type":3,"style":{"height":50}};
        listData.push(item);

        if (enabledFeatures.forum) {
            var item = {
                "item": "forumindex",
                "title": Localizer.getString("forum"),
                "image": "praten.png",
                "type": 3,
                "style": {"height": 50}
            };
            listData.push(item);
        }
        var item = {"item":"topvoters","title":Localizer.getString("top_voters"),"image":"topvoters.png","type":3,"style":{"height":50}};
        listData.push(item);

        var header = {"title":Localizer.getString("International"),"type":2,"style":{"height":40}};
        listData.push(header);
        var regions = [];
        for (var i=0; i < responseData.countries.length;i++){
            responseData.countries[i].type = 1;
            responseData.countries[i].isFixed = fixedRegions.indexOf(responseData.countries[i].country) != -1 ? true : false;
            responseData.countries[i].foundFixedIndex = fixedRegions.indexOf(responseData.countries[i].country);
            responseData.countries[i].countryLocalized = CountryUtil.getLocalizedCountryByName(responseData.countries[i].country,locale);
            responseData.countries[i].style = {"height":50}
            regions.push(responseData.countries[i])
        }
        regions.sort(function(a, b) {
            if (a.isFixed && b.isFixed) return a.foundFixedIndex - b.foundFixedIndex;
            if (a.isFixed && !b.isFixed) return -1;
            else if (b.isFixed && !a.isFixed) return 1;
            return a.countryLocalized.localeCompare(b.countryLocalized);
        });

        listData = listData.concat(regions);

        for (var i=0; i < listData.length;i++){
            if (listData[i].type == 1 && !listData[i].isFixed){
                var header = {"title":Localizer.getString("Regions"),"type":2,"style":{"height":40}};
                listData.splice(i, 0, header);
                break;
            }
        }

        return listData;
    },

    prepareFixtureData : function(options){

        Localizer.LocalizedTranslations = options.translations;
        Localizer.DefaultTranslations = options.defaultTranslations;

        var data = options.responseData;
        var dontSelectByCurrentDate = options.dontSelectByCurrentDate;
        var fontSize = options.fontSize;

        if (fontSize == "xlarge" || fontSize == "large") {
            var itemHeight = 60;
        }
        if (options.listType == "3"){
            itemHeight = 70;
        }
        else {
            itemHeight = 55;
        }

        var listData = [];
        var match;
        var headerId = 2;
        var prevWeek = -1;
        var header;
        var currentTc = new Date();
        currentTc.setHours(0,0,0,0);
        currentTc = currentTc.getTime();
        var foundSelected = false;

        for (var i=0; i < data.fixtures.length;i++){
            match = data.fixtures[i];

            match.type = 1;
            match.dayTimecodeLocalized = MatchUtil.getLocalizedDayTimecode(match);
            match.timecode = MatchUtil.getLocalizedTimecode(match);

            match.country = match.filegroup;
            match.league = match.leaguename;
            match.headerId = headerId;
            match.style = {"height": itemHeight};

            listData.push(match);
        }

        listData.sort(function(a, b) {
            if (a.timecode > b.timecode){
                return 1;
            }
            else if (a.timecode < b.timecode){
                return -1;
            }
            else{
                return a.localteam.localeCompare(b.localteam);
            }
        });

        var newListData = [];

        var prevHeader = null;
        for (var i=0; i < listData.length;i++){

            ///console.log(listData[i].id,listData[i].timecode,listData[i].week,listData[i].date,listData[i].time, "--->",new Date(listData[i].timecode));
            //select this block when playing live
            if (!dontSelectByCurrentDate && !foundSelected && MatchUtil.isPlaying(listData[i])){
                foundSelected = true;
                if (prevHeader){
                    prevHeader.style.selected = true;
                }
            }

            if (prevWeek != listData[i].dayTimecodeLocalized){

                header = {
                    "week":listData[i].week,
                    "date":listData[i].date,
                    "time":listData[i].time,
                    "stage":listData[i].stageName,
                    "stageName":listData[i].stageName,
                    "round":listData[i].stageRound,
                    "dayTimecodeLocalized":listData[i].dayTimecodeLocalized,
                    "style" : {"height": 30},
                    "type" : 2
                };

                prevHeader = header;

                if (!dontSelectByCurrentDate && !foundSelected && currentTc <= listData[i].dayTimecodeLocalized){
                    console.log("selecting header",new Date(currentTc),new Date(listData[i].dayTimecodeLocalized));
                    foundSelected = true;
                    header.style.selected = true;
                }
                newListData.push(header);
            }

            newListData.push(listData[i])
            prevWeek = listData[i].dayTimecodeLocalized;
        }

        if (!foundSelected && newListData.length > 0){
            newListData[newListData.length-1].style.selected = true;
        }

        return newListData;
    },

    prepareTeamFixtureData : function(options){

        console.log("prepare team fixture data",options);

        Localizer.LocalizedTranslations = options.translations;
        Localizer.DefaultTranslations = options.defaultTranslations;

        var data = options.responseData;
        var fontSize = options.fontSize;
        var listType = options.listType;

        if (fontSize == "xlarge" || fontSize == "large") {
            var itemHeight = 60;
        }
        if (options.listType == "3"){
            itemHeight = 70;
        }
        else {
            itemHeight = 55;
        }

        if (data.isProcessedByProcessor) return data;
        data.isProcessedByProcessor = true;

        var listData = [];
        var match;
        var headerId = 2;
        var currentTc = new Date();
        currentTc.setHours(0,0,0,0,0);
        currentTc = currentTc.getTime();

        var inTheFuture = [];
        var inThePast = [];
        var nowPlaying = null;
        var todayPlaying = null;
        var tomorrowPlaying = null;
        var usedIds = [];

        if (data.fixtures) {
            for (var i = 0; i < data.fixtures.length; i++) {
                if (usedIds.indexOf(data.fixtures[i].id) != -1) continue;
                usedIds.push(data.fixtures[i].id);

                match = data.fixtures[i];

                match.type = 1;
                match.dayTimecodeLocalized = MatchUtil.getLocalizedDayNoUTCTimecode(match);
                match.dayTimecodeLocalized = Math.floor(match.dayTimecodeLocalized / 1000) * 1000
                match.timecode = MatchUtil.getLocalizedTimecode(match);

                match.country = match.filegroup;
                match.league = match.leaguename;
                match.headerId = headerId;
                match.style = {"height": itemHeight};

                // console.log(new Date(match.dayTimecodeLocalized),new Date(currentTc))
                // console.log(match.dayTimecodeLocalized,currentTc)

                if (match.status != null && (!isNaN(match.status) || match.status == "HT" || match.status == "P") || match.status == "Break") {
                    nowPlaying = match;
                } else if (match.dayTimecodeLocalized == currentTc) {
                    if (match.status != null && match.status == "FT" || match.status == "AET" || match.status == "Pen." || match.status == "Aban.") {
                        match.isfinished = true;
                    }
                    todayPlaying = match;
                } else if (match.dayTimecodeLocalized < currentTc) {
                    inThePast.push(match);
                } else {
                    inTheFuture.push(match);
                }


                listData.push(match);
            }
        }

        inTheFuture.sort(function(a, b) {
            if (a.timecode > b.timecode){
                return 1;
            }
            else if (a.timecode < b.timecode){
                return -1;
            }
            else{
                return a.localteam.localeCompare(b.localteam);
            }
        });

        inThePast.sort(function(a, b) {
            if (a.timecode > b.timecode){
                return 1;
            }
            else if (a.timecode < b.timecode){
                return -1;
            }
            else{
                return a.localteam.localeCompare(b.localteam);
            }
        });

        inThePast.reverse();

        data.futureMatches = inTheFuture;
        data.pastMatches = inThePast;
        data.nowPlaying = nowPlaying;
        data.todayPlaying = todayPlaying;


        if (!data.nowPlaying && !data.todayPlaying && data.futureMatches[0]){
            //data.nextMatch = data.futureMatches[0];
            //data.futureMatches.splice(0,1);
        }

        if (todayPlaying && !todayPlaying.isfinished && inThePast.length > 0) {
            data.lastPlayedMatch = inThePast[0];
        }
        if (!todayPlaying && inThePast.length > 0) {
            data.lastPlayedMatch = inThePast[0];
        }

        var countryTableFound = false;
        if (data.leagues && data.leagues.length > 0) {
            for (var k = 0; k < data.leagues.length; k++) {
                if (data.country != data.leagues[k].country){
                    countryTableFound = true;
                }
            }
        }

        //some table stuff
        var newLeagues = [];

        if (data.leagues && data.leagues.length > 0){
            for (var k=0; k < data.leagues.length;k++) {


                // if (data.country != data.leagues[k].country){
                //     continue;
                // }
                if (data.leagues[k].table && data.leagues[k].table.filegroup == "empty"){
                    continue;
                }

                var newGroups = [];
                var useLeague = false;

                if (!data.leagues[k].table) continue;

                data.leagues[k].isRealCountry = CountryUtil.isRealCountry(data.leagues[k].country) ? 1 : 0;

                console.log("LLLLLL",data.leagues[k]);

                for (var i = 0; i < data.leagues[k].table.groups.length; i++) {

                    var group = data.leagues[k].table.groups[i];

                    if (data.leagues[k].table.groups[i].is_current != 1) continue;

                    var newTeams = [];

                    for (var j = 0; j < group.teams.length; j++) {
                        if (group.teams[j].id_gs == data.id_gs) {
                            console.log("found team",group.teams[j]);
                            newTeams.push(group.teams[j]);
                        }
                    }

                    data.leagues[k].table.groups[i].teams = newTeams;

                    if (newTeams.length) {
                        console.log("yes we have teams in this group");
                        newGroups.push(data.leagues[k].table.groups[i]);
                        useLeague = true;
                    }
                }

                if (useLeague){
                    newLeagues.push(data.leagues[k]);
                }
                data.leagues[k].table.groups = newGroups;
            }
        }

        data.leagues = newLeagues;
        data.leagues.sort((a,b)=>{
            return b.isRealCountry - a.isRealCountry;
        });

        if (data.squad) {
            for (var i = 0; i < data.squad.length; i++) {
                data.squad[i].type = 1;
                data.squad[i].style = {"height": 50};
            }

            data.squad = data.squad.sort(function(a, b) {
                if (a.position == "A" && b.position != "A"){
                    return -1;
                }
                else if (a.position == "M" && b.position != "M"){
                    return -1;
                }
                else if (a.position == "D" && b.position != "D"){
                    return -1;
                }
                else if (a.position == "G" && b.position != "G"){
                    return -1;
                }
                else{
                    return parseInt(b.goals) - parseInt(a.goals);
                }
            });

            var prevPosition = -1;
            for (var i=0; i < data.squad.length;i++){

                if (data.squad.injured != "True" && data.sidelined){
                    for (var j=0; j < data.sidelined.length;j++){
                        if (data.sidelined[j].id == data.squad[i].id){
                            data.squad[i].injuryDescription = data.sidelined[j].description;
                            data.squad[i].injuryFrom = data.sidelined[j].startdate;
                            break;
                        }
                    }
                }

                if (prevPosition != data.squad[i].position){
                    var header = {"type":2,"style":{"height":40}};
                    if (data.squad[i].position == "A"){
                        header.title = Localizer.getString("attackers");
                    }
                    else if (data.squad[i].position == "M"){
                        header.title = Localizer.getString("midfielders");
                    }
                    else if (data.squad[i].position == "G"){
                        header.title = Localizer.getString("goalkeepers");
                    }
                    else if (data.squad[i].position == "D"){
                        header.title = Localizer.getString("defenders");
                    }

                    data.squad.splice(i, 0, header);
                    i++;
                    prevPosition = data.squad[i].position;
                }
            }
        }

        if (data.coach) {

            var header = {"type":2,"style":{"height":40}};
            header.title = Localizer.getString("coach");

            var item = {};
            item.type = 3;
            item.style = {"height": 50};
            item.data = {coach: data.coach, coachid: data.coachid,coachbirthcountry: data.coachbirthcountry};

            if (!data.squad){
                data.squad = [];
            }
            data.squad.splice(0, 0, item);
            data.squad.splice(0, 0, header);
        }

        if (data.transfers){
            var transferData = [];

            if (data.transfers.in && data.transfers.in.length) {
                var header = {"type":2,"style":{"height":40}};
                header.title = Localizer.getString("incoming_transfers");
                transferData.push(header);
                for (var i = 0; i < data.transfers.in.length; i++) {
                    var item = {};
                    item.type = 1;
                    item.style = {"height": 55};
                    data.transfers.in[i].team = data.transfers.in[i].from;
                    item.data = data.transfers.in[i];
                    transferData.push(item);
                }
            }
            if (data.transfers.out && data.transfers.out.length) {
                var header = {"type": 2, "style": {"height": 40}};
                header.title = Localizer.getString("outgoing_transfers");
                transferData.push(header);
                for (var i = 0; i < data.transfers.out.length; i++) {
                    var item = {};
                    item.type = 1;
                    item.style = {"height": 55};
                    data.transfers.out[i].team = data.transfers.out[i].to;
                    item.data = data.transfers.out[i];
                    transferData.push(item);
                }
            }
            data.transfers = transferData;
        }

        if (data.trophies){
            var previousLeague = null;
            for (var i=0; i < data.trophies.length;i++){

                if (previousLeague != data.trophies[i].league){
                    var header = {"type":2,"style":{"height":40}};
                    header.title = data.trophies[i].league;
                    header.country = data.trophies[i].country;
                    data.trophies.splice(i, 0, header);
                    i++;
                    previousLeague = data.trophies[i].league;
                }
                var seasons =data.trophies[i].seasons;
                var splitted =seasons.replaceAll(" ","").split(",");
                data.trophies[i].seasons = splitted;
                data.trophies[i].type = 1;
                data.trophies[i].style = {"height": 60};
            }
        }

        return data;
    },

    preparePlayerData : function(data,translations,defaultTranslations,userLocale){

        // Localizer.LocalizedTranslations = translations;
        // DefaultTranslations = defaultTranslations;
        if (data.isProcessedByProcessor) return data;
        data.isProcessedByProcessor = true;

        data.type = 1;
        data.style = {"height": 55};

        var allStatistics = [];
        if (data.statistics && data.statistics.length){
            allStatistics = allStatistics.concat(data.statistics);
        }
        if (data.statisticscups && data.statisticscups.length){
            allStatistics = allStatistics.concat(data.statisticscups);
        }
        if (data.statisticscupsintl && data.statisticscupsintl.length){
            allStatistics = allStatistics.concat(data.statisticscupsintl);
        }
        if (data.statisticsintl && data.statisticsintl.length){
            allStatistics = allStatistics.concat(data.statisticsintl);
        }
        allStatistics.sort(function(a,b){
            return b.season.localeCompare(a.season);
        })

        if (data.sidelined && data.sidelined.length){
            for (var i=0; i < data.sidelined.length;i++){
                var item = data.sidelined[i];
                item.injurytype = item.type;
                item.type = 1;
                item.style = {"height": 50};
            }
        }

        if (data.trophies){
            var previousLeague = null;
            for (var i=0; i < data.trophies.length;i++){

                if (previousLeague != data.trophies[i].league){
                    var header = {"type":2,"style":{"height":40}};
                    header.title = data.trophies[i].league;
                    header.country = data.trophies[i].country;
                    data.trophies.splice(i, 0, header);
                    i++;
                    previousLeague = data.trophies[i].league;
                }
                var seasons =data.trophies[i].seasons;
                var splitted =seasons.replaceAll(" ","").split(",");
                data.trophies[i].seasons = splitted;
                data.trophies[i].type = 1;
                data.trophies[i].style = {"height": 60};
            }
        }

        if (data.transfers && data.transfers.length) {
            // var header = {"type":2,"style":{"height":40}};
            // header.title = Localizer.getString("incoming_transfers");
            // transferData.push(header);
            for (var i = 0; i < data.transfers.length; i++) {
                data.transfers[i].transferType = data.transfers[i].type;
                data.transfers[i].type = 1;
                data.transfers[i].style = {"height": 55};
                data.transfers[i].team = data.transfers[i].from;
                ////transferData.push(item);
            }
        }

        if (data.careerclubs && data.careerclubs.length) {
            for (var i = 0; i < data.careerclubs.length; i++) {
                data.careerclubs[i].type = 1;
                data.careerclubs[i].style = {"height": 55};
            }
        }

        data.allStatistics = allStatistics;
        return data;
    },

    prepareRankingData : function(data,translations,defaultTranslations,userLocale){

        for (var i=0; i < data.length;i++){
            var item = data[i];
            item.type = 1;
            item.style = {"height": 50};
        }

        return data;
    },

    preparePlayerMarketValuesData : function(data,filterData){

        var filteredData = [];
        for (var i=0; i < data.length;i++){

            //console.log(filterData,data[i].nationality.toLowerCase(),data[i]);
            if (filterData && filterData.length){
                //console.log(data[i].nationality);
               if (filterData.indexOf(data[i].nationality.toLowerCase()) == -1){
                   continue;
               }
            }
            var item = data[i];
            item.type = 1;
            item.style = {"height": 50};
            filteredData.push(item);
        }

        return filteredData;
    },

    prepareTopVotersData : function(data){
        var result = [];

        if (!data || !data.responseData) return result;
        for (var i=0; i < data.responseData.topVoters.length;i++){
            var item = data.responseData.topVoters[i];
            item.type = 1;
            item.style = {"height": 50};
            result.push(item);
        }
        return result;
    },

    prepareUserVotesData : function(data){
        var result = {};
        if (!data) return result;
        var futurelistData = [];
        var pastlistData = [];


        var pastVotes = data.voteResults;
        for (var i=0; i < pastVotes.length; i++){

            var match = pastVotes[i];
            match.type = 1;
            match.style = {"height": 50};
            match.isPast = true;
            match.dayTimecodeLocalized = MatchUtil.getLocalizedDayTimecode(match);
            match.timecode = MatchUtil.getLocalizedTimecode(match);

            match.country = match.filegroup;
            match.league = match.leaguename;
            //match.headerId = headerId;
            pastlistData.push(match);
        }

        pastlistData.sort(function(a, b) {
            if (a.timecode < b.timecode){
                return 1;
            }
            else if (a.timecode > b.timecode){
                return -1;
            }
            else{
                return a.localteam.localeCompare(b.localteam);
            }
        });

        var newListData = [];
        var header,prevWeek;
        var prevHeader = null;
        for (var i=0; i < pastlistData.length;i++){


            if (prevWeek != pastlistData[i].dayTimecodeLocalized){

                header = {
                    "week":pastlistData[i].week,
                    "date":pastlistData[i].date,
                    "time":pastlistData[i].time,
                    "stage":pastlistData[i].stageName,
                    "stageName":pastlistData[i].stageName,
                    "round":pastlistData[i].stageRound,
                    "dayTimecodeLocalized":pastlistData[i].dayTimecodeLocalized,
                    "style" : {"height": 30},
                    "type" : 2
                };

                prevHeader = header;

                newListData.push(header);
            }

            newListData.push(pastlistData[i])
            prevWeek = pastlistData[i].dayTimecodeLocalized;
        }
        pastlistData = newListData;



        var futureVotes = data.voteFuture;
        for (var i=0; i < futureVotes.length; i++){

            var match = futureVotes[i];
            match.type = 1;
            match.style = {"height": 50};

            match.dayTimecodeLocalized = MatchUtil.getLocalizedDayTimecode(match);
            match.timecode = MatchUtil.getLocalizedTimecode(match);

            match.country = match.filegroup;
            match.league = match.leaguename;
            //match.headerId = headerId;
            futurelistData.push(match);
        }

        futurelistData.sort(function(a, b) {
            if (a.timecode > b.timecode){
                return 1;
            }
            else if (a.timecode < b.timecode){
                return -1;
            }
            else{
                return a.localteam.localeCompare(b.localteam);
            }
        });

        var newListData = [];
        var header,prevWeek;
        var prevHeader = null;
        for (var i=0; i < futurelistData.length;i++){


            if (prevWeek != futurelistData[i].dayTimecodeLocalized){

                header = {
                    "week":futurelistData[i].week,
                    "date":futurelistData[i].date,
                    "time":futurelistData[i].time,
                    "stage":futurelistData[i].stageName,
                    "stageName":futurelistData[i].stageName,
                    "round":futurelistData[i].stageRound,
                    "dayTimecodeLocalized":futurelistData[i].dayTimecodeLocalized,
                    "style" : {"height": 30},
                    "type" : 2
                };

                prevHeader = header;

                newListData.push(header);
            }

            newListData.push(futurelistData[i])
            prevWeek = futurelistData[i].dayTimecodeLocalized;
        }

        futurelistData = newListData;

        data.listData = futurelistData;
        data.listData = data.listData.concat(pastlistData);
        ////data.listData.push(pastlistData);

        return data;
    },

    prepareForumIndexData : function(data){
        var prevGroup = null;
        for (var i=0; i < data.length;i++){

            if (data[i].group != prevGroup){
                var header = {"type":2,"style":{"height":40}};
                header.title = data[i].group;
                data.splice(i, 0, header);
                i++;
                prevGroup = data[i].group;
            }

            var item = data[i];
            item.type = 1;
            item.style = {"height": 55};
        }
        return data;
    },

    //binary search for finding nearest top offset element.
    //thanks to chatgtp. it gave this answer right away
    findNearestItem : function(array,target){
        let left = 0;
        let right = array.length - 1;
        var current;

        while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            current = array[mid];

            if (current.style.offset > target) {
                right = mid - 1;
            } else if (current.style.offset < target) {
                left = mid + 1;
            } else {
                return mid;
            }
        }

        // If we didn't find an exact match, return the nearest element
        if (left > 0) {
            const prev = array[left - 1];
            if (target - prev.style.offset < current.style.offset - target) {
                return left - 1;
            }
        }
        return left;
    },
};