import {DataProvider} from "../data/dataprovider.js";


export const Likelizer = {
    storageTeamLikes : "teamLikes",
    cachedTeamLikes : null,

    getTeamLikes : function(){
        if (this.cachedTeamLikes) return this.cachedTeamLikes;
        var data = {};
        try {
            var raw = localStorage.getItem(this.storageTeamLikes);
            data = JSON.parse(raw);
            if (!data) data = {};
        }
        catch (e) {
            console.warn("error getting team likes ",e);
        }
        this.cachedTeamFavorites = data;
        return data;
    },

    didLikeTeam : function(teamid){
        var data = this.getTeamLikes();
        if (data[teamid]) return true;
        return false;
    },

    likeTeam : function(teamid){
        if (!this.didLikeTeam(teamid)){
            this.cachedTeamLikes = null;
            var data = this.getTeamLikes();
            data[teamid] = 1;
            localStorage.setItem(this.storageTeamLikes,JSON.stringify(data));
            DataProvider.like("team",teamid,()=>{
                console.log("liked team ",teamid);
            })
            return true;
        }
    },

    unlikeTeam : function(key){

    }
};

if (typeof window !== 'undefined') {
    window.Likelizer = Likelizer;
}