export const ManiaCache = {
    maxSize: 100,
    size: 0,
    //storeName : "trrrffewrroo88"+Math.random()*100000,
    storeName: "cachedItems",
    //dbName : "maniacccrvvvvy33yy3w"+Math.random()*100000,
    dbName: "footballmaniadb",
    version: 2,
    shortCacheStateKey: "shortCache",
    memoryCacheObj: {},

    prune: function () {
        console.log("prune cache");

        try {

            const storeName = this.storeName;
            const version = this.version;
            const dbName = this.dbName;
            if (window.indexedDB) {
                var request = window.indexedDB.open(dbName, version);
                request.onerror = function () {
                    console.error("on error opening indexdb");
                    return;
                }
                request.onsuccess = function (event) {
                    request.result.onversionchange = function (e) {
                        request.result.close();
                        console.error("on error onversion change indexdb");
                        return;
                    };

                    var db = event.target.result;
                    var tx = db.transaction(storeName, "readwrite");
                    var store = tx.objectStore(storeName);
                    var deletions = 0;
                    request = store.openCursor();

                    request.onerror = function () {
                        console.error("on error opening cursor");
                        db.close();
                        return;
                    }
                    request.onsuccess = function (event) {
                        let cursor = event.target.result;
                        if (!cursor) {
                            console.log("no further cursor items found, deleted ", deletions, " items");
                            db.close();
                            return;
                        }
                        if (cursor.value && cursor.value.timestamp) {
                            if ((new Date().getTime() - cursor.value.timestamp) > 1000 * 60 * 60 * 24 * 7) {
                                console.log("delete cache", cursor.key);
                                deletions++;
                                var request = cursor.delete();
                                request.onsuccess = () => {
                                    console.log("deleted", request);
                                    cursor.continue();
                                };
                                request.onerror = () => {
                                    console.log("error deleting", request);
                                    cursor.continue();
                                }
                            } else {
                                cursor.continue();
                            }
                        } else {
                            cursor.continue();
                        }
                    }
                };
                request.onupgradeneeded = function (event) {
                }
            } else {
                cb(null);
            }
        }
        catch (e) {
            console.log("error pruning cache", e);
        }
    },

    set: function (key, value) {

        this.setMemoryCache(key, value);
        const storeName = this.storeName;
        const version = this.version;
        const dbName = this.dbName;

        setTimeout(function () {
            if (window.indexedDB) {
                var request = window.indexedDB.open(dbName, version);
                request.onsuccess = function (event) {
                    request.result.onversionchange = function (e) {
                        // Close immediately to allow the upgrade requested by another
                        // instance to proceed.
                        //console.log("weird error");
                        request.result.close();
                        return cb(null);
                    };

                    // //console.log("on success");
                    // Start a new transaction
                    var db = event.target.result;
                    ////console.log("db",db)

                    var tx = db.transaction(storeName, "readwrite");
                    var store = tx.objectStore(storeName);
                    //var index = store.index("NameIndex");
                    // var transaction = event.target.transaction;
                    //var objectStore = db.transaction.objectStore("cache");
                    //store.put({ key: key, value: value, timestamp: Date.now() });
                    store.put({'timestamp': Date.now(), 'value': value}, key);
                    tx.oncomplete = function () {
                        //console.log("Item "+key+" added to cache");
                    }
                    db.close();
                }
                request.onupgradeneeded = function (event) {
                    var db = event.target.result;

                    if (!db.objectStoreNames.contains(storeName)) {
                        const objectStore = db.createObjectStore(storeName);
                        //console.log("created object store",key,objectStore);
                        //db.close();
                        //objectStore.createIndex("key", "key", { unique: true });
                    } else {
                        //console.log("already created object store");
                    }
                }
                request.onblocked = function () {
                    //console.log("request blocked");
                    //db.close();
                }
                request.onerror = function () {
                    //console.log("request error",key,request);
                    ///db.close();
                }
            } else {
                //console.log("IndexedDB is not supported by this browser");
            }
        }, 1000)
    },

    get: async function (key, expire, cb) {
        if (this.getMemoryCache(key)) {
            //console.log("have memory cache",key);
            if (this.getMemoryCache(key+"_expire")){
                ///console.log("CACHE CACHE CACHE = have memory cache expire",key,new Date().getTime() - this.getMemoryCache(key+"_expire"));
                if ((new Date().getTime() - this.getMemoryCache(key+"_expire")) > expire){
                    //console.log("memory cache expired",key);
                    delete this.memoryCacheObj[key];
                    delete this.memoryCacheObj[key+"_expire"];
                    this.setMemoryCache(key+"_expire",null);
                    console.log("########## expired")
                }
                else{
                    return cb(this.getMemoryCache(key));
                }
            }
            else{
                //return cb(this.getMemoryCache(key));
            }

        }
        const storeName = this.storeName;
        const version = this.version;
        const dbName = this.dbName;
        if (window.indexedDB) {
            //console.log("get",key)
            //console.trace();
            var request = window.indexedDB.open(dbName, version);
            request.onerror = function () {
                //console.log("on error",key,request);
                return cb(null);
            }
            request.onsuccess = function (event) {

                request.result.onversionchange = function (e) {
                    // Close immediately to allow the upgrade requested by another
                    // instance to proceed.
                    //console.log("weird error");
                    request.result.close();
                    return cb(null);
                };

                //console.log("on scucess",key);
                var db = event.target.result;
                try {
                    if (!db.objectStoreNames.contains(storeName)) {
                        //console.log("store not found",key);
                        db.close();
                        return cb(null);
                    }

                    var tx = db.transaction(storeName, "readonly");
                    var store = tx.objectStore(storeName);
                } catch (e) {
                    //console.log("a super error. ",key,e)
                    db.close();
                    return cb(null);
                }

                var result = store.get(key);
                tx.oncomplete = function () {
                    db.close();
                    if (result.result && result.result.value) {

                        //console.log("cache diff ",result,(new Date().getTime() - result.result.timestamp));
                        if ((new Date().getTime() - result.result.timestamp) > expire) {
                            //console.log("cache expired. return null");
                            return cb(null);
                        }
                        //console.log("have cache",key);
                        return cb(result.result.value);
                    } else {
                        //console.log("have no cache ",key);
                        return cb(null);
                    }
                }
                tx.onerror = function () {
                    //console.log("on error");
                    db.close();
                    cb(null);
                }
                tx.onabort = function () {
                    //console.log("on abort");
                    db.close();
                    cb(null);
                }
            };
            request.onupgradeneeded = function (event) {
                //console.log("create object store from get",event)
                var db = event.target.result;

                if (!db.objectStoreNames.contains(storeName)) {
                    //console.log("created object store");
                    const objectStore = db.createObjectStore(storeName);
                    //objectStore.createIndex("key", "key", { unique: true });
                } else {
                    //console.log("already created object store");
                }
                //cb(null);
                //  db.close();
            }
        } else {
            //console.log("IndexedDB is not supported by this browser");
            cb(null);
        }
    },

    setShortCache: function (key, data) {
        try {
            localStorage.setItem(this.shortCacheStateKey + key, JSON.stringify(data));
        } catch (e) {
            console.log("short cache error", e);
        }
    },

    getShortCache: function (key) {
        var data = localStorage.getItem(this.shortCacheStateKey + key);
        if (!data) return null;
        try {
            return JSON.parse(data);
        } catch (e) {
            return null;
        }
    },

    setMemoryCache: function (key, value) {
        try {
            this.memoryCacheObj[key] = value;
            this.memoryCacheObj[key+"_expire"] = new Date().getTime();
        } catch (e) {
            console.log("memory cache error", e);
        }
    },

    getMemoryCache: function (key) {
        return this.memoryCacheObj[key];
    },

    deleteMemoryCache: function (key) {
        console.log("delete ", key);
        delete this.memoryCacheObj[key];
        delete this.memoryCacheObj[key+"_expire"];
    },

    flushShortCache: function (key) {
        localStorage.removeItem(this.shortCacheStateKey + key);
    },
};

// ManiaCache.set('key1', 'value1');
// ManiaCache.set('key2', 'value2');
// ManiaCache.get('key1'); // prints 'value1'
// //console.log(ManiaCache.get('key2')); // prints 'value2'
// //console.log(ManiaCache.get('key3')); // prints 'null'