export const DefaultTranslations = {
    "facebook_app_id": "222169131296470",
    "com.crashlytics.android.build_id": "RANDOM_UUID",
    "com.crashlytics.android.build_id__#": "<string name=\"google_app_id\">177818426888</string>",
    "app_name": "Football Mania",
    "app_logo_name": "Football Live Scores",
    "standen": "Standings",
    "reload": "Reload",
    "loading": "Loading..",
    "nointernet": "No internet connection",
    "noconnnection": "Can't Connect",
    "noserverconnection": "Connection to server could not be established",
    "tryagain": "try again",
    "settings": "Settings",
    "no_data": "No data found",
    "selectcompetition": "Competitions",
    "morecompetition": "More Competitions",
    "matchinfo": "Match info",
    "lineups": "Lineups",
    "teaminfo": "Team info",
    "squad": "Squad",
    "venue": "Venue",
    "capacity": "Capacity",
    "surface": "Surface",
    "transfers": "Transfers",
    "founded": "Founded",
    "coach": "Coach",
    "country": "Country",
    "attacker": "Attacker",
    "midfielder": "Midfielder",
    "defender": "Defender",
    "goalkeeper": "Goalkeeper",
    "loan": "Loan",
    "from": "From",
    "to": "To",
    "free": "Free",
    "unknown": "Unknown",
    "schedule": "Schedule",
    "live": "Live",
    "nowlive": "Now live",
    "live_matches": "Live matches",
    "todays_matches": "Todays Matches",
    "tomorrow": "Tomorrow",
    "currently_no_matches": "Currently no matches",
    "todaysmatches": "Today",
    "thisweekmatches": "This week",
    "nextweekmatches": "Next week",
    "round": "Round",
    "topscoreplayers": "Top Scorers",
    "career": "Career",
    "passport": "Personal Details",
    "season": "Season",
    "team": "Team",
    "age": "Age",
    "nationality": "Nationality",
    "birthplace": "Birthplace",
    "position": "Position",
    "weight": "Weight",
    "height": "Height",
    "competition": "Competition",
    "Europe": "Europe",
    "Asia": "Asia",
    "South_America": "South America",
    "Africa": "Africa",
    "Oceania": "Oceania",
    "North_Central_America": "North Central America",
    "World_Cup": "World Cup",
    "World_Cup_Qualifications": "World Cup Qualifications",
    "International_Cups": "International Cups",
    "Eurocups": "European Cups",
    "votes": "Votes",
    "voteMatch": "Vote",
    "already_voted": "You voted",
    "shotstotals": "Total shots",
    "shotsgoal": "Shots on goal",
    "goal": "Goal",
    "goals": "Goals",
    "fouls": "Fouls",
    "possessiontime": "Possession time",
    "corners": "Corners",
    "offsides": "Offsides",
    "yellowcard": "Yellow card",
    "yellowcards": "Yellow cards",
    "redcard": "Red card",
    "redcards": "Red cards",
    "saves": "Saves",
    "odds": "odds",
    "threewayhandicap": "3 Way Handicap",
    "stats": "stats",
    "rank": "rank",
    "cleansheet": "Clean sheet",
    "avggoalspergamescored": "Average goals per game scored",
    "avggoalspergameconceded": "Average goals per game conceded",
    "avgfirstgoalscored": "Average first goal scored",
    "avgfirstgoalconceded": "Average first goal conceded",
    "failedtoscore": "Failed to score",
    "scoringminutes": "Scoring minutes",
    "sidelined": "Sidelined",
    "home": "home",
    "away": "Away",
    "total": "Total",
    "table_gamesplayed_full": "Games played",
    "table_gamesplayed": "GP",
    "table_points_full": "Points",
    "table_points": "Pts",
    "table_wins_full": "Win",
    "table_wins": "W",
    "table_draw_full": "Draw",
    "table_draw": "D",
    "table_lost_full": "Lost",
    "table_lost": "L",
    "table_goalsagainst_full": "Goals against",
    "table_goalsfor_full": "Goals for",
    "table_goalsforagainst": "GF-GA",
    "table_goalsforagainst_full": "Goal difference",
    "table_goaldiff": "GD",
    "favorites": "Favourites",
    "favoriteteams": "Favourite Teams",
    "favoriteleagues": "Favourite Competitions",
    "substitutions": "Substitutions",
    "videos": "Videos",
    "searchresults": "Search results",
    "teams": "Teams",
    "league": "league",
    "leagues": "Leagues",
    "sounds": "Sounds",
    "entertainment": "Entertainment",
    "interrupted": "Interrupted",
    "city": "City",
    "world_ranking_list": "FIFA World Ranking List",
    "menu_favorite_team": "Favourite Team",
    "menu_favorite_league": "Favourite Competition",
    "menu_refresh": "Refresh",
    "menu_search": "Search",
    "menu_sharematch": "Share",
    "menu_unsubscribe": "Unsubscribe",
    "menu_subscribe": "Subscribe",
    "comments": "Chat",
    "previous_matches": "Previous matches",
    "versus": "Versus",
    "interrupted_short": "Intr.",
    "finaltime": "Full time",
    "finaltime_short": "FT",
    "halftime": "Half time",
    "halftime_short": "HT",
    "after_extra_time": "After extra time",
    "after_extra_time_short": "AET",
    "to_be_announced": "To be announced",
    "to_be_announced_short": "TBA",
    "penalty": "Penalty",
    "penalty_short": "Pen.",
    "postponed": "Postponed",
    "postponed_short": "Postp.",
    "abandoned": "Abandoned",
    "cancelled": "Cancelled",
    "cancelled_short": "Can.",
    "abandoned_short": "Aban.",
    "more_team_battle_info": "More team statistics",
    "news": "News",
    "source": "Source",
    "please_login": "Please login",
    "back": "Back",
    "close": "Close",
    "advertisement": "Advertisement",
    "we_are_sorry": "We're sorry!",
    "no_player_info": "We have no further information for this player yet..",
    "ok": "OK",
    "push_notification_warning": "You just enabled push notifications for a game and will receive them once a goal is made or the game has ended.",
    "ok_got_it": "OK, got it!",
    "no_match_info": "No match facts available",
    "no_team_info": "Sorry, we don't have any info on this team",
    "no_squad_info": "No squad found..",
    "no_transfer_info": "No transfers found..",
    "no_topscorers_info": "No top scorers yet..",
    "none": "None",
    "relegation": "Regelation",
    "promotion": "Promotion",
    "promotion_playoff": "Promotion Play-off",
    "show_only_top_leagues": "show only top leagues",
    "show_all": "show all",
    "no_live_matches": "There are currently no live matches",
    "notification_sound": "notification sound",
    "notification_vibration_enabled": "Notification Vibrate On",
    "voting_statistics": "voting statistics",
    "apply_filter": "apply filter",
    "all_time": "all time",
    "extra_time_short": "ET",
    "tap_again_to_exit": "Tap again to exit",
    "match_started": "Match started",
    "website": "website",
    "Northern_Ireland": "Northern Ireland",
    "assist": "assist",
    "h2h": "Head 2 Head",
    "liveticker": "Live Ticker",
    "agenda": "Calendar",
    "vkontakte": "ВКонтакте",
    "aggregate": "On aggregate",
    "winner": "winner:",
    "selectitemtoswap": "Select highlighted item to swap",
    "chattypehint": "Type here your message",
    "silent": "Silent",
    "ringtoneTitle": "Ringtone",
    "ringtoneSummary": "Ringtone: %s",
    "language": "Language",
    "contact": "Contact",
    "feedback_and_support": "Feedback & Support",
    "summary": "Your feedback is appreciated and will help us to improve our ability to serve you and other users the best experience. Please let us know your questions, issues and suggestions by clicking here or sending a mail to: World.Sport.Apps@Gmail.com",
    "starts_in": "Starts in %{param1} minutes",
    "announcement": "Announcement",
    "notification_enabled_types": "Enabled notifications",
    "reset_application": "Application reset",
    "reset_application_summary": "Reset this application to default installation",
    "reset_application_summary_extended": "This action will reset all favourites, push notifications and other custom settings. Are you sure you want to continue?",
    "resumed": "Resumed",
    "enabled": "Enabled",
    "vibration_enabled": "Vibrate on alert",
    "wake_when_push_notification_arrives": "Wake when push notification arrives",
    "notification_high_prio_enabled": "High priority for notifications",
    "England": "England",
    "Scotland": "Scotland",
    "Wales": "Wales",
    "Moldovia": "Moldovia",
    "Azerbaijan": "Azerbaijan",
    "Cambodia": "Cambodia",
    "Congo_Kinshasa": "Congo Kinshasa",
    "Guadeloupe": "Guadeloupe",
    "Ivory_Coast": "Ivory Coast",
    "remove_ads": "Remove Advertising",
    "remove_ads_summary": "Please choose one of the following options:",
    "remove_ads_summary_offer_short": "Select this to remove advertising",
    "remove_ads_summary_offer": "Remove advertising by subscribing to one of the following options below. The subscription will automatically renew by the end of the subscription period. You can stop the subscription from here",
    "premium_subscription_info_title": "You have an active premium subscription",
    "premium_subscription_info_expiration_status": "",
    "remove_premium_subscription_info_guide": "Click here to manage your Google app subscriptions",
    "remove_premium_autorenew_on": "Your subscription is automatically renewed",
    "remove_premium_autorenew_off": "Your subscription is cancelled and will not automatically be renewed",
    "remove_advertising_one_month": "1 month subscription",
    "remove_advertising_six_months": "6 months subscription",
    "remove_advertising": "1 year subscription",
    "remove_advertising_oneweek": "1 week subscription",
    "extraRingtones": [
    "whistle1",
    "whistle2",
    "whistle3",
    "whistle4",
    "whistle5",
    "whistle6",
    "whistle7",
    "whistle8",
    "whistle9",
    "whistle10",
    "whistle11",
    "whistle12",
    "crowd1",
    "crowd2",
    "crowd3"
],
    "extraRingtoneTitles": [
    "whistle1",
    "whistle2",
    "whistle3",
    "whistle4",
    "whistle5",
    "whistle6",
    "whistle7",
    "whistle8",
    "whistle9",
    "whistle10",
    "whistle11",
    "whistle12",
    "crowd1",
    "crowd2",
    "crowd3"
],
    "extra_time": "Extra time",
    "offline_mode": "Offline",
    "beta_test": "Beta testing",
    "beta_test_summary": "Interested in trying out the newest features? click here to join the beta test program",
    "reset_cache": "Reset cache",
    "reset_application_cache": "Removes all stored images and offline data",
    "permission_disabled_warning": "\"You have denied the permission to write and read data from your phone.\nWhile the app still works there will be a decrease in performance, more data usage and offline support is disabled.\nYou can enable this permission in your app settings.\"",
    "about_title": "About",
    "about_text": "Special thanks to Arjan Meijer for his high quality football data. And thanks to the Football Mania community for its valueable input and support. Football Mania is a product created by and for football fans from all over the world.\nThanks to Anthony Buttaci for his awesome work on updating many team logos.",
    "no_thanks": "No Thanks",
    "team_form": "Form",
    "error_no_gcm": "Cannot retreive Google Cloud messaging registration id. Please update Google playservices",
    "default_home_screen": "Default home/start screen",
    "watchlist": "My Watchlist",
    "fixtures": "Fixtures",
    "push_notification_title": "Push notification registration number. Click to test and copy to clipboard",
    "enable_community_logo_pack": "Logo pack",
    "enable_community_logo_pack_summary": "Click here to install Fan Logo Pack",
    "disclaimer": "Disclaimer",
    "installed": "Installed. Click to uninstall",
    "uninstall": "Uninstall",
    "uninstall_logo_pack": "Are you sure you want to uninstall the Logo pack",
    "enable_community_logo_pack_disclaimer": "The logo pack contains images from clubs and players.\nFootball Mania does not represent the owner of the images. The images are not authorized by, sponsored by, or associated with the trademark owner(s).\nAll logos are collected by football fans and for personal use only. Click OK to install.",
    "pause": "Break",
    "start": "Home",
    "cancel": "Cancel",
    "updated_at": "Last updated",
    "widget_no_data": "There are currently no items on your watchlist, nor are any of your favorite teams playing.",
    "suspended_short": "Susp",
    "suspended": "Suspended",
    "favorite_order": "Favorites order",
    "manual": "Manual",
    "alfabetical": "Alfabetical",
    "select_date": "Select different date from calendar",
    "top_voters": "Top User Predictions",
    "username": "Username",
    "hits": "Right",
    "misses": "Wrong",
    "info": "Info",
    "last_week": "Last week",
    "last_month": "Last month",
    "login": "Sign in",
    "logout": "Sign out",
    "not_logged_in": "Not signed in",
    "logged_in_as": "Signed in as: %s",
    "substitutes": "Substitutes",
    "odd_title": "Odds",
    "bookmaker": "Bookmaker",
    "bookmaker_value_format": "Bookmaker format",
    "odds_preference": "Preferred Bookmaker",
    "bookmakers_enabled": "Show Bookmakers in match screen",
    "user_fixture_ordering": "Favourite order (live,today,calendar)",
    "Kyrgyzstan": "Kyrgyzstan",
    "preferred_foot": "Preferred foot",
    "privacy": "Privacy policy",
    "profile": "Profile",
    "attendance": "Attendance",
    "predictions": "Predictions",
    "missed_penalty": "Penalty missed",
    "theme": "Theme",
    "theme_black": "Black",
    "theme_white": "White",
    "theme_green": "Green",
    "theme_green2": "Dark Green",
    "theme_dblue": "Dark Blue",
    "theme_red": "Red",
    "theme_orange": "Desert",
    "theme_blue": "Blue",
    "theme_purple": "Purple",
    "theme_pink": "Pink",
    "font_size": "Font size",
    "font_small": "Small",
    "font_medium": "Medium",
    "font_large": "Large",
    "font_xlarge": "XLarge",
    "graphics": "Graphics",
    "advanced_animations": "Advanced Animations",
    "clock_mode": "Clock Format",
    "clock_mode_24": "24 hours",
    "clock_mode_12": "12 hours",
    "Tajikistan": "Tajikistan",
    "gibraltar": "Gibraltar",
    "back_button_label": "Back",
    "next_button_label": "Next",
    "skip_button_label": "Skip",
    "skip_button_label__#": "Optional button to Skip a PreferenceActivity [CHAR LIMIT=20]",
    "download": "Download",
    "please_wait": "Please Wait",
    "next_match": "Next match on %{param1}",
    "terms_of_service": "Privacy Policy",
    "terms_of_service_popup_title": "",
    "terms_of_service_popup_explained": "We keep this app free by showing ads. We use an unique identifier on your device to serve ads. If you want to avoid any tracking, you can buy ad-free version of this app.",
    "terms_of_service_popup_introduction": "Scroll down and click \"I agree\" when you are ready to continue with Football Mania Live Scores.",
    "terms_of_service_popup_introduction2": "To be consistent with data protection laws, we're asking you to take a moment to review key points of Football Mania's Privacy Policy.",
    "terms_of_service_popup_data_title": "Data we progress:",
    "terms_of_service_data_usage_list": "When you use Football Mania Live Scores, we progress information about that activity. Including information like device ID and Cookie Data.\n  \nWe progress that information for services like Ads, Analytics and Crashalytics.",
    "terms_of_service_popup_why_usuing_data": "Why we progress it:",
    "terms_of_service_popup_why_usuing_data_explained": "We progress this data described in our policy, including to:\n\n  \n<li>Help our services deliver more useful, customized content such as favorites and relevant search results</li>.\n  \n<li>Improve the quality of our services and develop new ones.</li>\n  \n<li>Deliver ads based on your interest.</li>\n  \n<li>Conduct analytics and measurements to understand how our services are used. We use Google Analytics and Crashalytics\n   to understand user behavior and app crashes.\\n\\nThank you for your attention, \\nThe Football Mania Live Score team. \\n\\n If you have any questions or feedback, feel free to contact world.sport.apps@gmail.com\\n</li>",
    "terms_of_service_description": "Touch to view Privacy Policy",
    "terms_of_service_cancel_button": "Later",
    "terms_of_service_ok_button": "I agree",
    "overwrite_default_push_notification_behavior": "Enable custom sounds",
    "change_log": "Changelog",
    "change_log_description": "Click to view changelog and developer notes",
    "notice": "Notice",
    "forum": "Chat",
    "banned": "banned",
    "chat_vote_disabled": "We have disabled your voting functionality. Please contact the app admin for more information",
    "chat_disabled": "This chat room is archived and all activity is disabled.",
    "banned_cannot_post_reason": "Your account is currently banned. Please contact the app admin for more information.",
    "unknown_error": "An unknown error has happened. Please contact the app admin for more information.",
    "chat_auth_failure": "Authorization failed. Please try logging in again or contact the app admin for more information.",
    "removed": "Removed",
    "fastscroll_enabled": "Fast scrolling in lists",
    "experimental_mode": "Fancy mode (Android 9+)",
    "International_North_Central_America": "North Central America",
    "International_Africa": "International Africa",
    "International_Asia": "International Asia",
    "International_Oceania": "International Oceania",
    "International_South_America": "International South America",
    "International": "International",
    "Regions": "Regions",
    "Faroe_islands": "Faroe islands",
    "venue2": "venue",
    "possestiontime": "Ball possession",
    "shotsgoalmissed": "Missed shots on goal",
    "shotsgoalblocked": "Blocked shots on goal",
    "passestotal": "Total ball passes",
    "passesaccurate": "Total accurate ball passes",
    "shotstotal": "Total shots",
    "taptotryagain": "Tap to try again",
    "referee": "referee",
    "no_schedule_available": "no schedule available",
    "taptoreturn": "Tap to return to previous screen",
    "no_matches_found": "No football matches found",
    "you_have_enabled_favorite_filter": "You have enabled filter to show only favorite matches and leagues",
    "tap_to_return": "Tap to go back",
    "upcoming_matches": "Upcoming matches",
    "attackers": "attackers",
    "defenders": "defenders",
    "midfielders": "midfielders",
    "goalkeepers": "goalkeepers",
    "kneeinjury": "knee injury",
    "minorinjury": "minor injury",
    "anklefootinjury": "ankle/footinjury",
    "hamstring": "hamstring",
    "last_played_match": "last played match",
    "todays_played_match": "Today's played match",
    "today": "today",
    "thighmusclestrain": "thigh muscle injury",
    "brokenwrist": "broken wrist",
    "footinjury": "foot injury",
    "calfmusclestrain": "calf muscle strain",
    "knock": "minor injury",
    "trophies": "trophies",
    "Kosovo": "Kosovo",
    "incoming_transfers": "incoming transfers",
    "outgoing_transfers": "outgoing transfers",
    "free_exchange": "free exchange",
    "secondplace": "2nd",
    "firstplace": "1st",
    "dominican_republic": "Dominican Republic",
    "search_your_favorite_teams": "Search your favorite teams",
    "goal_disallowed": "Goal disallowed",
    "added": "added",
    "offside": "offside",
    "address": "Address",
    "penalties": "penalties",
    "match_events": "Match events",
    "match": "Match",
    "select_news_from_country": "Select news from another country",
    "description_add_news_source": "Do you miss news from a specific country or language?. Let us know by sending a good news source with RSS feed support.",
    "firstname": "firstname",
    "lastname": "lastname",
    "injury_still_there": "The injury is still present",
    "date": "date",
    "injury_type": "injury type",
    "injury_period": "injury period",
    "injury_suspended": "Player is suspended",
    "knee_injury": "Knee injury",
    "groin_pelvis_injury": "Groin/Pelvis injury",
    "pcl_knee_ligament_injury": "Knee ligament injury",
    "mcl_knee_ligament_injury": "Knee Ligament injury",
    "ankle_foot_injury": "Ankle foot injury",
    "virus": "virus",
    "illness": "illness",
    "thigh_muscle_rupture": "Thigh muscle rupture",
    "shoulder_injury": "Shoulder injury",
    "concussion": "concussion",
    "lcl_knee_ligament_injury": "Knee Ligament injury",
    "ankle_ligaments": "Ankle ligaments injury",
    "thigh_muscle_strain": "Thigh muscle strain",
    "back_injury": "Back injury",
    "broken_shinbone": "Broken shinbone",
    "period_from": "period from",
    "period_to": "period to",
    "days": "days",
    "groin_strain": "Groin strain",
    "calf_shin_injury": "calf shin injury",
    "hip_thigh_injury": "hip thigh injury",
    "achilles_tendonitis": "achilles tendonitis",
    "metatarsal_fracture": "metatarsal fracture",
    "ankle_fracture": "Ankle fracture",
    "injuries": "injuries",
    "market_value": "Market value",
    "right_foot": "right foot",
    "left_foot": "left foot",
    "search_your_favorite_league": "Search your favorite league/cup",
    "league_add_information": "Below you can see some suggestions. Use the search function above to find more Leagues or Cups",
    "tap_to_add": "Tap to add",
    "player_market_values": "Player Market Values",
    "name": "name",
    "birthdate": "birthdate",
    "predicted_lineups": "Predicted Team Lineups",
    "no_chat_messages": "No messages yet",
    "type_below_to_be_first": "Type below to be the first with a new message",
    "shortsclip": "shorts",
    "highlights": "highlights",
    "bookmakers_enabled_when_premium": "Bookmaker ads enabled when premium",
    "filter_by_country": "Filter by country",
    "selected": "selected:",
    "Democratic_Republic_of_Congo": "Democratic Republic of Congo",
    "Czechia": "Czechia",
    "Congo_DR": "Congo DR",
    "Curaçao": "Curaçao",
    "Korea_Republic": "Korea Republic",
    "North_Macedonia": "North Macedonia",
    "Republic_of_Ireland": "Republic of Ireland",
    "Syria": "Syria",
    "Türkiye": "Türkiye",
    "USA": "USA",
    "Côte_d_Ivoire": "Côte d'Ivoire",
    "playerstats": "Player stats",
    "update_version": "updating to version {}"
}