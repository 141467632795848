import {Panel} from "../bananaframework/src/controls/Panel.js";
import {TextBox} from "../bananaframework/src/controls/TextBox.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {BasePage} from "./basepage.js";

import {Router} from "../util/router.js";
import {Localizer} from "../util/localizer.js";
import {ManiaCache} from "../util/cache.js";
import {MatchUtil} from "../util/matchutil.js";
import {ThemeManager} from "../util/theme.js";

import {AnimatorMatch} from "./animators/animatormatch.js";
import {DataProvider} from "../data/dataprovider.js";
import {StateManager} from "../data/stateManager.js";

import {TabView,TabViewTab} from "../components/tabview.js";
import {NoDataMessage3,ErrorMessage} from "../components/errormessage.js";
import {SimpleTileGridRenderer} from "../components/simpletilegridrenderer.js";

import {CommentaryModal} from "../components/match/commentarymodal.js";
import {OddsModal} from "../components/match/oddsmodal.js";
import {MatchHeader} from "../components/match/matchheader.js";

import {ChatMenuPopup,ChatAdminMenuModal} from "../components/various.js";
import {ChatSocketIO} from "../components/chat/chatsocketio.js";

import {TitleListRender,
    EventListRender,
    StatsListRender,
    H2HListRender,
    VotingRender,
    StandingsRender,
    ShapeRender,
    CommentariesRender,
    OddsRender,
    AggregateRender,
    PenaltiesListRender,
    ExternalOddsIntegration,
    LineupListRender,
    SubstitutionsListRender,
    SubstitutesListRender,
    VideoHighlights,
    ShareMatchRender
} from "../components/match/listrendersmatch.js";

import {ChatMessageItemRender} from "../components/chat/listrenderschat.js";

export class Match extends BasePage{

    init () {
        super.init();
        //this is a bit hacky.
        //the id change event is fired even when we set the id of this page before
        //this logic. The reason is because the onhashchange event is async
        //and altough we change the url before this logic, the onhashchange event fires
        //afte this logic
        this.bind("renderComplete",()=>{

            var that = this;
            //alert("listen")
            //we do this to register back button scenario where page A is match and page B is also match
            setTimeout(function() {
                if (that.unloaded){
                    console.log("ignore, page is unloaded");
                    return;
                }
                UrlManager.listenModule('mid', function () {
                    UrlManager.unlistenModule("mid");
                    var id = UrlManager.getModule('mid');
                    if (id == undefined || UrlManager.getModule('section') != "Match") {
                        return console.warn("ignore opening match , probably navigating to other screen");
                    }
                    AppInstance.loadPage("Match", {"mid": id}, false);
                });
            },100);
        })

        this.pageName = "Match_"+UrlManager.getModule("mid");

        this.addCssClass("MatchPage");

        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();

        if (this.isNavigatedFromOutside){
            StateManager.flushPageState(this.pageName);
        }
        console.log("OPENING PAGE "+this.pageName+" VERSION "+this.version+" is from outside "+this.isNavigatedFromOutside)

        this.coordinatoranimator = new AnimatorMatch();
        this.coordinatoranimator.reducerRatio = 1;
        this.coordinatoranimator.transitionDuration = 20;
        this.coordinatoranimator.alwaysCollapseOnBackScrolling = false;

        if (Router.isNavigatedFromPush()){
            //remove push from history url. otherwise when navigating back button doesnt work
            UrlManager.removeModule("fromPush")
            UrlManager.updateUrl(true);
            Router.allowBackButtonQuitApp();
            Router.showBanner();
        }
        else{
            Router.disallowBackButtonQuitApp();
        }

        this.createPageLayout();
    }

    storeState (){

        var d = {};
        if (this.tabview) {
            d.tabIndex = this.tabview.currentActiveTab,
            d.matchstatsScrollPosition = this.matchstatsListrender.getScroll(),
            d.headerCollapsed = this.coordinatoranimator.isCollapsed
        }
        if (this.matchlineupsListrender){
            d.matchlineupScrollPosition = this.matchlineupsListrender.getScroll();
        }
        if (this.matchHighlightListrender){
            d.matchhighlightScrollPosition = this.matchHighlightListrender.getScroll();
        }
        if (this.commentariesModal){
            d.commentariesModalOpen = 1;
        }
        if (this.oddsModal){
            d.oddsModalOpen = 1;
        }

        console.log("store ",d);
        StateManager.savePageState(this.pageName,d);
    }

    unload (){
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();
        clearTimeout(this.matchLoadHandler);
        UrlManager.unlistenModule('mid');
    }

    onVisibilityChange (){
        console.log("visibility change ",document.hidden,this);
        if (this.previousDocumentHidden = true && !document.hidden){
            this.autoInterval(0);
            Router.showBanner();
        }
        this.previousDocumentHidden = document.hidden;
    }

    onOffline() {
        this.getPageTemplate().createMenuMatch(false,false);
    }
    onOnline() {
        this.autoInterval(0);
        Router.showBanner();
    }

    createPageLayout (){
        this.matchHeader = new MatchHeader();
        this.matchHeader.setVisible(false);
        this.coordinatoranimator.addCollapser(this.matchHeader);

        var row2 = new Panel();
        this.addControl(this.matchHeader);
        this.addControl(row2);
        this.contentContainer = row2;
    }

    createComponents (){
        this.getPageTemplate().hideTitle();
    }

    onWindowResize (){

        super.onWindowResize();
        if (document.fullscreenElement){
            console.warn("ignore resize event because fullscreen element is active");
            return;
        }

        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }

        if (this.matchstatsListrender) {
            this.matchstatsListrender.onWindowResize();
        }

        if (this.matchlineupsListrender){
            this.matchlineupsListrender.onWindowResize();
        }

        if (this.matchHighlightListrender){
            this.matchHighlightListrender.onWindowResize();
        }
    }

    updateDisplay (){
        var previousState = StateManager.getCurrentPageState(this.pageName);

        if (previousState) {
            this.coordinatoranimator.prerun(previousState.headerCollapsed);
        }
        else{
            this.coordinatoranimator.prerun(false);
        }
        this.runApp();
    }

    runApp (){

        this.showLoader();

        AppInstance.setup(this.getProxy(function(result){

            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            if (!result){
                this.hideLoader();
                console.error("error app setup");
                return this.showError("",()=>{
                    this.runApp();
                });
            }

            var cachedHeaderData = ManiaCache.getMemoryCache("match");
            if (cachedHeaderData){
                this.matchHeader.setPresetData(cachedHeaderData);
            }

            DataProvider.getMatch(UrlManager.getModule("mid"),this.getProxy(function(response){

                if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

                this.hideLoader();

                if (!response.isOk()){

                    if (response.getStatus() == 404) {
                        return this.showPageDataDoesNotExist("", this.getProxy(function () {
                            this.runApp();
                        }))
                    }
                    else{
                        return this.showError("", this.getProxy(function () {
                            this.runApp();
                        }))
                    }
                }
                var data = response.getData();
                this.matchData = data;

                console.log("loaded match data",data);

                if (MatchUtil.isFinished(data)){
                    if (data.status == "Pen."){
                        this.coordinatoranimator.shrinkType = 4;
                    }
                    else{
                        this.coordinatoranimator.shrinkType = 1;
                    }
                    this.getPageTemplate().createMenuMatch(false,response.getData().id);
                }
                else{

                    this.getPageTemplate().createMenuMatch(true,response.getData().id);

                    if (data.aggregateScore){
                        this.coordinatoranimator.shrinkType = 2;
                    }
                    else{
                        this.coordinatoranimator.shrinkType = 3;
                    }
                }

                this.coordinatoranimator.matchStatus = data.status;

                this.matchHeader.setVisible(true);
                ManiaCache.setShortCache("match",data)
                this.matchHeader.setPresetData(data,true);

                this.createTabs(data);

                this.tabview .bind("tabChanged",()=>{
                    this.coordinatoranimator.tabChange();
                    this.storeState();
                    console.log("tab changed",this.tabview.currentActiveTab);

                    //in case the match highlights tab is selected and the highlights are not loaded yet
                    if (this.matchHighlightListrender && this.matchHighlightListrender.containerTarget.tabIndex == this.tabview.currentActiveTab
                    && this.waitingForActiveHighlightsTab
                    ){
                        this.waitingForActiveHighlightsTab = false;
                        this.loadMatchHighlights(this.matchData)
                    }

                    if (this.bottomInput){
                        jQuery("#"+this.bottomInput.getClientId()).blur();
                    }

                    if (this.chatListrender && this.chatListrender.containerTarget.parent.tabIndex == this.tabview.currentActiveTab){
                        this.loadMatchChat();

                        if (this.chatListrender.indexRenderedItemRenderMap) {
                            if (this.chatMenuPopup) {
                                this.chatMenuPopup.slideOut();
                            }
                            for (var i = 0; i < this.chatListrender.indexRenderedItemRenderMap.length; i++) {
                                this.chatListrender.indexRenderedItemRenderMap[i].unblur();
                            }
                            this.inBlurMode = false;
                        }

                    }

                });

                var storeHandler = null;
                var storeHandler2 = null;
                var storeHandler3 = null;
                var tabCount = 1;
                this.matchstatsListrender.bind("scroll",this.getProxy(function(e,scroll) {
                    clearTimeout(storeHandler);
                    storeHandler = setTimeout(this.getProxy(function () {
                        this.storeState();
                    }),200)
                }));

                if (this.matchlineupsListrender) {
                    this.matchlineupsListrender.bind("scroll", this.getProxy(function (e, scroll) {
                        clearTimeout(storeHandler2);
                        storeHandler2 = setTimeout(this.getProxy(function () {
                            this.storeState();
                        }), 200)
                    }));
                    ++tabCount
                }

                if ( this.matchHighlightListrender) {
                    this.matchHighlightListrender.bind("scroll", this.getProxy(function (e, scroll) {
                        clearTimeout(storeHandler2);
                        storeHandler3 = setTimeout(this.getProxy(function () {
                            this.storeState();
                        }), 200)
                    }));
                    ++tabCount
                }
                if ( this.chatListrender) {
                    ++tabCount
                }

                this.tabview.currentActiveTab = FootballManiaConfig.isRtl ? tabCount-1 : 0;

                var previousState = StateManager.getCurrentPageState(this.pageName);
                if (previousState) {
                    if (previousState.tabIndex != null) {
                        this.tabview.currentActiveTab = previousState.tabIndex;
                    }
                    this.matchstatsListrender.scrollTo(previousState.matchstatsScrollPosition || 0);
                    console.log("restore scroll ",previousState.matchlineupScrollPosition)

                    if (this.matchlineupsListrender) {
                        this.matchlineupsListrender.scrollTo(previousState.matchlineupScrollPosition || 0);
                    }
                    if (this.matchHighlightListrender) {
                        this.matchHighlightListrender.scrollTo(previousState.matchhighlightScrollPosition || 0);
                    }
                }

                this.contentContainer.invalidateDisplay();
                this.coordinatoranimator.ensureStartPositions(); //we call this to ensure tabview is collapsed without flicker
                this.coordinatoranimator.run();

                this.loadMatchStats(data);

                if (data.commentaries && data.commentaries.teams) {
                    this.loadMatchLineups(data);
                }
                else if (data.commentaries_predicted && data.commentaries_predicted.teams) {
                    var oldCommentaries = data.commentaries;
                    data.commentaries = data.commentaries_predicted;
                    if (oldCommentaries) {
                        data.commentaries.commentaries = oldCommentaries.commentaries;
                    }
                    data.commentaries.isPredicted = true;
                    this.loadMatchLineups(data);
                }

                if (FootballManiaConfig.enableVideo
                    && data.videoHighlights
                    && (data.videoHighlights.files.length > 0 || data.videoHighlights.clips.length > 0)
                    && FootballManiaConfig.disabledVideoCountries.indexOf(FootballManiaConfig.userLocale) == -1
                ){
                    this.loadMatchHighlights(data);
                }

                if (FootballManiaConfig.enabledFeatures.matchChat
                    && this.chatListrender.containerTarget.parent.tabIndex == this.tabview.currentActiveTab
                ){
                    this.loadMatchChat();
                }

                ///some logic for reopening commentary modal after refresh
                this.bind("onModalOpen",this.getProxy(function(e,modal){
                    this.commentariesModal = null;
                    this.oddsModal = null;
                    if (modal instanceof CommentaryModal){
                        this.commentariesModal = modal;
                    }
                    else if(modal instanceof OddsModal){
                        this.oddsModal = modal;
                    }
                    this.storeState();
                }));
                this.bind("onModalClose",()=>{
                    this.commentariesModal = null;
                    this.oddsModal = null;
                    this.storeState();
                });

                if (previousState && previousState.commentariesModalOpen){
                    setTimeout(()=> {
                        var urlIdentifier = "matchmodal_"+UrlManager.getModule("mid");
                        var modal = new CommentaryModal(urlIdentifier);
                        this.addControl(modal, true);
                    });
                }
                else if (previousState && previousState.oddsModalOpen){
                    setTimeout(()=> {
                        var urlIdentifier = "oddsmodal_"+UrlManager.getModule("mid");
                        var modal = new OddsModal(urlIdentifier,data);
                        this.addControl(modal, true);
                    });
                }

            }),true);
        }))
    }

    createTabs (data){

        var tabview = new TabView();
        //FootballManiaConfig.graphics.animations = false;
        if (ThemeManager.getCurrentTheme() != "light") {
            tabview.parallaxBg = this.matchHeader.backgroundImage;
        }

        this.contentContainer.addControl(tabview);

        var tabs = [];

        var tab1;
        var tab2;
        var tab3;
        var tab4;

        tab1 = new TabViewTab();
        tab1.addCssClass("listViewScrollableContainer");
        tab1.title = Localizer.getString("matchinfo");

        if ((data.commentaries && data.commentaries.teams) || (data.commentaries_predicted && data.commentaries_predicted.teams)){
            tab2 = new TabViewTab();
            tab2.addCssClass("listViewScrollableContainer");
            tab2.title = Localizer.getString("lineups");
        }
        if (FootballManiaConfig.enableVideo
                && data.videoHighlights
                && (data.videoHighlights.files.length > 0 || data.videoHighlights.clips.length > 0)
                && FootballManiaConfig.disabledVideoCountries.indexOf(FootballManiaConfig.userLocale) == -1
            ){

            tab3 = new TabViewTab();
            tab3.addCssClass("listViewScrollableContainer");
            tab3.title = Localizer.getString("highlights");
            tab3.iconClass = "icon-video";
        }

        if (FootballManiaConfig.enabledFeatures.matchChat){
            tab4 = new TabViewTab();
            tab4.heightIsMaxContentHeight = true;
            //this tab is not the scrollable element, it
            tab4.setStyle("width:100%;height:100%;position:relative;");
            tab4.title = Localizer.getString("chat");
            tab4.iconClass = "icon-chat";
            this.tab4 = tab4;
        }

        var usedTabIndices = 0;
        if (FootballManiaConfig.isRtl){
            if (tab4) {
                tab4.addControl(this.createChatTabLayout(tab4,usedTabIndices));
                tabs.push(tab4);
                usedTabIndices++;
            }
            if (tab3) {
                this.createMatchHighlightControls(tab3, usedTabIndices);
                this.coordinatoranimator.addScrollerContainer(tab3);
                tabs.push(tab3);
                usedTabIndices++;
            }
            if (tab2) {
                this.createMatchLineupControls(tab2, usedTabIndices);
                this.coordinatoranimator.addScrollerContainer(tab2);
                tabs.push(tab2);
                usedTabIndices++;
            }
            this.createMatchStatsControls(tab1,usedTabIndices);
            this.coordinatoranimator.addScrollerContainer(tab1);
            tabs.push(tab1);
        }
        else{
            this.createMatchStatsControls(tab1,usedTabIndices);
            this.coordinatoranimator.addScrollerContainer(tab1);
            tabs.push(tab1);
            usedTabIndices++;

            if (tab2) {
                this.createMatchLineupControls(tab2, usedTabIndices);
                this.coordinatoranimator.addScrollerContainer(tab2);
                tabs.push(tab2);
                usedTabIndices++;
            }
            if (tab3) {
                this.createMatchHighlightControls(tab3, usedTabIndices);
                this.coordinatoranimator.addScrollerContainer(tab3);
                tabs.push(tab3);
                usedTabIndices++;
            }
            if (tab4) {
                tab4.addControl(this.createChatTabLayout(tab4,usedTabIndices));
                tabs.push(tab4);
            }
        }

        this.coordinatoranimator.addTopBar(this.getPageTemplate().topBar);
        this.coordinatoranimator.addTabView(tabview);

        tabview.setTabs(tabs);

        this.tabview =tabview;

        this.bind("stickyBannerLoadFail", () => {
            console.error("sticky banner load fail -> set height to 100%");
            if (tab1) this.coordinatoranimator.setScrollerBottomMargin(tab1.tabIndex,0);
            if (tab2) this.coordinatoranimator.setScrollerBottomMargin(tab2.tabIndex,0);
            if (tab3) this.coordinatoranimator.setScrollerBottomMargin(tab3.tabIndex,0);
            if (tab4) this.coordinatoranimator.setScrollerBottomMargin(tab4.tabIndex,0);
        });
        //triggered from page template
        this.bind("stickyBannerLoadSuccess", () => {
            console.log("sticky banner load success -> set height to 100% - 50px",tab1);
            if (tab1) {
                this.coordinatoranimator.setScrollerBottomMargin(tab1.tabIndex,50);
                this.coordinatoranimator.getScrollerContainer(tab1.tabIndex).setCss({"height": "calc(100% - 50px)"});
                this.coordinatoranimator.recalculateTab(tab1.tabIndex);
            }
            if (tab2) {
                this.coordinatoranimator.setScrollerBottomMargin(tab2.tabIndex,50);
                this.coordinatoranimator.getScrollerContainer(tab2.tabIndex).setCss({"height": "calc(100% - 50px)"});
                this.coordinatoranimator.recalculateTab(tab2.tabIndex);
            }
            if (tab3) {
                this.coordinatoranimator.setScrollerBottomMargin(tab3.tabIndex,50);
                this.coordinatoranimator.getScrollerContainer(tab3.tabIndex).setCss({"height": "calc(100% - 50px)"});
                this.coordinatoranimator.recalculateTab(tab3.tabIndex);
            }

            if (tab4) {
                this.coordinatoranimator.setScrollerBottomMargin(tab4.tabIndex,100);
                this.coordinatoranimator.getScrollerContainer(tab4.tabIndex).setCss({"height": "calc(100% - 100px)"});
                this.coordinatoranimator.recalculateTab(tab4.tabIndex);

                if (this.chatBottomInputContainer){
                    this.chatBottomInputContainer.setCss({"bottom": "50px"});
                }
            }
        });

        if (tab4) {
            this.coordinatoranimator.setScrollerBottomMargin(tab4.tabIndex,50);
            this.coordinatoranimator.getScrollerContainer(tab4.tabIndex).setCss({"height": "calc(100% - 50px)"});
            this.coordinatoranimator.recalculateTab(tab4.tabIndex);
        }

        this.getPageTemplate().loadStickyBannerAd();
    }

    createChatTabLayout (tab,index){

        var bottom = new Panel();
        bottom.addCssClass("tableFooter")

        var input = new TextBox();
        input.addCssClass("ChatInput")
        input.setData(Localizer.getString("chattypehint"));

        this.chatMenuPopup = new ChatMenuPopup();
        this.chatMenuPopup.isAdmin = true;
        this.chatMenuPopup.bind("thumbup",() =>{
            console.log(this.selectedChatData);
            if (!this.selectedChatData) {
                console.error("no selected chat data");
                return;
            }
            this.chatListrender.rateMessage(this.selectedChatData,1);
        });
        this.chatMenuPopup.bind("thumbdown",() =>{
            console.log(this.selectedChatData);
            if (!this.selectedChatData) {
                console.error("no selected chat data");
                return;
            }
            this.chatListrender.rateMessage(this.selectedChatData,-1);
        });
        this.chatMenuPopup.bind("requestAdminMenu",() =>{
            console.log(this.selectedChatData);
            if (!this.selectedChatData) {
                console.error("no selected chat data");
                return;
            }

            var adminPanel = new ChatAdminMenuModal();
            adminPanel.setData(this.selectedChatData);
            this.adminPanel = adminPanel;
            this.getPage().addControl(adminPanel,true);
            clearTimeout(this.menuSlideInTimeouthandler);

            this.getPage().bind("onModalClose",() =>{
                this.chatMenuPopup.slideOut();
                for (var i=0; i < this.chatListrender.indexRenderedItemRenderMap.length; i++) {
                    this.chatListrender.indexRenderedItemRenderMap[i].unblur();
                }
                this.inBlurMode = false;
            });

            adminPanel.bind("removeMessage",() =>{
                this.chatListrender.adminRemoveMessage(this.selectedChatData);
            });
            adminPanel.bind("banUser1day",() =>{
                this.chatListrender.adminBanUser(this.selectedChatData,1);
            });
            adminPanel.bind("banUser7days",() =>{
                this.chatListrender.adminBanUser(this.selectedChatData,7);
            });
            adminPanel.bind("banUser365days",() =>{
                this.chatListrender.adminBanUser(this.selectedChatData,365);
            });
            adminPanel.bind("unbanuser",() =>{
                this.chatListrender.adminUnbanUser(this.selectedChatData,365);
            });

        });

        bottom.addControl(this.chatMenuPopup);
        bottom.addControl(input);
        bottom.setVisible(false);

        var arrow = new Panel();
        arrow.addCssClass("arrow-right")
        bottom.addControl(arrow);

        var scroller = new Panel();
        scroller.addCssClass("listViewScrollableContainer");
        scroller.addCssClass("ChatScrollerContainer");
        scroller.setCss({"display":"flex","flex-direction":"column"});
        scroller.forceMarginTop = "auto";

        this.coordinatoranimator.addScrollerContainer(scroller);

        ///set fixed height of scroller container table items.
        //this is needed because bottom description container is dynamic in height
        bottom.setStyle("display: flex;flex-direction: row;align-items: center;justify-content: flex-start;")
        scroller.setStyle("width:100%;height:calc(100% - 50px);position:relative;overflow-y: scroll;transform: translate(0,0);");

        this.bottomInputContainer = bottom;
        this.bottomInput = input;
        this.bottomArrow = arrow;

        this.createChatControls(scroller,index);

        var bottomContainer = new Panel();
        bottomContainer.setStyle("z-index:9999999;bottom:0px;position:fixed;width:100%;height:50px;");
        bottomContainer.addControl(bottom);
        this.chatBottomInputContainer = bottomContainer;

        this.tab4.addControl(bottomContainer);

        return scroller;
    }

    getMatchData (){
        return this.matchData;
    }

    createMatchStatsControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();

        listRender.containerTarget = tab;

        listRender.setItemRender(function(data){

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new TitleListRender(),
                };
            }
            else if (data.type == 2) {
                return {
                    typeId: 2,
                    render: new EventListRender(),
                };
            }
            else if (data.type == 3) {
                return {
                    typeId: 3,
                    render: new StatsListRender(),
                };
            }
            else if (data.type == 4) {
                return {
                    typeId: 4,
                    render: new H2HListRender(),
                };
            }
            else if (data.type == 5) {
                return {
                    typeId: 5,
                    render: new VotingRender(),
                };
            }
            else if (data.type == 6) {
                return {
                    typeId: 6,
                    render: new StandingsRender(),
                };
            }
            else if (data.type == 7) {
                return {
                    typeId: 7,
                    render: new ShapeRender(),
                };
            }
            else if (data.type == 8) {
                return {
                    typeId: 8,
                    render: new CommentariesRender(),
                };
            }
            else if (data.type == 9) {
                return {
                    typeId: 9,
                    render: new OddsRender(),
                };
            }
            else if (data.type == 10) {
                return {
                    typeId: 10,
                    render: new AggregateRender(),
                };
            }
            else if (data.type == 11) {
                return {
                    typeId: 11,
                    render: new PenaltiesListRender(),
                };
            }
            else if (data.type == 12) {
                return {
                    typeId: 12,
                    render: new ExternalOddsIntegration(),
                };
            }
            else if (data.type == 13) {
                return {
                    typeId: 13,
                    render: new ShareMatchRender(),
                };
            }
        })

        listRender.bind('createdList',()=>{
            this.coordinatoranimator.recalculateTab(index);
            this.coordinatoranimator.ensureStartPositions(); //needed for scrolllist to collapse mode
        });

        // listRender.bind('onFullscreenEvent',this.getProxy(function(e,fullscreen){
        //     console.log("onFullscreenEvent",fullscreen);
        //     //FootballManiaConfig.graphics.animations = !fullscreen;
        //     //this.getPage().hide();
        //     //jQuery(".BPage").hide();
        //     console.log("ignoreWindowResizeEvent",fullscreen);
        //     if (fullscreen) {
        //         this.ignoreWindowResizeEvent = fullscreen;
        //     }
        //     else{
        //         setTimeout(() => {
        //             this.ignoreWindowResizeEvent = false;
        //         }, 1000);
        //     }
        //     //listRender.setVisible(false);
        //     //asd();
        // }));

        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            listRender.scrollTo(previousState.matchstatsScrollPosition || 0);
        }

        container.addControl(listRender);
        this.matchstatsListrender = listRender;

        tab.addControl(container);
    }

    createMatchLineupControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();

        listRender.containerTarget = tab;

        listRender.setItemRender(function(data){

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new LineupListRender(),
                };
            }
            else if (data.type == 2) {
                return {
                    typeId: 2,
                    render: new SubstitutionsListRender(),
                };
            }
            else if (data.type == 3) {
                return {
                    typeId: 3,
                    render: new SubstitutesListRender(),
                };
            }
        })

        listRender.bind('createdList',()=>{
            this.coordinatoranimator.recalculateTab(index);
        });

        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            console.log("restore scroll #2 : ",previousState.matchlineupScrollPosition)
            listRender.scrollTo(previousState.matchlineupScrollPosition || 0);
        }

        container.addControl(listRender);
        this.matchlineupsListrender = listRender;

        tab.addControl(container);
    }

    createMatchHighlightControls (tab,index){
        console.log("create match highlight controls");
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();

        listRender.containerTarget = tab;

        listRender.setItemRender(function(data){

            if (data.type == 13) {
                return {
                    typeId: 13,
                    render: new VideoHighlights(),
                };
            }
        })

        listRender.bind('createdList',()=>{
            this.coordinatoranimator.recalculateTab(index);
            this.coordinatoranimator.ensureStartPositions(); //needed for scrolllist to collapse mode
        });

        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            listRender.scrollTo(previousState.matchhighlightScrollPosition || 0);
        }

        container.addControl(listRender);
        this.matchHighlightListrender = listRender;

        tab.addControl(container);
    }

    createChatControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;display: flex;flex-direction: column;justify-content: flex-end;");

        var listRender = new ChatSocketIO(true);

        var chatLocale = AppInstance.getConfig().userLocale;
        if (FootballManiaConfig.availableChatLocales.indexOf(chatLocale) == -1) {
            chatLocale = FootballManiaConfig.default_chat_locale;
        }

        listRender.chatIndex = UrlManager.getModule("mid")+"_"+chatLocale;
        //  listRender.chatIndex = UrlManager.getModule("mid")+"_it";
        listRender.containerTarget = tab;

        listRender.setItemRender(function(data){
            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new ChatMessageItemRender(),
                };
            }
        })

        listRender.emptyTemplate = (cb)=>{
            return new NoDataMessage3({
                cb (){

                },
                message : Localizer.getString("no_chat_messages")
            });
        }

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }
        listRender.bind("click", (data) => {
            console.log("click");
            if (this.chatMenuPopup){
                this.chatMenuPopup.slideOut();
            }
            if (listRender.indexRenderedItemRenderMap) {
                for (var i = 0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                    listRender.indexRenderedItemRenderMap[i].unblur();
                }
            }
            this.inBlurMode = false;
        });
        listRender.bind('createdList',()=>{
            this.tabview.tabs[this.chatListrender.containerTarget.parent.tabIndex].hideLoader();
            this.coordinatoranimator.recalculateTab(index);
            //NOT CALLED?????
        });
        listRender.bind('refresh_chat',()=>{
            this.tabview.tabs[this.chatListrender.containerTarget.parent.tabIndex].hideLoader();
            this.coordinatoranimator.recalculateTab(index);
            //NOT CALLED?????
        });

        listRender.bind('refresh_chat',()=>{
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            this.tabview.tabs[0].hideLoader();
            this.bottomInputContainer.setVisible(true);
        });
        listRender.bind("accepted", () => {
            console.log("accepted");
            this.coordinatoranimator.recalculateTab(index);
            this.acceptInteraction();
        });
        listRender.bind("incoming_message", (data) => {
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            clearTimeout(this.menuSlideInTimeouthandler);
            this.chatMenuPopup.slideOut();
            for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                listRender.indexRenderedItemRenderMap[i].unblur();
            }
        });
        listRender.bind("voteFailed", (data) => {
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            clearTimeout(this.menuSlideInTimeouthandler);
            this.chatMenuPopup.slideOut();
            for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                listRender.indexRenderedItemRenderMap[i].unblur();
            }
        });

        listRender.bind("authentication_start", (data) => {
            this.getPageTemplate().hideStickBannerAd();
        });
        listRender.bind("authentication_end", (data) => {
            this.getPageTemplate().loadStickyBannerAd();
        });
        listRender.bind("authentication_error", (data) => {
            //this.getPageTemplate().createMenuForumChat(); //in case we auto logout
        });
        listRender.bind("adminActionFailed", (data) => {
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            clearTimeout(this.menuSlideInTimeouthandler);
            this.chatMenuPopup.slideOut();
            for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                listRender.indexRenderedItemRenderMap[i].unblur();
            }
            if (this.adminPanel) this.adminPanel.destroy();
        });
        listRender.bind("itemClick", (e,data) => {
            console.log("itemClick",data);
            if (!this.chatMenuPopup.isAdmin && data.removed == 1){
                console.log("message removed");
                return;
            }

            if (this.inBlurMode){
                clearTimeout(this.menuSlideInTimeouthandler);
                this.chatMenuPopup.slideOut();
                console.log("slideout");
                for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                    listRender.indexRenderedItemRenderMap[i].unblur();
                }
                this.inBlurMode = false;
                return;
            }
            else{
                this.chatMenuPopup.enableVote(data.userVote != 1 && data.userVote != -1);
            }

            console.log("slidein");
            this.chatMenuPopup.slideIn();

            clearTimeout(this.menuSlideInTimeouthandler);
            this.menuSlideInTimeouthandler = setTimeout(() => {
                console.log("timeout for slideout");
                this.chatMenuPopup.slideOut();
                this.inBlurMode = false;
                for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                    listRender.indexRenderedItemRenderMap[i].unblur();
                }
                this.selectedChatData = null;

            }, 5000);

            console.log("go check ",listRender.indexRenderedItemRenderMap);
            for (var i=0; i < listRender.indexRenderedItemRenderMap.length; i++) {
                console.log("go check ",listRender.indexRenderedItemRenderMap[i].data);
                if (!this.inBlurMode && listRender.indexRenderedItemRenderMap[i].data == data){
                    listRender.indexRenderedItemRenderMap[i].unblur();
                    console.log("blur",listRender.indexRenderedItemRenderMap[i].data);
                    this.selectedChatData = data;
                }
                else{
                    listRender.indexRenderedItemRenderMap[i].blur();
                }
            }
            this.inBlurMode = !this.inBlurMode;
        });

        listRender.setPlaceHolderWidth("100%;");

        container.addControl(listRender);

        this.chatListrender = listRender;

        tab.addControl(listRender);
    }

    loadMatchStats (data,ignoreCache){

        if (!data){
            return this.autoInterval();
        }

        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

        var listData = [];

        //if (FootballManiaConfig.enabledOddsCountries.indexOf(FootballManiaConfig.userLocale) != -1) {

        //}

        var item = {"data":data,"type":1};
        listData.push(item);

        var item = {"data": data, "type": 12};
        listData.push(item);

        if (data.penalties && data.penalties.length) {
            var item2 = {"data": data, "type": 11};
            listData.push(item2);
        }

        if (data.events && data.events.length) {

            if (data.commentaries && data.commentaries.events && data.commentaries.events.length){
                data.events = data.commentaries.events;
            }

            var item2 = {"data": data, "type": 2};
            listData.push(item2);
        }

        if (data.aggregate) {
            var item2 = {"data": data, "type": 10};
            listData.push(item2);
        }

        if (data.localteamStanding && data.visitorteamStanding) {
            var item2 = {"data": data, "type": 6};
            listData.push(item2);
        }

        if (data.commentaries && data.commentaries.commentaries && data.commentaries.commentaries.length > 1) {
            var item2 = {"data": data, "type": 8};
            listData.push(item2);
        }

        if (data.commentaries && data.commentaries.stats) {
            var item2 = {"data": data, "type": 3};
            listData.push(item2);
        }

        item = {"data": data, "type": 5};
        listData.push(item);

        if (data.odds && data.odds.bookmakers && data.odds.bookmakers.length) {
            var item2 = {"data": data, "type": 9};
            listData.push(item2);
        }

        if (data.localteamshape && data.visitorteamshape){
            item = {"data": data, "type": 7};
            listData.push(item);
        }

        var item2 = {"data": data, "type": 13};
        //listData.push(item2);

        if (data.h2hMatches) {
            var item2 = {"data": data, "type": 4};
            listData.push(item2);
        }

        var timeout = 0;
        if (this.matchstatsListrender.containerTarget.tabIndex != this.tabview.currentActiveTab) {
            timeout = 500;
            this.tabview.tabs[this.matchstatsListrender.containerTarget.tabIndex].showLoader();
        }
        setTimeout(()=>{
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            this.tabview.tabs[this.matchstatsListrender.containerTarget.tabIndex].hideLoader();
            this.matchstatsListrender.setDataSource(listData);
        },timeout);

        this.autoInterval();
    }

    loadMatchLineups (data,ignoreCache){

        var listData = [];

        var item = {"data":data,"type":1};
        listData.push(item);

        if (data.commentaries.substitutions && ((data.commentaries.substitutions.localteam && data.commentaries.substitutions.localteam.length) || (data.commentaries.substitutions.visitorteam && data.commentaries.substitutions.visitorteam.length))) {
            var item = {"data": data, "type": 2};
            listData.push(item);
        }

        if (data.commentaries.substitutes && ((data.commentaries.substitutes.localteam && data.commentaries.substitutes.localteam.length) || (data.commentaries.substitutes.visitorteam && data.commentaries.substitutes.visitorteam.length))){
            var item = {"data": data, "type": 3};
            listData.push(item);
        }

        var timeout = 0;
        if (this.matchlineupsListrender.containerTarget.tabIndex != this.tabview.currentActiveTab) {
            this.tabview.tabs[this.matchlineupsListrender.containerTarget.tabIndex].showLoader();
            timeout = 500;
        }

        setTimeout(()=>{
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            this.tabview.tabs[this.matchlineupsListrender.containerTarget.tabIndex].hideLoader();
            this.matchlineupsListrender.setDataSource(listData);
        },timeout);
    }

    loadMatchHighlights (data,ignoreCache){
        console.log("load match highlights",this.matchHighlightListrender)
        var listData = [];

        var item = {"data":data,"type":13};
        listData.push(item);

        var timeout = 0;
        if (this.matchHighlightListrender.containerTarget.tabIndex != this.tabview.currentActiveTab) {
            this.tabview.tabs[this.matchHighlightListrender.containerTarget.tabIndex].showLoader();
            this.waitingForActiveHighlightsTab = true;
            return;
        }

        setTimeout(()=>{
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            this.tabview.tabs[this.matchHighlightListrender.containerTarget.tabIndex].hideLoader();
            this.matchHighlightListrender.setDataSource([{"type":13,"data":data}]);
        },timeout);
    }

    loadMatchChat (){
        console.log("load match cht");
        if (!this.chatListrender.initialized) {
            this.tabview.tabs[this.chatListrender.containerTarget.parent.tabIndex].showLoader();
            this.chatListrender.initSocketIO();
        }
        else{

            this.chatListrender.scrollTo(9999999);
        }
    }

    autoInterval (overrideInterval){
        clearTimeout(this.matchLoadHandler);
        this.matchLoadHandler = setTimeout(()=>{
            if (document.hidden){
                console.warn("no auto refresh because page is hidden");
                return;
            }
            ///if old data match is already finished do nothing.
            if (this.matchData && MatchUtil.isFinished(this.matchData)){
                console.log("match is already finished, no auto refresh");
                return;
            }

            DataProvider.getMatch(UrlManager.getModule("mid"),this.getProxy(function(response) {

                if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

                if (!response.isOk()){
                    this.autoInterval();
                    return;
                }
                //todo error screen

                //the header of hte matchscreen uses cached data, which can be set from the outside.
                this.matchData = response.getData();
                ManiaCache.setShortCache("match",this.matchData)
                this.matchHeader.setPresetData(this.matchData,true);

                //coordinator needs this to properly collapse based on different status
                this.coordinatoranimator.matchStatus = this.matchData.status;

                if (MatchUtil.isFinished(this.matchData)){
                    this.getPageTemplate().createMenuMatch(false,this.matchData.id);
                    console.log("stop auto refresh. match is finished")
                    return;
                }
                else{
                    this.getPageTemplate().createMenuMatch(true,this.matchData.id);
                }

                this.loadMatchStats(this.matchData);

                if (this.matchlineupsListrender) {

                    if (this.matchData.commentaries && !this.matchData.commentaries.teams
                    && this.matchData.commentaries_predicted && this.matchData.commentaries_predicted.teams) {

                        var oldCommentaries = this.matchData.commentaries;
                        this.matchData.commentaries = this.matchData.commentaries_predicted;
                        this.matchData.commentaries.isPredicted = true;
                        this.matchData.commentaries.commentaries = oldCommentaries.commentaries;
                    }

                    if (this.matchData.commentaries && this.matchData.commentaries.teams) {
                        this.loadMatchLineups(this.matchData);
                    }
                }

            }));
        },overrideInterval != null ? overrideInterval : AppInstance.getConfig().refreshIntervals.match)
    }

    acceptInteraction (data){

        var message = "";

        // this.bottomArrow.unbind('click');
        // this.bottomInput.unbind('click');
        // this.bottomInput.unbind('keyup');
        this.bottomInput.bind('click', () =>{
            this.bottomInput.setData("");
        });
        this.bottomArrow.bind('click', () =>{
            this.chatListrender.addMessage(this.bottomInput.getData());
            this.bottomInput.setData("");
        });
        this.bottomInput.bind('keyup', (e) =>{
            if (e.keyCode == 13) {
                this.chatListrender.addMessage(this.bottomInput.getData());
                this.bottomInput.setData("");
            }
            else{
                message = this.bottomInput.getData();
                console.log("the current message = "+message);
            }
        });
    }
};