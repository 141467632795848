import {CustomListControl} from "../bananaframework/src/controls/listcontrols/CustomListControl.js";
import {
    DataGridTileItemRender
} from "../bananaframework/src/controls/listcontrols/datagrids/listrenders/DataGridTileItemRender.js";
import {Panel} from "../bananaframework/src/controls/Panel.js";
import {svgRepo} from "../util/utils.js";

export class LazyTileGridRenderer extends CustomListControl {
    init() {
        super.init();
        this.addCssClass("LazyTileGrid")
        this.defaultContentItemRender = DataGridTileItemRender;
        // this.lazyTileGridRendererWorker = new Worker('js/util/workers.js');
    }

    unload() {
        var scrollEl = jQuery("#" + this.containerTarget.getClientId());
        scrollEl.off("scroll", this.scrollMethod); //what if others listen to this event
        if (this.fastScrollContainerHandler) {
            var fastScrollEl = jQuery("#" + this.fastScrollContainerHandler.getClientId());
            fastScrollEl.off("touchmove", this.fastScrollTouchMoveMethod);
            fastScrollEl.off("touchend", this.fastScrollTouchEndMethod);
            fastScrollEl.on("click", this.fastScrollClickMethod);
        }
        if (this.tabview) {
            $(this.tabview).off('swipeStart', this.swipeStartMethod);
            $(this.tabview).off('swipeEnd ', this.swipeEndMethod);
        }
        if (this.enablePullToRefresh) {
            scrollEl.off("touchstart", this.pullToRefreshTouchStartMethod);
            scrollEl.off("touchend", this.pullToRefreshTouchEndMethod);
            scrollEl.off("touchmove", this.pullToRefreshTouchMoveMethod);
        }

        this.indexTilePlaceHolderMap = null;
        this.datasourceByType = null;
        this.datasourceIndexByTypeNormalized = null;

        clearTimeout(this.callLaterMethod);
        clearTimeout(this.indicerHandler);
        //this.lazyTileGridRendererWorker.terminate();
    }

    createComponents() {
        super.createComponents();

        //when we know the tabview we can know when user swipes and apply data when user is finished swiping
        if (this.tabview) {
            this.swipeStartMethod = (e) => {
                this.isSwiping = true;
            };
            //
            this.swipeEndMethod = (e) => {
                this.isSwiping = false;
                if (this.callSetDataSourceLater) {
                    var ds = this.callSetDataSourceLater;
                    this.callSetDataSourceLater = null;
                    this.setDataSource(ds);
                }
            };

            //prevent the data to load when touching. usually when scrolling.
            //it creates an annoying flicker. on touch up we apply the data
            $(this.tabview).on('swipeStart', this.swipeStartMethod);
            $(this.tabview).on('swipeEnd ', this.swipeEndMethod);
        } else {
            console.warn("no tabview found, so no swipe events can be detected. please add it for better user experience")
        }
    }

    setDataSource(ds) {
        if (this.isSwiping) {
            console.warn("user is swiping, so we dont apply the data now, but later when user is finished swiping");
            this.callSetDataSourceLater = ds;
            return;
        }

        if (this.stopOnDatasourceChangeUpdatesFlag) {
            console.warn("do not update list when stopOnDatasourceChangeUpdatesFlag is set");
            this.postponedDatasource = ds;
            return;
        }

        if (!this.datasource) {
            this.datasource = ds; //we dont need to clone this, cause do we change and save the datasource?
            this.clear();
            this.createControls();
            this.invalidateDisplay();
            //this.triggerEvent('onSetDataSource');
            return this;
        } else {
            this.datasource = ds;
            var scroll = this.getScroll();
            this.createItems();
            this.scrollTo(scroll);
        }
    }

    /**
     * @return {DataGridTileItemRender}
     */
    getDefaultItemRender() {
        return this.defaultContentItemRender;
    }

    /**
     * Set the global item render for this list render.
     * @param {DataGridTileItemRender} itemRender
     */
    setItemRender(render) {
        this.defaultContentItemRender = render;
        this.triggerEvent('itemRenderChanged');
    }

    stopOnDatasourceChangeUpdates() {
        //console.log("stopOnDatasourceChangeUpdates");
        this.stopOnDatasourceChangeUpdatesFlag = true;
    }

    continueOnDatasourceChangeUpdates() {
        this.stopOnDatasourceChangeUpdatesFlag = false;
        if (this.postponedDatasource) {
            //console.log("continueOnDatasourceChangeUpdates");
            this.setDataSource(this.postponedDatasource);
            this.postponedDatasource = null;
        }
        else{
            //console.log("continueOnDatasourceChangeUpdates but no postponedDatasource");
        }
    }

    showError(errorMessage, cb) {
        if (!this.errorTemplate) {
            return console.error("cannot show error without template");
        }

        this.createControls();
        this.mainContainer.clear();
        var control = this.errorTemplate({'message': errorMessage, "cb": cb});
        this.mainContainer.addControl(control, true);
        this.renderedErrorTemplate = control;
        this.invalidateDisplay();
    }

    hideError() {
        this.mainContainer.clear();
    }

    /**
     * Invoked after changing datasource or invalidating
     * @ignore
     */
    createControls() {
        super.createControls();
        this.clear();
        this.mainContainer = new Panel();
        this.mainContainer.addCssClass("listViewMainContainer")
        this.addControl(this.mainContainer);

        if (this.enableFastScroll) {
            this.fastScrollContainerHandler = new Panel();
            this.fastScrollContainerHandler.addCssClass("listViewFastScrollContainer");
            if (FootballManiaConfig.isRtl) {
                this.fastScrollContainerHandler.setCss({"left": "0px"});
            } else {
                this.fastScrollContainerHandler.setCss({"right": "0px"});
            }
            this.addControl(this.fastScrollContainerHandler);
        }
    }

    onWindowResize() {
        var delay = 20;
        if (!this.windowResizeHandler) delay = 0;

        var scrollEl = jQuery("#" + this.containerTarget.getClientId());
        this.contentHeight = scrollEl.height();

        clearTimeout(this.windowResizeHandler);

        this.windowResizeHandler = setTimeout(() => {
            if (!this.mainContainer) return;
            console.log("on window resize proceed");
            var scroll = this.getScroll();
            this.createItems(false);
            this.scrollTo(scroll);
        }, delay)
    }

    /**
     * @ignore
     */
    updateDisplay() {
        if (!this.datasource) return;
        this.createItems(true);

        if (this.enableFastScroll && this.fastScrollContainerHandler) {
            var scrollEl = jQuery("#" + this.fastScrollContainerHandler.getClientId());
            scrollEl.off("click");
            this.fastScrollClickMethod = (e) => {
                console.log("fast click", e, e.target.className);
                //logic below is to make sure the click events are propagated to the correct element
                //the fast scrollbar container is a sibling of the content scroll container and therefor
                //no events are propogated to the content scroll container
                //we do it manually. Can we do it in a better way??
                if (!e.originalEvent) return;
                if (e.target.className != "listViewFastScrollContainer") {
                    // console.warn("do not propogate click when not originating from listViewFastScrollContainer");
                    // var els = document.elementsFromPoint(e.originalEvent.clientX, e.originalEvent.clientY);
                    // if (els.length < 1) return;
                    // console.log(els[0])
                    // $(els[0]).trigger("click");
                    return;
                }
                var els = document.elementsFromPoint(e.originalEvent.clientX, e.originalEvent.clientY);
                if (els.length < 1) return;
                $(els[1]).trigger("click");
            }
            scrollEl.on("click", this.fastScrollClickMethod);
        }
    }

    scrollTo(y) {
        if (this.containerTarget.getParent()) {
            this.scrollLater = y;
            return jQuery("#" + this.containerTarget.getClientId()).scrollTop(y);
        }
        this.scrollLater = y;
    }

    scrollToSmooth(y, duration) {
        if (!this.containerTarget.getParent()) return;
        jQuery("#" + this.containerTarget.getClientId()).animate({
            scrollTop: y
        }, duration);
    }

    scrollToLastSelectedOrTop() {
        if (this.selectedItem != undefined) {
            this.scrollTo(this.selectedItem);
        } else {
            this.scrollTo(0);
        }
    }

    getScroll() {
        return jQuery("#" + this.containerTarget.getClientId()).scrollTop();
    }

    itemHeight = 60;
    overlapItems = 3;
    minItemsRenderAtOnce = 2;
    scrollMethod = null;
    lazyTileGridRendererWorker = null;

    autoMove() {

        if (!this.datasource.length) return;

        this.indexTilePlaceHolderMap = [];
        this.indexRenderedItemRenderMap = [];

        var scrollEl = jQuery("#" + this.containerTarget.getClientId());

        var previousTopVisibleItem = [];
        var topVisibleItem = [];

        var prevScrollTop = null;
        var scrollTop = -1;
        var lastFoundTopVisibleItem = [];

        var renderList = [];
        var relativeIndexItem;
        var targetPos;

        var run = (type, scrollTop) => {

            if (!this.datasourceByType[type]) return;

            if (scrollTop == 0) {
                topVisibleItem[type] = 0;
                lastFoundTopVisibleItem[type] = 0;
            } else {
                if (this.datasourceIndexByTypeNormalized) {
                    topVisibleItem[type] = this.datasourceIndexByTypeNormalized[type][Math.floor(scrollTop / 40) * 40];
                } else {
                    topVisibleItem[type] = Processors.findNearestItem(this.datasourceByType[type], scrollTop);
                }
                topVisibleItem[type] = (topVisibleItem[type] >= 5) ? topVisibleItem[type] - 5 : 0;
            }
            if (previousTopVisibleItem[type] == topVisibleItem[type]) {
                return;
            }

            previousTopVisibleItem[type] = topVisibleItem[type];

            renderList[type] = [];
            relativeIndexItem = Math.floor(topVisibleItem[type] / this.maxItemsToRender)
            // console.log(type,"relative index item",relativeIndexItem,"maxrender",this.maxItemsToRender);
            for (var i = 0; i < this.maxItemsToRender; i++) {

                if (i >= (topVisibleItem[type] % this.maxItemsToRender)) {
                    renderList[type][i] = (i + (relativeIndexItem * this.maxItemsToRender));
                } else {
                    renderList[type][i] = ((i + this.maxItemsToRender) + (relativeIndexItem * this.maxItemsToRender));
                }
            }
            //console.log(type,renderList[type]);
            for (var i = 0; i < renderList[type].length; i++) {
                //console.log("x",this.datasourceByType[type]);
                if (!this.datasourceByType[type][renderList[type][i]]) continue;

                //console.log("y");
                this.datasourceByType[type][renderList[type][i]].level = relativeIndexItem;

                //a new item needs to be rendered
                if (!this.indexTilePlaceHolderMap[type] || !this.indexTilePlaceHolderMap[type][i]) {
                    //console.log("==============> create placeholder",i,type);
                    this.createDivPlaceHolder(i, true, this.datasourceByType[type][renderList[type][i]].style.height, type);
                    this.indexTilePlaceHolderMap[type][i].renderType = this.datasourceByType[type][renderList[type][i]].type;
                    this.createItemRenderByIndex(i, this.datasourceByType[type][renderList[type][i]], true, type);
                    ///console.log("create item render ",i,type)
                }

                var indexedPlaceHolder = this.indexTilePlaceHolderMap[type][i];

                var prevIndex = indexedPlaceHolder.currentDataIndex;
                indexedPlaceHolder.currentDataIndex = renderList[type][i];

                //and existing item needs to be updated
                if (prevIndex != indexedPlaceHolder.currentDataIndex) {

                    if (this.indexRenderedItemRenderMap[type][i] != this.datasourceByType[type][renderList[type][i]]) {
                        //console.log("==============> update placeholder",i,type);
                        this.indexRenderedItemRenderMap[type][i].setData(this.datasourceByType[type][renderList[type][i]]);
                        targetPos = this.datasourceByType[type][indexedPlaceHolder.currentDataIndex].style.offset;
                        indexedPlaceHolder.setCss({"top": targetPos + "px"});
                        ///console.log("set data ",i,type)
                    }
                }
            }
        };

        var length = Object.keys(this.datasourceByType).length + 4;
        this.scrollMethod = () => {

            scrollTop = scrollEl.scrollTop();

            this.triggerEvent("scroll", scrollTop);

            for (var i = 0; i < length; i++) {
                // if (i != 5) continue;
                run(i + 1, scrollTop, prevScrollTop);
            }

            prevScrollTop = scrollTop;
        };

        if (this.scrollMethod) {
            scrollEl.off("scroll", this.scrollMethod);
        }

        scrollEl.scroll(this.scrollMethod);

        for (var i = 0; i < length; i++) {
            run(i + 1, scrollEl.scrollTop(), -1)
        }
    };

    heightIndex = null;
    datasourceByType = null;
    calculatedScreenHeight = 0;
    calculatedItemsHeight = 0;
    renderListContainers = null; //strange when defined as empty array, the variable is shared across multiple instances. why?
    datasourceTypeCount = 0;
    previousWasEmptyDataSource = false;
    datasourceIndexNormalized = null;

    createItems(allowFlickerBetweenRender) {
        if (!this.datasource || !this.datasource.length) {

            this.mainContainer.clear();
            if (this.emptyTemplate) {
                var control = this.emptyTemplate();
                // this.mainContainer.setCss({'height':"100%"})
                // t;
                //this.mainContainer.addControl("asdsdadsadsa")
                this.mainContainer.addControl(control);
                this.mainContainer.invalidateDisplay();
                // console.log("aap");
                this.previousWasEmptyDataSource = true;
                this.mainContainer.setCss({'height': "100%"});
            }
            return;
        }

        this.calculatedScreenHeight = 0;
        this.calculatedItemsHeight = 0;

        if (this.renderedErrorTemplate) {
            this.renderedErrorTemplate.remove();
        }

        if (this.previousWasEmptyDataSource) {
            this.mainContainer.clear();
            this.previousWasEmptyDataSource = false;
        }

        if (!this.renderListContainers) this.renderListContainers = [];

        var renderListContainer = new Panel();
        renderListContainer.addCssClass("maincontainer2");

        ///we do some optimization here to prevent flickering whne new list items
        ///are created. we create a container which we render hidden, we wait
        //for x ms and then simultanously hide and show the old and new one.
        if (this.renderListContainers.length) {
            //console.log("we have a previous render",this.renderListContainers.length);
            //renderListContainer.setVisible(false);
            renderListContainer.setCss({"visibility": "hidden"});
        }
        this.renderListContainers.push(renderListContainer);

        // console.log("data length",this.datasource.length)
        //calculate scrolling area
        var height = 0;
        var id = 1;
        this.datasourceByType = [];
        this.datasourceTypeCount = 0;
        var selectedItem = -1;

        for (var i = 0; i < this.datasource.length; i++) {

            this.datasource[i].style.offset = height;
            this.datasource[i].listId = id;

            if (this.datasource[i].style.selected) {
                selectedItem = this.datasource[i].style.offset;
                this.selectedItem = selectedItem;
            }

            if (!this.datasourceByType[this.datasource[i].type]) {
                this.datasourceTypeCount++;
                this.datasourceByType[this.datasource[i].type] = [];
            }
            this.datasourceByType[this.datasource[i].type].push(this.datasource[i]);

            id++;
            height += this.datasource[i].style.height;
        }

        this.positionIndexCalculated = false; //important to reset this flag

        this.datasourceIndexByTypeNormalized = {};

        for (var t = 0; t < 15; t++) {

            if (!this.datasourceByType[t]) continue;
            if (!this.datasourceIndexByTypeNormalized[t]) this.datasourceIndexByTypeNormalized[t] = {};

            var x = 0;
            for (var i = 0; i <= height; i += 40) {

                if (!this.datasourceByType[t][x]) break;

                if (i <= (this.datasourceByType[t][x].style.offset + this.datasourceByType[t][x].style.height)) {
                    this.datasourceIndexByTypeNormalized[t][i] = x;
                } else {
                    x++;
                    i -= 40;
                }
            }
        }

        // //some logic to index datasource to an offset. we run this logic a little later to let ui be more responsive.
        // //without this index a binary search is used to find the correct item.
        // this.indicerHandler = setTimeout(()=>{
        //     return;
        //     var t0 = new Date().getTime();
        //     var found = null;
        //     var it = 0;
        //     this.datasourceIndexByTypeNormalized = {};
        //     for (var x=0; x < 15; x++){
        //         if (!this.datasourceByType[x]) continue;
        //         it = 0;
        //         for (var i=0; i < height; i+=40){
        //             for (var j=it; j < this.datasourceByType[x].length;j++){
        //                 if (i <= this.datasourceByType[x][j].style.offset){
        //                     //found = this.datasourceByType[x][j];
        //                     found = j;
        //                     it = j;
        //                     break;
        //                 }
        //             }
        //             if (!this.datasourceIndexByTypeNormalized[x]) this.datasourceIndexByTypeNormalized[x] = {};
        //             this.datasourceIndexByTypeNormalized[x][i] = found;
        //         }
        //     }
        //     console.log("c",(new Date().getTime()-t0),this.datasourceIndexByTypeNormalized);
        // },500);


        this.calculatedItemsHeight = height;

        var dimensions = this.containerTarget.getDimensions();
        this.calculatedScreenHeight = dimensions.height;

        var avarageHeight = height / this.datasource.length;
        this.itemHeight = avarageHeight;

        if (!this.maxItemsToRender) {
            this.maxItemsToRender = Math.ceil(dimensions.height / avarageHeight);
            this.maxItemsToRender += 8;
        }

        this.maxItemsToRender = Math.max(FootballManiaConfig.graphics.listRenderMaxRenderCount, this.maxItemsToRender); ///hack
        //this.maxItemsToRender = 5;

        this.mainContainer.setCss({'height': height + "px"});
        if (this.fastScrollContainerHandler && this.enableFastScroll) {
            this.fastScrollContainerHandler.setCss({'height': height + "px"});
        }
        //console.log("set height ",height);
        this.mainContainer.addControl(renderListContainer, true);

        if (this.scrollLater != undefined) {
            this.scrollTo(this.scrollLater);
            this.scrollLater = undefined;
        } else if (selectedItem != undefined) {
            this.scrollTo(selectedItem);
        }
        this.autoMove();
        renderListContainer.invalidateDisplay();

        var delay = 80;
        if (allowFlickerBetweenRender) delay = 0;
        //console.log("renderlist container length",this.renderListContainers.length);
        clearTimeout(this.rerenderTimeoutHandler);
        if (this.renderListContainers.length > 1) {
            this.rerenderTimeoutHandler = setTimeout(() => {
                if (this.renderListContainers.length > 1) {
                    this.renderListContainers[this.renderListContainers.length - 1].setCss({"display": ""});

                    for (var i = 0; i < this.renderListContainers.length - 1; i++) {
                        //console.log("remove list container ",i)
                        this.getPage().removeControl(this.renderListContainers[i]);
                    }
                    //this.renderListContainers[1].setCss({"display": ""});
                    this.renderListContainers = [];
                    this.renderListContainers.push(renderListContainer);
                    renderListContainer.setCss({"visibility": ""});
                }
                this.triggerEvent("createdList")
            }, delay)
        } else {
            this.triggerEvent("createdList")
        }
        this.activateFastScroll();
        this.activatePullToRefresh();
    }

    getItemByScrollPosition(scrollPosition) {
        if (!this.positionIndexCalculated) {
            this.positionIndex = {};
            var x = 0;
            for (var i = 0; i <= this.calculatedItemsHeight; i += 40) {

                if (!this.datasource[x]) break;
                //if (x >= 10000) break;

                if (i <= (this.datasource[x].style.offset + this.datasource[x].style.height)) {
                    this.positionIndex[i] = x;
                } else {
                    x++;
                    i -= 40;
                }
            }
            this.positionIndexCalculated = true;
        }

        var quantized = Math.floor(scrollPosition / 40) * 40;
        return this.datasource[this.positionIndex[quantized]];
    }

    activatePullToRefresh() {

        if (!this.enablePullToRefresh || !this.enablePullToRefresh) return;

        var scrollEl = jQuery("#" + this.containerTarget.getClientId());
        var topOffset;
        var startY = -1;
        var hold = false;
        var indicatorTriggered = false;
        var timeoutHandler;

        scrollEl.off("touchstart", this.pullToRefreshTouchStartMethod);
        this.pullToRefreshTouchStartMethod = () => {
            hold = false;
        };
        scrollEl.on("touchstart", this.pullToRefreshTouchStartMethod);

        scrollEl.off("touchend", this.pullToRefreshTouchEndMethod);
        this.pullToRefreshTouchEndMethod = () => {
            console.log("end")
            startY = -1;
            indicatorTriggered = false;
            clearTimeout(timeoutHandler);
            this.deactivatePullToRefresh();
            this.continueOnDatasourceChangeUpdates();
        };
        scrollEl.on("touchend", this.pullToRefreshTouchEndMethod);

        scrollEl.off("touchmove", this.pullToRefreshTouchMoveMethod);
        this.pullToRefreshTouchMoveMethod = (e) => {
            if (hold || this.isSwiping) return;
            this.stopOnDatasourceChangeUpdates();
            topOffset = scrollEl.scrollTop();
            if (topOffset > 0){
                hold = true;
                return;
            }
            if (startY == -1){
                this.stopOnDatasourceChangeUpdates();
                startY = e.originalEvent.touches[0].clientY;
                //add html before
            }
            var diff = e.originalEvent.touches[0].clientY - startY;

            if (topOffset == 0 && diff > 0){
                var pullTo = Math.min(70,Math.floor(diff));
                this.mainContainer.setCss({"transform":"translateY("+pullTo/(70/50)+"px)"});

                if (!indicatorTriggered && diff >=70) {
                    indicatorTriggered = true;
                    var svgContent = svgRepo.ball;
                    jQuery("#" + this.mainContainer.getClientId()).prepend(`<div id="pullToRefreshContainer" class="pullToRefreshContainer">`+svgContent+`</div>`);
                    clearTimeout(timeoutHandler);
                    timeoutHandler = setTimeout(() => {
                        hold = true;
                        startY = -1;
                        indicatorTriggered = false;
                        this.deactivatePullToRefresh();
                        this.continueOnDatasourceChangeUpdates();
                        this.triggerEvent("pullToRefreshAction")
                    }, 800);
                }
                else if (!indicatorTriggered){
                    clearTimeout(timeoutHandler);
                }
            }
        };
        scrollEl.on("touchmove", this.pullToRefreshTouchMoveMethod);
    }

    deactivatePullToRefresh(){
        jQuery("#pullToRefreshContainer").remove();
        this.mainContainer.setCss({"transform":"translateY(0px)", "transition-duration": "100ms"});
    }

    activateFastScroll() {

        if (!this.fastScrollContainerHandler || !this.enableFastScroll) return;
        if (this.fastScrollActivated) return;
        this.fastScrollActivated = true;

        var fastScrollEl = jQuery("#" + this.fastScrollContainerHandler.getClientId());
        var scrollEl = jQuery("#" + this.containerTarget.getClientId());
        var topOffset = scrollEl.offset().top;
        this.contentHeight = scrollEl.height();
        var scrollHeight = fastScrollEl[0].scrollHeight;

        if (!this.contentHeight || !scrollHeight || scrollHeight / this.contentHeight < 2) {
            console.log("do not activate fast scroll when scroll content is too smalll");
            return;
        }

        fastScrollEl.off("touchend", this.fastScrollTouchEndMethod);
        this.fastScrollTouchEndMethod = () => {

            this.isFastScrolling = false;
            previousClickedFastScrollItem = null;
            clearTimeout(this.fastScrollItemAnimationHandle)
            this.fastScrollItemAnimationHandle = setTimeout(() => {
                if (this.unloaded || !this.fastScrollIndicatorControl) return;
                this.fastScrollIndicatorControl.addCssClass("fastscrollbaritemanimateout")
            }, 100);
        };
        fastScrollEl.on("touchend", this.fastScrollTouchEndMethod);

        this.indicatorpos = 0;
        var touchCurrentPos = 0;
        var scrollPercentage = 0;
        var previousClickedFastScrollItem = null;
        var currentFastScrollItem = null;

        fastScrollEl.off("touchmove", this.fastScrollTouchMoveMethod);
        this.fastScrollTouchMoveMethod = (e) => {
            this.isFastScrolling = true;
            if (!this.fastScrollIndicatorControl) {
                this.fastScrollIndicatorControl = new Panel();
                this.fastScrollIndicatorControl.addCssClass("FastScrollContentItem");

                if (FootballManiaConfig.isRtl) {
                    this.fastScrollIndicatorControl.addCssClass("FastScrollContentItemRTL");
                }

                this.fastScrollContainerHandler.addControl(this.fastScrollIndicatorControl, true);
                // this.fastScrollIndicatorControl.bind("click",(e)=>{
                //     e.stopPropagation();
                //     console.log("trigger click for fastscrollindicator",this.indicatorpos);
                //     currentFastScrollItem = this.getItemByScrollPosition(this.indicatorpos);
                //     this.triggerEvent("fastScrollIndicatorClicked",currentFastScrollItem);
                // });

                if (!this.fastScrollElementTemplate) {
                    return console.warn("no fast scroll element defined")
                }

                if (this.fastScrollElementTemplate) {
                    this.fastScrollElement = this.fastScrollElementTemplate();
                    this.fastScrollIndicatorControl.addControl(this.fastScrollElement, true);
                }
            }

            e.preventDefault()
            e.stopImmediatePropagation();
            e.stopPropagation();
            touchCurrentPos = e.originalEvent.touches[0].clientY;
            scrollPercentage = Math.min(1, Math.max(0, (touchCurrentPos - topOffset) / (this.contentHeight)));

            this.scrollTo(scrollPercentage * scrollHeight);
            //console.log(scrollPercentage,scrollEl.scrollTop());

            if (!this.fastScrollElementTemplate) return;

            this.indicatorpos = Math.max(topOffset, Math.min(this.calculatedItemsHeight, ((scrollPercentage * scrollHeight) + (scrollPercentage * this.contentHeight))));

            this.fastScrollIndicatorControl.removeCssClass("fastscrollbaritemanimateout")

            currentFastScrollItem = this.getItemByScrollPosition(this.indicatorpos);
            if (currentFastScrollItem != previousClickedFastScrollItem) {
                this.triggerEvent("requestFastScrollIndicatorItem", {
                    "item": currentFastScrollItem,
                    "element": this.fastScrollElement
                });
            }
            previousClickedFastScrollItem = currentFastScrollItem;

            this.fastScrollIndicatorControl.setCss({
                "top": this.indicatorpos + "px",
            });

        };
        fastScrollEl.on("touchmove", this.fastScrollTouchMoveMethod);
    }

    createDivPlaceHolder(index, instantRender, height, type) {
        //console.log("create div placeholder ",index,this.renderListContainers[this.renderListContainers.length-1].getClientId());
        var tileplaceholder = new Panel();
        tileplaceholder.addCssClass("BDataGridTilePlaceHolder");
        tileplaceholder.setCss({"position": "absolute"});

        if (this.placeHolderWidth) {
            tileplaceholder.setCss({width: this.placeHolderWidth});
        }

        //tileplaceholder.bind('mouseenter',this.getProxy(function(e){this.onRowMouseOver(e); return true;}),tileplaceholder);
        //tileplaceholder.bind('mouseleave',this.getProxy(function(e){this.onRowMouseOut(e);return true;}),tileplaceholder);
        //tileplaceholder.bind('click',this.getProxy(function(e){this.onRowMouseClick(e);return true;}),tileplaceholder);

        this.renderListContainers[this.renderListContainers.length - 1].addControl(tileplaceholder, instantRender);

        if (!this.indexTilePlaceHolderMap) this.indexTilePlaceHolderMap = [];
        if (!this.indexTilePlaceHolderMap[type]) this.indexTilePlaceHolderMap[type] = [];
        this.indexTilePlaceHolderMap[type][index] = tileplaceholder;

        //  console.log(this.indexTilePlaceHolderMap)
    }

    setPlaceHolderWidth(width) {
        this.placeHolderWidth = width;
    }

    createItemRenderByIndex(index, data, instantRerender, type) {
        //console.log("create item render ",index)
        var itemRenderFactory = this.defaultContentItemRender;

        var itemRender = itemRenderFactory(data).render;

        if (!itemRender) return console.warn("no item render created");
        //console.log(itemRender);
        //data.index = index;
        if (!this.indexRenderedItemRenderMap[type]) this.indexRenderedItemRenderMap[type] = [];
        this.indexRenderedItemRenderMap[type][index] = itemRender;
        //itemRender.setData(data,true); //no instant render needed

        itemRender.setListRender(this);
        this.indexTilePlaceHolderMap[type][index].clear();
        this.indexTilePlaceHolderMap[type][index].addControl(itemRender, instantRerender);

        if (instantRerender) {
            //this.indexTilePlaceHolderMap[index].invalidateDisplay();
        }
    }

    onRowMouseOver(e) {
    }

    onRowMouseOut(e) {
    }

    onRowMouseClick(e) {
    }

    selectIndex(index) {
    }

    deSelectIndex(index) {

    }
};

