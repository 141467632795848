import {Panel} from "../bananaframework/src/controls/Panel.js";
import {Image} from "../bananaframework/src/controls/Image.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {BasePage} from "./basepage.js";

import {Router} from "../util/router.js";
import {Localizer} from "../util/localizer.js";
import {DataProvider} from "../data/dataprovider.js";
import {StateManager} from "../data/stateManager.js";
import {CountryUtil} from "../util/country.js";

import {TabView,TabViewTab} from "../components/tabview.js";
import {SimpleTileGridRenderer} from "../components/simpletilegridrenderer.js";
import {ErrorMessage} from "../components/errormessage.js";
import {AnimatorLeague} from "./animators/animatorleague.js";

import {CountryLeagueListRender} from "../components/home/listrendershome.js";

export class CountryLeagues extends BasePage{

    init () {
        this.pageName = "CountryLeagues_"+UrlManager.getModule("lid");
        this.version = 1;
        super.init();
        this.setStyle("height:100%");

        this.bind("renderComplete",()=>{

            var that = this;
            //alert("listen")
            //we do this to register back button scenario where page A is match and page B is also match
            setTimeout(function() {
                if (that.unloaded){
                    console.log("ignore, page is unloaded");
                    return;
                }
                UrlManager.listenModule('cid', function () {
                    UrlManager.unlistenModule("cid");
                    var id = UrlManager.getModule('cid');
                    if (id == undefined || UrlManager.getModule('section') != "CountryLeagues") {
                        return console.warn("ignore opening league , probably navigating to other screen");
                    }
                    AppInstance.loadPage("CountryLeagues", {"cid": id}, false);
                });
            },100);

        });

        this.pageName = "CountryLeagues_"+UrlManager.getModule("lid");

        this.addCssClass("LeaguePage");

        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();

        if (this.isNavigatedFromOutside){
            StateManager.flushPageState(this.pageName);
        }

        console.log("OPENING PAGE "+this.pageName+" VERSION "+this.version+" is from outside "+this.isNavigatedFromOutside)

        this.coordinatoranimator = new AnimatorLeague();

        Router.disallowBackButtonQuitApp();
        this.createPageLayout();

        if (AppInstance.workersInitialized) {
            this.wrapper1 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper1.addEventListener("message", this.wrapper1);
        }
    }

    onWorkerMessageReceived (e){
        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
        this.tabview.tabs[0].hideLoader();
        this.competitionsListrender.setDataSource(e.data);
        this.autoInterval();
    }

    storeState (){

        var d = {
            "tabIndex":this.tabview.currentActiveTab,
            "competitionsScrollPosition" : this.competitionsListrender.getScroll(),
            "headerCollapsed" : this.coordinatoranimator.isCollapsed
        }
        StateManager.savePageState(this.pageName,d);
    }

    unload (){
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();
        clearTimeout(this.competitionsLoadHandler);
        UrlManager.unlistenModule('cid');

        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.removeEventListener("message", this.wrapper1);
        }
    }

    onVisibilityChange (){
        //page is becoming visible after hide
        if (this.previousDocumentHidden = true && !document.hidden){

        }
        this.previousDocumentHidden = document.hidden;
        //console.log("visibility change ",document.hidden,this);
    }

    createPageLayout (){
        var row2 = new Panel();
        this.addControl(row2);
        this.contentContainer = row2;
    }

    createComponents (){
        this.getPageTemplate().showTitle();
    }

    onWindowResize (){
        super.onWindowResize();
        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }

        if (this.competitionsListrender) {
            this.competitionsListrender.onWindowResize();
        }
    }

    updateDisplay (){

        this.runApp();
    }

    runApp (){

        console.log("run app")

        this.showLoader();

        AppInstance.setup((result)=>{

            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            if (!result){
                this.hideLoader();
                console.error("error app setup");
                return this.showError("",()=>{
                    this.runApp();
                });
            }

            var country = decodeURI(UrlManager.getModule("cid"));

            DataProvider.getCountryLeagues(country,(response)=>{

                this.hideLoader();

                if (this.unloaded){ return console.warn("page is unloaded abort further actions"); }

                if (!response.isOk()){

                    if (response.getStatus() == 404) {
                        return this.showPageDataDoesNotExist("", ()=> {
                            this.runApp();
                        });
                    }
                    else{
                        return this.showError("", ()=> {
                            this.runApp();
                        });
                    }
                }

                var data = response.getData();

                this.createTitle(data);
                this.createTabs(data);

                this.getPageTemplate().createMenuCountryLeagues(response.getData());

                this.tabview.bind("tabChanged",()=>{
                    this.storeState();
                    this.coordinatoranimator.tabChange();
                });

                var storeHandler = null;
                this.competitionsListrender.bind("scroll",(e,scroll)=> {
                    clearTimeout(storeHandler);
                    storeHandler = setTimeout(()=> {
                        this.storeState();
                    },200);
                });

                var previousState = StateManager.getCurrentPageState(this.pageName);
                if (previousState) {
                    this.tabview.currentActiveTab = previousState.tabIndex;
                    this.competitionsListrender.scrollTo(previousState.competitionsScrollPosition || 0);
                    console.log("restored scroll ",previousState.competitionsScrollPosition)
                    this.coordinatoranimator.isCollapsed = previousState.headerCollapsed;
                }

                this.contentContainer.invalidateDisplay();
                this.coordinatoranimator.prepare();

                this.loadCountryLeagues(data);
            },true);
        });
    }

    createTitle (data){

        var country = decodeURI(UrlManager.getModule("cid"));

        var image = new Image();
        image.setStyle("width:28px;")
        image.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/flags/"+country.replaceAll(" ","-").toLowerCase()+".png");
        var title = new Panel();
        title.addControl(CountryUtil.getLocalizedCountryByName(country));

        this.getPageTemplate().topTitleContainer.addControl(image);
        this.getPageTemplate().topTitleContainer.addControl(title);
        this.getPageTemplate().topTitleContainer.invalidateDisplay();
    }

    createTabs (data){
        var tabview = new TabView();
        this.contentContainer.addControl(tabview);

        var tabs = [];

        var tab1;
        tab1 = new TabViewTab();
        tab1.setStyle("width:100%;height:100%;position:relative;overflow-y: scroll;transform: translate(0,0);");
        tab1.title = Localizer.getString("competitions");
        tabs.push(tab1);

        this.createCountryLeaguesControls(tab1,0);
        this.coordinatoranimator.addScrollerContainer(tab1);

        this.coordinatoranimator.addCollapser(this.getPageTemplate().topBar);
        this.coordinatoranimator.addTabView(tabview);

        tabview.setTabs(tabs);

        tabview.bind("onReady",()=>{
           this.coordinatoranimator.run();
        });

        this.tabview =tabview;

        tab1.showLoader();
    }

    createCountryLeaguesControls (tab,index){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();

        listRender.containerTarget = tab;

        listRender.setItemRender(function(data){

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new CountryLeagueListRender(),
                };
            }
        })

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',()=>{
            this.coordinatoranimator.recalculateTab(index);
        });

        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            console.log("restore scroll #2 : ",previousState.competitionsScrollPosition)
            listRender.scrollTo(previousState.competitionsScrollPosition || 0);
        }

        container.addControl(listRender);
        this.competitionsListrender = listRender;

        tab.addControl(container);
    }

    loadCountryLeagues (data,ignoreCache){

        if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

        for (var i = 0; i < data.length; i++) {
            data[i].type = 1;
            data[i].country = decodeURI(UrlManager.getModule("cid"));
        }

        this.tabview.tabs[0].hideLoader();
        this.competitionsListrender.setDataSource(data);

        Router.disallowBackButtonQuitApp();
    }
};