import {Localizer} from "./localizer.js";
import {LevensteinDistance} from "./utils.js";

export const CountryUtil = {

    cache : {},

    getLocalizedCountryByName : function(name,forceLocale){
        var locale = nameCountries[name];

        if (!locale){
            //console.log("not found ",name);
            return Localizer.getString(name);
        }

        if (this.cache[locale+name+forceLocale || FootballManiaConfig.userLocale]){
            return this.cache[locale+name+forceLocale || FootballManiaConfig.userLocale];
        }

        if (!Intl.DisplayNames){
            //console.warn("Intl.DisplayNames not supported. use default name");
            return name;
        }
        var regionNamesInEnglish = new Intl.DisplayNames(
            [forceLocale || FootballManiaConfig.userLocale],
            { type: 'region' });

        this.cache[locale+name+forceLocale || FootballManiaConfig.userLocale] = regionNamesInEnglish.of(locale);
        return this.cache[locale+name+forceLocale || FootballManiaConfig.userLocale];
    },

    getCountryByLocale : function(locale,displayLocale){

        if (this.cache[locale+displayLocale]){
            return this.cache[locale+displayLocale];
        }

        var regionNamesInEnglish = new Intl.DisplayNames(
            [displayLocale],
            { type: 'region' });

        this.cache[locale+displayLocale] = regionNamesInEnglish.of(locale.toUpperCase());
        return this.cache[locale+displayLocale];
    },

    getLocalizedLanguageNameByLocale : function(locale,forceLocale){

        locale = locale.toUpperCase();
        if (this.cache[locale]){
            return this.cache[locale];
        }

        const languageNames = new Intl.DisplayNames([locale], {
            type: 'language'
        });
        this.cache[locale] = languageNames.of(locale);
        return this.cache[locale];
    },

    getRegionByLocale : function(forceLocale){
        if (FootballManiaConfig.userLocaleFull.region){
            var region = FootballManiaConfig.userLocaleFull.region;
        }
        else{
            console.log("cannot determine reliable the region, use default language");
            var region = FootballManiaConfig.userLocale;
        }
        var countryName = this.getCountryByLocale(region,"en-EN");
        if (countryToRegion[countryName]) {
            return countryToRegion[countryName];
        }
        return "Unknown";
    },

    isRealCountry : function(country){
        return countryToRegion[country] ? true : false;
    },

    localizeCountries : function(countries,locale){
        var localizedCountries = [];
        for (var i = 0; i < countries.length; i++){
            var country = countries[i];
            var localized = this.getLocalizedCountryByName(country,locale);
            if (localized){
                localizedCountries.push({
                    "name" : country.toLowerCase(),
                    "localized" : localized
                });
            }
            else{
                console.log("=========> cannot localize country ",country);
                localizedCountries.push({
                    "name" : country.toLowerCase(),
                    "localized" : country
                });
            }
        }
        //sort by localized name
        localizedCountries.sort(function(a,b){
            if (a.localized < b.localized){
                return -1;
            }
            if (a.localized > b.localized){
                return 1;
            }
            return 0;
        });

        return localizedCountries;
    },

    findCountries : function(countrystack,search,locale){
        var countries = [];
        for (var i=0; i <  countrystack.length; i++) {

            var distance = LevensteinDistance(countrystack[i].localized.toLowerCase(),search.toLowerCase());

            if (distance < 2 || countrystack[i].localized.toLowerCase().match(search.toLowerCase())){
                countries.push(countrystack[i]);
            }
        }
        return countries;
    },
};

if (typeof window !== 'undefined') {
    window.CountryUtil = CountryUtil;
}

export const countryRegions ={
    "AD": "Southern Europe",
    "AE": "Western Asia, Middle East",
    "AF": "Central Asia",
    "AG": "Caribbean",
    "AI": "Caribbean",
    "AL": "Balkans",
    "AM": "Western Asia",
    "AO": "Central Africa",
    "AQ": "Antarctica",
    "AR": "South America",
    "AS": "American Samoa",
    "AT": "Western Europe",
    "AU": "Oceania",
    "AW": "Caribbean",
    "AX": "Scandinavia",
    "AZ": "Western Asia",
    "BA": "Balkans",
    "BB": "Caribbean",
    "BD": "Southern Asia",
    "BE": "Western Europe",
    "BF": "Western Africa",
    "BG": "Central Europe",
    "BH": "Western Asia, Middle East",
    "BI": "East Africa, Central Africa",
    "BJ": "Western Africa",
    "BL": "Caribbean",
    "BM": "Caribbean, North America",
    "BN": "South-East Asia",
    "BO": "South America",
    "BQ": "Caribbean",
    "BR": "South America",
    "BS": "Caribbean",
    "BT": "Southern Asia",
    "BV": "Scandinavia, Nordic Countries",
    "BW": "Southern Africa",
    "BY": "Central Europe",
    "BZ": "Caribbean",
    "CA": "North America",
    "CC": "Central America",
    "CD": "Central Africa",
    "CF": "Central Africa",
    "CG": "Central Africa",
    "CH": "Western Europe",
    "CI": "Western Africa",
    "CK": "Oceania",
    "CL": "South America",
    "CM": "Central Africa",
    "CN": "East Asia",
    "CO": "South America",
    "CR": "Central America",
    "CU": "Caribbean",
    "CV": "Western Africa",
    "CW": "Caribbean",
    "CX": "Oceania",
    "CY": "Western Asia",
    "CZ": "Central Europe",
    "DE": "Western Europe",
    "DJ": "East Africa",
    "DK": "Scandinavia, Nordic Countries",
    "DM": "Caribbean",
    "DO": "Caribbean",
    "DZ": "Northern Africa",
    "EC": "South America",
    "EE": "Baltic Countries",
    "EG": "Northern Africa",
    "EH": "Western Sahara",
    "ER": "East Africa",
    "ES": "Southern Europe",
    "ET": "East Africa",
    "FI": "Nordic Countries",
    "FJ": "Oceania",
    "FK": "South America",
    "FM": "Oceania",
    "FO": "Scandinavia, Nordic Countries",
    "FR": "Western Europe",
    "GA": "Central Africa",
    "GB": "Western Europe",
    "GD": "Caribbean",
    "GE": "Western Asia",
    "GF": "South America",
    "GG": "Western Europe",
    "GH": "Western African",
    "GI": "Southern Europe",
    "GL": "North America",
    "GM": "Western Africa",
    "GN": "Western Africa",
    "GP": "Caribbean",
    "GQ": "Central Africa",
    "GR": "Southern Europe",
    "GS": "South America",
    "GT": "Central America",
    "GU": "Oceania",
    "GW": "Western Africa",
    "GY": "Caribbean",
    "HK": "East Asia",
    "HM": "Oceania",
    "HN": "Central America",
    "HR": "Balkans",
    "HT": "Caribbean",
    "HU": "Central Europe",
    "ID": "South-East Asia, Oceania",
    "IE": "Western Europe",
    "IL": "Western Asia, Middle East",
    "IM": "Western Europe",
    "IN": "Southern Asia",
    "IO": "Southern Asia",
    "IQ": "Western Asia, Middle East",
    "IR": "Western Asia, Middle East",
    "IS": "Scandinavia, Nordic Countries",
    "IT": "Southern Europe",
    "JE": "Western Europe",
    "JM": "Caribbean",
    "JO": "Western Asia, Middle East",
    "JP": "East Asia",
    "KE": "East Africa",
    "KG": "Central Asia",
    "KH": "South-East Asia",
    "KI": "Oceania",
    "KM": "East Africa",
    "KN": "Caribbean",
    "KP": "East Asia",
    "KR": "East Asia",
    "KW": "Western Asia, Middle East",
    "KY": "Caribbean",
    "KZ": "Central Asia",
    "LA": "South-East Asia",
    "LB": "Western Asia, Middle East",
    "LC": "Caribbean",
    "LI": "Western Europe",
    "LK": "Southern Asia",
    "LR": "Western Africa",
    "LS": "Southern Africa",
    "LT": "Baltic Countries",
    "LU": "Western Europe",
    "LV": "Baltic Countries",
    "LY": "Northern Africa",
    "MA": "Northern Africa",
    "MC": "Western Europe",
    "MD": "Central Europe",
    "ME": "Balkans",
    "MF": "Caribbean",
    "MG": "East Africa, Southern Africa",
    "MH": "Oceania",
    "MK": "Balkans",
    "ML": "Western Africa",
    "MM": "South-East Asia",
    "MN": "East Asia",
    "MO": "East Asia",
    "MP": "South-East Asia",
    "MQ": "Caribbean",
    "MR": "Western Africa",
    "MS": "Caribbean",
    "MT": "Southern Europe",
    "MU": "East Africa",
    "MV": "Southern Asia",
    "MW": "East Africa, Southern Africa",
    "MX": "North America",
    "MY": "South-East Asia",
    "MZ": "East Africa, Southern Africa",
    "NA": "Southern Africa",
    "NC": "Oceania",
    "NE": "Western Africa",
    "NF": "Oceania",
    "NG": "Western Africa",
    "NI": "Central America",
    "NL": "Western Europe, Nordic Countries",
    "NO": "Scandinavia, Nordic Countries",
    "NP": "Southern Asia",
    "NR": "Oceania",
    "NU": "Oceania",
    "NZ": "Oceania",
    "OM": "Western Asia, Middle East",
    "PA": "Central America",
    "PE": "South America",
    "PF": "Oceania",
    "PG": "Oceania",
    "PH": "South-East Asia",
    "PK": "Southern Asia",
    "PL": "Central Europe",
    "PM": "North America",
    "PN": "Oceania",
    "PR": "Caribbean",
    "PS": "Western Asia, Middle East",
    "PT": "Southern Europe",
    "PW": "Oceania",
    "PY": "South America",
    "QA": "Western Asia, Middle East",
    "RE": "East Africa",
    "RO": "Central Europe",
    "RS": "Balkans",
    "RU": "Eastern Europe",
    "RW": "East Africa, Central Africa",
    "SA": "Western Asia, Middle East",
    "SB": "Oceania",
    "SC": "East Africa",
    "SD": "Northern Africa, East Africa",
    "SE": "Scandinavia, Nordic Countries",
    "SG": "South-East Asia",
    "SH": "Western Africa",
    "SI": "Balkans",
    "SJ": "Scandinavia",
    "SK": "Central Europe",
    "SL": "Western Africa",
    "SM": "Southern Europe",
    "SN": "Western Africa",
    "SO": "East Africa",
    "SR": "South America",
    "SS": "East Africa, Northern Africa",
    "ST": "Central Africa",
    "SV": "Central America",
    "SX": "Caribbean",
    "SY": "Western Asia, Middle East",
    "SZ": "Southern Africa",
    "TC": "Caribbean",
    "TD": "Central Africa",
    "TF": "Oceania",
    "TG": "Western Africa",
    "TH": "South-East Asia",
    "TJ": "Central Asia",
    "TK": "Oceania",
    "TL": "South-East Asia",
    "TM": "Central Asia",
    "TN": "Northern Africa",
    "TO": "Oceania",
    "TR": "Western Asia, Middle East",
    "TT": "Caribbean",
    "TV": "Oceania",
    "TW": "East Asia",
    "TZ": "East Africa",
    "UA": "Central Europe",
    "UG": "East Africa",
    "UM": "Oceania",
    "US": "North America",
    "UY": "South America",
    "UZ": "Central Asia",
    "VA": "Southern Europe",
    "VC": "Caribbean",
    "VE": "South America",
    "VG": "Caribbean",
    "VI": "Caribbean",
    "VN": "South-East Asia",
    "VU": "Oceania",
    "WF": "Oceania",
    "WS": "Oceania",
    "XK": "Balkans",
    "YE": "Western Asia, Middle East",
    "YT": "East Africa",
    "ZA": "Southern Africa",
    "ZM": "East Africa, Southern Africa",
    "ZW": "East Africa, Southern Africa"
};

var countryToRegion = {
    "Afghanistan": "Southern and Central Asia",
    "Albania": "Southern Europe",
    "Algeria": "Northern Africa",
    "American Samoa": "Polynesia",
    "Andorra": "Southern Europe",
    "Angola": "Central Africa",
    "Anguilla": "Caribbean",
    "Antarctica": "Antarctica",
    "Antigua and Barbuda": "Caribbean",
    "Argentina": "South America",
    "Armenia": "Middle East",
    "Aruba": "Caribbean",
    "Australia": "Australia and New Zealand",
    "Austria": "Western Europe",
    "Azerbaijan": "Middle East",
    "Bahamas": "Caribbean",
    "Bahrain": "Middle East",
    "Bangladesh": "Southern and Central Asia",
    "Barbados": "Caribbean",
    "Belarus": "Eastern Europe",
    "Belgium": "Western Europe",
    "Belize": "Central America",
    "Benin": "Western Africa",
    "Bermuda": "North America",
    "Bhutan": "Southern and Central Asia",
    "Bolivia": "South America",
    "Bosnia and Herzegovina": "Southern Europe",
    "Botswana": "Southern Africa",
    "Bouvet Island": "Antarctica",
    "Brazil": "South America",
    "British Indian Ocean Territory": "Eastern Africa",
    "Brunei": "Southeast Asia",
    "Bulgaria": "Eastern Europe",
    "Burkina Faso": "Western Africa",
    "Burundi": "Eastern Africa",
    "Cambodia": "Southeast Asia",
    "Cameroon": "Central Africa",
    "Canada": "North America",
    "Cape Verde": "Western Africa",
    "Cayman Islands": "Caribbean",
    "Central African Republic": "Central Africa",
    "Chad": "Central Africa",
    "Chile": "South America",
    "China": "Eastern Asia",
    "Christmas Island": "Australia and New Zealand",
    "Cocos (Keeling) Islands": "Australia and New Zealand",
    "Colombia": "South America",
    "Comoros": "Eastern Africa",
    "Congo": "Central Africa",
    "Cook Islands": "Polynesia",
    "Costa Rica": "Central America",
    "Croatia": "Southern Europe",
    "Cuba": "Caribbean",
    "Cyprus": "Middle East",
    "Czech Republic": "Eastern Europe",
    "Denmark": "Nordic Countries",
    "Djibouti": "Eastern Africa",
    "Dominica": "Caribbean",
    "Dominican Republic": "Caribbean",
    "East Timor": "Southeast Asia",
    "Ecuador": "South America",
    "Egypt": "Northern Africa",
    "El Salvador": "Central America",
    "England": "Western Europe",
    "Equatorial Guinea": "Central Africa",
    "Eritrea": "Eastern Africa",
    "Estonia": "Baltic Countries",
    "Ethiopia": "Eastern Africa",
    "Falkland Islands": "South America",
    "Faroe Islands": "Nordic Countries",
    "Fiji Islands": "Melanesia",
    "Finland": "Nordic Countries",
    "France": "Western Europe",
    "French Guiana": "South America",
    "French Polynesia": "Polynesia",
    "French Southern territories": "Antarctica",
    "Gabon": "Central Africa",
    "Gambia": "Western Africa",
    "Georgia": "Middle East",
    "Germany": "Western Europe",
    "Ghana": "Western Africa",
    "Gibraltar": "Southern Europe",
    "Greece": "Southern Europe",
    "Greenland": "North America",
    "Grenada": "Caribbean",
    "Guadeloupe": "Caribbean",
    "Guam": "Micronesia",
    "Guatemala": "Central America",
    "Guinea": "Western Africa",
    "Guinea-Bissau": "Western Africa",
    "Guyana": "South America",
    "Haiti": "Caribbean",
    "Heard Island and McDonald Islands": "Antarctica",
    "Holy See (Vatican City State)": "Southern Europe",
    "Honduras": "Central America",
    "Hong Kong": "Eastern Asia",
    "Hungary": "Eastern Europe",
    "Iceland": "Nordic Countries",
    "India": "Southern and Central Asia",
    "Indonesia": "Southeast Asia",
    "Iran": "Southern and Central Asia",
    "Iraq": "Middle East",
    "Ireland": "British Isles",
    "Israel": "Middle East",
    "Italy": "Southern Europe",
    "Ivory Coast": "Western Africa",
    "Jamaica": "Caribbean",
    "Japan": "Eastern Asia",
    "Jordan": "Middle East",
    "Kazakhstan": "Southern and Central Asia",
    "Kenya": "Eastern Africa",
    "Kiribati": "Micronesia",
    "Kuwait": "Middle East",
    "Kyrgyzstan": "Southern and Central Asia",
    "Laos": "Southeast Asia",
    "Latvia": "Baltic Countries",
    "Lebanon": "Middle East",
    "Lesotho": "Southern Africa",
    "Liberia": "Western Africa",
    "Libyan Arab Jamahiriya": "Northern Africa",
    "Liechtenstein": "Western Europe",
    "Lithuania": "Baltic Countries",
    "Luxembourg": "Western Europe",
    "Macao": "Eastern Asia",
    "North Macedonia": "Southern Europe",
    "Madagascar": "Eastern Africa",
    "Malawi": "Eastern Africa",
    "Malaysia": "Southeast Asia",
    "Maldives": "Southern and Central Asia",
    "Mali": "Western Africa",
    "Malta": "Southern Europe",
    "Marshall Islands": "Micronesia",
    "Martinique": "Caribbean",
    "Mauritania": "Western Africa",
    "Mauritius": "Eastern Africa",
    "Mayotte": "Eastern Africa",
    "Mexico": "Central America",
    "Micronesia, Federated States of": "Micronesia",
    "Moldova": "Eastern Europe",
    "Monaco": "Western Europe",
    "Mongolia": "Eastern Asia",
    "Montserrat": "Caribbean",
    "Morocco": "Northern Africa",
    "Mozambique": "Eastern Africa",
    "Myanmar": "Southeast Asia",
    "Namibia": "Southern Africa",
    "Nauru": "Micronesia",
    "Nepal": "Southern and Central Asia",
    "Netherlands": "Western Europe",
    "Netherlands Antilles": "Caribbean",
    "New Caledonia": "Melanesia",
    "New Zealand": "Australia and New Zealand",
    "Nicaragua": "Central America",
    "Niger": "Western Africa",
    "Nigeria": "Western Africa",
    "Niue": "Polynesia",
    "Norfolk Island": "Australia and New Zealand",
    "North Korea": "Eastern Asia",
    "Northern Ireland": "Western Europe",
    "Northern Mariana Islands": "Micronesia",
    "Norway": "Nordic Countries",
    "Oman": "Middle East",
    "Pakistan": "Southern and Central Asia",
    "Palau": "Micronesia",
    "Palestine": "Middle East",
    "Panama": "Central America",
    "Papua New Guinea": "Melanesia",
    "Paraguay": "South America",
    "Peru": "South America",
    "Philippines": "Southeast Asia",
    "Pitcairn": "Polynesia",
    "Poland": "Eastern Europe",
    "Portugal": "Southern Europe",
    "Puerto Rico": "Caribbean",
    "Qatar": "Middle East",
    "Reunion": "Eastern Africa",
    "Romania": "Eastern Europe",
    "Russian Federation": "Eastern Europe",
    "Rwanda": "Eastern Africa",
    "Saint Helena": "Western Africa",
    "Saint Kitts and Nevis": "Caribbean",
    "Saint Lucia": "Caribbean",
    "Saint Pierre and Miquelon": "North America",
    "Saint Vincent and the Grenadines": "Caribbean",
    "Samoa": "Polynesia",
    "San Marino": "Southern Europe",
    "Sao Tome and Principe": "Central Africa",
    "Saudi Arabia": "Middle East",
    "Scotland": "Western Europe",
    "Senegal": "Western Africa",
    "Serbia": "Central and Southeast Europe",
    "Seychelles": "Eastern Africa",
    "Sierra Leone": "Western Africa",
    "Singapore": "Southeast Asia",
    "Slovakia": "Eastern Europe",
    "Slovenia": "Southern Europe",
    "Solomon Islands": "Melanesia",
    "Somalia": "Eastern Africa",
    "South Africa": "Southern Africa",
    "South Georgia and the South Sandwich Islands": "Antarctica",
    "South Korea": "Eastern Asia",
    "South Sudan": "Eastern Africa",
    "Spain": "Southern Europe",
    "Sri Lanka": "Southern Asia",
    "Sudan": "Northern Africa",
    "Suriname": "South America",
    "Svalbard and Jan Mayen": "Nordic Countries",
    "Swaziland": "Southern Africa",
    "Sweden": "Nordic Countries",
    "Switzerland": "Western Europe",
    "Syria": "Middle East",
    "Tajikistan": "Southern and Central Asia",
    "Tanzania": "Eastern Africa",
    "Thailand": "Southeast Asia",
    "The Democratic Republic of Congo": "Central Africa",
    "Togo": "Western Africa",
    "Tokelau": "Polynesia",
    "Tonga": "Polynesia",
    "Trinidad and Tobago": "Caribbean",
    "Tunisia": "Northern Africa",
    "Turkey": "Middle East",
    "Turkmenistan": "Southern and Central Asia",
    "Turks and Caicos Islands": "Caribbean",
    "Tuvalu": "Polynesia",
    "Uganda": "Eastern Africa",
    "Ukraine": "Eastern Europe",
    "United Arab Emirates": "Middle East",
    //"United Kingdom": "British Isles",
    "United Kingdom": "Western Europe",
    "United States": "North America",
    "United States Minor Outlying Islands": "Micronesia/Caribbean",
    "Uruguay": "South America",
    "Uzbekistan": "Southern and Central Asia",
    "Vanuatu": "Melanesia",
    "Venezuela": "South America",
    "Vietnam": "Southeast Asia",
    "Virgin Islands, British": null,
    "Virgin Islands, U.S.": null,
    "Wales": "Western Europe",
    "Wallis and Futuna": "Polynesia",
    "Western Sahara": "Northern Africa",
    "Yemen": "Middle East",
    "Zambia": "Eastern Africa",
    "Zimbabwe": "Eastern Africa"
};

export const nameCountries = {
    'Afghanistan' : 'AF',
    'Aland Islands' : 'AX',
    'Albania' : 'AL',
    'Algeria' : 'DZ',
    'American Samoa' : 'AS',
    'Andorra' : 'AD',
    'Angola' : 'AO',
    'Anguilla' : 'AI',
    'Antarctica' : 'AQ',
    'Antigua And Barbuda' : 'AG',
    'Argentina' : 'AR',
    'Armenia' : 'AM',
    'Aruba' : 'AW',
    'Australia' : 'AU',
    'Austria' : 'AT',
    'Azerbaijan' : 'AZ',
    'Bahamas' : 'BS',
    'Bahrain' : 'BH',
    'Bangladesh' : 'BD',
    'Barbados' : 'BB',
    'Belarus' : 'BY',
    'Belgium' : 'BE',
    'Belize' : 'BZ',
    'Benin' : 'BJ',
    'Bermuda' : 'BM',
    'Bhutan' : 'BT',
    'Bolivia' : 'BO',
    'Bosnia And Herzegovina' : 'BA',
    'Bosnia and Herzegovina' : 'BA',
    'Botswana' : 'BW',
    'Bouvet Island' : 'BV',
    'Brazil' : 'BR',
    'British Indian Ocean Territory' : 'IO',
    'Brunei Darussalam' : 'BN',
    'Bulgaria' : 'BG',
    'Burkina Faso' : 'BF',
    'Burundi' : 'BI',
    'Cambodia' : 'KH',
    'Cameroon' : 'CM',
    'Canada' : 'CA',
    'Cape Verde' : 'CV',
    'Cayman Islands' : 'KY',
    'Central African Republic' : 'CF',
    'Chad' : 'TD',
    'Chile' : 'CL',
    'China' : 'CN',
    'Christmas Island' : 'CX',
    'Cocos (Keeling) Islands' : 'CC',
    'Colombia' : 'CO',
    'Comoros' : 'KM',
    'Congo' : 'CG',
    'Congo Kinshasa' : 'CD',
    'Cook Islands' : 'CK',
    'Costa Rica' : 'CR',
    'Cote D\'Ivoire' : 'CI',
    'Ivory Coast' : 'CI',
    'Croatia' : 'HR',
    'Cuba' : 'CU',
    'Cyprus' : 'CY',
    'Czech Republic' : 'CZ',
    'Denmark' : 'DK',
    'Djibouti' : 'DJ',
    'Dominica' : 'DM',
    'Dominican Republic' : 'DO',
    'Ecuador' : 'EC',
    'Egypt' : 'EG',
    'El Salvador' : 'SV',
    'El salvador' : 'SV',
    'Equatorial Guinea' : 'GQ',
    'Eritrea' : 'ER',
    'Estonia' : 'EE',
    'Ethiopia' : 'ET',
    'Falkland Islands (Malvinas)' : 'FK',
    'Faroe Islands' : 'FO',
    'Fiji' : 'FJ',
    'Finland' : 'FI',
    'France' : 'FR',
    'French Guiana' : 'GF',
    'French Polynesia' : 'PF',
    'French Southern Territories' : 'TF',
    'Gabon' : 'GA',
    'Gambia' : 'GM',
    'Georgia' : 'GE',
    'Germany' : 'DE',
    'Ghana' : 'GH',
    'Gibraltar' : 'GI',
    'Greece' : 'GR',
    'Greenland' : 'GL',
    'Grenada' : 'GD',
    'Guadeloupe' : 'GP',
    'Guam' : 'GU',
    'Guatemala' : 'GT',
    'Guernsey' : 'GG',
    'Guinea' : 'GN',
    'Guinea-Bissau' : 'GW',
    'Guyana' : 'GY',
    'Haiti' : 'HT',
    'Heard Island & Mcdonald Islands' : 'HM',
    'Holy See (Vatican City State)' : 'VA',
    'Honduras' : 'HN',
    'Hong Kong' : 'HK',
    'Hungary' : 'HU',
    'Iceland' : 'IS',
    'India' : 'IN',
    'Indonesia' : 'ID',
    'Iran, Islamic Republic Of' : 'IR',
    'Iran' : 'IR',
    'Iraq' : 'IQ',
    'Ireland' : 'IE',
    'Isle Of Man' : 'IM',
    'Israel' : 'IL',
    'Italy' : 'IT',
    'Jamaica' : 'JM',
    'Japan' : 'JP',
    'Jersey' : 'JE',
    'Jordan' : 'JO',
    'Kazakhstan' : 'KZ',
    'Kenya' : 'KE',
    'Kiribati' : 'KI',
    'South Korea' : 'KR',
    'Kuwait' : 'KW',
    'Kyrgyzstan' : 'KG',
    'Lao People\'s Democratic Republic' : 'LA',
    'Latvia' : 'LV',
    'Lebanon' : 'LB',
    'Lesotho' : 'LS',
    'Liberia' : 'LR',
    'Libyan Arab Jamahiriya' : 'LY',
    'Libya' : 'LY',
    'Liechtenstein' : 'LI',
    'Lithuania' : 'LT',
    'Luxembourg' : 'LU',
    'Macao' : 'MO',
    'Macedonia' : 'MK',
    'Madagascar' : 'MG',
    'Malawi' : 'MW',
    'Malaysia' : 'MY',
    'Maldives' : 'MV',
    'Mali' : 'ML',
    'Malta' : 'MT',
    'Marshall Islands' : 'MH',
    'Martinique' : 'MQ',
    'Mauritania' : 'MR',
    'Mauritius' : 'MU',
    'Mayotte' : 'YT',
    'Mexico' : 'MX',
    'Micronesia, Federated States Of' : 'FM',
    'Moldova' : 'MD',
    'Moldovia' : 'MD',
    'Monaco' : 'MC',
    'Mongolia' : 'MN',
    'Montenegro' : 'ME',
    'Montserrat' : 'MS',
    'Morocco' : 'MA',
    'Mozambique' : 'MZ',
    'Myanmar' : 'MM',
    'Namibia' : 'NA',
    'Nauru' : 'NR',
    'Nepal' : 'NP',
    'Netherlands' : 'NL',
    'Netherlands Antilles' : 'AN',
    'New Caledonia' : 'NC',
    'New Zealand' : 'NZ',
    'Nicaragua' : 'NI',
    'Niger' : 'NE',
    'Nigeria' : 'NG',
    'Niue' : 'NU',
    'Norfolk Island' : 'NF',
    'Northern Mariana Islands' : 'MP',
    'Norway' : 'NO',
    'Oman' : 'OM',
    'Pakistan' : 'PK',
    'Palau' : 'PW',
    'Palestinian Territory, Occupied' : 'PS',
    'Palestine' : 'PS',
    'Panama' : 'PA',
    'Papua New Guinea' : 'PG',
    'Paraguay' : 'PY',
    'Peru' : 'PE',
    'Philippines' : 'PH',
    'Pitcairn' : 'PN',
    'Poland' : 'PL',
    'Portugal' : 'PT',
    'Puerto Rico' : 'PR',
    'Qatar' : 'QA',
    'Reunion' : 'RE',
    'Romania' : 'RO',
    'Russian Federation' : 'RU',
    'Russia' : 'RU',
    'Rwanda' : 'RW',
    'Saint Barthelemy' : 'BL',
    'Saint Helena' : 'SH',
    'Saint Kitts And Nevis' : 'KN',
    'Saint Lucia' : 'LC',
    'Saint Martin' : 'MF',
    'Saint Pierre And Miquelon' : 'PM',
    'Saint Vincent And Grenadines' : 'VC',
    'Samoa' : 'WS',
    'San Marino' : 'SM',
    'Sao Tome And Principe' : 'ST',
    'Saudi Arabia' : 'SA',
    'Senegal' : 'SN',
    'Serbia' : 'RS',
    'Seychelles' : 'SC',
    'Sierra Leone' : 'SL',
    'Singapore' : 'SG',
    'Slovakia' : 'SK',
    'Slovenia' : 'SI',
    'Solomon Islands' : 'SB',
    'Somalia' : 'SO',
    'South Africa' : 'ZA',
    'South Georgia And Sandwich Isl.' : 'GS',
    'Spain' : 'ES',
    'Sri Lanka' : 'LK',
    'Sudan' : 'SD',
    'Suriname' : 'SR',
    'Svalbard And Jan Mayen' : 'SJ',
    'Swaziland' : 'SZ',
    'Sweden' : 'SE',
    'Switzerland' : 'CH',
    'Syrian Arab Republic' : 'SY',
    'syria' : 'SY',
    'Taiwan' : 'TW',
    'Tajikistan' : 'TJ',
    'Tanzania' : 'TZ',
    'Thailand' : 'TH',
    'Timor-Leste' : 'TL',
    'Togo' : 'TG',
    'Tokelau' : 'TK',
    'Tonga' : 'TO',
    'Trinidad and Tobago' : 'TT',
    'Tunisia' : 'TN',
    'Turkey' : 'TR',
    'Turkmenistan' : 'TM',
    'Turks And Caicos Islands' : 'TC',
    'Tuvalu' : 'TV',
    'Uganda' : 'UG',
    'Ukraine' : 'UA',
    'United Arab Emirates' : 'AE',
    'United Kingdom' : 'GB',
    'United States' : 'US',
    'United States Outlying Islands' : 'UM',
    'Uruguay' : 'UY',
    'Uzbekistan' : 'UZ',
    'Vanuatu' : 'VU',
    'Venezuela' : 'VE',
    'Viet Nam' : 'VN',
    'Vietnam' : 'VN',
    'Virgin Islands, British' : 'VG',
    'Virgin Islands, U.S.' : 'VI',
    'Wallis And Futuna' : 'WF',
    'Western Sahara' : 'EH',
    'Yemen' : 'YE',
    'Zambia' : 'ZM',
    'Zimbabwe' : 'ZW'
};