import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {Image} from "../../bananaframework/src/controls/Image.js";

import {Localizer} from "../../util/localizer.js";
import {ImagePlaceHolder} from "../imageplaceholder.js";


export class PlayerHeader extends Panel{

    init () {
        super.init();
        this.addCssClass("MatchHeaderContainer TeamHeaderContainer");
        this.bounceEnabled = true;

        var homeContainer = new Panel();
        homeContainer.addCssClass("MatchHeaderHomeContainer");
        homeContainer.setStyle("width:100%;");

        this.backgroundImage = new Image();
        this.backgroundImage.setVisible(false);
        this.backgroundImage.addCssClass("MatchHeaderBackgroundImage");
        this.backgroundImage.setStyle("filter: blur(8px);width:170%;height:170%;");
        //this.backgroundImage.addCssClass("fade-in-image2");

        this.homeImage = new ImagePlaceHolder();
        this.homeImage.addCssClass("MatchHeaderTeamLogoContainer");
        this.homeImage.setStyle("height:100px;width:100px;");

        this.teamFlag = new ImagePlaceHolder();
        this.teamFlag.setStyle("margin-inline-start:4px;width:40px; height:40px;align-self: center;border-radius:30px; overflow:hidden; position:absolute; top:3px; right:calc(50% + 55px);");

        this.image = new Image();
        this.image.setStyle("width:40px; height:40px; position:absolute;top:3px; left:calc(50% + 55px);");
        this.image.addCssClass("MatchStatsContainerFormStart flexHorizontalAlign");

        this.homeTitle = new Panel();
        this.homeTitle.addCssClass("MatchHeaderTeamText");

        this.addControl(this.backgroundImage);
        this.addControl(homeContainer);

        homeContainer.addControl(this.homeImage);
        homeContainer.addControl(this.teamFlag);
        homeContainer.addControl(this.image);
        homeContainer.addControl(this.homeTitle);
    }

    setPresetData (data,instantRender){

        if (!data){
            console.error("cannot set preset data without data");
            return;
        }
        if (instantRender) {
            if (data.nationality) {
                this.backgroundImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.nationality.replaceAll(" ", "-").toLowerCase() + ".png");
                this.backgroundImage.setVisible(true);
            }
        }

        this.homeTitle.clear();
        //this.homeTitle.addControl(data.name,true);

        if (!AppInstance.getConfig().isRtl) {
            this.homeTitle.addControl(data.firstname + " " + data.lastname, true);
        }
        else{
            this.homeTitle.addControl(data.lastname + " " + data.firstname, true);
        }

        if (!this.oldData || this.oldData.id != data.id) {

            if (AppInstance.getConfig().enabledFeatures.playerImages) {

                this.homeImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/playerimages/" + data.id + "_rnd.png", 100, 100);
                this.homeImage.setAttribute("alt",Localizer.getString("player image"));

                if (data.nationality) {

                    this.teamFlag.imageObjectStyle = "margin-top:-15px;margin-left:-16px;";
                    this.teamFlag.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.nationality.replaceAll(" ", "-").toLowerCase() + ".png", 70, 70);
                } else {
                    this.teamFlag.remove();
                }

                if (data.teamid) {
                    this.image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/teams_gs/" + data.teamid + "_small_rnd.png", AppInstance.getConfig().sizes.fixtureListImageSize, AppInstance.getConfig().sizes.fixtureListImageSize);
                }
                else{
                    this.image.remove();
                }
            }
            else{
                this.teamFlag.remove();
                this.image.remove();
                this.homeImage.imageObjectStyle = "margin-top:-40px;margin-left:-40px;";
                this.homeImage.setCss({'overflow':"hidden","border-radius":"50%"});
                if (data.nationality) {
                    this.homeImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.nationality.replaceAll(" ", "-").toLowerCase() + ".png", 180, 180);
                }
                //this.image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/teams_gs/" + data.teamid + "_small_rnd.png", AppInstance.getConfig().sizes.fixtureListImageSize, AppInstance.getConfig().sizes.fixtureListImageSize);
            }
        }
        this.oldData = data;
    }

    setData (data){
    }
};