import {Panel} from "../bananaframework/src/controls/Panel.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

export class Modal extends Panel{

	title = null;
	classname = null;
	disableHiding = false

	init(urlIdentifier){
		super.init();

		this.addCssClass("homodal");

		var bg = new Panel();
		bg.addCssClass("homodal-bg");
		this.addControl(bg);
		this.bg = bg;

		var scrollPanel = new Panel();
		scrollPanel.addCssClass("homodal-scrollpanel");
		this.addControl(scrollPanel);
		this.scrollPanel = scrollPanel;

		var mainPanel = new Panel();
		mainPanel.addCssClass("homodal-mainpanel");
		this.scrollPanel.addControl(mainPanel);
		this.mainPanel = mainPanel;

		var mainPanelWrapper = new Panel();
		mainPanelWrapper.addCssClass("homodal-mainpanel-wrapper");
		this.mainPanel.addControl(mainPanelWrapper);
		this.mainPanelWrapper = mainPanelWrapper;

		var marginPanel = new Panel();
		marginPanel.addCssClass("homodal-margin");
		mainPanelWrapper.addControl(marginPanel);

		var contentPanelBg = new Panel();
		contentPanelBg.addCssClass("homodal-contentsbg");
		mainPanelWrapper.addControl(contentPanelBg);

		var contentPanel = new Panel();
		contentPanel.addCssClass("homodal-contents");
		marginPanel.addControl(contentPanel);
		this.contentPanel = contentPanel;

		var close = new Panel();
		close.addCssClass("icon-close homodal-closeButton");
		contentPanel.addControl(close);

		this.addControl = function(control){
			this.contentPanel.addControl(control,true);
		}

		if (!urlIdentifier){
			urlIdentifier = "m_"+this.getId();
		}

		this.urlIdentifier = urlIdentifier;

		if (!UrlManager.getModule(urlIdentifier)) {
			UrlManager.registerModule(urlIdentifier);
			UrlManager.setModule(urlIdentifier, "open");
		}

		UrlManager.listenModule(urlIdentifier, ()=> {
			console.log("received url event",urlIdentifier);
			if (!UrlManager.getModule(urlIdentifier)){
				console.log("received url event @3",urlIdentifier);
				UrlManager.unlistenModule(urlIdentifier);
				UrlManager.removeModule(urlIdentifier);
				this.destroy();
			}
		});

		close.bind("click",()=>{
			history.back();
			this.destroy();
		});
	}

	updateDisplay(){
		this.bg.addCssClass("homodal-bg-ani");
		this.mainPanel.addCssClass("homodal-mainpanel-ani");
		this.reValidateSize();

		if (!this.disableHiding) {
			this.bg.bind("touchend",()=>{
				this.destroy();
			});
			this.mainPanel.bind("click",(e)=>{
				e.stopPropagation(); //otherwise window click closes the modal
			});
			this.scrollPanel.bind("click",()=>{
				this.destroy();
			});

			///todo what about other onkeyup listeners?
			jQuery(window).unbind('keyup');
			jQuery(window).on('keyup', (e)=> {
				if (e.key == "Escape") {
					this.destroy();
				}
			});
		}

		this.getPage().triggerEvent("onModalOpen",this);
	}

	destroy (){
		jQuery(window).unbind('keyup');
		UrlManager.unlistenModule(this.urlIdentifier);
		//UrlManager.removeModule(this.urlIdentifier);
		this.bg.addCssClass("homodal-bg-closing");
		this.mainPanel.addCssClass("homodal-mainpanel-ani-close");
		var that = this;
		setTimeout(function(){
			that.remove();
		},200);
		this.getPage().triggerEvent("onModalClose",this);
	}

	reValidateSize(){
		var windowHeight = $(window).height();
		//this.mainPanel.setCss({"margin-top":Math.max(0,(((windowHeight-height)/2)))})
		this.mainPanel.setCss({"margin-top":50+"px"})
		this.scrollPanel.setCss({"height":windowHeight+"px"});
		this.mainPanelWrapper.setCss({"height":windowHeight-100+"px"});
	}
};