import {Panel} from "../bananaframework/src/controls/Panel.js";
import {Image} from "../bananaframework/src/controls/Image.js";
import {Control} from "../bananaframework/src/controls/Control.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {BasePage} from "./basepage.js";

import {Router} from "../util/router.js";
import {ManiaPreferences} from "../util/preferences.js";
import {Localizer} from "../util/localizer.js";
import {ManiaCache} from "../util/cache.js";
import {Favorites} from "../util/favorites.js";
import {Subscriber} from "../util/subscriber.js";
import {Processors} from "../util/processors.js";

import {AnimatorAgenda} from "./animators/animatoragenda.js";
import {DataProvider} from "../data/dataprovider.js";
import {StateManager} from "../data/stateManager.js";

import {TabView2,TabViewTab2} from "../components/tabview2.js";
import {LazyTileGridRenderer} from "../components/lazytilegridrenderer.js";
import {ErrorMessage} from "../components/errormessage.js";

import {MatchItemRender,
        MatchHeaderItemRender} from "../components/home/listrendershome.js";


export class Agenda extends BasePage{

    init () {
        this.pageName = "Agenda";
        this.version = 1;

        super.init();

        this.addCssClass("AgendaPage");
        this.listRenders = [];
        this.workers = [];
        this.allowableAutoRefreshDates = [];
        this.autoRefreshTimeoutHandlers = [];

        //here we know if we come from the same page or another.
        //if we navigate from back history or refresh we are not from the outside.
        //if we navigate to this page from another and the referaldata is passed in the url params
        //then we are from the outside.
        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();

        if (this.isNavigatedFromOutside){
            StateManager.flushPageState(this.pageName);
        }

        this.requestedDateToShow = UrlManager.getModule("date");
        if (this.requestedDateToShow){
            this.requestedDateToShow = this.requestedDateToShow.replaceAll("-","/");
        }

        console.log("OPENING PAGE "+this.pageName+" VERSION "+this.version+" is from outside "+this.isNavigatedFromOutside,"---",this.requestedDateToShow)

        this.createPageLayout();
        this.coordinatoranimator = new AnimatorAgenda();
        Router.disallowBackButtonQuitApp();

        if (AppInstance.workersInitialized) {
            this.wrapper1 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper1.addEventListener("message", this.wrapper1);

            this.wrapper2 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper2.addEventListener("message", this.wrapper2);

            this.wrapper3 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper3.addEventListener("message", this.wrapper3);
        }

        ManiaPreferences.bind("showOnlyFavoritesAgendaChanged",()=>{
            this.onShowOnlyFavoritesAgendaChanged();
        });
    }

    findListRenderById (id){
        for (var i = 0; i < this.tabview.tabContents.controls.length; i++) {
            try {
                if (this.tabview.tabContents.controls[i].controls[1].controls[0].controls[0].controls[0].getId() == id) {
                    return this.tabview.tabContents.controls[i].controls[1].controls[0].controls[0].controls[0];
                }
            }
            catch (e) {}
        }
    }

    findIndexForRenderById (id){
        for (var i=0; i < this.tabview.tabContents.controls.length;i++){
            try {
                if (!this.tabview.tabContents.controls[i].controls[1]) {
                    continue;
                }
                if (this.tabview.tabContents.controls[i].controls[1].controls[0].controls[0].controls[0].getId() == id) {
                    return i;
                }
            }
            catch (e) {}
        }
        return -1;
    }

    onShowOnlyFavoritesAgendaChanged (e,setting){
        this.reApplyDataSourceInListRenders();
    }

    onWorkerMessageReceived (e){
        console.log("receive message for listrender id ",e.data[0]);

        if (this.unloaded) {
            return console.warn("page is unloaded abort further actions");
        }

        var listRender = this.findListRenderById(e.data[0]);

        if (!listRender){
            console.error("no listrender found for ",e.data[0]);
            return;
        }
        listRender.parent.parent.hideLoader();
        listRender.setDataSource(e.data[1]);
    }

    storeState (){

        var activeScrollPosition = 0;
        if (this.visibleListRender){
            activeScrollPosition = this.visibleListRender.getScroll();
        }

        var d = {
            "tabIndex":this.tabview.currentActiveTab,
            "activeScrollPosition" : activeScrollPosition,
            "headerCollapsed" : this.coordinatoranimator.isCollapsed
        }
        console.log("store state",d)
        StateManager.savePageState(this.pageName,d);
    }

    unload (){
        this.storeState();
        //alert("unload")
        this.stopAutoRefreshes();
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();

        for (var key in this.autoRefreshTimeoutHandlers){
            clearTimeout(this.autoRefreshTimeoutHandlers[key]);
        }

        this.autoRefreshTimeoutHandlers = null;

        this.getPageTemplate().topTitleContainer.clear();
        ManiaPreferences.unbind("showOnlyFavoritesAgendaChanged");
        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.removeEventListener("message", this.wrapper1);
            AppInstance.workerHelper2.removeEventListener("message", this.wrapper2);
            AppInstance.workerHelper3.removeEventListener("message", this.wrapper3);
        }
    }

    createPageLayout (){
        var row1 = new Panel();
        this.addControl(row1);
        this.contentContainer = row1;
    }

    createComponents (){
        this.getPageTemplate().createMenuAgenda();
        //this.getPageTemplate().hideTitle();
        this.getPageTemplate().showTitle();
        this.createTitle();
    }

    createTitle (){
       //  var image = new Image();
       //  image.setStyle("width:28px;")
       //  image.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/flags/agendaitem.png");
       //  var title = new Panel();
       //  console.log("get title");
       //  title.addControl(Localizer.getString("agenda"));
       //  //this.getPageTemplate().topTitleContainer.addControl(image);
       //  this.getPageTemplate().topTitleContainer.addControl(title);
       //  this.getPageTemplate().topTitleContainer.invalidateDisplay();
       this.getPageTemplate().topTitleContainer.setVisible(false);
    }

    onVisibilityChange (){
        //page is becoming visible after hide
        if (this.previousDocumentHidden = true && !document.hidden){
            this.stopAutoRefreshes();
            this.startAutoRefreshes(); //note that this will not instantly load new data. but that doesnt matter for this page
        }
        this.previousDocumentHidden = document.hidden;

        if (document.hidden){
            this.stopAutoRefreshes();
        }
    }

    onWindowResize (){
        super.onWindowResize();
        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }
        //TODO onwindow resize for active listrenders???
    }

    onOffline (){
        console.log("on offline")
    }

    onOnline (){
        console.log("on online")
    }

    updateDisplay (){
        this.runApp();
    }

    runApp (){
        console.log("run app")
        this.showLoader();
        AppInstance.setup((result)=>{

            console.log("setup complete")
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            if (!result){
                this.hideLoader();
                console.error("error app setup");
                return this.showError("",()=>{
                    this.runApp();
                });
            }

            this.hideLoader();
            this.createTabs();

            this.tabview.bind("tabChanged",()=>{
                console.log("tab changed  activetab",this.tabview.currentActiveTab,"active element",this.tabview.elementIndex)
                this.storeState();
                this.coordinatoranimator.tabChange();
            });

            var previousState = StateManager.getCurrentPageState(this.pageName);
            if (previousState) {
                this.tabview.currentActiveTab = previousState.tabIndex;
                this.coordinatoranimator.isCollapsed = previousState.headerCollapsed;
            }

            this.contentContainer.invalidateDisplay();

            this.coordinatoranimator.prepare();
            this.coordinatoranimator.onCollapseChanged = ()=>{
                console.log("oncollapserchanged")
                this.storeState();
            }
        });
    }

    createTabs (){

        var tabview = new TabView2();
        this.tabview =tabview;
        this.tabview.maxVisibleTabs = 5;

        this.contentContainer.addControl(tabview);

        this.coordinatoranimator.addCollapser(this.getPageTemplate().topBar);
        this.coordinatoranimator.addTabView(tabview);

        var d = new Date();
        d.setUTCMinutes(0);
        d.setUTCHours(0);
        d.setUTCSeconds(0);
        d.setUTCMilliseconds(0);
        d.setTime(d.getTime()- 1000 * 60 * 60 * 24)

        //this.allowableAutoRefreshDates.push(Intl.DateTimeFormat('en-UK').format(d));
        //console.log("CCCCC",Intl.DateTimeFormat('en-UK').format(d),('0' + d.getDate()).slice(-2) + '/'
        //     + ('0' + (d.getMonth()+1)).slice(-2) + '/'
        //     + d.getFullYear())
        this.allowableAutoRefreshDates.push(('0' + d.getDate()).slice(-2) + '/'
            + ('0' + (d.getMonth()+1)).slice(-2) + '/'
            + d.getFullYear());

        d.setTime(d.getTime()+ 1000 * 60 * 60 * 24);
        this.allowableAutoRefreshDates.push(('0' + d.getDate()).slice(-2) + '/'
            + ('0' + (d.getMonth()+1)).slice(-2) + '/'
            + d.getFullYear());

        d.setTime(d.getTime()+ 1000 * 60 * 60 * 24);
        this.allowableAutoRefreshDates.push(('0' + d.getDate()).slice(-2) + '/'
            + ('0' + (d.getMonth()+1)).slice(-2) + '/'
            + d.getFullYear());

        d = new Date();
        d.setMinutes(0);
        d.setHours(0);
        d.setSeconds(0);
        d.setMilliseconds(0);

        console.log(this.allowableAutoRefreshDates);
        var totalItems = 100;
        //var totalItems = 10;
        if (FootballManiaConfig.isRtl) {
            var startTC = d.getTime() + (1000 * (60 * 60 * 24)) * (Math.floor(totalItems / 2))
        }
        else{
            var startTC = d.getTime() - (1000 * (60 * 60 * 24)) * (Math.floor(totalItems / 2))
        }
        var dot = new Date();
        var now = new Date();
        dot.setTime(startTC);

        var tabTitles = [];
        var dateIndixes = [];
        var dataFeedExtendedVersion = []; //for far future or past data feeds we use different source
        var half = Math.floor(totalItems/2);
        var useNormalLength = 5;
        var formatter = Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
            month: 'short', day: 'numeric'
        })

        var formatter2 = Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
            weekday: 'short'
        });

        for (var i=0; i < totalItems;i++){

            //var dateIntl = Intl.DateTimeFormat('en-UK').format(dot);
            //dateIndixes.push(dateIntl);
            var dateIntl = ('0' + dot.getDate()).slice(-2) + '/'
                + ('0' + (dot.getMonth()+1)).slice(-2) + '/'
                + dot.getFullYear();
            dateIndixes.push(dateIntl);

            var date = formatter.format(dot);

            if (dot.getDate() == now.getDate() && dot.getMonth() == now.getMonth() && dot.getFullYear() == now.getFullYear()){
                var day = Localizer.getString("todaysmatches")
            }
            else{
                var day = formatter2.format(dot);
            }

            if ( (i < half && (half - useNormalLength) < i) || ( i >= half && (half + useNormalLength > i))){
                dataFeedExtendedVersion[i] = false;
            }
            else{
                dataFeedExtendedVersion[i] = true;
            }
            var tab1 = {};
            tab1.title = day+"<br>"+date;

            //when page is from outsided we use this property
            if (dateIntl == this.requestedDateToShow){
                console.log("+++++++++++++++++++",dateIntl,this.requestedDateToShow)
                tab1.initialSelected = true;
                console.log("match")
                tabview.currentActiveTab = i;
                //this.storeState();
            }

            tabTitles.push(tab1);

            if (FootballManiaConfig.isRtl) {
                dot.setDate(dot.getDate() - 1);
            }
            else {
                dot.setDate(dot.getDate() + 1);
            }
        }

        if (FootballManiaConfig.isRtl){
            //tabview.currentActiveTab = -1
        }

        var storeHandler;
        var listRenderScrollMethod = ()=>{
            clearTimeout(storeHandler);
            storeHandler = setTimeout(()=> {
                this.storeState();
            },500)
        };

        //called when tab content is changed
        tabview.bind("newContentTabVisible",(e,tabContentElement)=>{

            if (this.visibleListRender) this.visibleListRender.unbind("scroll",listRenderScrollMethod);
            this.visibleListRender = tabContentElement.controls[1].controls[0].controls[0].controls[0];
            console.log("new content tab visible",this.visibleListRender.date)

            this.coordinatoranimator.setActiveScrollContainer(tabContentElement.controls[1].controls[0]);
            this.visibleListRender.bind("scroll",listRenderScrollMethod);

            this.stopAutoRefreshes();
            this.startAutoRefreshes();
            this.coordinatoranimator.run();

            UrlManager.setModule("date",this.visibleListRender.date.replaceAll("/","-"),false,true);
        });

        //called everytime when tabview wants to get content for a tab
        //date contains element index and data index.
        //usually when 3 tabs are rendered element index goes from 0,1,2
        //where 1 is the center one. data index depends on the tab titles
        tabview.bind("requestTabContent",(e,data)=>{
            data.target.clear();

            //out of bounds when most left tab or most right tab is selected
            if (data.dataIndex < 0 || data.dataIndex > dateIndixes.length-1) return;

            var tabContent = new TabViewTab2();
            tabContent.addCssClass("tabviewTabContent")
            tabContent.addCssClass("noscrollbar")
            data.target.addControl(tabContent);

            var listRender = this.createAgendaDay(tabContent,data.elementIndex);
            data.target.invalidateDisplay();
            listRender.date = dateIndixes[data.dataIndex];

            console.log("request content",listRender.date ,data,"index",data.elementIndex,"old index",data.oldIndex,"listrender ",listRender.id);


            if (data.dataIndex == tabview.currentActiveTab){

                //ok when we add the listrender to the target element
                //the listrender will only be available async.
                //thats why we add settimeout. this needs to be researched further.
                //remoing the settimeout will cause the listrender to be null in the worker recevied call
                ((listRender, data) => {
                    setTimeout(() => {
                        this.loadAgendaDay(dateIndixes[data.dataIndex], listRender, dataFeedExtendedVersion[data.dataIndex]);
                        var previousState = StateManager.getCurrentPageState(this.pageName);
                        console.log("previous state", this.pageName, previousState);
                        if (previousState) {
                            listRender.scrollTo(previousState.activeScrollPosition || 0);
                        }
                    }, 20);

                })(listRender, data);

            }
            else{
                ((listRender, data)=> {
                    setTimeout(()=> {
                        this.loadAgendaDay(dateIndixes[data.dataIndex], listRender, dataFeedExtendedVersion[data.dataIndex]);
                    },300);

                })(listRender,data);
            }
        });

        if (FootballManiaConfig.isRtl){
            //tabTitles.reverse();
        }

        tabview.setTabTitles(tabTitles);

        tabview.bind("onReady",()=>{
            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };
            this.contentContainer.addCssClass("fadeIn").setStyle("margin-top:0;");
            this.coordinatoranimator.run();
        });


        this.bind("stickyBannerLoadFail", () => {
            console.error("sticky banner load fail -> set height to 100%");
            if (tab1) this.coordinatoranimator.setScrollerBottomMargin(0);
            this.coordinatoranimator.recalculateTab(1);

        });
        //triggered from page template
        this.bind("stickyBannerLoadSuccess", () => {
            console.log("sticky banner load success -> set height to 100% - 50px",this.tabview.currentActiveTab);
            if (tab1) {
                this.coordinatoranimator.setScrollerBottomMargin(50);
                this.coordinatoranimator.recalculateTab(1);
            }
        });
        this.getPageTemplate().loadStickyBannerAd();
    }

    createAgendaDay (tab,elementIndex){
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new LazyTileGridRenderer();
        listRender.containerTarget = tab;
        listRender.enableFastScroll = true;

        listRender.setItemRender(function(data){

            if (data.type == 2) {
                return {
                    typeId: 1,
                    render: new MatchHeaderItemRender(),
                };
            }

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new MatchItemRender(),
                };
            }
        })

        listRender.emptyTemplate = function(){
            var empty = new Panel()
            empty.addCssClass("listViewEmptyData");

            var attention = new Panel();
            attention.addCssClass("icon-attention");
            attention.setStyle("width:60px;height:60px;")
            empty.addControl(attention);

            empty.addControl(Localizer.getString("no_matches_found"));
            //empty.addControl("<div style='margin-top:20px;' class='text-small'>"+Localizer.getString("you_have_enabled_favorite_filter")+"</div>");

            let set = ManiaPreferences.getShowOnlyFavoritesAgenda();
            if (set) {
                empty.addControl("<div style='color:orange;margin-top:20px;margin-left:50px;margin-right:50px;' class='text-small'>" + Localizer.getString("you_have_enabled_favorite_filter") + "</div>");
            }

            return empty;
        }

        listRender.errorTemplate = function(cb){
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function(){
            return new ErrorMessage();
        }

        listRender.bind('createdList',()=>{
            this.coordinatoranimator.recalculateTab(this.findIndexForRenderById(listRender.getId()));
        });

        listRender.fastScrollElementTemplate = ()=>{
            var panel = new Control();
            var icon = new Image();
            var label = new Panel();
            panel.addControl(icon);
            panel.addControl(label);
            return panel;
        }

        listRender.bind('requestFastScrollIndicatorItem',(e,data)=>{
            if (!data.item){
                return;
            }
            var icon = data.element.controls[0];
            var label = data.element.controls[1];

            icon.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/flags/"+data.item.country.replaceAll(" ","-").toLowerCase()+".png",30,30);

            label.clear();
            label.addControl("<b>"+data.item.localizedCountry+"</b><br>"+"<p style='font-size:13px;'>"+data.item.league+"</p>",true);
        });

        listRender.setPlaceHolderWidth("100%;");

        var previousState = StateManager.getCurrentPageState(this.pageName
        );
        if (previousState) {
            listRender.scrollTo(previousState.todayScrollPosition || 0);
        }
        container.addControl(listRender);

        tab.addControl(container);

        return listRender;
    }

    loadAgendaDay (date,listRenderTarget,extendedDataFeed,allowAutoRefresh, ignoreCache,dontShowLoader,isFromAutoRefresh){

        //when page is already loaded then we dont load when page is hidden. but not first time, because it can happen that interstitial hides the screen first time
        if (this["firstTimeLoading"+date] && document.hidden){
            return this.autoRefresh(listRenderTarget);
        }
        this["firstTimeLoading"+date] = true;

        if (ManiaCache.getMemoryCache("fixtureDate"+date)){
            //this.listRenders[elementIndex].setDataSource(ManiaCache.getMemoryCache("fixtureDate"+date));
            //return this.autoRefreshToday(2000);
        }

        if (!dontShowLoader) {
            listRenderTarget.parent.parent.showLoader();
        }
        listRenderTarget.date = date;

        DataProvider.getFixturesForDate(date,extendedDataFeed,(response)=>{

            if (this.unloaded){ return console.warn("page is unloaded abort further actions"); };

            if (!response.isOk()){
                listRenderTarget.parent.parent.hideLoader();
                if (isFromAutoRefresh){ //dont show error when auto refreshing and i.e no internet
                    return;
                }

                var str = Localizer.getString("nointernet")+"<br>"+Localizer.getString("taptotryagain");
                listRenderTarget.showError(str,()=>{
                    this.loadAgendaDay(date,listRenderTarget,extendedDataFeed,allowAutoRefresh,true);
                });
                return;
            }

            listRenderTarget.loadedDatasource = response.getData();

            var options = {
                methodType: 7,
                responseData: response.getData(),
                fixedRegions: AppInstance.getConfig().fixedRegions,
                fixedLeagueOrder: AppInstance.getConfig().fixedLeaguesOrder,
                favoriteLeagues: Favorites.getFavoriteLeagues(),
                favoriteTeams: Favorites.getFavoriteTeams(),
                translations: Localizer.getLoadedLocale(),
                defaultTranslations : Localizer.DefaultTranslations,
                locale: AppInstance.getLocale(),
                userRegionLocale: FootballManiaConfig.userRegionLocale,
                userCountry: FootballManiaConfig.detectedUserCountry,
                hiddenTitles: FootballManiaConfig.hideCountryTitleFor,
                listRenderId: listRenderTarget.getId(),
                showOnlyFavorites: ManiaPreferences.getShowOnlyFavoritesAgenda(),
                subscribedTeams : Subscriber.getSubscribedTeams(),
                subscribedLeagues : Subscriber.getSubscribedLeagues(),
                subscribedMatches : Subscriber.getSubscribedMatches(),
                fontSize: FootballManiaConfig.size,
                listType : ManiaPreferences.getMatchListType()
            }

            if (!FootballManiaConfig.useWebWorkers) {

                var list = Processors.prepareTodayFeedData(options)
                var data = {data:[listRenderTarget.getId(), list.listData, list.foundUnfiltered]};
                this.onWorkerMessageReceived(data);
            }
            else{
                //some round robin logic to get one of the X workers.
                if (this.usedWorker == undefined) {
                    this.usedWorker = 0;
                } else if (this.usedWorker >= this.workers.length - 1) {
                    this.usedWorker = 0;
                } else {
                    this.usedWorker += 1;
                }

                AppInstance.getWorker(this.usedWorker).postMessage(options);
            }

            if (allowAutoRefresh && this.findListRenderById(listRenderTarget.getId())) {
                this.autoRefresh(listRenderTarget);
            }

        },ignoreCache)
    }

    autoRefresh (listRender,overrideInterval){
        if (this.unloaded){
            return console.warn("no auto refresh because page is unloaded");
        };
        if (!listRender || !listRender.date){
            console.error("no listrender or date",listRender);
            return;
        }
        clearTimeout(this.autoRefreshTimeoutHandlers[listRender.date]);
        this.autoRefreshTimeoutHandlers[listRender.date] = setTimeout(()=>{

            if (document.hidden){
                console.warn("no auto refresh because page is hidden");
                return;
            }
            console.log("auto refresh now",listRender.date);
            this.loadAgendaDay(listRender.date,listRender,false,true,true,true,true)

        },overrideInterval != null ? overrideInterval : AppInstance.getConfig().refreshIntervals.agenda)
    }

    stopAutoRefreshes (){
        for (var i=0; i <this.autoRefreshTimeoutHandlers.length;i++){
            clearTimeout(this.autoRefreshTimeoutHandlers[this.autoRefreshTimeoutHandlers[i]]);
        }
        this.autoRefreshTimeoutHandlers = [];
    }

    startAutoRefreshes (listRender){
        this.stopAutoRefreshes();
        //console.log("start auto refreshes");
        for (var i = 0; i < this.tabview.tabContents.controls.length; i++) {
            try {
                //ugly way of finding active listrender
                var listRender = this.tabview.tabContents.controls[i].controls[1].controls[0].controls[0].controls[0];

                //console.log("loop listrender",this.allowableAutoRefreshDates,listRender.date);
                var allowRefresh = this.allowableAutoRefreshDates.indexOf(listRender.date) != -1 ? true : false;
                if (allowRefresh) {
                   // console.log("allow auto refresh");
                    this.autoRefresh(listRender)
                }
                //console.log(listRender,listRender.date);

            }
            catch (e) {
                console.error("error reloading listrender maybe out of bounds ",e);
            }
        }
    }

    /**
     * call this when you want to set datasource in listrender again
     * for example after you enabled filter. worker is using filter data
     */
    reApplyDataSourceInListRenders (){
        console.log("reapply datasource in listrender")
        for (var i = 0; i < this.tabview.tabContents.controls.length; i++) {
            try {

                if (this.usedWorker == undefined){
                    this.usedWorker = 0;
                }
                else if (this.usedWorker >= this.workers.length-1){
                    this.usedWorker = 0;
                }
                else{
                    this.usedWorker+=1;
                }

                //ugly way of finding active listrender
                var listRender = this.tabview.tabContents.controls[i].controls[1].controls[0].controls[0].controls[0];
                listRender.scrollTo(0);

                if (!listRender.loadedDatasource){
                    console.error("no datasource to reapply");
                    continue;
                }

                console.log(listRender.loadedDatasource);

                var options = {
                    methodType:7,
                    responseData:listRender.loadedDatasource,
                    fixedRegions : AppInstance.getConfig().fixedRegions,
                    fixedLeagueOrder : AppInstance.getConfig().fixedLeaguesOrder,
                    favoriteLeagues : Favorites.getFavoriteLeagues(),
                    favoriteTeams : Favorites.getFavoriteTeams(),
                    translations : Localizer.getLoadedLocale(),
                    defaultTranslations : Localizer.DefaultTranslations,
                    locale : AppInstance.getLocale(),
                    userRegionLocale : FootballManiaConfig.userRegionLocale,
                    userCountry: FootballManiaConfig.detectedUserCountry,
                    hiddenTitles : FootballManiaConfig.hideCountryTitleFor,
                    listRenderId : listRender.getId(),
                    showOnlyFavorites : ManiaPreferences.getShowOnlyFavoritesAgenda(),
                    subscribedTeams : Subscriber.getSubscribedTeams(),
                    subscribedLeagues : Subscriber.getSubscribedLeagues(),
                    subscribedMatches : Subscriber.getSubscribedMatches(),
                    fontSize : FootballManiaConfig.size,
                    listType : ManiaPreferences.getMatchListType()
                }

                AppInstance.getWorker(this.usedWorker).postMessage(options);
            }
            catch (e) {
                console.error("error reapplying datasource in listrender ",e);
            }
        }
    }
};