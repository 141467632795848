import {Panel} from "../bananaframework/src/controls/Panel.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {BasePage} from "./basepage.js";

import {Router} from "../util/router.js";
import {Localizer} from "../util/localizer.js";
import {Processors} from "../util/processors.js";

import {ErrorMessage, NoDataMessage2, NoDataMessage} from "../components/errormessage.js";

import {AnimatorLeague} from "./animators/animatorleague.js";
import {DataProvider} from "../data/dataprovider.js";
import {StateManager} from "../data/stateManager.js";

import {TabView, TabViewTab} from "../components/tabview.js";
import {LazyTileGridRenderer} from "../components/lazytilegridrenderer.js";

import {PlayerMarketValueItemRender} from "../components/player/listrendersplayermarketvalues.js";

import {Playermarktvaluesfiltermodal} from "../components/player/playermarktvaluesfiltermodal.js";

export class PlayerValuesRanking extends BasePage {

    init() {
        this.pageName = "PlayerMarketValue";
        this.version = 1;

        super.init();

        this.setStyle("height:100%");

        this.pageName = "PlayerMarketValues";

        this.addCssClass("PlayerMarketValuePage");

        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();

        if (this.isNavigatedFromOutside) {
            StateManager.flushPageState(this.pageName);
        }

        console.log("OPENING PAGE " + this.pageName + " VERSION " + this.version + " is from outside " + this.isNavigatedFromOutside)

        this.coordinatoranimator = new AnimatorLeague();

        Router.disallowBackButtonQuitApp();

        this.createPageLayout();

        if (AppInstance.workersInitialized) {
            this.wrapper1 = this.onWorkerMessageReceived.bind(this);
            AppInstance.workerHelper1.addEventListener("message", this.wrapper1);
        }
    }

    onWorkerMessageReceived(e) {
        if (this.unloaded) {
            return console.warn("page is unloaded abort further actions");
        }
        ;
        this.tabview.tabs[0].hideLoader();
        this.topValueListrender.setDataSource(e.data);
        this.autoInterval();
    }

    storeState() {

        var d = {
            "tabIndex": this.tabview.currentActiveTab,
            "topValueScrollPosition": this.topValueListrender.getScroll(),
            "headerCollapsed": this.coordinatoranimator.isCollapsed
        }

        if (this.filterModal) {
            d.filterModalOpen = 1;
        }

        StateManager.savePageState(this.pageName, d);
    }

    unload() {
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();
        clearTimeout(this.topValueLoadHandler);
        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.removeEventListener("message", this.wrapper1);
        }
    }

    onVisibilityChange() {
        //page is becoming visible after hide
        if (this.previousDocumentHidden = true && !document.hidden) {
            this.autoInterval(0);
        }
        this.previousDocumentHidden = document.hidden;
        //console.log("visibility change ",document.hidden,this);
    }

    createPageLayout() {
        var row2 = new Panel();
        this.addControl(row2);
        this.contentContainer = row2;
    }

    createComponents() {
        this.getPageTemplate().showTitle();
    }


    onWindowResize() {
        super.onWindowResize();
        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }

        if (this.topValueListrender) {
            this.topValueListrender.onWindowResize();
        }
    }

    updateDisplay() {

        this.runApp();
    }

    runApp() {

        console.log("run app")

        this.showLoader();

        if (!AppInstance.setupAlreadyDone) {
            this.showLoader();
        }

        AppInstance.setup(this.getProxy(function (result) {

            if (this.unloaded) {
                return console.warn("page is unloaded abort further actions");
            }
            ;

            if (!result) {
                this.hideLoader();
                console.error("error app setup");
                return this.showError("", this.getProxy(function () {
                    this.runApp();
                }))
            }

            DataProvider.getPlayerMarketValueRanking(this.getProxy(function (response) {

                this.hideLoader();

                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }

                if (!response.isOk()) {

                    if (response.getStatus() == 404) {
                        return this.showPageDataDoesNotExist("", this.getProxy(function () {
                            this.runApp();
                        }))
                    } else {
                        return this.showError("", this.getProxy(function () {
                            this.runApp();
                        }))
                    }
                }

                var data = response.getData();

                console.log("data", data);

                this.createTitle(data);
                this.createTabs(data);

                this.getPageTemplate().createMenuPlayerMarketValues(response.getData());

                this.tabview.bind("tabChanged", this.getProxy(function () {
                    this.storeState();
                    this.coordinatoranimator.tabChange();
                }));

                var storeHandler = null;
                var storeHandler2 = null;
                var storeHandler3 = null;
                this.topValueListrender.bind("scroll", this.getProxy(function (e, scroll) {
                    clearTimeout(storeHandler);
                    storeHandler = setTimeout(this.getProxy(function () {
                        this.storeState();
                    }), 200)
                }));

                this.bind("onModalOpen", (e, modal) => {
                    console.log(modal.urlIdentifier);
                    this.filterModal = null;
                    if (modal.urlIdentifier == "countryfilter") {
                        this.filterModal = modal;
                        this.getPageTemplate().hideStickBannerAd();
                        this.preventStickBannerAdOnce = true;
                        this.storeState();
                    }
                    ;
                });
                this.bind("onModalClose", () => {
                    this.filterModal = null;
                    this.getPageTemplate().loadStickyBannerAd();
                    this.storeState();
                });
                this.bind("countrySelected", (e, selected) => {
                    console.log("countries filter selected", selected, this.lastLoadedData);
                    this.selectedFilterData = selected;
                    if (this.lastLoadedData) {
                        this.processMarketValueData(this.lastLoadedData);
                    }
                    //this.filterDataSource(selected);
                });

                var previousState = StateManager.getCurrentPageState(this.pageName);
                console.log("previousState", previousState);
                if (previousState) {
                    this.tabview.currentActiveTab = previousState.tabIndex;
                    this.topValueListrender.scrollTo(previousState.topValueScrollPosition || 0);

                    this.coordinatoranimator.isCollapsed = previousState.headerCollapsed;
                    console.log("previousState.filterModalOpen", previousState.filterModalOpen);
                    if (previousState.filterModalOpen) {
                        setTimeout(() => {
                            var urlIdentifier = "countryfilter";
                            var modal = new Playermarktvaluesfiltermodal(urlIdentifier);
                            this.addControl(modal, true);
                        });
                    }
                }

                this.contentContainer.invalidateDisplay();
                this.coordinatoranimator.prepare();

                this.lastLoadedData = data;
                this.processMarketValueData(data);

            }), false);
        }));

        this.bind("filterClicked", () => {
            var urlIdentifier = "countryfilter";
            var modal = new Playermarktvaluesfiltermodal(urlIdentifier);
            this.addControl(modal, true);
            this.getPageTemplate().hideStickBannerAd();
        })
    }

    createTitle(data) {
        this.getPageTemplate().topTitleContainer.setVisible(false);
    }

    createTabs(data) {
        var tabview = new TabView();
        this.contentContainer.addControl(tabview);

        var tabs = [];

        var tab1;
        tab1 = new TabViewTab();
        tab1.setStyle("width:100%;height:100%;position:relative;overflow-y: scroll;transform: translate(0,0);");
        tab1.title = Localizer.getString("player_market_values");
        tabs.push(tab1);

        this.createTopValueControls(tab1, 0);
        this.coordinatoranimator.addScrollerContainer(tab1);


        this.coordinatoranimator.addCollapser(this.getPageTemplate().topBar);
        this.coordinatoranimator.addTabView(tabview);

        tabview.setTabs(tabs);

        tabview.bind("onReady", this.getProxy(function () {
            this.coordinatoranimator.run();
        }));

        this.tabview = tabview;

        tab1.showLoader();

        this.bind("stickyBannerLoadFail", () => {
            console.error("sticky banner load fail -> set height to 100%");
            if (tab1) tab1.setCss({'height': '100%'});
        });
        //triggered from page template
        this.bind("stickyBannerLoadSuccess", () => {
            if (this.preventStickBannerAdOnce) {
                this.preventStickBannerAdOnce = false;
                this.hideStickBannerAd();
                return;
            }
            console.log("sticky banner load success -> set height to 100% - 50px");
            if (tab1) tab1.setCss({'height': 'calc(100% - 50px)'});
        });
        this.bind("stickyBannerHide", () => {
            console.error("sticky banner hide -> set height to 100%");
            if (tab1) tab1.setCss({'height': '100%'});
        });
        this.getPageTemplate().loadStickyBannerAd();
    }

    createTopValueControls(tab, index) {
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new LazyTileGridRenderer();
        listRender.containerTarget = tab;
        listRender.enableFastScroll = true;
        listRender.setItemRender(function (data) {
            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new PlayerMarketValueItemRender(),
                };
            }
        })

        listRender.emptyTemplate = function (cb) {
            return new NoDataMessage({
                cb: function () {
                    history.back();
                },
                message: Localizer.getString("no_data")
            });
        }

        listRender.errorTemplate = function (cb) {
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function () {
            return new ErrorMessage();
        }

        listRender.bind('createdList', this.getProxy(function () {
            this.coordinatoranimator.recalculateTab(index);
        }))

        listRender.setPlaceHolderWidth("100%;");

        var previousState = StateManager.getCurrentPageState(this.pageName
        );
        if (previousState) {
            listRender.scrollTo(previousState.fixturesScrollPosition || 0);
        }

        container.addControl(listRender);

        this.topValueListrender = listRender;

        tab.addControl(container);
    }

    processMarketValueData(data, ignoreCache) {

        var selectedFilterData = null;
        var countries = UrlManager.getModule("filter_countries");
        if (countries) {
            countries = atob(countries);
            countries = countries.split(",");
            selectedFilterData = countries;
        }


        if (!data) {
            return this.autoInterval();
        }

        if (this.unloaded) {
            return console.warn("page is unloaded abort further actions");
        }
        ;

        console.log("aaa")
        if (!FootballManiaConfig.useWebWorkers) {
            var list = Processors.preparePlayerMarketValuesData(data, selectedFilterData);
            var data = {data: list};
            this.onWorkerMessageReceived(data);
        } else {
            AppInstance.workerHelper1.postMessage({
                methodType: 11,
                filterData: selectedFilterData,
                data: data,
            });
        }
        Router.disallowBackButtonQuitApp();
    }

    autoInterval(overrideInterval) {

        clearTimeout(this.topValueLoadHandler);
        this.topValueLoadHandler = setTimeout(this.getProxy(function () {

            if (document.hidden) {
                console.warn("no auto refresh because page is hidden");
                return;
            }

            DataProvider.getPlayerMarketValueRanking(this.getProxy(function (response) {

                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }
                ;

                if (!response.isOk()) {
                    this.autoInterval();
                    return;
                }
                //todo error screen

                this.processMarketValueData(response.getData());
            }));
        }), overrideInterval != null ? overrideInterval : AppInstance.getConfig().refreshIntervals.ranking)
    }
};