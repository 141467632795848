import {Panel} from "../../bananaframework/src/controls/Panel.js"
import {ImagePlaceHolder, teamPlaceholder} from "../imageplaceholder.js"
import {
    DataGridTileItemRender
} from "../../bananaframework/src/controls/listcontrols/datagrids/listrenders/DataGridTileItemRender.js"
import {Uservotesmodal} from "./uservotesmodal.js";
import {Router} from "../../util/router.js";
import {MatchHeaderDateRender} from "../home/listrendershome.js";

export class TopVoterItemRender extends DataGridTileItemRender {

    init(data) {
        super.init();
        this.data = data;
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");
        this.panel.setStyle("margin-bottom:40px;");
        //
        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);
        this.panel.setStyle("margin-top:3px;margin-bottom:-3px;");
        this.card.setStyle("padding-bottom:4px;height:48px;cursor:pointer;");
        //
        this.content = new Panel();
        this.content.addCssClass("flexHorizontalAlign");
        this.content.setStyle("align-items: flex-start;");

        this.card.addControl(this.content);


        this.playerImage = new ImagePlaceHolder();
        //this.playerImage.placeholderData = teamPlaceholder;
        this.playerImage.image.setStyle("border-radius: 20px;overflow: hidden;");
        this.playerImage.setStyle("width:40px; height:40px;align-self: center;")
        this.content.addControl(this.playerImage);

        this.rank = new Panel();
        this.rank.setStyle("align-self: center;padding-inline-start:4px;padding-inline-end:4px;");
        this.content.addControl(this.rank);

        this.playerName = new Panel();
        this.playerName.setStyle("align-self: center;margin-inline-start: 8px;line-height: 21px;");
        this.content.addControl(this.playerName);


        var margin = "margin-left:auto;";
        if (FootballManiaConfig.isRtl) {
            margin = "margin-right:auto;";
        }

        this.rightContainer = new Panel();
        this.rightContainer.setStyle(margin + "align-self: center;display:flex;flex-direction: column;justify-content: space-around;height:100%;")
        this.content.addControl(this.rightContainer);


        this.score = new Panel();
        this.score.setStyle("text-align:end;font-size:12px;");

        this.playerScoreRatio = new Panel();
        this.playerScoreRatio.setStyle("text-align:end;font-size:13px;font-weight:bold;")

        this.rightContainer.addControl(this.playerScoreRatio);
        this.rightContainer.addControl(this.score);

        this.addControl(this.panel);
        this.valueFormatter = Intl.NumberFormat('en-EN', {style: 'currency', currency: 'EUR'})

        this.content.bind("click", () => {
            var urlIdentifier = "userVotesModal_" + this.data.id;
            var pan = new Uservotesmodal(urlIdentifier);
            this.modal = pan;
            this.getPage().addControl(pan, true);
        });
    }

    setData(data) {

        this.data = data;
        this.rank.clear();
        this.playerName.clear();
        this.playerScoreRatio.clear();
        this.score.clear();

        var ratio = "";
        if (data.hits && data.misses) {
            ratio = data.hits / data.misses;
            ratio = Math.round(ratio * 100) / 100;

            if (ratio >= 1.8) {
                this.playerScoreRatio.setCss({color: "#aa02a2"});
            } else if (ratio > 1.5) {
                this.playerScoreRatio.setCss({color: "#00ff00"});
            } else {
                this.playerScoreRatio.setCss({color: ""});
            }
        } else {
            this.playerScoreRatio.setCss({color: ""});
        }


        this.rank.addControl(data.rank + "", true);
        this.playerImage.setImage(data.social_profile_image, 40, 40);
        // this.playerCountryImage.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/flags/"+data.usercountry.replace(/\s|\'/g, '-').toLowerCase()+".png");
        this.playerName.addControl((data.social_username || "Anonymous") + "<br> <i> " + data.points + "</i>", true);
        this.playerScoreRatio.addControl(ratio + "", true);
        this.score.addControl(data.hits + " / " + data.misses, true);
    }
};

export class TopVoterUserVoteItemRender extends DataGridTileItemRender {

    init(data) {
        super.init();
        this.data = data;
        this.panel = new Panel();
        this.panel.setStyle("padding-left:10px;padding-right:5px;border-radius:2px;background-color: var(--listHeaderBackground);width: 100%;display:flex;height:48px;justify-content: space-between;flex-direction: row;");

        this.left = new Panel();
        this.left.setStyle("display:flex;flex-direction: column;justify-content: space-evenly;");

        this.right = new Panel();
        this.right.setStyle("display:flex;flex-direction: column;justify-content: space-evenly;");
        this.rightTopContainer = new Panel();
        this.rightTopContainer.setStyle("display:flex;flex-direction: row;justify-content: space-between;")
        this.rightBottomContainer = new Panel();
        this.rightBottomContainer.setStyle("display:flex;flex-direction: row;justify-content: space-between;")
        this.right.addControl(this.rightTopContainer);
        this.right.addControl(this.rightBottomContainer);

        this.homeTeam = new Panel();
        this.homeTeam.setStyle("text-align:start;");

        this.awayTeam = new Panel();
        this.awayTeam.setStyle("text-align:start;");

        this.homePoints = new Panel();
        this.homePoints.setStyle("text-align:start;width:20px;");

        this.awayPoints = new Panel();
        this.awayPoints.setStyle("text-align:start;width:20px;");

        this.homeChoice = new Panel();
        this.homeChoice.setStyle("text-align:end;");

        this.awayChoice = new Panel();
        this.awayChoice.setStyle("text-align:end;");

        this.drawChoice = new Panel();
        this.drawChoice.setStyle("text-align:end;");

        this.left.addControl(this.homeTeam);
        this.left.addControl(this.awayTeam);

        this.rightTopContainer.addControl(this.homePoints);
        this.rightTopContainer.addControl(this.homeChoice);
        this.rightBottomContainer.addControl(this.awayPoints);
        this.rightBottomContainer.addControl(this.awayChoice);
        this.rightBottomContainer.addControl(this.drawChoice);

        this.panel.addControl(this.left);
        this.panel.addControl(this.right);
        this.addControl(this.panel);

        this.panel.bind("click", () => {
            Router.showMatchScreen(null, this.data);
        });

    }

    setData(data) {
        this.data = data;

        this.homeTeam.clear();
        this.homeTeam.addControl(data.localteam);

        this.awayTeam.clear();
        this.awayTeam.addControl(data.visitorteam);

        this.homePoints.clear();
        this.homePoints.addControl("1");

        this.awayPoints.clear();
        this.awayPoints.addControl("2");

        this.homeChoice.clear();
        this.awayChoice.clear();
        this.drawChoice.clear();

        if (this.data.votedfor == "0") {
            this.homeChoice.addControl("X");
        } else if (this.data.votedfor == "1") {
            this.awayChoice.addControl("X");
        } else {
            this.drawChoice.addControl("<p style='margin-top:-10px;'>X</p>");
        }

        if (this.data.isPast) {
            if (this.data.localscore > this.data.visitorscore && this.data.votedfor == "0") {
                this.panel.setCss({"background-color": "green"});
            } else if (this.data.localscore < this.data.visitorscore && this.data.votedfor == "1") {
                this.panel.setCss({"background-color": "green"});
            } else if (this.data.localscore == this.data.visitorscore && this.data.votedfor == "2") {
                this.panel.setCss({"background-color": "green"});
            } else {
                this.panel.setCss({"background-color": "#df0707"});
            }
        } else {
            this.panel.setCss({"background-color": "var(--listHeaderBackground)"});
        }

        this.panel.invalidateDisplay();
    }
};

export class TopVoterUserVoteHeaderItemRender extends MatchHeaderDateRender {

    init(data) {
        super.init(data);
        this.panel.setStyle("background:none;border:none;")
    }

    setData(data, preventRender, preventInstantRender) {
        //this._super(data);

        this.panel.clear();
        var d = Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }).format(data.dayTimecodeLocalized);
        //this.panel.addControl(MatchUtil.getLocalizedDate(data,true),true);
        this.panel.addControl("<div style='width: 100%;'>" + d + "</div>");
    }

};