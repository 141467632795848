import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {Favorites} from "../../util/favorites.js";

export const DragHelper = function(){

    this.activateForItem = (item)=> {

        item.panel.addCssClass("dragHandlerContainer");
        var handle = new Panel();
        handle.addCssClass("dragHandlerHandleContainer");
        handle.setStyle("height:40px;width:100px;");

        var icon = new Panel();
        icon.setStyle("height:25px;width:25px;");
        icon.addCssClass("icon-draghandler");
        handle.addControl(icon);

        item.isLiveInfo.clear();
        item.isLiveInfo.addControl(handle,true);
        var dropTargets = [];

        item.tempdh_touchStartMethod = (e)=>{

            this.wasDragging = false;
            clearTimeout(item.longPressHandler);
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();

            var targets = this.listrender.indexRenderedItemRenderMap[this.dragItem.data.type];
            dropTargets = [];
            for (var i=0; i <targets.length; i++){
                if (targets[i].data == item.data) continue;
                dropTargets.push(targets[i].panel);
            }

            if (this.clonedDragged){
                console.log("remove",this.clonedDragged);
                this.clonedDragged.remove();
            }
            else{
                console.log("not remove");
            }

            var dimensions = this.listrender.getDimensions();

            item.clonedDragged = $( "#"+item.panel.getClientId()).clone();
            var y = e.originalEvent.touches[0].pageY;
            var x = e.originalEvent.touches[0].pageX;
            var startY = y;
            var startX = y;
            var windowHeight = $(window).height();
            var pos = y-dimensions.offset.top-25;
            item.clonedDragged.css({"display":"none","animation":"none","position":"absolute","z-index":1000,"transform": "translateY("+(pos)+"px)"});
            $("#"+item.parent.parent.getClientId()).append(item.clonedDragged);

            var startScroll = this.listrender.getScroll();
            var currentOffset = 0;
            var dragItemVisible = false;
            var foundDropTarget = null;

            item.tempdh_touchMoveMethod = (e)=>{


                var y = e.originalEvent.touches[0].pageY;

                if (!dragItemVisible && Math.abs(startY-y) > 8){
                    dragItemVisible = true;
                    item.clonedDragged.css({"display":"flex"});
                    this.wasDragging = true;
                }
                // else if (!dragItemVisible && Math.abs(startX-x) > 8){
                //     console.log("dragging in x direction");
                //
                //
                //     return;
                // }

                this.listrender.triggerEvent("onDrag");

                var pos = y-dimensions.offset.top;
                const elements = document.elementsFromPoint(0, y);

                //droptargets are all possible items on which we can drop the dragged item
                //the elements are all elements at the current cursor position
                var stop = false;
                foundDropTarget = null;
                for (var j=0; j < elements.length; j++){

                    if (stop) break;

                    if (elements[j].id == this.listrender.renderListContainers[0].getClientId()){
                        continue;
                    }
                    for (var i=0; i < dropTargets.length; i++){
                        if (elements[j].id == dropTargets[i].getClientId()){
                            foundDropTarget = dropTargets[i];
                            break;
                        }
                    }
                }

                for (var i=0 ; i < dropTargets.length; i++){
                    if (dropTargets[i] != foundDropTarget){
                        dropTargets[i].removeCssClass("dropTarget");
                    }
                    else{
                        foundDropTarget.addCssClass("dropTarget");
                    }
                }

                item.clonedDragged.css({ "transform": "translateY("+(pos-currentOffset-25)+"px)"});

                if (y < 50){

                    if (!this.scrollerRunning){
                        this.scrollerRunning = setInterval(()=>{
                            if (this.listrender.getScroll() <= 0){
                                return;
                            }
                            currentOffset+=20;
                            var scrollTarget = startScroll-currentOffset;
                           // currentOffset = Math.min(50,currentOffset);
                            //console.log("auto scrolling",currentOffset);
                            //currentScroll-=currentOffset;
                            this.listrender.scrollToSmooth(scrollTarget,40);
                        },40);
                    }

                }
                else if (windowHeight - y < 50){
                    if (!this.scrollerRunning){
                        this.scrollerRunning = setInterval(()=>{
                            currentOffset-=20;
                            var scrollTarget = startScroll-currentOffset;
                            // currentOffset = Math.min(50,currentOffset);
                            //console.log("auto scrolling",currentOffset);
                            //currentScroll-=currentOffset;
                            this.listrender.scrollToSmooth(scrollTarget,40);
                        },40);
                    }
                }
                else{
                    if (this.scrollerRunning){
                        clearInterval(this.scrollerRunning);
                        this.scrollerRunning = null;
                    }
                }
            };

            item.tempdh_touchEndMethod = (e)=>{

                clearInterval(this.scrollerRunning);
                item.panel.unbind("touchmove",item.tempdh_touchMoveMethod);
                item.panel.unbind("touchend",item.tempdh_touchEndMethod);

                if (foundDropTarget){
                    console.log("swap item ",item," with",foundDropTarget.parent.data);
                    this.listrender.triggerEvent("requestSwapItems",[item.data,foundDropTarget.parent.data]);

                    var tempData = foundDropTarget.parent.data;
                    foundDropTarget.parent.setData(item.data,false,true);
                    item.setData(tempData,false,true);
                    this.deactivateForItem(item);
                    this.deactivateForItem(foundDropTarget.parent);
                    this.activateForItem(item);
                    this.activateForItem(foundDropTarget.parent);

                    if (item.data.type == 5){
                        console.log("swap league item",item,"foundDropTarget",foundDropTarget.parent);
                        Favorites.swapFavoriteLeagues(item.data.key,foundDropTarget.parent.data.key);
                    }
                    else if (item.data.type == 4){
                        console.log("swap team item",item,"foundDropTarget",foundDropTarget.parent);
                        Favorites.swapFavoriteTeams(item.data.id,foundDropTarget.parent.data.id);
                    }
                    else{
                        console.error("swapping this item is not supported");
                    }
                    //
                }

                for (var i=0; i < dropTargets.length; i++){
                    dropTargets[i].removeCssClass("dropTarget");
                }

                if (item.clonedDragged) {
                    item.clonedDragged.remove();
                }
                this.clonedDragged = null;

                if (!this.wasDragging){
                    console.log("was not dragging. trigger event")
                    this.listrender.triggerEvent("requestDeActivateDrag");
                    return;
                }
            }

            item.panel.bind("touchmove",item.tempdh_touchMoveMethod);
            item.panel.bind("touchend",item.tempdh_touchEndMethod);
        };

        item.panel.bind("touchstart",item.tempdh_touchStartMethod);
    }

    this.deactivateForItem = (item)=> {
        item.panel.unbind("touchstart",item.tempdh_touchStartMethod);

        item.panel.removeCssClass("dragHandlerContainer");
        item.isLiveInfo.clear();
    }

    this.setDragItem = (item)=> {
        console.log("set drag item",item);
        this.dragItem = item;
    }

    this.setListrender = (listrender)=> {
        console.log("set listrender",listrender);
        this.listrender = listrender;
    }

    this.activate = ()=> {
        console.log("activate");
        this.listrender.fastScrollContainerHandler.setVisible(false);

        var targets = this.listrender.indexRenderedItemRenderMap[this.dragItem.data.type];

        for (var i=0; i <targets.length; i++){
            this.activateForItem(targets[i]);
        }

        $('body').off({
            'touchstart': this.touchBodyStartMethod});

        //here we deactivate when the user touches outside the list
        this.touchBodyStartMethod = (e)=>{
            var y = e.originalEvent.touches[0].pageY;
            const elements = document.elementsFromPoint(0, y);
            var found = false;
            for (var j=0; j < elements.length; j++){
                for (var i=0; i < targets.length; i++){
                    if (elements[j].id == targets[i].panel.getClientId()){
                        found = true;
                        break;
                    }
                }
            }
            if (!found){
                this.listrender.triggerEvent("requestDeActivateDrag");
                return;
            }
        }

        $('body').on({
            'touchstart': this.touchBodyStartMethod});
    }

    this.deactivate = ()=> {

        this.listrender.fastScrollContainerHandler.setVisible(true);
        var targets = this.listrender.indexRenderedItemRenderMap[this.dragItem.data.type];

        for (var i=0; i <targets.length; i++){
            this.deactivateForItem(targets[i]);
        }

        $('body').off({
            'touchstart': this.touchBodyStartMethod});
    }
}