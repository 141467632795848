import {Panel} from "../bananaframework/src/controls/Panel.js";
import {UrlManager} from "../bananaframework/src/util/UrlManager.js";

import {BasePage} from "./basepage.js";

import {Router} from "../util/router.js";
import {Localizer} from "../util/localizer.js";
import {Processors} from "../util/processors.js";
import {ThemeManager} from "../util/theme.js";

import {ErrorMessage, NoDataMessage2, NoDataMessage} from "../components/errormessage.js";

import {AnimatorTeam} from "./animators/animatorteam.js";
import {TeamHeader} from "../components/team/teamheader.js";
import {DataProvider} from "../data/dataprovider.js";
import {StateManager} from "../data/stateManager.js";

import {TabView, TabViewTab} from "../components/tabview.js";
import {SimpleTileGridRenderer} from "../components/simpletilegridrenderer.js";

import {
    FutureTeamFixturesRender,
    PastTeamFixturesRender,
    LastPlayedTeamFixturesRender,
    NowPlayingTeamFixturesRender,
    TodayPlayingTeamFixturesRender,
    NextPlayingTeamFixturesRender,
    TeamLeagueStandingsItemRender,
    TeamGeneralInfoItemRender,
    SquadListrender,
    SquadHeaderItemRender,
    SquadCoachItemRender,
    TransfersListrender,
    TransferHeaderItemRender,
    TrophiesListrender,
    TrophyHeaderItemRender
}
    from "../components/team/listrendersteam.js";
import {ManiaPreferences} from "../util/preferences.js";


export class Team extends BasePage {

    init() {
        this.pageName = "Team";
        this.version = 1;

        super.init();
        this.setStyle("height:100%");

        //this is a bit hacky.
        //the id change event is fired even when we set the id of this page before
        //this logic. The reason is because the onhashchange event is async
        //and altough we change the url before this logic, the onhashchange event fires
        //afte this logic
        this.bind("renderComplete", this.getProxy(function () {
            //we do this to register back button scenario where page A is match and page B is also match
            setTimeout(function () {
                UrlManager.listenModule('tid', function () {
                    var id = UrlManager.getModule('tid');
                    if (id == undefined || UrlManager.getModule('section') != "Team") {
                        return console.warn("ignore opening league , probably navigating to other screen");
                    }
                    AppInstance.loadPage("Team", {"tid": id}, false);
                });
            }, 100);
        }))

        this.pageName = "Team_" + UrlManager.getModule("tid");

        this.addCssClass("LeaguePage");

        this.isNavigatedFromOutside = Router.navigationIsFromOutside();
        Router.updateCurrentReferalData();

        if (this.isNavigatedFromOutside) {
            StateManager.flushPageState(this.pageName);
        }

        console.log("OPENING PAGE " + this.pageName + " VERSION " + this.version + " is from outside " + this.isNavigatedFromOutside)

        this.coordinatoranimator = new AnimatorTeam();
        this.coordinatoranimator.reducerRatio = 1;
        this.coordinatoranimator.transitionDuration = 20;

        Router.disallowBackButtonQuitApp();
        this.createPageLayout();

        this.wrapper1 = this.onWorkerMessageReceived.bind(this);

        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.addEventListener("message", this.wrapper1);
        }
    }

    onWorkerMessageReceived(e) {
        if (this.unloaded) {
            return console.warn("page is unloaded abort further actions");
        }
        ;

        var listData = [];

        if (e.data[1].leagues.length) { //standings
            var item = {"data": e.data[1], "type": 7};
            listData.push(item);
        }
        if (e.data[1].nowPlaying) {
            var item = {"data": e.data[1], "type": 4};
            listData.push(item);
        } else if (e.data[1].todayPlaying) {
            var item = {"data": e.data[1], "type": 5};
            listData.push(item);
        } else if (e.data[1].nextMatch) {
            var item = {"data": e.data[1], "type": 6};
            listData.push(item);
        } else if (e.data[1].lastPlayedMatch) {
            var item = {"data": e.data[1], "type": 3};
            listData.push(item);
        }
        if (e.data[1].futureMatches && e.data[1].futureMatches.length) {
            var item = {"data": e.data[1], "type": 1};
            listData.push(item);
        }
        if (e.data[1].pastMatches && e.data[1].pastMatches.length) {
            var item = {"data": e.data[1], "type": 2};
            listData.push(item);
        }
        var item = {"data": e.data[1], "type": 8};
        listData.push(item);

        var tabIndex = 0;
        var timeout = 0;
        if (this.tabview.currentActiveTab != tabIndex) {
            timeout = 400;
        }

        setTimeout(this.getProxy(function () {
            this.teamListrender.containerTarget.hideLoader();
            this.teamListrender.setDataSource(listData);
        }), timeout);

        if (this.squadListrender) {
            tabIndex++;
            timeout = 0;

            if (this.tabview.currentActiveTab != tabIndex) {
                timeout = 500;
            }

            setTimeout(this.getProxy(function () {
                this.squadListrender.containerTarget.hideLoader();
                this.squadListrender.setDataSource(e.data[1].squad);
            }), timeout);
        }
        if (this.transfersListrender) {
            tabIndex++;
            timeout = 0;

            if (this.tabview.currentActiveTab != tabIndex) {
                timeout = 600;
            }

            setTimeout(this.getProxy(function () {
                this.transfersListrender.containerTarget.hideLoader();
                this.transfersListrender.setDataSource(e.data[1].transfers);
            }), timeout);
        }
        if (this.trophiesListrender) {
            tabIndex++;
            timeout = 0;
            if (this.tabview.currentActiveTab != tabIndex) {
                timeout = 700;
            }
            setTimeout(this.getProxy(function () {
                this.trophiesListrender.containerTarget.hideLoader();
                this.trophiesListrender.setDataSource(e.data[1].trophies);
            }), timeout);
        }
        this.autoInterval();
    }

    storeState() {

        var d = {
            "tabIndex": this.tabview.currentActiveTab,
            "teamListrenderScrollPosition": this.teamListrender.getScroll(),
            "headerCollapsed": this.coordinatoranimator.isCollapsed
        }

        if (this.squadListrender) {
            d.squadListrenderScrollPosition = this.squadListrender.getScroll();
        }
        if (this.transfersListrender) {
            d.transfersListrenderScrollPosition = this.transfersListrender.getScroll();
        }
        if (this.trophiesListrender) {
            d.trophiesListrenderScrollPosition = this.trophiesListrender.getScroll();
        }

        StateManager.savePageState(this.pageName, d);
    }

    unload() {
        this.unloaded = true;
        $.xhrPool.abortAll();
        this.coordinatoranimator.unload();
        clearTimeout(this.teamLoadHandler);
        UrlManager.unlistenModule('tid');

        if (AppInstance.workersInitialized) {
            AppInstance.workerHelper1.removeEventListener("message", this.wrapper1);
        }
    }

    onVisibilityChange() {
        //page is becoming visible after hide
        if (this.previousDocumentHidden = true && !document.hidden) {
            this.autoInterval(0);
        }
        this.previousDocumentHidden = document.hidden;
        //console.log("visibility change ",document.hidden,this);
    }

    onOffline() {
        this.getPageTemplate().createMenuTeam(false,false);
    }
    onOnline() {
        this.autoInterval(0);
        Router.showBanner();
    }

    createPageLayout() {
        this.teamHeader = new TeamHeader();
        this.teamHeader.setVisible(false);
        this.coordinatoranimator.addCollapser(this.teamHeader);

        var row2 = new Panel();
        this.addControl(this.teamHeader);
        this.addControl(row2);
        this.contentContainer = row2;
    }

    createComponents() {
        this.getPageTemplate().hideTitle();
    }

    onWindowResize() {
        super.onWindowResize();
        if (this.tabview) {
            this.tabview.onWindowResize();
        }
        if (this.coordinatoranimator) {
            this.coordinatoranimator.onWindowResize();
        }

        if (this.teamListrender) {
            this.teamListrender.onWindowResize();
        }

        if (this.squadListrender) {
            this.squadListrender.onWindowResize();
        }

        if (this.transfersListrender) {
            this.transfersListrender.onWindowResize();
        }

        if (this.trophiesListrender) {
            this.trophiesListrender.onWindowResize();
        }
    }

    updateDisplay() {

        var previousState = StateManager.getCurrentPageState(this.pageName);

        if (previousState) {
            this.coordinatoranimator.prerun(previousState.headerCollapsed);
        } else {
            this.coordinatoranimator.prerun(false);
        }
        this.runApp();
    }

    runApp() {
        if (!AppInstance.setupAlreadyDone) {
            this.showLoader();
        }
        AppInstance.setup(this.getProxy(function (result) {

            if (this.unloaded) {
                return console.warn("page is unloaded abort further actions");
            }
            ;

            if (!result) {
                this.hideLoader();
                console.error("error app setup");
                return this.showError("", this.getProxy(function () {
                    this.runApp();
                }))
            }
            this.showLoader();
            DataProvider.getTeam(UrlManager.getModule("tid"), this.getProxy(function (response) {
                this.hideLoader();
                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }

                if (!response.isOk()) {

                    if (response.getStatus() == 404) {
                        return this.showPageDataDoesNotExist("", this.getProxy(function () {
                            this.runApp();
                        }))
                    } else {
                        return this.showError("", this.getProxy(function () {
                            this.runApp();
                        }))
                    }
                }

                var data = response.getData();
                if (!data.teamname) {
                    return this.showPageDataDoesNotExist("", this.getProxy(function () {
                        this.runApp();
                    }))
                }

                this.createTabs(data);

                this.teamHeader.setPresetData(data, true);
                this.teamHeader.setVisible(true);

                this.getPageTemplate().createMenuTeam(response.getData());

                this.tabview.bind("tabChanged", this.getProxy(function () {
                    this.storeState();
                    this.coordinatoranimator.tabChange();
                }));

                var storeHandler = null;
                var storeHandler2 = null;
                var storeHandler3 = null;
                var storeHandler4 = null;
                this.teamListrender.bind("scroll", this.getProxy(function (e, scroll) {
                    clearTimeout(storeHandler);
                    storeHandler = setTimeout(this.getProxy(function () {
                        this.storeState();
                    }), 200)
                }));

                if (this.squadListrender) {
                    this.squadListrender.bind("scroll", this.getProxy(function (e, scroll) {
                        clearTimeout(storeHandler2);
                        storeHandler2 = setTimeout(this.getProxy(function () {
                            this.storeState();
                        }), 200)
                    }));
                }

                if (this.transfersListrender) {
                    this.transfersListrender.bind("scroll", this.getProxy(function (e, scroll) {
                        clearTimeout(storeHandler3);
                        storeHandler3 = setTimeout(this.getProxy(function () {
                            this.storeState();
                        }), 200)
                    }));
                }

                if (this.trophiesListrender) {
                    this.trophiesListrender.bind("scroll", this.getProxy(function (e, scroll) {
                        clearTimeout(storeHandler4);
                        storeHandler4 = setTimeout(this.getProxy(function () {
                            this.storeState();
                        }), 200)
                    }));
                }


                var previousState = StateManager.getCurrentPageState(this.pageName);
                if (previousState) {
                    this.tabview.currentActiveTab = previousState.tabIndex;
                    this.teamListrender.scrollTo(previousState.teamListrenderScrollPosition || 0);

                    if (this.squadListrender) {
                        this.squadListrender.scrollTo(previousState.squadListrenderScrollPosition || 0);
                    }
                    if (this.transfersListrender) {
                        this.transfersListrender.scrollTo(previousState.transfersListrenderScrollPosition || 0);
                    }
                    if (this.trophiesListrender) {
                        this.trophiesListrender.scrollTo(previousState.trophiesListrenderScrollPosition || 0);
                    }
                    this.coordinatoranimator.isCollapsed = previousState.headerCollapsed;
                }

                this.contentContainer.invalidateDisplay();
                this.coordinatoranimator.ensureStartPositions(); //we call this to ensure tabview is collapsed without flicker
                this.coordinatoranimator.run();
                //this.coordinatoranimator.prepare();

                var tabIndex = 0;
                this.loadFixtures(data, tabIndex);

            }), false);
        }))
    }

    createTitle(data) {
        if (!data) return;
        var title = new Panel();

        title.addControl(data.teamname);
        this.getPageTemplate().topTitleContainer.addControl(title);
        this.getPageTemplate().topTitleContainer.invalidateDisplay();
    }

    createTabs(data) {
        var tabview = new TabView();

        if (ThemeManager.getCurrentTheme() != "light") {
            tabview.parallaxBg = this.teamHeader.backgroundImage;
        }
        var p = new Panel();
        p.setStyle("background-color:red;height:20px; width:100%;float:left;");
        this.contentContainer.addControl(tabview);

        var tabs = [];

        var tab1;
        var tab2;
        var tab3;
        var tab4;

        var tabIndexUsed = 0;

        if (FootballManiaConfig.isRtl) {
            if (data.trophies) {
                tab4 = new TabViewTab();
                tab4.addCssClass("listViewScrollableContainer");
                tab4.title = Localizer.getString("trophies");
                tabs.push(tab4);
                this.createTrophiesControls(tab4, tabIndexUsed);
                this.coordinatoranimator.addScrollerContainer(tab4);
                tab4.showLoader();
                tabIndexUsed += 1
            }
            if ((data.isProcessedByProcessor && data.transfers && data.transfers.length)
                || (!data.isProcessedByProcessor && data.transfers && ((data.transfers.in && data.transfers.in.length) || (data.transfers.out && data.transfers.out.length)))
            ) {
                tab3 = new TabViewTab();
                tab3.addCssClass("listViewScrollableContainer");
                tab3.title = Localizer.getString("transfers");
                tabs.push(tab3);
                this.createTransfersControls(tab3, tabIndexUsed);
                this.coordinatoranimator.addScrollerContainer(tab3);
                tab3.showLoader();
                tabIndexUsed += 1;
            }
            if (data.squad) {
                tab2 = new TabViewTab();
                tab2.addCssClass("listViewScrollableContainer");
                tab2.title = Localizer.getString("team");
                tabs.push(tab2);
                this.createSquadControls(tab2, tabIndexUsed);
                tabIndexUsed += 1;
                this.coordinatoranimator.addScrollerContainer(tab2);
                tab2.showLoader();
            }

            tab1 = new TabViewTab();
            tab1.addCssClass("listViewScrollableContainer");
            tab1.title = Localizer.getString("info");
            tabs.push(tab1);
            this.createTeamControls(tab1, tabIndexUsed);
            this.coordinatoranimator.addScrollerContainer(tab1);
            tab1.showLoader();

            tabview.currentActiveTab = tabIndexUsed;
        } else {
            tab1 = new TabViewTab();
            tab1.addCssClass("listViewScrollableContainer");
            tab1.title = Localizer.getString("info");
            tabs.push(tab1);
            this.createTeamControls(tab1, tabIndexUsed);
            this.coordinatoranimator.addScrollerContainer(tab1);
            tab1.showLoader();
            tabIndexUsed += 1;

            if (data.squad) {
                tab2 = new TabViewTab();
                tab2.addCssClass("listViewScrollableContainer");
                tab2.title = Localizer.getString("team");
                tabs.push(tab2);
                this.createSquadControls(tab2, tabIndexUsed);
                tabIndexUsed += 1;
                this.coordinatoranimator.addScrollerContainer(tab2);
                tab2.showLoader();
            }
            if ((data.isProcessedByProcessor && data.transfers && data.transfers.length)
                || (!data.isProcessedByProcessor && data.transfers && ((data.transfers.in && data.transfers.in.length) || (data.transfers.out && data.transfers.out.length)))
            ) {

                tab3 = new TabViewTab();
                tab3.addCssClass("listViewScrollableContainer");
                tab3.title = Localizer.getString("transfers");
                tabs.push(tab3);
                this.createTransfersControls(tab3, tabIndexUsed);
                tabIndexUsed += 1;
                this.coordinatoranimator.addScrollerContainer(tab3);
                tab3.showLoader();
            }
            if (data.trophies) {
                tab4 = new TabViewTab();
                tab4.addCssClass("listViewScrollableContainer");
                tab4.title = Localizer.getString("trophies");
                tabs.push(tab4);
                this.createTrophiesControls(tab4, tabIndexUsed);
                tabIndexUsed += 1
                this.coordinatoranimator.addScrollerContainer(tab4);
                tab4.showLoader();
            }
        }

        this.coordinatoranimator.addTopBar(this.getPageTemplate().topBar);
        this.coordinatoranimator.addTabView(tabview);

        tabview.setTabs(tabs);

        tabview.bind("onReady", this.getProxy(function () {
            this.coordinatoranimator.run();
        }));

        this.tabview = tabview;

        this.bind("stickyBannerLoadFail", () => {
            console.error("sticky banner load fail -> set height to 100%");
            if (tab1) tab1.setCss({'height': '100%'});
            if (tab2) tab2.setCss({'height': '100%'});
            if (tab3) tab3.setCss({'height': '100%'});
            if (tab4) tab4.setCss({'height': '100%'});
        });
        //triggered from page template
        this.bind("stickyBannerLoadSuccess", () => {
            console.log("sticky banner load success -> set height to 100% - 50px");
            if (tab1) tab1.setCss({'height': 'calc(100% - 50px)'});
            if (tab2) tab2.setCss({'height': 'calc(100% - 50px)'});
            if (tab3) tab3.setCss({'height': 'calc(100% - 50px)'});
            if (tab4) tab4.setCss({'height': 'calc(100% - 50px)'});
        });
        this.getPageTemplate().loadStickyBannerAd();
    }

    createTeamControls(tab, index) {
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();
        listRender.containerTarget = tab;

        listRender.setItemRender(function (data) {

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new FutureTeamFixturesRender(),
                };
            } else if (data.type == 2) {
                return {
                    typeId: 1,
                    render: new PastTeamFixturesRender(),
                };
            } else if (data.type == 3) {
                return {
                    typeId: 1,
                    render: new LastPlayedTeamFixturesRender(),
                };
            } else if (data.type == 4) {
                return {
                    typeId: 1,
                    render: new NowPlayingTeamFixturesRender(),
                };
            } else if (data.type == 5) {
                return {
                    typeId: 1,
                    render: new TodayPlayingTeamFixturesRender(),
                };
            } else if (data.type == 6) {
                return {
                    typeId: 1,
                    render: new NextPlayingTeamFixturesRender(),
                };
            } else if (data.type == 7) {
                return {
                    typeId: 1,
                    render: new TeamLeagueStandingsItemRender(),
                };
            } else if (data.type == 8) {
                return {
                    typeId: 1,
                    render: new TeamGeneralInfoItemRender(),
                };
            }
        })

        listRender.emptyTemplate = function (cb) {
            return new NoDataMessage({
                cb: function () {
                    history.back();
                },
                message: Localizer.getString("no_data")
            });
        }

        listRender.errorTemplate = function (cb) {
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function () {
            return new ErrorMessage();
        }

        listRender.bind('createdList', this.getProxy(function () {
            this.coordinatoranimator.recalculateTab(index);
            this.coordinatoranimator.ensureStartPositions();
        }))

        var previousState = StateManager.getCurrentPageState(this.pageName
        );
        if (previousState) {
            listRender.scrollTo(previousState.fixturesScrollPosition || 0);
        }

        container.addControl(listRender);

        this.teamListrender = listRender;

        tab.addControl(container);
    }

    createSquadControls(tab, index) {
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();

        listRender.containerTarget = tab;

        listRender.setItemRender(function (data) {


            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new SquadListrender(),
                };
            } else if (data.type == 2) {
                return {
                    typeId: 1,
                    render: new SquadHeaderItemRender(),
                };
            } else if (data.type == 3) {
                return {
                    typeId: 1,
                    render: new SquadCoachItemRender(),
                };
            }
        })

        listRender.errorTemplate = function (cb) {
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function () {
            return new ErrorMessage();
        }

        listRender.bind('createdList', this.getProxy(function () {
            this.coordinatoranimator.recalculateTab(index);
        }))

        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            listRender.scrollTo(previousState.squadListrenderScrollPosition || 0);
        }

        container.addControl(listRender);
        this.squadListrender = listRender;

        tab.addControl(container);
    }

    createTransfersControls(tab, index) {
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();

        listRender.containerTarget = tab;

        listRender.setItemRender(function (data) {

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new TransfersListrender(),
                };
            } else if (data.type == 2) {
                return {
                    typeId: 1,
                    render: new TransferHeaderItemRender(),
                };
            }
        })

        listRender.errorTemplate = function (cb) {
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function () {
            return new ErrorMessage();
        }

        listRender.emptyTemplate = function (cb) {
            return new NoDataMessage2({
                message: Localizer.getString("no_transfer_data")
            });
        }

        listRender.bind('createdList', this.getProxy(function () {
            this.coordinatoranimator.recalculateTab(index);
        }))

        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            listRender.scrollTo(previousState.transfersListrenderScrollPosition || 0);
        }

        container.addControl(listRender);
        this.transfersListrender = listRender;

        tab.addControl(container);
    }

    createTrophiesControls(tab, index) {
        var container = new Panel();
        container.setStyle("width:100%;margin:0 auto;height:100%;");

        var listRender = new SimpleTileGridRenderer();

        listRender.containerTarget = tab;

        listRender.setItemRender(function (data) {

            if (data.type == 1) {
                return {
                    typeId: 1,
                    render: new TrophiesListrender(),
                };
            }
            if (data.type == 2) {
                return {
                    typeId: 2,
                    render: new TrophyHeaderItemRender(),
                };
            }
        })

        listRender.errorTemplate = function (cb) {
            return new ErrorMessage(cb);
        }

        listRender.noInternetTemplate = function () {
            return new ErrorMessage();
        }

        listRender.emptyTemplate = function (cb) {
            return new NoDataMessage2({
                message: Localizer.getString("no_data")
            });
        }

        listRender.bind('createdList', this.getProxy(function () {
            this.coordinatoranimator.recalculateTab(index);
        }))

        var previousState = StateManager.getCurrentPageState(this.pageName);
        if (previousState) {
            listRender.scrollTo(previousState.trophiesListrenderScrollPosition || 0);
        }

        container.addControl(listRender);
        this.trophiesListrender = listRender;

        tab.addControl(container);
    }


    loadFixtures(data, ignoreCache) {

        if (!data) {
            return this.autoInterval();
        }

        var tabIndex = 0;

        if (this.unloaded) {
            return console.warn("page is unloaded abort further actions");
        }
        ;

        var timeout = 0;
        if (this.tabview.currentActiveTab != tabIndex) {
            timeout = 600;
        }

        var options = {
            methodType: 8,
            responseData: data,
            dontSelectCurrentMatch: true,
            translations: Localizer.getLoadedLocale(),
            defaultTranslations: Localizer.DefaultTranslations,
            locale: AppInstance.getLocale(),
            fontSize: FootballManiaConfig.size,
            listType : ManiaPreferences.getMatchListType()
        }

        if (!FootballManiaConfig.useWebWorkers) {
            var list = Processors.prepareTeamFixtureData(options);
            var data = {data: [0, list]};
            this.onWorkerMessageReceived(data);
        } else {
            AppInstance.workerHelper1.postMessage(options);
        }

        Router.disallowBackButtonQuitApp();
    }

    autoInterval(overrideInterval) {
        clearTimeout(this.teamLoadHandler);
        this.teamLoadHandler = setTimeout(this.getProxy(function () {

            if (document.hidden) {
                console.warn("no auto refresh because page is hidden");
                return;
            }

            DataProvider.getTeam(UrlManager.getModule("tid"), this.getProxy(function (response) {

                if (this.unloaded) {
                    return console.warn("page is unloaded abort further actions");
                }
                ;

                if (!response.isOk()) {
                    this.autoInterval();
                    return;
                }

                this.getPageTemplate().createMenuTeam(response.getData());

                var options = {
                    methodType: 8,
                    responseData: response.getData(),
                    translations: Localizer.getLoadedLocale(),
                    defaultTranslations: Localizer.DefaultTranslations,
                    locale: AppInstance.getLocale(),
                    fontSize: FootballManiaConfig.size,
                    listType : ManiaPreferences.getMatchListType()
                }

                if (!FootballManiaConfig.useWebWorkers) {
                    var list = Processors.prepareTeamFixtureData(options);
                    var data = {data: [0, list]};
                    this.onWorkerMessageReceived(data);
                } else {
                    AppInstance.workerHelper1.postMessage(options);
                }

            }));
        }), overrideInterval != null ? overrideInterval : AppInstance.getConfig().refreshIntervals.team)
    }
};