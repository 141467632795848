import {DataProvider} from "../data/dataprovider.js";

export const ManiaAds = {

    loadStickyBanner : function(target,cb) {
        DataProvider.getAdtekAd("sticky",(data) => {
            if (!data.isOk()){
                console.log("no sticky ad available");
                cb(true);
                return;
            }

            try {
                var html_string = data.getData();
                var iFrame = $("#" + target.getClientId());
                var iFrameParent = iFrame.parent();
                iFrame.remove();
                iFrame.attr('src', "data:text/html;charset=utf-8," + escape(html_string));
                iFrameParent.append(iFrame);
                cb();
            }
            catch (e){
                console.error("failed to load house ad "+e);
                cb(true);
            }
        });
    },

    loadHouseAd : function(target,cb) {

        if(/Android/i.test(navigator.userAgent)){
            var type = "housead_android";
        }
        else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)){
            var type = "housead_ios";
        }
        else{
            console.warn("no house ad for this device");
            return cb(true);
        }

        console.log("loading house ad "+type);
        DataProvider.getAdtekAd(type,(data) => {
            if (!data.isOk()){
                console.log("no sticky ad available");
                cb(true);
                return;
            }

            try {
                var html_string = data.getData();
                var iFrame = $("#" + target.getClientId());
                var iFrameParent = iFrame.parent();
                iFrame.remove();
                iFrame.attr('src', "data:text/html;charset=utf-8," + escape(html_string));
                iFrameParent.append(iFrame);
                cb();
            }
            catch (e){
                console.error("failed to load house ad "+e);
                cb(true);
            }
        });
    },
}