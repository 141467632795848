import {ManiaCache} from "../util/cache.js";
import {DateTimecode}  from "../util/datetime.js";
import {UrlManager}  from "../bananaframework/src/util/UrlManager.js";

var DataProviderConfig = {
    baseUrl : "/footapi/",
    baseImageUrl : "",
}

var Response = function(){
    var isError = false;
    var errorMessage;
    var errorCode;
    var data;
    var status;
    var authorized;
    this.error = function(e){
        status = e.status;
        console.log(e);
        authorized = (e.statusText != "Unauthorized");
        console.log("authorized ",authorized)

        if (!authorized){
            isError = true;
        }
        if (status == 404 || status == 400 || status == 500 || status == 0){
            isError = true;
        }

        if (e.responseText){
            try{
                var error = JSON.parse(e.responseText);
                this.errorMessage = error.message;
                this.errorCode = error.code;
            }
            catch (ex) {
                //console.log("error parsing error message",ex);
                this.errorMessage = "Error fetching data. An unusual error ocured"
                this.errorCode = 500
                isError = true;
            }
        }
        return this;
    }
    this.getStatus = function(){
        return status;
    }
    this.getErrorMessage = function(){
        return this.errorMessage || "";
    }
    this.getErrorCode = function(){
        return this.errorCode || "";
    }
    this.success = function(d){
        data = d;
        status = 200;
        authorized = true;
        return this;
    }
    this.getData = function(){
        return data;
    }
    this.isOk = function(){
        return !isError;
    }
    this.isNoInternet = function(){

    }
    this.isTimeout = function(){

    }
    this.isAuthorized = function(){
        return authorized;
    }
}

export const DataProvider = {

    getExtraUrlParts : function(){
        return "lang="+AppInstance.getConfig().userLocale+"&version="+AppInstance.getConfig().apiVersion;
    },

    iamhere : function(){
        return;
    },

    getGeneralCall : function(url,cacheExpireTime,ignoreCache,cb,label,forceJson){
        var expireTime = cacheExpireTime;
        //console.log("get general call",url,cacheExpireTime,ignoreCache,cb,label,forceJson,AppInstance.isOnline());
        if (!AppInstance.isOnline()){
            console.log("thzee app is not online. put a long expire time");
            ignoreCache = false;
            expireTime = 999999999;
        }

        var xhr = function(){
            $.ajax({
                timeout: FootballManiaConfig.ajaxTimeout,
                xhrFields: {},
                url: url,
                data: {},
                crossDomain: true,
                type: "GET",
                success: function (data) {
                    ManiaCache.set(url, data);
                    console.log("retreived "+label+" from xhr");
                    if (!cb){
                        console.error("no callback for "+label);
                        return;
                    }
                    if (forceJson){
                        return cb(new Response().success(JSON.parse(data)));
                    }
                    return cb(new Response().success(data));
                },
                error: function (e) {
                    console.error(e);
                    if (!cb){
                        console.error("no callback for "+label);
                        return;
                    }
                    return cb(new Response().error(e))
                }
            });
        }
        if (ignoreCache){
            xhr();
        }
        else {
            //console.log("get",url,expireTime);
            ManiaCache.get(url, expireTime, function (result) {
                if (result) {
                    console.log("retreived "+label+" from cache");
                    if (!cb){
                        console.error("no callback for "+label);
                        return;
                    }
                    if (forceJson){
                        return cb(new Response().success(JSON.parse(result)));
                    }
                    return cb(new Response().success(result));
                }
                xhr();
            })
        }
    },

    postGeneric : function(url,data,cb,label){
        var xhr = function(){
            $.ajax({
                timeout: FootballManiaConfig.ajaxTimeout,
                xhrFields: {},
                url: url,
                data: data,
                crossDomain: true,
                type: "POST",
                success: function (data) {
                    console.log("post "+label);
                    if (cb) {
                        return cb(new Response().success(data));
                    }
                },
                error: function (e) {
                    console.error(e);
                    if (cb) {
                        return cb(new Response().error(e))
                    }
                }
            });
        }
        xhr();
    },

    getAppStart : function(cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.home
        var url = AppInstance.getConfig().url.dataBaseUrl + "fixtures/feed_appstart.json?"+DataProvider.getExtraUrlParts();
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getAppStart");
    },

    getConfig : function(cb,ignoreCache){

        var cacheExpireTime = 600000;
        if (!AppInstance.isOnline()){
            ignoreCache = false;
            cacheExpireTime = 999999999;
        }

        var url = AppInstance.getConfig().url.dataBaseUrl + "config.json?"+DataProvider.getExtraUrlParts();

        var xhr = function(){
            $.ajax({
                timeout: FootballManiaConfig.ajaxTimeout,
                xhrFields: {},
                url: url,
                data: {},
                crossDomain: true,
                type: "GET",
                success: function (data) {
                    //ManiaCache.set(url, data);
                    localStorage.setItem("mania_config",JSON.stringify({data:data,timestamp:new Date().getTime()}));
                    console.log("retreived config from xhr");
                    return cb(new Response().success(data));
                },
                error: function (e) {
                    return cb(new Response().error(e))
                }
            });
        }

        if (ignoreCache){
            xhr();
        }
        else {
            var cachedResult = localStorage.getItem("mania_config");
            var hardcodedConfigCacheTime = cacheExpireTime;
            if (cachedResult){
                cachedResult = JSON.parse(cachedResult);
                ///console.log("we have cached result",cachedResult.timestamp)
                if (new Date().getTime() - cachedResult.timestamp < hardcodedConfigCacheTime){
                    return cb(new Response().success(cachedResult));
                }
                else{
                    xhr();
                }
            }
            else{
                xhr();
            }
        }
    },

    getTranslations : function(locale,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.translations;
        var url = "/js/translations/"+locale+".json?"+DataProvider.getExtraUrlParts();
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getTranslations");
    },

    getLiveNow : function(cb,ignoreCache,cacheExpireTime){
        var cacheExpireTime = cacheExpireTime || FootballManiaConfig.cacheTimes.live;
        var url = AppInstance.getConfig().url.dataBaseUrl + "fixtures/feed_livenow.json?"+DataProvider.getExtraUrlParts();
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getLive");
    },

    getToday : function(cb,ignoreCache,cacheExpireTime){
        var cacheExpireTime = cacheExpireTime || FootballManiaConfig.cacheTimes.today;
        var date = new DateTimecode();
        var urlPart = DataProvider.getExtraUrlParts()+"&date="+date.getLocalDateTime("%d/%m/%Y")+"&tzoffset="+(new Date().getTimezoneOffset()*-1)
        var url = AppInstance.getConfig().url.dataBaseUrl + "fixtures/feed_matches_aggregated_short.json?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getToday");
    },

    getFixturesForDate : function(date,long,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.datefixtures;
        var urlPart = DataProvider.getExtraUrlParts()+"&date="+(date+"")+"&tzoffset="+(new Date().getTimezoneOffset()*-1)
        if (long) {
            var url = AppInstance.getConfig().url.dataBaseUrl + "fixtures/feed_matches_aggregated_long.json?" + urlPart;
        }
        else{
            var url = AppInstance.getConfig().url.dataBaseUrl + "fixtures/feed_matches_aggregated.json?" + urlPart;
        }
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"GETFIXTURESFORDATE");
    },

    getMatch : function(id,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.match;
        var urlPart = DataProvider.getExtraUrlParts()+"&h2h=1";
        if (FootballManiaConfig.disallowedHighlightRegions.indexOf(FootballManiaConfig.userRegionLocale) == -1){
            urlPart += '&vid=1';
        }
        var url = AppInstance.getConfig().url.dataBaseUrl + "matches/"+id+".json?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"GETMATCH");
    },

    getMatchVotes : function(id,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.match;
        var url = AppInstance.getConfig().url.voteBaseUrl + "getVotes.php?matchid="+id;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"GETVOTES");
    },

    getLeagueMatches : function(key,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.league;
        // return cb(new Response().success(JSON.parse(JSON.stringify(data))));
        //var url = AppInstance.getConfig().url.dataBaseUrl + "fixtures/feed_matches_aggregated_short.json?"+urlPart;
        var urlPart = DataProvider.getExtraUrlParts();
        var url = AppInstance.getConfig().url.dataBaseUrl +"fixtures_v2/" + key.replaceAll(" ", "") + "_small.json?"+urlPart
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"LEAGUEMATCHES");
    },

    getTeam : function(id,cb,ignoreCache,cacheExpireTime,simple){
        var cacheExpireTime = cacheExpireTime != null ? cacheExpireTime : FootballManiaConfig.cacheTimes.team;
        if (simple){
            var urlTarget = "team_gs_simple";
        }
        else{
            var urlTarget = "team_gs";
        }
        var urlPart = DataProvider.getExtraUrlParts();
        var url = AppInstance.getConfig().url.dataBaseUrl +urlTarget+"/"+id+".json?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"TEAM");
    },

    getPlayer : function(id,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.player;
        var urlPart = DataProvider.getExtraUrlParts();
        var url = AppInstance.getConfig().url.dataBaseUrl +"players/"+id+".json?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"player");
    },

    getCoach : function(id,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.player;
        var urlPart = DataProvider.getExtraUrlParts();
        var url = AppInstance.getConfig().url.dataBaseUrl +"coaches/"+id+".json?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"coach");
    },

    getCountryLeagues : function(country,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.countryLeagues;
        var urlPart = DataProvider.getExtraUrlParts();
        country = country.replaceAll(" ", "");
        var url = AppInstance.getConfig().url.dataBaseUrl +"fixtures/feed_leagues_country_"+country+".json?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"countryLeagues");
    },

    getInfo : function(cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.userinfo;
        var urlPart = DataProvider.getExtraUrlParts();
        var url = "https://boogiewoogiedoo.com/getInfo?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"userinfo");
    },

    getCommentaries : function(matchid,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.commentaries;
        var urlPart = DataProvider.getExtraUrlParts();
        var url = AppInstance.getConfig().url.dataBaseUrl +"commentaries/"+matchid+".json?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getcommentaries");
    },

    getNews : function(locale,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.news;
        var urlPart = DataProvider.getExtraUrlParts();
        if (AppInstance.getConfig().newsLocaleRemap[locale]){
            locale = AppInstance.getConfig().newsLocaleRemap[locale];
        }
        var url = AppInstance.getConfig().url.dataBaseUrl +"news/"+locale.toUpperCase()+".json?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getnews");
    },

    getSuggestions : function(cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.suggestions;
        var urlPart = DataProvider.getExtraUrlParts();
        var locale = FootballManiaConfig.userLocaleFull;
        if (!locale.baseName || locale.baseName.length < 4){
            locale = FootballManiaConfig.userLocale+"-"+FootballManiaConfig.userLocale.toUpperCase();
        }
        else{
            locale = locale.baseName;
        }
        var url = AppInstance.getConfig().url.dataBaseUrl + "suggestions/"+locale.replace("-","_")+".json?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getsuggestions");
    },

    getRanking : function(year,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.ranking;
        var urlPart = DataProvider.getExtraUrlParts()+"&tzoffset="+(new Date().getTimezoneOffset()*-1);
        var url = AppInstance.getConfig().url.dataBaseUrl + "rankings/ranking_fifa_"+year+".json?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getranking");
    },

    getPlayerMarketValueRanking : function(cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.ranking;
        var urlPart = DataProvider.getExtraUrlParts()+"&tzoffset="+(new Date().getTimezoneOffset()*-1);
        var url = AppInstance.getConfig().url.dataBaseUrl + "otherdata/topMarketValuedPlayers.json?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getplayermarketvalues");
    },

    getOtherData : function(otherdata,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.ranking;
        var urlPart = DataProvider.getExtraUrlParts()+"&tzoffset="+(new Date().getTimezoneOffset()*-1);
        var url = AppInstance.getConfig().url.dataBaseUrl + "otherdata/"+otherdata+"?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getOtherData");
    },

    getTopVoters : function(cb,ignoreCache,period){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.ranking;
        var urlPart = DataProvider.getExtraUrlParts();
        if (FootballManiaConfig.isAndroid){
            urlPart += "&gcmid="+window.JSAndroidBridge.getFCMToken();
        }
        if (period == "alltime"){
            var topVoterUrlPart = "topVoters.json";
        }
        else{
            var topVoterUrlPart = "topVotersLastMonth.json";
        }
        var url = AppInstance.getConfig().url.dataBaseUrl+topVoterUrlPart+"?"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getTopVoters","json");
    },

    getTopVoterUser : function(id,cb,ignoreCache,period){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.ranking;
        var urlPart = "userVotes/"+id+".json?"+DataProvider.getExtraUrlParts();
        var url = AppInstance.getConfig().url.dataBaseUrl+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getTopVoterUser");
    },

    getAdtekMatchOdds: function(matchid,cb,ignoreCache){
        ignoreCache = true; //ads should never cache
        //var urlPart = DataProvider.getExtraUrlParts()+"&tzoffset="+(new Date().getTimezoneOffset()*-1);
        var urlPart = "?type=odds&appid=1&mid="+matchid+"&random="+Math.round(Math.random()*1000);
        var manualCountry = UrlManager.getModule("oddsCountry");
        if (manualCountry){
            urlPart += "&country="+manualCountry;
        }
        //urlPart += "&country=BR";
        var url = "https://boogiewoogiedoo.com/request_creative"+urlPart;
        this.getGeneralCall(url,0,ignoreCache,cb,"getAdtekMatchOdds");
    },

    getAdtekAd: function(type,cb,ignoreCache){
        ignoreCache = true; //ads should never cache
        //var urlPart = DataProvider.getExtraUrlParts()+"&tzoffset="+(new Date().getTimezoneOffset()*-1);
        var urlPart = "?type="+type+"&appid=1"+"&random="+Math.round(Math.random()*1000);
        var manualCountry = UrlManager.getModule("oddsCountry");
        //manualCountry = "IN";
        if (manualCountry){
            urlPart += "&country="+manualCountry;
        }
        //urlPart += "&country=BR";
        var url = "https://boogiewoogiedoo.com/request_creative"+urlPart;
        this.getGeneralCall(url,0,ignoreCache,cb,"getAdtekAd");
    },

    getForumIndex : function(cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.forum;
        var urlPart = DataProvider.getExtraUrlParts()
        var url = AppInstance.getConfig().url.dataBaseUrl + "forum/index.json?lang=" + urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"getForumIndex",true);
    },

    search : function(q,cb,ignoreCache){
        var cacheExpireTime = FootballManiaConfig.cacheTimes.search;
        if (!AppInstance.isOnline()){
            ignoreCache = false;
            cacheExpireTime = 999999999;
        }
        var urlPart = DataProvider.getExtraUrlParts();
        var url = AppInstance.getConfig().url.dataBaseUrl +"search_v3?q=" + encodeURIComponent(q) + "&"+urlPart;
        this.getGeneralCall(url,cacheExpireTime,ignoreCache,cb,"search");
    },
    searchtrack : function(data,cb){
        var urlPart = DataProvider.getExtraUrlParts();
        var url = AppInstance.getConfig().url.dataBaseUrl +"track?"+urlPart;
        console.log(url);
        var xhr = function(){
            $.ajax({
                timeout: FootballManiaConfig.ajaxTimeout,
                xhrFields: {},
                url: url,
                data: data,
                crossDomain: true,
                type: "POST",
                success: function (data) {
                    console.log("post track");
                    if (cb) {
                        return cb(new Response().success(data));
                    }
                },
                error: function (e) {
                    console.error(e);
                    if (cb) {
                        return cb(new Response().error(e))
                    }
                }
            });
        }
        xhr();
    },

    getTeamLike : function(team,cb){
        var urlPart = team;
        var url = AppInstance.getConfig().url.dataBaseUrl +"likes/team.json?id="+urlPart;
        console.log(url);
        var xhr = function(){
            $.ajax({
                timeout: FootballManiaConfig.ajaxTimeout,
                xhrFields: {},
                url: url,
                crossDomain: true,
                type: "GET",
                success: function (data) {
                    console.log("get team like");
                    if (cb) {
                        return cb(new Response().success(data));
                    }
                },
                error: function (e) {
                    console.error(e);
                    if (cb) {
                        return cb(new Response().error(e))
                    }
                }
            });
        }
        xhr();
    },

    like : function(type,id,cb){
        var urlPart = DataProvider.getExtraUrlParts();
        var url = AppInstance.getConfig().url.dataBaseUrl +"footapi/likes/team";
        var data = {
            id : id
        }
        var xhr = function(){
            $.ajax({
                timeout: FootballManiaConfig.ajaxTimeout,
                xhrFields: {},
                url: url,
                data: data,
                crossDomain: true,
                type: "POST",
                success: function (data) {
                    console.log("post team like");
                    if (cb) {
                        return cb(new Response().success(data));
                    }
                },
                error: function (e) {
                    console.error(e);
                    if (cb) {
                        return cb(new Response().error(e))
                    }
                }
            });
        }
        xhr();
    }
}
