import {Control} from "../controls/Control.js";
import {Page} from "../controls/Page.js";
import {UrlManager} from "../util/UrlManager.js";

window.domuuid = 0;

export class Application extends Control{

    constructor(settings) {
        super();
        this.initApplication(settings);
    }

    initApplication (settings)
    {
        console.log("STARTING APPLICATION",settings);
        this.delayedSheduledPageLoad = [];
        // Add the logger
        if (!jQuery){
            console.error("JQuery is not found. JQuery required.");
            return;
        }

        if (!settings)
        {
            settings = {};
        }

        this.settings = settings;

        //to remember the vertical scroll position of a page
        this.id = 0; // Will increment on each page load

        // if (settings.applicationName)
        // {
        //     namespace('Banana')[settings.applicationName] = this;
        // }
        // else
        // {
        //     namespace('Banana').Application = this;
        // }
        //
        // if (!settings.pageTemplate)
        // {
        //     settings.pageTemplate = "PageTemplate";
        // }
        //
        // if (!settings.paths)
        // {
        //     settings.paths = {};
        // }
        //
        // if (!settings.paths.pages)
        // {
        //     settings.paths.pages = "Application.Pages";
        // }
    }

    /**
     * When a user closes/refresh the browser tab/window,
     * the current page will be removed + all registered event
     * @ignore
     */
    prepareUnload ()
    {
        jQuery(window).on("beforeunload",()=>
        {
            jQuery('*').unbind();
            if (this.activePage)
            {
                this.activePage.remove();
            }
            if (this.pageTemplate)
            {
                this.pageTemplate.remove();
            }
            Banana = null;
        });
    }

    /**
     * Automatically called by Banana
     * The page template is specified in application settings with the "pageTemplate" property.
     * If no Page template is given, we use default PageTemplate
     * @ignore
     */
    loadPageTemplate ()
    {
        if (!this.settings.pageTemplate) {
            console.error("no page template defined. please define one");
            return;
        }

        var pageTemplateClass = this.settings.pageTemplate;

        if (!pageTemplateClass)
        {
            console.error("Error: could not find Template: "+objectname);
            return;
        }

        this.pageTemplate = new pageTemplateClass();
    }

    /**
     * @returns {Array} of settings
     */
    getSettings ()
    {
        return this.settings;
    }

    isLoadingPage = false
    delayedSheduledPageLoad = []

    processDelayedSheduledPageLoad (){
        if (this.delayedSheduledPageLoad.length > 0){
            ///console.log("process delayed page load	");
            var page = this.delayedSheduledPageLoad.shift();
            this.loadPage(page.page, page.urlParams, page.ignoreHistoryParams, page.cb);
        }
    }

    /**
     * Loads a page.
     *
     * Loads a page by specifying the full namespace name of the page.
     * Additional parameters can be send along. Parameters should be in key => value format
     * stored in an Object. All parameters will be written in the hash section of the url.
     * Loading a page also results in saving the url parameters of the current page. This means
     * that we when we have a url like http://mysite?page=foo#section=Home&id=12
     * we save the id parameter only. Unless specified we restore the original parameters when the
     * page is reloaded.
     *
     * @param {string} page to be loaded. This is the full namespace name
     * @param {object} key value format
     * @param {bool} ignoreHistoryParams if true we ignore previous url params.
     */
    loadPage (page,urlParams,ignoreHistoryParams,cb)
    {
        console.log("load page: "+page);
        //if we load a page while loading a page, the page gets corrupted due to async behavior
        if (this.isLoadingPage){
            this.delayedSheduledPageLoad.push({page:page,urlParams:urlParams,ignoreHistoryParams:ignoreHistoryParams,cb:cb});
            console.warn("cannot load page while loading page. delay loading");
            return;
        }
        this.isLoadingPage = true;
        //console.log("------> load page");
        //we save the current url params to use them later on when we return to this page
        UrlManager.saveModuleHistory('section');

        //clean the url
        UrlManager.clearUrl()

        //Here we assign the history property to a global class property.
        //We need to do this because below code which is registering url parameters in the urlmanager one by one.
        //If every Url Manager action results into a url change, we would get loads of history points
        //in our browser. This is something we don't want. To prevent this situation we register them
        //but without modifing the url. Later in the Run() method we modify the url at once.
        this.loadingWithHistory = !ignoreHistoryParams;

        if (this.loadingWithHistory)
        {
            var history = UrlManager.getModuleHistory(page);

            var his;
            for (his in history)
            {
                if (history.hasOwnProperty(his))
                {
                    UrlManager.registerModule(his);
                    UrlManager.setModule(his,history[his], true);
                }
            }
        }

        if (urlParams)
        {
            var prop;

            for (prop in urlParams)
            {
                if (urlParams.hasOwnProperty(prop))
                {
                    UrlManager.registerModule(prop);
                    UrlManager.setModule(prop,urlParams[prop], true);
                }
            }
        }

        this.run(page,cb);
    }

    /**
     * This method is used when only one single page instance is attached to the application.
     *
     * @ignore
     */
    instantiateSinglePage ()
    {
        if (!this.pageTemplate)
        {
            this.loadPageTemplate();
        }

        this.page.bind('renderComplete',()=>{
            console.log("render complete received");

            if (this.activePage)
            {
                this.activePage.removeDom();
                this.activePage.clearProps();
                this.activePage = undefined;
            }

            this.activePage = this.page;
            this.activePage.setVisible(true);
            this.isLoadingPage = false;
            this.processDelayedSheduledPageLoad();
        });

        //our new page will be not visible
        this.page.setVisible(false);

        //now we add it to the pagetemplate
        this.pageTemplate.addPageControl(this.page);

        this.pageTemplate.setApplication(this);

        //load the whole page
        this.pageTemplate.run(this);
    }

    /**
     * Run a page
     *
     * This function should only be initially called to bootstrap the
     * framework. Consecutive pages should be loaded by using {@link #loadPage}.
     *
     * @param {string} page
     */
    run (page,cb)
    {
        console.log("run page: "+(page ? page : "Default"));
        // Save the parameter
        if (page)
        {
            this.page = page;
        }

        if (page instanceof Page)
        {
            return this.instantiateSinglePage();
        }

        this.triggerEvent('onPreLoadPage');

        // If this is the first page we run, we register all the url parameters
        if (!this.activePage)
        {
            UrlManager.autoRegisterModules();
        }

        if (!this.pageTemplate)
        {
            this.loadPageTemplate();
        }

        // Determine page (section) to load
        var section = this.page || UrlManager.getModule('section') || this.settings.defaultPage;
        this.section = section;

        this.pageTemplate.triggerEvent('preLoadPage',section);

        // Unset saved parameter for next calls
        this.page = undefined;

        //the register url section registers the section without modifing the url.
        //we receive a change event
        this.registerUrlSection(section);
        //apply url change in the Url Manager
        if (!this.loadingWithHistory)
        {
            //UrlManager.updateUrl(section);
            UrlManager.updateUrl(true);
            //history.pushState(null,null,"/page/"+section);
        }

        var load = ()=>{

            if (!this.settings.pages[section]){
                console.error("Page "+section+" not found");
                this.isLoadingPage = false; //important when page is not found. otherwise stuclk
                return this.loadPage(this.settings.defaultPage);
            }

            var pageClass = new this.settings.pages[section]();

            //if the pageclass cannot be find use the default one
            if (!pageClass)
            {
                if (this.settings.defaultPage === section)
                {
                    console.error("Error default page "+this.settings.defaultSection+" cannot be loaded");
                    return;
                }
                else
                {
                    console.error("Page "+section+" not found. Load default page "+this.settings.defaultPage);
                }
                this.isLoadingPage = false; //important when page is not found. otherwise stuclk
                return this.loadPage(this.settings.defaultPage);
            }

            //id of the application which thus increments every page load
            this.id++;

            var newPage = pageClass;

            //when a page is rendered we remove the old page.
            //We already removed its events and timers. now its time for the visible items as well
            //We also remove its properties. This is too prevent memory leaks
            newPage.bind('renderComplete',()=>{

                if (this.activePage)
                {
                    this.activePage.removeDom();
                    this.activePage.clearProps();
                    this.activePage = undefined;
                }

                this.activePage = newPage;
                this.activePage.setVisible(true);
                this.isLoadingPage = false;
                this.processDelayedSheduledPageLoad();
            });

            //our new page will be not visible yet
            newPage.setVisible(false);

            //now we add it to the pagetemplate
            this.pageTemplate.addPageControl(newPage);

            this.pageTemplate.setApplication(this);

            //update the url to its final state
            if (this.activePage) {
                UrlManager.updateUrl(false);
            }
            else{
                UrlManager.updateUrl(true);
            }
            //run the page. if everything is ok we will receive a renderComplete event
            this.pageTemplate.run();

            UrlManager.unlistenModule('section');
            UrlManager.listenModule('section',()=>
            {
                this.loadPage(UrlManager.getModule('section'));
            });

            if (cb) cb();
        };

        //if we have an active page we want it to be removed
        //but only its internal data. like (events, timers and more)
        //the dom needs to stay to prevent an ugly flickering between page transitions
        if (this.activePage)
        {
            ///console.log("active page yes, remove it");
            //this is extremely important. Our remove function below is asynchronous
            // it might happen that the section change which is also asnchronous (statement above) results into
            //a url change during removing of the controls. so we need to stop the url manager from listening
            UrlManager.stopChecking();

            this.activePage.remove(true,()=>{
                UrlManager.startChecking();
                load();
            });
        }
        else
        {
            ///console.log("no active page");
            load();
        }
    }

    /**
     * Registers section url and binds a change event to it
     * which determines which page should be loaded
     * @param {String} section
     * @ignore
     */
    registerUrlSection (section)
    {
        UrlManager.registerModule('section',section);
        UrlManager.setModule('section',section,true);

        //UrlManager.setModule('section',section,true);
    }
};