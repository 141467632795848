import {Panel} from "../../bananaframework/src/controls/Panel.js"
import {Image} from "../../bananaframework/src/controls/Image.js"
import {DataGridTileItemRender} from "../../bananaframework/src/controls/listcontrols/datagrids/listrenders/DataGridTileItemRender.js"

import {Router} from "../../util/router.js";
import {ImagePlaceHolder} from "../imageplaceholder.js";

export class PlayerMarketValueItemRender extends DataGridTileItemRender{

    init (data)
    {
        super.init();
        this.data = data;
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");
        this.panel.setStyle("margin-bottom:40px;");
        //
        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard ");
        this.panel.addControl(this.card);
        this.panel.setStyle("margin-top:3px;margin-bottom:-3px;");
        this.card.setStyle("padding-bottom:4px;height:48px;cursor:pointer;");
        //
        this.content = new Panel();
        this.content.addCssClass("flexHorizontalAlign");
        this.content.setStyle("align-items: flex-start;");

        this.card.addControl(this.content);

        this.rank = new Panel();
        this.rank.setStyle("align-self: center;padding-inline-start:4px;padding-inline-end:4px;min-width:28px;");
        this.content.addControl(this.rank);

        this.playerImage = new ImagePlaceHolder();
        this.playerImage.setStyle("width:40px; height:40px;align-self: center;border-radius:30px; overflow:hidden;")
        this.content.addControl(this.playerImage);

        this.playerCountryImage = new Image();
        this.playerCountryImage.setStyle("align-self: center;margin-inline-start: 8px;width: 20px;");
        this.content.addControl(this.playerCountryImage);


        this.playerName = new Panel();
        this.playerName.setStyle("align-self: center;margin-inline-start: 8px;line-height: 21px;");
        this.content.addControl(this.playerName);

        this.playerValue = new Panel();

        var margin = "margin-left:auto;";
        if (FootballManiaConfig.isRtl){
            margin = "margin-right:auto;";
        }

        this.playerValue.setStyle(margin+"align-self: center;")
        this.content.addControl(this.playerValue);

        this.addControl(this.panel);
        this.valueFormatter = Intl.NumberFormat('en-EN', { style: 'currency', currency: 'EUR' })

        this.content.bind("click",()=>{

            Router.showPlayerScreen(this.data.id);

        });
    }

    setData (data){

        this.data = data;
        this.rank.clear();
        this.playerName.clear();
        this.playerValue.clear();

        this.rank.addControl(data.rank+"",true);

        if (AppInstance.getConfig().enabledFeatures.playerImages) {
            this.playerImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/playerimages/" + data.id + "_small_rnd.png", 40, 40);
            this.playerCountryImage.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/flags/"+data.nationality.replace(/\s|\'/g, '-').toLowerCase()+".png");
        }
        else{
            this.playerImage.imageObjectStyle = "margin-top:-16px;margin-left:-16px;";
            this.playerImage.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/flags/"+data.nationality.replace(/\s|\'/g, '-').toLowerCase()+".png",72,72);
            this.playerCountryImage.setVisible(false);
        }
        this.playerName.addControl(data.name+ " <br> <i style='font-weight:bold;'> "+data.team+"</i>",true);
        this.playerValue.addControl(this.valueFormatter.format(data.marketvalue),true);
    }
};
