import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {InputControl} from "../../bananaframework/src/controls/InputControl.js";
import {Image} from "../../bananaframework/src/controls/Image.js";

import {Modal} from "../modal.js";
import {Localizer} from "../../util/localizer.js";
import {Favorites} from "../../util/favorites.js";
import {DataProvider} from "../../data/dataprovider.js";

export class FavoriteTeamAdderModal extends Modal {

    init(urlIdentifier) {
        super.init(urlIdentifier);
    }

    createComponents() {
        super.createComponents();

        var dim = this.getPage().getDimensions();

        var p = new Panel();
        p.setStyle("height:" + (dim.height - 50) + "px;justify-content: flex-start;");
        p.addCssClass("flexVerticalAlign");

        var search = new Panel();
        search.addCssClass("topBarSearchContainer")
        search.setStyle("margin-top:10px;position:relative;border: 1px solid #184548;width: 80%;border-radius: 13px;margin-bottom: 16px;");
        var searchInput = new InputControl();
        search.addControl(searchInput);
        searchInput.setStyle("height:40px;text-align: center;")

        var searchPh = new Panel();
        searchPh.addControl(Localizer.getString("search_your_favorite_teams"));
        searchPh.setStyle("font-style: italic;opacity:0.7;position:absolute;top:12px;left:0px;right:0px;bottom:0px;pointer-events:none;")
        search.addControl(searchPh);

        this.searchInput = searchInput;
        p.addControl(search);

        var searchResults = new Panel();
        searchResults.addCssClass("addFavoriteModalSuggestList");
        searchResults.setStyle("height:100%;overflow-y:scroll;width:85%;");
        p.addControl(searchResults)

        this.addControl(p)
        this.setVisible(false);

        setTimeout(() => {
            this.setVisible(true);
            this.reValidateSize();
        }, 50);

        searchInput.bind("click", () => {
            searchPh.setVisible(false);
        });

        searchInput.bind("blur", () => {
            if (!jQuery("#" + searchInput.clientId).val()) {
                //searchPh.setVisible(true);
            }
        });

        searchInput.bind("keydown", (e) => {
            console.log("keydown");
            clearTimeout(this.inputter);
            this.inputter = setTimeout(() => {

                if (!jQuery("#" + searchInput.clientId).val()) {
                    searchResults.clear();
                    //searchPh.setVisible(true);
                    return this.renderSuggestions(searchResults);
                }

                if (e.originalEvent.key == "Enter") {
                    jQuery("#" + searchInput.clientId).blur();
                    return;
                }

                DataProvider.search(jQuery("#" + searchInput.clientId).val(), (response) => {
                    searchResults.clear();
                    if (!response.isOk()) {
                        return this.renderSuggestions(searchResults);
                    }
                    var d = response.getData();

                    if (!d.result.length) {
                        console.log("render suggestions");
                        return this.renderSuggestions(searchResults);
                    }

                    var processedTeams = 0;
                    var max = 10;
                    for (var i = 0; i < d.result.length; i++) {

                        var result = d.result[i];

                        if (result.type == "t") {
                            if (processedTeams >= max) continue;
                            processedTeams++;
                            this.renderSearchResult(result, searchResults);
                        }
                    }
                    searchResults.invalidateDisplay();
                });
            }, 200);
        });

        this.renderSuggestions(searchResults);
    }

    renderTeamItem(team, target, isFavorite, controlIndex) {
        var resultP = new Panel();
        resultP.addCssClass("topBarSearchResultItem");
        resultP.setStyle("justify-content:space-between;");

        var subContainer = new Panel();
        subContainer.addCssClass("flexHorizontalAlign");

        var image = new Image();
        image.setStyle("width:40px;height:40px;")

        if (AppInstance.getConfig().enabledFeatures.teamImages) {
            image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + team.id_gs + "_small_rnd.png");
        } else {
            image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/country/" + team.id_gs + "_small_rnd.png");
        }

        subContainer.addControl(image);

        subContainer.addControl("<div style='flex: none;'>");

        if (team.countrygs) {
            subContainer.addControl(team.name || team.team + " - " + team.countrygs);
        } else {
            subContainer.addControl(team.name || team.team);
        }
        var showRemove = false;
        if (isFavorite) {
            subContainer.setStyle("opacity:0.4;justify-content:space-between;");
            subContainer.addControl(" (" + Localizer.getString("added") + ")");
            showRemove = true;
        }

        subContainer.addControl("</div>");
        resultP.addControl(subContainer);
        if (showRemove) {
            var removeButton = new Panel();
            removeButton.addCssClass("icon-close");
            removeButton.setStyle("opacity:0.5;background-color: red;right:0;display:flex;width:40px;height:40px;margin-inline-start:4px;")
            resultP.addControl(removeButton);

            removeButton.bind("click", (e) => {
                console.log('remove favorite', target.getControls().indexOf(resultP));
                Favorites.removeFavoriteTeam(team.id_gs);
                subContainer.setCss({"opacity": "1"});
                this.getPage().triggerEvent("onFavoriteChanged");
                e.stopPropagation();
                e.preventDefault();
                e.stopImmediatePropagation();
                var controlIndex = target.getControls().indexOf(resultP);
                this.renderTeamItem(team, target, Favorites.getFavoriteTeams()[team.id_gs], controlIndex);
                resultP.remove();
            });
        }

        if (controlIndex != null) {
            target.addControlAt(resultP, controlIndex, true);
        } else {
            target.addControl(resultP);
        }

        resultP.bind("click", () => {
            //console.log("add favorite",team.id_gs,team.name)
            if (!Favorites.addFavoriteTeam(team.id_gs, team.team || team.name)) {
                return this.getPage().getPageTemplate().showToast(Localizer.getString("max_favorite_teams_reached"));
            }
            var controlIndex = target.getControls().indexOf(resultP);
            this.renderTeamItem(team, target, true, controlIndex);
            resultP.remove();
            subContainer.setCss({"opacity": "0.2"});
            this.getPage().triggerEvent("onFavoriteChanged");
        });
    }

    renderSuggestions(target) {

        DataProvider.getSuggestions((response) => {
            if (!response.isOk()) {
                console.error("cannot load suggestions");
                return;
            }
            var suggestions = response.getData();
            if (suggestions.suggestedLeagues && suggestions.suggestedLeagues.length) {
                for (var i = 0; i < suggestions.suggestedLeagues.length; i++) {

                    var league = suggestions.suggestedLeagues[i];

                    if (!league.suggestedTeams.length) continue;

                    var resultP = new Panel();
                    resultP.addCssClass("suggestionsItemTitle");
                    resultP.addControl(league.leagueName);
                    target.addControl(resultP);

                    for (var j = 0; j < league.suggestedTeams.length; j++) {
                        if (i > 0 && j > 5) continue;
                        var team = league.suggestedTeams[j];
                        this.renderTeamItem(team, target, Favorites.getFavoriteTeams()[team.id_gs]);
                    }
                }
            }
            if (suggestions.suggestedOtherLeagues && suggestions.suggestedOtherLeagues.length) {
                for (var i = 0; i < suggestions.suggestedOtherLeagues.length; i++) {

                    var league = suggestions.suggestedOtherLeagues[i];

                    if (!league.suggestedTeams.length) continue;

                    var resultP = new Panel();
                    resultP.addCssClass("suggestionsItemTitle");
                    resultP.addControl(league.leagueName);
                    target.addControl(resultP);

                    for (var j = 0; j < league.suggestedTeams.length; j++) {
                        var team = league.suggestedTeams[j];
                        this.renderTeamItem(team, target, Favorites.getFavoriteTeams()[team.id_gs]);
                    }
                }
            }
            target.invalidateDisplay();
        });
    }

    renderSearchResult(data, target) {
        this.renderTeamItem(data, target, Favorites.getFavoriteTeams()[data.id_gs]);
    }

};
