import {DataProvider}  from "../data/dataprovider.js";
import {DefaultTranslations}  from "../translations/default_translations.js";

export const Localizer = {

    DefaultTranslations : DefaultTranslations,
    LocalizedTranslations : null,

    getString : function(str){

        // console.log("get str",str);
        var key = str.replace(/\s|'/g,"_");
        if (!this.LocalizedTranslations){
            console.error("no localizations found");
            return this.DefaultTranslations[key] ? this.DefaultTranslations[key] : str;
        }
        // if (!this.DefaultTranslations){
        //     console.error("no default translations found" ,str);
        //     return str;
        // }

        try {
            return this.LocalizedTranslations[key] ? this.LocalizedTranslations[key]
                : this.DefaultTranslations[key] ? this.DefaultTranslations[key] : str;
        }
        catch (e){
            console.error("error getting string",str,e);
            return str;
        }
    },

    hasString : function(key){
        return this.LocalizedTranslations && this.LocalizedTranslations[key];
    },

    loadLocale : function(locale,cb){

        if (AppInstance.getConfig().allowableLocales.indexOf(locale)== -1){
            console.log("locale",locale,"not supported");
            locale = AppInstance.getConfig().defaultLocale;
        }

        DataProvider.getTranslations(locale,(response)=>{
            if (response.isOk()){
                console.log("locale "+locale+" loaded succesfully");
                this.LocalizedTranslations = response.getData();
                return cb(true);
            }

            cb(false);
        });
    },

    getLoadedLocale : function(){
        return this.LocalizedTranslations;
    }
};