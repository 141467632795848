import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {Image} from "../../bananaframework/src/controls/Image.js";

import {Modal} from "../modal.js";
import {Localizer} from "../../util/localizer.js";
import {CountryUtil} from "../../util/country.js";
import {ManiaPreferences} from "../../util/preferences.js";

export class LanguageSelectModal extends Modal {

    init(urlIdentifier, data) {
        super.init(urlIdentifier);
        this.data = data;
    }

    reValidateSize() {
        var windowHeight = $(window).height();
        //this.mainPanel.setCss({"margin-top":Math.max(0,(((windowHeight-height)/2)))})
        this.mainPanel.setCss({"margin-top": 50 + "px"})
        this.scrollPanel.setCss({"height": windowHeight + "px"});
        this.mainPanelWrapper.setCss({"height": windowHeight - 150 + "px"});
    }

    createComponents() {

        super.createComponents();

        var dim = this.getPage().getDimensions();

        var p = new Panel();
        p.setStyle("height:" + (dim.height - 200) + "px;justify-content: flex-start;align-items: flex-start;overflow-y:scroll;");
        // p.addCssClass("flexVerticalAlign");

        var title = new Panel();
        title.addCssClass("addFavoriteModalTitle");
        title.addControl(Localizer.getString("select_news_from_country"));
        this.addControl(title);

        this.addControl(p)
        this.setVisible(false);

        setTimeout(() => {
            this.setVisible(true);
            this.reValidateSize();
        }, 50);

        //
        var selectedColor = "#285a99";


        for (var i = 0; i < AppInstance.getConfig().availableNewsLocales.length; i++) {
            var lang = AppInstance.getConfig().availableNewsLocales[i];
            this.renderLanguageItem(lang, p)
        }

        var title = new Panel();
        title.setStyle("font-size: 11px;margin-top: 10px;margin-bottom: 10px;");
        title.addControl(Localizer.getString("description_add_news_source"));
        this.addControl(title);
    }

    renderLanguageItem(lang, target) {

        var mainContainer = new Panel();
        mainContainer.addCssClass("flexHorizontalAlign");
        mainContainer.setStyle("width:100%;height:45px;border-bottom: 1px solid gray;cursor:pointer;");

        var languageName = new Panel();
        languageName.addControl(CountryUtil.getCountryByLocale(lang == "en" ? "uk" : lang, AppInstance.getConfig().userLocale));
        languageName.setStyle("margin-inline-start: 10px;align-self: center;")

        var flagsAndSelector = new Panel();
        flagsAndSelector.addCssClass("flexHorizontalAlign");

        var flagContainer = new Panel();
        flagContainer.addCssClass("flexHorizontalAlign");

        var country = lang == "en" ? "united kingdom" : CountryUtil.getCountryByLocale(lang, "EN");
        var flag = new Image();
        flag.setStyle("width:40px;height:40px;margin-inline-end:4px;")
        flag.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + country.replaceAll(" ", "-").toLowerCase() + ".png", 30, 30);
        flagContainer.addControl(flag);

        var selector = new Panel();
        selector.setStyle("background-color:red;width:40px;height:40px;margin-inline-start: 10px;align-self: center;");

        flagsAndSelector.addControl(flagContainer);
        //flagsAndSelector.addControl(selector);

        mainContainer.addControl(languageName);
        mainContainer.addControl(flagsAndSelector);
        target.addControl(mainContainer);

        mainContainer.bind("click", this.getProxy(function () {
            ManiaPreferences.setUserNewsLocale(lang);
            navigation.back()
        }));
    }
};