
//this control runs in combination with collapser (topbar) and tabiew
// when target scroller scrolls down it collapses the topbar and tabview tabs.
export const AnimatorAgenda = function(){

    var version = "1.0";

    var tabs = [];
    var scrollerMethods = [];
    var touchStartMethod = null;
    var touchEndMethod = null;
    var collapser = null;
    var tabview = null;
    var ignoreEvents = false;
    var ignoreEventForIndex = [];
    var lastListIndexScrolling = -1
    var unloaded = false;

    this.isTouching = false;
    this.reducerRatio = 0.3;
    this.transitionDuration = 100;
    this.alwaysCollapseOnBackScrolling = true;
    this.isCollapsed = false;
    this.lastRecalculateWasCollapsed = null;
    this.isVirtuallyCollapsed = false;
    this.lastScrollPositionAfterTouchStart = -1;
    this.lastYOffsetAfterTouchStart = 0;
    this.currentTranslateY = 0;
    this.currentActiveTab = -1;
    this.onCollapseChanged = null;

    this.addScrollerContainer = function(tab){
        tabs.push(tab);
    }

    this.addCollapser = function(c){
        collapser = c;
    }

    this.addTabView = function(tab){
        tabview = tab;
    }

    this.unload = function(){
        unloaded = true;
        if (collapser) {
            collapser.setCss({"transform": "translate(0px,0px)", "transition-duration": "0ms"}) //reset
        }
        if (tabs) {
            for (var i = 0; i < tabs.length; i++) {
                var scrollEl = jQuery("#" + tabs[i].getClientId());

                if (scrollerMethods[i]) {
                    scrollEl.off("scroll", scrollerMethods[i]);
                }
            }
        }

        $('body').off({
            'touchstart': touchStartMethod,
            'touchend' : touchEndMethod});

        collapser = null;
        tabs = null;
    }

    this.onWindowResize = function(){
        this.recalculate(true);
    }

    this.tabChange = function(){

        if (!FootballManiaConfig.graphics.animations) return;

        var delay =300;
        var that =this;
        $("#"+collapser.getClientId()).css({"transform":"translate(0px,0px)","transition-duration": delay+"ms"});
        $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,0px)","transition-duration": delay+"ms"});

        this.currentTranslateY = 0;
        this.isVirtuallyCollapsed = false; //set this to false, because we set all scroll positions to 0 so everything becomes decollapsed
        this.isCollapsed = false;
        setTimeout(function(){
            that.recalculate();
        },delay)

        for (var i =0; i < tabview.tabContents.controls.length;i++) {
            try {
                tabview.tabContents.controls[i].controls[1].controls[0].controls[0].controls[0].scrollTo(0);
            }
            catch (e) {
                console.error("cannot recalculate no scroll container found");
            }
        }
    }

    this.setScrollerBottomMargin = function(margin){
        this.scrollerBottomMargin = margin;
    }

    this.recalculateTab = function(i){

        if (!FootballManiaConfig.graphics.animations) return;

        if (!tabs){
            console.warn("cannot recalcuate without tabs");
            return;
        }
        if (!tabview || !tabview.tabContents || !tabview.tabContents.controls[i] || !tabview.tabContents.controls[i].controls[1] || !tabview.tabContents.controls[i].controls[1].controls[0]){
            console.warn("cannot recalcuate without tabview elements");
            return;
        }

        var contentWindowHeight = tabview.tabContents.getDimensions().height;
        var listviewContentHeight = tabview.tabContents.controls[i].controls[1].controls[0].controls[0].controls[0].getDimensions().height;

        if (collapser) {
            var collapserHeight = collapser.getDimensions().height;
        }
        var scrollEl = jQuery("#"+this.activeScrollContainer.getClientId());
        var top  = collapserHeight;

        if (!scrollEl || !scrollEl[0]){
            console.error("some error with finding scroller");
            return;
        }


        var tab = tabview.tabContents.controls[i]
       // console.log(i,"RECALCULATE TAB ",i,"scrollheight",scrollEl[0].scrollHeight,"contentheight",contentWindowHeight,"listview height",listviewContentHeight,this.scrollerBottomMargin)

        var extraMargin = 0;
        if (this.scrollerBottomMargin){
            //console.log(tabview.currentActiveTab,"extra margin",this.scrollerBottomMargin)
            extraMargin = this.scrollerBottomMargin;
        }

        $("#" + tab.getClientId()).css({"top": "-" +top+"px"});
        $("#" + tab.getClientId()).css({"height":"calc(100% + "+(top-extraMargin)+"px)"});
        //$("#" + tab.getClientId()).css({"height": contentWindowHeight + collapserHeight + "px"});

        $("#" + tab.controls[1].controls[0].controls[0].getClientId()).css({
            "position": "relative",
            "top": top+"px",
            "height" : ""
        });



        //
        // if (!$("#"+tab.controls[1].controls[0].getClientId()).isVScrollable()){
        //     console.log(i,"content is not scrollable");
        //     // $("#" + tab.getClientId()).css({"top": "-" +top+"px"});
        //     // //$("#" + tab.getClientId()).css({"height": contentWindowHeight + collapserHeight + "px"});
        //     //
        //     // $("#" + tab.controls[1].controls[0].controls[0].getClientId()).css({
        //     //     "position": "relative",
        //     //     "top": top+"px",
        //     //     "height" : contentWindowHeight+collapserHeight+"px"
        //     // });
        //     //
        //
        //     // $("#" + tab.getClientId()).css({"box-sizing": "border-box"});
        //     //$("#" + tab.getClientId()).css({"top": "-" +top+"px"});
        //     //$("#" + tab.controls[1].controls[0].controls[0].getClientId()).css({"margin-top": "+" + top + "px", "height": "100%"});
        //     //$("#" + tab.parent.getClientId()).css({"height":"calc(100% + "+top+"px)"});
        // }
        // else{
        //     $("#" + tab.getClientId()).css({"top": "-" +top+"px"});
        //     //$("#" + tab.getClientId()).css({"height": contentWindowHeight + collapserHeight + "px"});
        //
        //     $("#" + tab.controls[1].controls[0].controls[0].getClientId()).css({
        //         "position": "relative",
        //         "top": top+"px",
        //         "height" : ""
        //     });
        //
        //
        //
        //     $("#" + tab.getClientId()).css({"box-sizing": "border-box"});
        //     //$("#" + tab.getClientId()).css({"top": "-" +top+"px"});
        //     // $("#" + tab.controls[1].controls[0].controls[0].getClientId()).css({"margin-top": "+" + top + "px", "height": ""});
        //     // $("#" + tab.parent.getClientId()).css({"height":"calc(100% + "+top+"px)"});
        //
        //     console.log(i,"content is scrollable",this.isCollapsed);
        // }
        //
        //
        //
        // return;
        // //when scrolllist is longer than the window (we can acualy scroll)
        // if (listviewContentHeight > contentWindowHeight) {
        //     $("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "-" +top+"px"});
        //     $("#" + tabview.tabContents.controls[i].getClientId()).css({"height": contentWindowHeight + collapserHeight + "px"});
        //
        //     $("#" + tabview.tabContents.controls[i].controls[1].controls[0].controls[0].getClientId()).css({
        //         "position": "relative",
        //         "top": top+"px",
        //         "height" : contentWindowHeight+collapserHeight+"px"
        //     });
        //     //}
        //     $("#" + tabview.tabContents.controls[i].controls[0].getClientId()).css({"height": contentWindowHeight + collapserHeight + "px"});
        //     //enlarge the scrollbar
        // }
        // else{
        //     //console.log("no nonon ono no");
        //    //console.log("tab ",i,' content is not needed for scroll',contentWindowHeight)
        //     if (this.isCollapsed){
        //         //$("#" + tabview.tabContents.controls[i].getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "100ms"});
        //         //$("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "-" +top+"px"});
        //     }
        //     else{
        //         //$("#" + tabview.tabContents.controls[i].getClientId()).css({"transform":"translate(0px,0px)","transition-duration": "100ms"})
        //         $("#" + tabview.tabContents.controls[i].getClientId()).css({"top": "0px"});
        //         //$("#" + tabview.tabContents.controls[i].getClientId()).css({"height": contentWindowHeight + "px"});
        //
        //         if (scrollEl.scrollTop() == 0){
        //             $("#" + tabview.tabContents.controls[i].controls[1].controls[0].controls[0].getClientId()).css({
        //                 "position": "absolute",
        //                 "top": "0px",
        //             });
        //         }
        //         else{
        //
        //         }
        //     }
        // }
    }

    this.recalculate = function(force){

        //return;
        if (!FootballManiaConfig.graphics.animations) return;

        if (!tabview){
            //console.log("cannot recalculate without tabview");
            return;
        }

        console.log("RECALCULATE POSITIONS",tabview.currentActiveTab,"isCollapsed",this.isCollapsed);

        if (!force && this.lastRecalculateWasCollapsed!= null && this.isCollapsed == this.lastRecalculateWasCollapsed){
            //console.log("dont repeat calculation");
            return;
        }
        this.lastRecalculateWasCollapsed = this.isCollapsed;
        var collapserHeight = collapser.getDimensions().height;

        if (this.isCollapsed){
            $("#"+collapser.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
        }
        for (var i =0; i < tabview.tabContents.controls.length;i++) {

            if (!tabview.tabContents.controls[i].controls[1].controls[0]){
                console.error("cannot recalculate tab without list");
                continue;
            }

            this.recalculateTab(i);
        }
    }

    this.autoCollapseOrOpen = function(scrollPosition){

        if (this.isAutoCollapsing) return;

        this.isAutoCollapsing = true;

        if (this.currentTranslateY == 0){
            this.isCollapsed = false;
            this.isVirtuallyCollapsed = false;
        }
        else if (this.currentTranslateY == -collapser.getDimensions().height){
            this.isCollapsed = true;
            this.isVirtuallyCollapsed = true;
        }

        if (this.currentTranslateY == 0 || this.currentTranslateY == -collapser.getDimensions().height){
            //console.log("no auto collapse when already open or closed",this.currentTranslateY);
            this.recalculate();
            this.isAutoCollapsing = false;
            return;
        }
        var collapserHeight = collapser.getDimensions().height;
        var that = this;
        var delay = 300;

        if ((scrollPosition) > (collapserHeight * (1/this.reducerRatio))/2){
            $("#"+collapser.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": delay+"ms"});
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": delay+"ms"});

            that.currentTranslateY = -collapserHeight;
            this.isCollapsed = true;
            this.isVirtuallyCollapsed = true;
            this.onCollapseChanged();
            clearTimeout(this.tempTimeout);
            this.tempTimeout = setTimeout(function(){
                that.currentTranslateY = -collapserHeight;
                that.recalculate();
                that.isAutoCollapsing = false;
            },delay)

        }
        else {
            $("#"+collapser.getClientId()).css({"transform":"translate(0px,0px)","transition-duration": delay+"ms"});
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,0px)","transition-duration": delay+"ms"});

            that.currentTranslateY = 0;
            this.isCollapsed = false;
            this.isVirtuallyCollapsed = false;
            this.onCollapseChanged();
            clearTimeout(this.tempTimeout);
            this.tempTimeout = setTimeout(function(){
                //that.currentTranslateY = 0;
                that.recalculate();
                that.isAutoCollapsing = false;
            },delay)
        }
    }

    this.prepare = function(){
        if (!FootballManiaConfig.graphics.animations) return;

        var collapserHeight = collapser.getDimensions().height;

        //this.isVirtuallyCollapsed = false;
        //make sure we start with a collapsed one before everything gets rendered. causes flickering
        if (this.isCollapsed){
            this.isVirtuallyCollapsed = true;
            $("#"+collapser.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
            $("#"+tabview.tabHolder.getClientId()).css({"transform":"translate(0px,"+-collapserHeight+"px)","transition-duration": "0ms"});
        }
    }

    this.setActiveScrollContainer = function(active){
        if (this.activeScrollContainer){
            var scrollEl = jQuery("#"+this.activeScrollContainer.getClientId());
            scrollEl.off("scroll",this.scrollMethod);
        }
        this.activeScrollContainer = active;
    }

    this.run = function(){

        if (!FootballManiaConfig.graphics.animations) return;

        console.log("RUN ANIMATOR FOR AGENDA "+version);
        lastListIndexScrolling = tabview.currentActiveTab;

        var that = this;
        var timeoutHandler = null;
        var timeoutHandler2 = null;

        $('body').off({
            'touchstart': touchStartMethod,
            'touchend' : touchEndMethod});

        touchStartMethod = function (e) {
            clearTimeout(timeoutHandler2);
            clearTimeout(timeoutHandler);
            clearTimeout(that.tempTimeout);
            that.isTouching = true;

            //var scrollElement = tabs[tabview.currentActiveTab];
            var scrollElement = that.activeScrollContainer;
            var topOffset = document.querySelector("#" + scrollElement.getClientId()).scrollTop
            that.lastScrollPositionAfterTouchStart = topOffset;
            that.lastYOffsetAfterTouchStart = that.currentTranslateY;
        };

        touchEndMethod = function(){
            that.isTouching = false;
        }

        $('body').on({
            'touchstart': touchStartMethod,
            'touchend' : touchEndMethod});

        var scrollEl = jQuery("#"+this.activeScrollContainer.getClientId());
        scrollEl.scroll(this.scrollerMethod);
        jQuery("#"+this.activeScrollContainer.getClientId()).on("scroll",this.scrollMethod)

        if (scrollEl[0]) {
            this.recalculate();
        }
    }

    var prevScroll = -1;
    var that = this;

    this.scrollMethod = function(e){

        if (that.ticking) return;
        var index = 0;
        var scrollDiff;

        window.requestAnimationFrame(function() {

            that.ticking = true;

            var topOffset = e.originalEvent.target.scrollTop;
            if (prevScroll == topOffset) {
                that.ticking = false;
                // console.log("do nothing no scroll change");
                return;
            }
            lastListIndexScrolling = index;
            clearTimeout(that.timeoutHandler2);

            that.timeoutHandler2 = setTimeout(function () {
                clearTimeout(that.timeoutHandler);
                var topOffset = e.originalEvent.target.scrollTop;
                if (!that.isTouching) {
                    that.autoCollapseOrOpen(topOffset);
                }
            }, 250);

            var topOffset = e.originalEvent.target.scrollTop;
            var collapserHeight = collapser.getDimensions().height;

            if (!that.alwaysCollapseOnBackScrolling) {
                scrollDiff = Math.max(-topOffset, -collapserHeight);

            } else {

                if (that.isCollapsed && that.isVirtuallyCollapsed) {

                    var t = ((that.lastScrollPositionAfterTouchStart - topOffset) * that.reducerRatio) - collapserHeight;
                    scrollDiff = Math.max(-collapserHeight, Math.min(0, t));
                } else {
                    if (that.lastScrollPositionAfterTouchStart !=-1) {
                        var t = ((that.lastScrollPositionAfterTouchStart - topOffset) * that.reducerRatio) + that.lastYOffsetAfterTouchStart;
                        scrollDiff = Math.min(0, Math.max(-collapserHeight, t));
                    }
                    else{
                        //first time otherwise we always have collapsed after startng
                        scrollDiff = 0;
                    }
                }
            }

            that.currentTranslateY = scrollDiff;

            ///console.log("scroll event ",index,scrollDiff,that.isCollapsed)

            //this virtualcollapsed value is a little different than iscollapsed
            //when user is collapsing and no touchend event is fired then the state of
            //iscollapsed remains the same. the virtualcollapsed one changes
            //this is needed in the recalculation of positions
            if (scrollDiff == -collapserHeight) {
                that.isVirtuallyCollapsed = true;
            } else {
                that.isVirtuallyCollapsed = false;
            }

            //collapser.setCss({"transform":"translate(0px,"+scrollDiff+"px)","transition-duration": this.transitionDuration+"ms"});
            collapser.setCss({
                "transform": "translate(0px," + scrollDiff + "px)",
                "transition-duration": that.transitionDuration + "ms"
            });
            //tabview.tabHolder.setCss({"transform":"translate(0px,"+scrollDiff+"px)","transition-duration": this.transitionDuration+"100ms"});
            tabview.tabHolder.setCss({
                "transform": "translate(0px," + scrollDiff + "px)",
                "transition-duration": that.transitionDuration + "ms"
            });

            prevScroll = e.originalEvent.target.scrollTop;
            that.ticking = false;
        });
    }
}